import React from 'react';

const WeRadio = ({ ...props }) => {
  const { innerRef, onChange, label, checked, id, htmlFor } = props;
  return (
    <>
      <div>
        <input
          ref={innerRef}
          checked={checked}
          className="form-check-input"
          type="radio"
          id={id}
          onChange={(e) => {
            let checked = e.target.checked;
            onChange(checked);
          }}
        />
        <label className="form-check-label" htmlFor={htmlFor}>
          {label}
        </label>
      </div>
    </>
  );
};
export default React.memo(WeRadio);
