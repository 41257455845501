import WeInput from 'components/weInput';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const AddRoomDialog = ({ ...props }) => {
  const { showDialog, onDialogClose, btnConfirm, onRoomInputChange, formScheduleData } = props;
  return (
    <Modal show={showDialog} centered>
      <Modal.Header>
        <Modal.Title>
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5"> Add Room </h1>
            <a
              className="close closeBtn"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onDialogClose}>
              <i className="fa-solid fa-xmark"></i>
            </a>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Submit room for event.</p>
        <WeInput
          onChange={onRoomInputChange}
          inputLabel=" Room Title"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              btnConfirm();
            }
          }}
          autoFocus={true}
          formError={formScheduleData.roomTitleError}
          value={formScheduleData.roomTitle ?? ''}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn secondary-btn" onClick={onDialogClose}>
          Close
        </Button>
        <Button className="btn primary-btn" variant="primary" onClick={btnConfirm}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(AddRoomDialog);
