import WeButton from 'components/button';
import WeInput from 'components/weInput';
// import WeRadio from 'components/weRadio';
import WeTextArea from 'components/weTextArea';
import WeToggle from 'components/weToggle';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addAttendeeInfoSave, eventCheckOutForm, getEventCheckOutForm } from 'store/slice/options';
// import AttendeeInfo from './subComponents/attendeeInfo';
// import AttendeeInfoTicket from './subComponents/attendeeInfoTicket';
import CustomInfo from './subComponents/customInfo';

const CheckoutForm = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { checkOutFormData } = useSelector((state) => state.options);
  const [checkOutValues, setCheckOutValues] = useState({
    eventUid: id,
    attendeeInfo: checkOutFormData?.attendeeInfoData,
    informationFrom: 'buyer',
    ticketIds: [],
    attendeeQuestions: [],
    isRegistrationTime: false,
    registrationTimeLimit: 0,
    isRefund: false,
    refundMessage: '',
    checkOutMessage: ''
  });
  useEffect(() => {
    dispatch(getEventCheckOutForm({ eventUid: id }));
  }, []);

  useEffect(() => {
    if (checkOutFormData) {
      setCheckOutValues(checkOutFormData);
      dispatch(addAttendeeInfoSave(true));
    }
  }, [checkOutFormData]);

  useEffect(() => {
    if (checkOutValues) {
      dispatch(addAttendeeInfoSave(false));
    }
  }, [checkOutValues]);

  const updateCheckoutForm = () => {
    checkOutValues.eventUid = id;
    dispatch(eventCheckOutForm(checkOutValues));
  };

  return (
    <>
      <form action="">
        <h2>Checkout Form</h2>
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

        <div className="mt-3">
          {/* <h4>Collect information from:</h4>

          <div className="mb-4">
            <div className="form-check mt-3">
              <WeRadio
                checked={checkOutValues.informationFrom == 'buyer' ? true : false}
                label="Buyer"
                onChange={(checked) => {
                  setCheckOutValues((checkOutValues) => ({
                    ...checkOutValues,
                    informationFrom: checked ? 'buyer' : ''
                  }));
                  // checkOutValues.informationFrom="buyer";
                  // updateCheckoutForm();
                }}
              />
            </div>
            <div className="form-check">
              <WeRadio
                checked={checkOutValues.informationFrom == 'attendee' ? true : false}
                label="Each Attendee"
                onChange={(checked) => {
                  setCheckOutValues((checkOutValues) => ({
                    ...checkOutValues,
                    informationFrom: checked ? 'attendee' : ''
                  }));
                }}
              />
            </div>
          </div>
          <div className="mb-2">Collect attendee information on the following tickets</div> */}

          {/* <AttendeeInfoTicket
            id={id}
            ticketIdsData={checkOutValues.ticketIds}
            func={(data) => {
              // checkOutValues.ticketIds = data;
              setCheckOutValues((checkOutValues) => ({
                ...checkOutValues,
                ticketIds: data
              }));
            }}
          />
          <hr /> */}

          {/* <AttendeeInfo
            id={id}
            attendeeInfo={checkOutValues.attendeeInfo}
            updatedAttendeeItems={(data) => {
              setCheckOutValues((checkOutValues) => ({
                ...checkOutValues,
                attendeeInfo: data
              }));
            }}
          /> */}

          <CustomInfo
            id={id}
            attendeeQuestions={checkOutValues.attendeeQuestions}
            func={(data) => {
              setCheckOutValues((checkOutValues) => ({
                ...checkOutValues,
                attendeeQuestions: data
              }));
            }}
          />
          <hr />
          <h4>Registration Time Limit</h4>
          <p className="mb-0">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.
          </p>

          <div className="mt-2">
            <WeToggle
              checkToogle={checkOutValues && checkOutValues.isRegistrationTime}
              onChange={(checked) => {
                setCheckOutValues((checkOutValues) => ({
                  ...checkOutValues,
                  isRegistrationTime: checked
                }));
              }}
              onSwitchClick={() => {
                // checkOutValues.isRegistrationTime = !checkOutValues.isRegistrationTime;
                setCheckOutValues((checkOutValues) => ({
                  ...checkOutValues,
                  isRegistrationTime: !checkOutValues.isRegistrationTime
                }));
              }}
              toggleLabel="Registration Time Limit"
            />
          </div>
          <div className="row">
            <div className="col-12 col-xl-7">
              <WeInput
                type={'number'}
                minVal={0}
                onChange={(val) => {
                  setCheckOutValues((checkOutValues) => ({
                    ...checkOutValues,
                    registrationTimeLimit: val
                  }));
                  //checkOutValues.registrationTimeLimit = val;
                }}
                inputLabel="Time Limit (In Mins)"
                value={checkOutValues.registrationTimeLimit}
              />
            </div>
          </div>
          <hr />
          <h4>Refunds</h4>
          <p className="mb-0">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.
          </p>
          <div className="mt-2">
            <WeToggle
              checkToogle={checkOutValues && checkOutValues.isRefund}
              onChange={(checked) => {
                setCheckOutValues((checkOutValues) => ({
                  ...checkOutValues,
                  isRefund: checked
                }));
              }}
              onSwitchClick={() => {
                // checkOutValues.isRefund = !checkOutValues.isRefund;
                setCheckOutValues((checkOutValues) => ({
                  ...checkOutValues,
                  isRefund: !checkOutValues.isRefund,
                  refundMessage : ''
                }));
              }}
              toggleLabel="Accepting Refunds"
            />
          </div>
          {checkOutValues.isRefund ? (
            <div className="row">
              <div className="col-12 col-xl-7">
                <WeTextArea
                  maxRows={4}
                  maxLength={150}
                  onChange={(val) => {
                    setCheckOutValues((checkOutValues) => ({
                      ...checkOutValues,
                      refundMessage: val
                    }));
                  }}
                  inputLabel="Custom Refund Message"
                  value={checkOutValues.refundMessage ?? ''}
                />
                {/* <WeButton className="mt-5" text="Save" /> */}
              </div>
            </div>
          ) : (
            ''
          )}

          <hr />
          <h4>Custom Checkout Message</h4>
          <p className="mb-0">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.
          </p>
          <div className="row">
            <div className="col-12 col-xl-7">
              <WeTextArea
                maxRows={4}
                maxLength={150}
                onChange={(val) => {
                  setCheckOutValues((checkOutValues) => ({
                    ...checkOutValues,
                    checkOutMessage: val
                  }));
                }}
                inputLabel="Custom Message"
                value={checkOutValues.checkOutMessage ?? ''}
              />

              {/* {checkOutValues.checkOutMessage?.length > 0 ? (
                <WeButton className="mt-5" text="Save" />
              ) : (
                ''
              )} */}
            </div>
          </div>

          <WeButton text="Save" onClick={updateCheckoutForm} />
        </div>
      </form>
    </>
  );
};

export default CheckoutForm;
