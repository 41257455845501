export const fedTaxClassData = [
  //   {
  //     label: 'Select Classification',
  //     id: 'selectClassification'
  //   },
  {
    label: 'Sole Proprietorship / LLC',
    id: 'soleProprietorshipLlc'
  },
  {
    label: 'Partnership',
    id: 'Partnership'
  },
  {
    label: 'C Corp',
    id: 'cCorp'
  },
  {
    label: 'S Corp',
    id: 'sCorp'
  },
  {
    label: 'Trust / Estate',
    id: 'trustEstate'
  },
  {
    label: 'Other',
    id: 'other'
  }
];

export const exemptPayeeCode = [
  {
    label: '1',
    id: 1
  },
  {
    label: '2',
    id: 2
  },
  {
    label: '3',
    id: 3
  },
  {
    label: '4',
    id: 4
  },
  {
    label: '5',
    id: 5
  },
  {
    label: '6',
    id: 6
  },
  {
    label: '7',
    id: 7
  },
  {
    label: '8',
    id: 8
  },
  {
    label: '1',
    id: 1
  },
  {
    label: '9',
    id: 9
  },
  {
    label: '10',
    id: 10
  },
  {
    label: '12',
    id: 12
  },
  {
    label: '13',
    id: 13
  }
];

export const stateData = [
  {
    label: 'Alabama',
    id: 'alabama'
  },
  {
    label: 'Alaska',
    id: 'alaska'
  },
  {
    label: 'Arizona',
    id: 'arizona'
  },
  {
    label: 'Arkansas',
    id: 'arkansas'
  }
];
