import WeButton from 'components/button';
import WeDropDown from 'components/weDropDown';
import WeInput from 'components/weInput';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  allTicketList,
  apiTicketFatchingStart,
  applyCoupon,
  getAttendeesData,
  handleCode
} from 'store/slice/tickets';
import SelectTicketComponent from '../subcomponent/selectTicketComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import EmptyView from 'components/emptyView';
import { selectValue } from 'utils/appConstant';
import { eventDetail, eventSelectedActiveMenu } from 'store/slice/event';
import { toast } from 'react-toastify';
import SpinnerComponent from 'components/spinnerComponent';

const AddAttendees = (props) => {
  const { func } = props;
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const validCoupon = (e) => [' '].includes(e.key) && e.preventDefault();

  const {
    ticketListData,
    CouponData,
    codeData,
    totalPrice,
    totalFair,
    errorData,
    attendeeTicketData,
    isFetching,
    isCouponDataFetching
  } = useSelector((state) => state.tickets);
  const { eventDetailData } = useSelector((state) => state.event);
  const [paidBy, setPaidBy] = useState('');
  const [paidByErr, setPaidByErr] = useState('');
  // const [couponDiscount, setCouponDiscount] = useState('');
  const [couponCode, setCouponCode] = useState('');
  // const [codeData, setCodeData] = useState('');
  // const [totalPriceValue, setTotalPriceValue] = useState('');
  const [totalErr, setTotalErr] = useState('');
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    if (id) {
      const requestParam = {
        eventUid: id
      };
      dispatch(apiTicketFatchingStart(true));
      dispatch(allTicketList(requestParam));
      dispatch(eventDetail(id));
    }
  }, [id]);

  // useEffect(() => {
  //   if (CouponData) {
  //     if (CouponData.type == 'percent') {
  //       setCouponDiscount(CouponData.discount / 100);
  //     } else {
  //       setCouponDiscount(CouponData.discount);
  //     }
  //   }
  // }, [CouponData]);

  // useEffect(() => {
  //   if (totalPrice) {
  //     setTotalPriceValue(totalPrice - couponDiscount);
  //   }
  // }, [totalPrice]);

  useEffect(() => {
    setSpinner(false);
  }, [ticketListData]);

  useEffect(() => {
    if (codeData.length > 0) {
      handleApplyCode();
    }
  }, [attendeeTicketData]);

  useEffect(() => {
    if (codeData.length > 0) {
      handleApplyCode();
    }
  }, [codeData]);

  useEffect(() => {
    fetchAllTickets();
  }, []);

  const fetchAllTickets = () => {
    const data = {
      eventUid: id
    };
    dispatch(allTicketList(data));
  };
  const paidByData = [
    {
      label: 'Cash',
      id: 'cash'
    },
    {
      label: 'Check',
      id: 'check'
    },
    {
      label: 'Paypal',
      id: 'paypal'
    },
    {
      label: 'Stripe',
      id: 'stripe'
    },
    {
      label: 'Credit Card',
      id: 'creditCard'
    },
    {
      label: 'Debit Card',
      id: 'debitCard'
    },
    {
      label: 'Free',
      id: 'free'
    },
    {
      label: 'Other',
      id: 'other'
    }
  ];

  const handleSubmit = () => {
    if (!paidBy) {
      setPaidByErr('Please Select Mode Of Payment');
      return;
    }

    let count = 0;
    errorData?.map((data) => {
      if (data) {
        count = count + 1;
      }
    });
    if (count > 0) {
      toast.error('Ticket quentity is more then available ticket');
      return;
    }

    let count1 = 0;
    attendeeTicketData?.map((data) => {
      if (data.quantity > 0) {
        count1 = count1 + 1;
      }
    });
    if (count1 == 0) {
      toast.error('Please select ticket');
      return;
    }

    const attendeesData = {
      eventUid: id,
      paidBy: paidBy,
      couponName: couponCode,
      ticket: attendeeTicketData,
      CouponData: CouponData,
      total: totalPrice
    };
    dispatch(getAttendeesData(attendeesData));
    func();
  };

  const handleAddTicket = () => {
    navigate(`/event/${id}/edit/tickets`);
    dispatch(eventSelectedActiveMenu('tickets'));
  };

  const handlePublish = () => {
    navigate(`/event/${id}/edit/publish`);
    dispatch(eventSelectedActiveMenu('publish'));
  };

  const handleApplyCode = () => {
    const arrayOfTicketData = [];
    let count = 0;
    attendeeTicketData.map((item) => {
      if (item.quantity > 0) {
        const ticketData = {
          ticketId: item.ticketItem.id,
          quantity: item.quantity,
          amount: item.unitPrice
        };
        arrayOfTicketData.push(ticketData);
        count = count + 1;
      }
    });
    if (count > 0) {
      let data = {
        eventUid: id,
        ticketIds: arrayOfTicketData,
        promoCodeArr: codeData,
        amount: totalFair
      };
      dispatch(applyCoupon(data));
    }
  };

  console.log("totalPrice && totalFair  == ",totalPrice , totalFair );

  return isFetching ? (
    <SpinnerComponent />
  ) : (
    <>
      <h2>Add Attendees</h2>
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

      {ticketListData?.length === 0 || ticketListData == null ? (
        <>
          <EmptyView
            text="No Tickets Created"
            message="Create Tickets for adding attendees."
            showButton={true}
            btnText={'Add Ticket'}
            onBtnClick={handleAddTicket}
          />
        </>
      ) : eventDetailData?.publishType == 'draft' ? (
        <EmptyView
          text="Event not publish"
          message="Publish your event for adding attendees."
          showButton={true}
          btnText={'Publish Event'}
          onBtnClick={handlePublish}
        />
      ) : (
        <>
          {' '}
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5">
              <WeDropDown
                label="Paid by"
                selectData={paidByData}
                defaultValue={paidBy ? selectValue(paidByData, paidBy) : ''}
                emptyValue="Select Paid by"
                formError={paidByErr}
                onSelectItem={(item) => {
                  setPaidBy(item);
                  setPaidByErr('');
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5">
              <WeInput
                className={codeData.length > 0 ? 'input-block disabled' : 'input-block'}
                disabled={codeData.length == 1}
                onChange={(val) => setCouponCode(val.toUpperCase())}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && couponCode) {
                    const data = {
                      value: couponCode,
                      type: 'add'
                    };
                    dispatch(handleCode(data));
                    setCouponCode('');
                  }
                  validCoupon(e);
                }}
                value={couponCode}
                inputLabel="Coupon Code/ On Hold"
              />
              {couponCode ? (
                <WeButton
                  text="Apply"
                  onClick={() => {
                    const data = {
                      value: couponCode,
                      type: 'add'
                    };
                    dispatch(handleCode(data));
                    setCouponCode('');
                  }}
                />
              ) : (
                ''
              )}
              {/* {couponCode ? (
                  CouponData?.status === 1 ? (
                    <span className="text-success">{CouponData?.message}</span>
                  ) : (
                    <span className="text-danger">{CouponData?.message}</span>
                  )
                ) : (
                  ''
                )} */}
              <div className="pills">
                {codeData &&
                  codeData?.length > 0 &&
                  codeData?.map((val, index) => {
                    return (
                      <>
                        <div
                          className="pill alert alert-dismissible"
                          style={{ cursor: 'pointer' }}
                          key={index}>
                          <span className="text">{val}</span>
                          <a
                            className="mark-close"
                            aria-label="Close"
                            onClick={() => {
                              const data = {
                                value: val,
                                type: 'remove'
                              };
                              dispatch(handleCode(data));
                            }}>
                            <i className="fa-solid fa-xmark"></i>
                          </a>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          {spinner ? (
            <div className="text-center">
              <Spinner as="span" size="lg" />
            </div>
          ) : (
            ''
          )}
          <SelectTicketComponent
            ticketListData={ticketListData}
            setTotalErr={setTotalErr}
            totalErr={totalErr}
            onHoldData={CouponData?.onHoldName}
          />
          <div className="row d-flex justify-content-end">
            <div className="col-12 col-md-6 col-lg-12">
              <div className="row d-flex justify-content-end">
                <div className="col-12 col-lg-12 col-xl-9">
                  <div className="row d-flex justify-content-end">
                    <div className="col-12 col-lg-12 col-xl-7">
                      <div className="block">
                        <div className="mb-3">
                          <strong style={{ fontSize: '1.1em' }}>Amount: </strong>$
                          {parseFloat(totalFair).toFixed(2)}
                        </div>{' '}
                        <div className="mb-3">
                          <strong style={{ fontSize: '1.1em' }}>Discount: </strong>$
                          {CouponData?.discountAmount && totalFair > 0
                            ? parseFloat(CouponData?.discountAmount).toFixed(2)
                            : parseFloat(0).toFixed(2)}
                        </div>
                        <h5 className="mb-3">Total</h5>
                        <div className="mt-4 mt-lg-2">
                          <WeInput
                            type="number"
                            minVal={0}
                            placeholder={'0.00'}
                            inputLabel="Sum Total"
                            className={'input-block mt-4 mt-lg-2 disabled'}
                            disabled={true}
                            value={
                              totalPrice && totalFair > 0
                                ? parseFloat(totalPrice).toFixed(2)
                                : parseFloat(0).toFixed(2)
                            }
                            prefix={'$'}
                          />
                        </div>
                        <WeButton
                          className="mt-4"
                          text={isCouponDataFetching ? 'Loading...' : 'Next'}
                          onClick={() => (!isCouponDataFetching ? handleSubmit() : '')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(AddAttendees);
