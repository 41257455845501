import AddGuestDialog from 'components/dialogs/addGuestDialog';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteGuest, getGuestList } from 'store/slice/options';
// import Dropdown from 'react-bootstrap/Dropdown';
import ConfirmDialog from 'components/dialogs/confirmDialog';
import { eventDetail, eventSelectedActiveMenu } from 'store/slice/event';
import image from '../../../../assets/img/default.jpg';
import EmptyView from 'components/emptyView';
import PageForNoPermission from 'pages/pageForNoPermission';
import PaginatioonComponent from 'components/paginatioonComponent';

const GuestList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let page = 1;
  let limit = 10;
  const [pageNo, setPageNo] = useState(page);
  const [showAddGuestDialog, setshowAddGuestDialog] = useState();
  const [showConfirmDialog, setShowConfirmDialog] = useState();
  const { guestsListData, totalData } = useSelector((state) => state.options);
  const { eventDetailData, userPermissionData, isOwnerValue } = useSelector((state) => state.event);
  const { ticketListData } = useSelector((state) => state.tickets);

  useEffect(() => {
    fetchAllTickets();
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(eventDetail(id));
    }
  }, [id]);

  const handlePagination = (data) => {
    // const page = flag == 'next' ? pageNo + 1 : pageNo - 1;
    console.log('onPageChange', data.selected + 1);
    setPageNo(data.selected + 1);
  };

  useEffect(() => {
    fetchAllTickets();
  }, [pageNo]);

  const fetchAllTickets = () => {
    const requestParam = {
      eventUid: eventDetailData ? eventDetailData.eventUid : id,
      page: pageNo,
      perPage: limit
    };
    dispatch(getGuestList(requestParam));
  };

  const deleteGuestById = (_id) => {
    dispatch(deleteGuest(_id));
  };

  const handleAddTicket = () => {
    navigate(`/event/${id}/edit/tickets`);
    dispatch(eventSelectedActiveMenu('tickets'));
  };

  return isOwnerValue || userPermissionData?.attendee.manageGuestList ? (
    <>
      <h2>Guest List</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat
        mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper
        suscipit, posuere a, pede.
      </p>

      {ticketListData?.length === 0 ? (
        <>
          <EmptyView
            text="No Tickets Created"
            message="Create Tickets for adding Guest."
            showButton={true}
            btnText={'Add Ticket'}
            onBtnClick={handleAddTicket}
          />
        </>
      ) : (
        <>
          <a
            className="btn primary-btn"
            onClick={() =>
              setshowAddGuestDialog((showAddGuestDialog) => ({
                ...showAddGuestDialog,
                show: true,
                item: null
              }))
            }>
            Add Guest
          </a>
          <div style={{ height: 'auto', minHeight: '35rem' }}>
            {guestsListData?.map((item) => {
              return (
                <>
                  <a href="#" className="item-profile dark mt-3">
                    <div
                      className="icon d-flex align-items-center"
                      onClick={() => {
                        setshowAddGuestDialog((showAddGuestDialog) => ({
                          ...showAddGuestDialog,
                          show: true,
                          item: item
                        }));
                      }}>
                      <span className="d-block p-3">
                        <i className="fa-solid fa-pen-to-square"></i>
                      </span>
                    </div>
                    <div className="profile-image">
                      <img
                        src={item?.profileImage ? item?.profileImage : image}
                        alt=""
                        style={{ height: '61px' }}
                      />
                    </div>
                    <div className="profile-content">
                      {item.userName ? (
                        <div className="name">{item.userName}</div>
                      ) : (
                        <div className="name">
                          {item.firstName} {item.lastName}
                        </div>
                      )}
                      <div className="handle">
                        {eventDetailData?.title} ({item.numberOfGuests})
                      </div>
                    </div>
                    <div className="icon d-flex align-items-center ms-auto">
                      <span
                        className="d-block p-2 pe-3"
                        onClick={() => {
                          setShowConfirmDialog((showConfirmDialog) => ({
                            ...showConfirmDialog,
                            show: true,
                            data: item._id
                          }));
                        }}>
                        <i className="fa-solid fa-trash-can"></i>
                      </span>
                    </div>
                  </a>
                </>
              );
            })}
          </div>

          {guestsListData && totalData > limit ? (
            <div className="mt-3" style={{ float: 'right' }}>
              <PaginatioonComponent
                pageCount={
                  totalData % limit === 0
                    ? Math.floor(totalData / limit)
                    : Math.floor(totalData / limit) + 1
                }
                onPageChange={handlePagination}
                pageNo={pageNo}
              />
            </div>
          ) : (
            ''
          )}
        </>
      )}

      <AddGuestDialog
        showDialog={showAddGuestDialog?.show}
        onDialogClose={() =>
          setshowAddGuestDialog((showAddGuestDialog) => ({
            ...showAddGuestDialog,
            show: false
          }))
        }
        data={showAddGuestDialog?.item}
        guestData={showAddGuestDialog?.data}
      />

      <ConfirmDialog
        showDialog={showConfirmDialog?.show}
        title={'Delete Guest'}
        message={'Are you sure you want to delete this guest?'}
        onDialogClose={() => {
          setShowConfirmDialog((showConfirmDialog) => ({
            ...showConfirmDialog,
            show: false
          }));
        }}
        btnConfirm={() => {
          deleteGuestById(showConfirmDialog.data);
          setShowConfirmDialog((showConfirmDialog) => ({
            ...showConfirmDialog,
            show: false
          }));
        }}
      />
    </>
  ) : (
    <PageForNoPermission />
  );
};

export default GuestList;
