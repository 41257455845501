import React, { useRef, useState } from 'react';
import { useOutsideAlerter } from 'utils/appConstant';
//import { useOutsideAlerter } from 'utils/appConstant';

const MultipleSelectDropDown = ({ ...props }) => {
  const {
    label,
    onSelectItem,
    selectData,
    formError,
    selectDataLength,
    emptyValue,
    defaultSelectValue
  } = props;

  const [listToggle, setListToggle] = useState(false);
  const wrapperRef = useRef(null);

  const fetchAllTickets = () => {
    setListToggle(false);
  };

  useOutsideAlerter(wrapperRef, fetchAllTickets);

  const checkSelect = (id) => {
    const ispresent = defaultSelectValue?.includes(id);
    return ispresent;
  };

  return (
    <>
      <div className="multiple-select-container" ref={wrapperRef}>
        <div
          className={listToggle ? 'select-btn open' : 'select-btn'}
          onClick={() => setListToggle(!listToggle)}>
          <span className="btn-label"> {label}</span>
          <span data-checked="0" className="btn-text">
            {selectDataLength ? selectDataLength + ' Selected' : emptyValue}
          </span>
          <span className="arrow-dwn">
            <i className="fa-solid fa-sort"></i>
          </span>
        </div>
        <ul className="list-items" style={{ zIndex: '100', maxHeight: '12rem', overflow: 'scroll' }}>
          {selectData &&
            selectData.map((item, i) => {
              const isSelected = checkSelect(item.id);
              return (
                <>
                  <li className="item" key={i}>
                    <label style={{ height: 'max-content', margin: '5px', alignItems: 'start' }}>
                      <div>
                        <input
                          className="form-check-input order-checkbox"
                          type="checkbox"
                          id={item.id}
                          checked={isSelected ? isSelected : false}
                          onClick={() => onSelectItem(item)}
                        />
                      </div>
                      <span>{item.label}</span>
                    </label>
                  </li>
                </>
              );
            })}
          {/* <WeButton text="Done" onClick={() => setListToggle(false)} style={{ float: 'right' }} /> */}
        </ul>
      </div>
      {formError && <div style={{ color: 'red' }}>{formError}</div>}
    </>
  );
};
export default React.memo(MultipleSelectDropDown);
