import AddTeamMemberDialog from 'components/dialogs/addTeamMemberDialog';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import image from '../../../../../assets/img/default.jpg';
import ConfirmDialog from 'components/dialogs/confirmDialog';
import { useParams } from 'react-router-dom';
import { deleteTeamMember } from 'store/slice/options';
import { Spinner } from 'react-bootstrap';
import PaginatioonComponent from 'components/paginatioonComponent';
const TeamList = ({ ...props }) => {
  const { isFetching, totalData, handlePagination, limit, pageNo } = props;

  const [showAddTeamMemberDialog, setshowAddTeamMemberDialog] = useState(false);
  const { teamMemberList } = useSelector((state) => state.options);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [memberId, setMemberId] = useState();
  const handleDelete = () => {
    let requestParam = {
      eventUid: id,
      teamMemberId: memberId?.id,
      invitationStatus: memberId?.status
    };
    dispatch(deleteTeamMember(requestParam));
    setShowConfirmDeleteDialog(false);
  };
  return (
    <>
      <ConfirmDialog
        message={'Are you sure, you want to remove this member?'}
        showDialog={showConfirmDeleteDialog}
        onDialogClose={() => {
          setShowConfirmDeleteDialog(false);
        }}
        btnConfirm={handleDelete}
      />
      <div className="col-12 col-lg-6">
        <div className="company-team-info-overview">
          <h4>My Team</h4>
          <div style={{ height: 'auto', minHeight: '35rem' }}>
            {teamMemberList?.map((data, index) => {
              return (
                <a href="#" className="item-profile dark mt-3" key={index}>
                  {/* <div className="icon d-flex align-items-center">
                  <span className="d-block p-3">
                    <i className="fa-solid fa-pen-to-square"></i>
                  </span>
                </div> */}
                  <div className="profile-image">
                    <img src={data?.profileImage ? data?.profileImage : image} alt="" />
                  </div>
                  <div className="profile-content">
                    <div className="name">{data?.userName ? data?.userName : data?.userEmail}</div>
                    <div className="handle">{data?.roleData}</div>
                    <div className="handle">
                      <b>Status:- </b>
                      {data?.invitationStatus == 'accepted' ? (
                        <span style={{ color: '#50c350' }}>Accepted</span>
                      ) : (
                        <span style={{ color: '#7c7cf7' }}>Pending</span>
                      )}
                    </div>
                  </div>
                  <div className="icon d-flex align-items-center ms-auto">
                    <span
                      className="d-block p-2 pe-3"
                      onClick={() => {
                        const deleteData = {
                          id: data?._id,
                          status: data?.invitationStatus
                        };
                        setMemberId(deleteData);
                        setShowConfirmDeleteDialog(true);
                      }}>
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </div>
                </a>
              );
            })}
          </div>
          {teamMemberList && totalData > limit ? (
            <div className="mt-3" style={{ float: 'right' }}>
              <PaginatioonComponent
                pageCount={
                  totalData % limit === 0
                    ? Math.floor(totalData / limit)
                    : Math.floor(totalData / limit) + 1
                }
                onPageChange={handlePagination}
                pageNo={pageNo}
              />
            </div>
          ) : (
            ''
          )}

          {isFetching ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            ''
          )}

          <a onClick={() => setshowAddTeamMemberDialog(true)} className="btn primary-btn mt-3">
            Add Team Member
          </a>
        </div>
      </div>

      <AddTeamMemberDialog
        showDialog={showAddTeamMemberDialog}
        onDialogClose={() => setshowAddTeamMemberDialog(false)}
      />
    </>
  );
};
export default React.memo(TeamList);
