import AddNewNotesDialog from 'components/dialogs/addNewNotesDialog';
import ConfirmDialog from 'components/dialogs/confirmDialog';
import TicketTransferDialog from 'components/dialogs/ticketTransferDialog';
import WeDropDown from 'components/weDropDown';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getOrderDetails,
  previewOrderPdf,
  refundUserByOrderId,
  sendEmailOrNotificationToOrder
} from 'store/slice/order';

const OrdersDetail = () => {
  const { _id } = useParams();
  const dispatch = useDispatch();
  const { orderData, notesData } = useSelector((state) => state.order);
  const [show, setShow] = useState(false);
  const [showAddNotesDialog, setshowAddNotesDialog] = useState(false);
  // const [notesDialogShow, setNotesDialogShow] = useState(false);
  const [orderAction, setOrderAction] = useState();
  const [showConfirmDialog, setShowConfirmDialog] = useState();
  const idArray = [_id];

  const orderTypeData = [
    {
      label: 'Email Invoice',
      id: 'emailInvoice'
    },
    {
      label: 'Resend Order Notification',
      id: 'notification'
    },
    {
      label: 'Refund',
      id: 'refund'
    },
    {
      label: 'Print Tickets',
      id: 'printTickets'
    },
    {
      label: 'Transfer Ticket',
      id: 'transferTicket'
    }
  ];

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  const fetchOrderDetails = () => {
    dispatch(getOrderDetails(_id));
  };

  const handleAction = (val) => {
    if (val == 'emailInvoice') {
      setShowConfirmDialog((showConfirmDialog) => ({
        ...showConfirmDialog,
        show: true,
        title: 'Send Email Invoice',
        message: 'Are you sure you want to send email invoice?',
        type: val
      }));
    } else if (val == 'notification') {
      setShowConfirmDialog((showConfirmDialog) => ({
        ...showConfirmDialog,
        show: true,
        title: 'Send Resend Order Notification',
        message: 'Are you sure you want to resend order notification?',
        type: val
      }));
    } else if (val == 'refund') {
      setShowConfirmDialog((showConfirmDialog) => ({
        ...showConfirmDialog,
        show: true,
        title: 'Refund Order',
        message: 'Are you sure you want to refund this order?',
        type: val
      }));
    } else if (val == 'printTickets') {
      const data = {
        orderId: _id
      };
      dispatch(previewOrderPdf(data));
    } else {
      setShow(true);
    }
  };

  const handleActionConfirm = () => {
    if (orderAction == 'refund') {
      const data = {
        orderIds: idArray
      };
      dispatch(refundUserByOrderId(data));
    } else {
      const data = {
        orderIds: idArray,
        status: orderAction == 'emailInvoice' ? 1 : 2
      };
      dispatch(sendEmailOrNotificationToOrder(data));
    }
    setOrderAction('');
  };

  return (
    <>
      <div className="order-details">
        <h2>Order: {orderData?.referenceId}</h2>
        <small>
          Payment via {orderData?.paidBy ? orderData?.paidBy : '-'} (Stripe)
          {/* (ch_3NHHWDD82A6S5FD3FB5). */}
          <br />
          Paid on{' '}
          {orderData?.updatedAt
            ? moment(orderData?.updatedAt).format('MMMM DD, YYYY @ hh:mm a.')
            : '-'}{' '}
          <br />
          {/* Customer IP: 999.555.3.999 */}
        </small>
        <hr />
        <div className="row">
          <div className="col-12 col-xl-6 mb-3 mb-xl-0">
            <h4>Buying Info</h4>
            <p>
              <div className="title">Organizer Name:</div>
              <div className="content">
                {orderData?.organiserName ? orderData?.organiserName : '-'}
                {orderData?.isVerifiedOrganiser ?? (
                  <span className="fa-stack fa-1x">
                    <i className="fa-solid fa-badge fa-stack-2x"></i>
                    <i className="fa-solid fa-check fa-stack-1x fa-inverse"></i>
                  </span>
                )}
              </div>
            </p>
            <p>
              <div className="title">Event Name:</div>
              <div className="content">
                {orderData?.eventName ? orderData?.eventName : '-'}
                <small>{orderData?.venue}</small>
              </div>
            </p>
            <p>
              <div className="title">Buyer's Name:</div>
              <div className="content">{orderData?.buyerName ? orderData?.buyerName : '-'}</div>
            </p>
            <p>
              <div className="title">Total Amount:</div>
              <div className="content">${orderData?.amount ? orderData?.amount : 0}</div>
            </p>
            <p>
              <div className="title">Total Ticket Amount:</div>
              <div className="content">{orderData?.totalTicket}</div>
            </p>
          </div>
          <div className="col-12 col-xl-6">
            <h4>Order Action</h4>

            <div className="row mb-4">
              <div className="col-12 col-md-6 col-lg-8">
                <WeDropDown
                  label="Order Action"
                  selectData={orderTypeData}
                  onSelectItem={(val) => {
                    setOrderAction(val);
                    handleAction(val);
                  }}
                  defaultValue={orderAction ? orderAction : ''}
                  emptyValue="Select Order Action"
                />
              </div>
            </div>
            <h4>Notes</h4>

            {notesData
              ? notesData?.map((item) => {
                  return (
                    <>
                      <div className="block notes-author">
                        <div className="name">
                          {item.userName}
                          <small>
                            {' '}
                            {moment(item.time).format('MMMM DD, yyyy')} at{' '}
                            {moment(item.time).format('hh:mm A')}
                          </small>
                        </div>
                        <p>{item.note}</p>
                      </div>
                    </>
                  );
                })
              : ''}
            <a className="mt-3 d-block" onClick={() => setshowAddNotesDialog(true)}>
              Add New Note
            </a>

            {/* <!-- Room Modal --> */}
            {/* <div
              className="modal fade"
              id="newNote"
              tabIndex="-1"
              aria-labelledby="newNoteLabel"
              aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content add-room-modal">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="newNoteLabel">
                      New Note
                    </h1>
                    <a href="#" className="close" data-bs-dismiss="modal" aria-label="Close">
                      <i className="fa-solid fa-xmark"></i>
                    </a>
                  </div>
                  <div className="modal-body">
                    <form action="" id="newNoteForm">
                      <div className="input-block mt-1">
                        <label htmlFor="roomTitle" className="form-label">
                          Write note
                        </label>
                        <textarea className="form-control" id="writeNote" rows="4"></textarea>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <a href="#" className="btn secondary-btn" data-bs-dismiss="modal">
                      Close
                    </a>
                    <a
                      href="#"
                      className="btn primary-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal">
                      Save
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <hr />
        <h4>Attendee Info</h4>
        <div className="d-block d-md-none">
          scroll <i className="fa-solid fa-arrow-right-long"></i>
        </div>
        <div className="ticket-table order-details-list fw-bold">
          <div className="row heading d-block">
            <div className="col-12">
              <div className="row">
                <div className="col-3">Name</div>
                <div className="col-6">Ticket Type</div>
                <div className="col-2">Price</div>
              </div>
            </div>
          </div>

          {orderData?.attendeeData ? (
            orderData?.attendeeData?.map((item) => {
              return (
                <>
                  <div className="row event-row">
                    <div className="col-12 mt-1">
                      <div className="row">
                        <div className="col-3">
                          <div className="one-line">
                            {item.attendeeName ? item.attendeeName : '-'}
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="one-line">{item.ticketName ? item.ticketName : '-'}</div>
                        </div>
                        <div className="col-2">${item.ticketPrice ? item.ticketPrice : 0}</div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <p className="text-center pt-5"> No Data Found</p>
          )}
        </div>
      </div>

      <TicketTransferDialog
        showDialog={show}
        onDialogClose={() => {
          setShow(false);
          setOrderAction('');
        }}
        ticketListData={orderData?.attendeeData}
      />

      <AddNewNotesDialog
        showDialog={showAddNotesDialog}
        onDialogClose={() => {
          setshowAddNotesDialog(false);
        }}
      />

      <ConfirmDialog
        showDialog={showConfirmDialog?.show}
        title={showConfirmDialog?.title}
        message={showConfirmDialog?.message}
        onDialogClose={() => {
          setOrderAction('');
          setShowConfirmDialog((showConfirmDialog) => ({
            ...showConfirmDialog,
            show: false
          }));
        }}
        btnConfirm={() => {
          setShowConfirmDialog((showConfirmDialog) => ({
            ...showConfirmDialog,
            show: false
          }));
          handleActionConfirm();
        }}
      />
    </>
  );
};
export default React.memo(OrdersDetail);
