import ConfirmDialog from 'components/dialogs/confirmDialog';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deletePreviousTeam, getPreviousTeamList } from 'store/slice/event';
const PreviousTeam = (props) => {
  const { func } = props;
  const dispatch = useDispatch();
  const { previousTeamData } = useSelector((state) => state.event);
  let teamData = previousTeamData ? [...previousTeamData] : [];
  const [showConfirmDialog, setShowConfirmDialog] = useState();

  useEffect(() => {
    dispatch(getPreviousTeamList());
  }, []);

  // useEffect(() => {
  //   if (previousTeamData) {
  //     teamList();
  //   }
  // }, [previousTeamData]);

  // const teamList = () => {
  //   for (let i = 0; i < previousTeamData?.length; i++) {
  //     const data = {
  //       roleId: previousTeamData[i].roleId,
  //       userId: previousTeamData[i].userId
  //     };
  //     teamData.push(data);
  //   }
  // };

  const handleDelete = (indexValue) => {
    teamData.splice(indexValue, 1);
    func(teamData);
    dispatch(deletePreviousTeam(indexValue));
  };

  return (
    <>
      <div className="mt-5">
        {previousTeamData ? <h4>Your Previous Team was</h4> : ''}
        {previousTeamData ? (
          previousTeamData?.map((item, index) => {
            return (
              <>
                <div className="row mt-3">
                  <div className="col-5">
                    <div style={{ paddingTop: '10px' }}>{item.userName}</div>
                  </div>
                  <div className="col-5">
                    <div style={{ paddingTop: '10px' }}>{item.roleName}</div>
                  </div>
                  <div className="col-2">
                    <a
                      href="#"
                      className="item-profile dark"
                      onClick={() => {
                        setShowConfirmDialog((showConfirmDialog) => ({
                          ...showConfirmDialog,
                          show: true,
                          index: index
                        }));
                      }}>
                      <div className="icon d-flex ms-auto">
                        <span className="d-block">
                          <i className="fa-solid fa-trash-can"></i>
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <p className="text-center pt-5">You have not created any team yet</p>
        )}
      </div>
      <ConfirmDialog
        showDialog={showConfirmDialog?.show}
        title={'Delete Team'}
        message={'Are you sure you want to delete team?'}
        onDialogClose={() => {
          setShowConfirmDialog((showConfirmDialog) => ({
            ...showConfirmDialog,
            show: false
          }));
        }}
        btnConfirm={() => {
          handleDelete(showConfirmDialog.index);
          setShowConfirmDialog((showConfirmDialog) => ({
            ...showConfirmDialog,
            show: false
          }));
        }}
      />
    </>
  );
};
export default React.memo(PreviousTeam);
