import WeButton from 'components/button';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'rxjs';
import { userRegister } from 'store/slice/auth';
import { validateEmpty, validEmail } from 'utils/appConstant';
const SignUp = () => {
  const navigate = useNavigate('');
  const dispatch = useDispatch();
  const { id } = useParams();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setlastNameNameError] = useState('');
  const [userName, setUserName] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [show, setShow] = useState(false);

  const { isLoading } = useSelector((state) => state.auth);

  const handleLogin = () => {
    if (id) {
      navigate(`/inviteAccept/${id}`);
    } else {
      navigate(`/`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      setNameError('Please enter name.');
      return;
    }
    if (!lastName) {
      setlastNameNameError('Please enter last name.');
      return;
    }
    if (!userName) {
      setUserNameError('Please enter username.');
      return;
    }
    if (!email) {
      setEmailError('Please enter email.');
      return;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Please enter valid email.');
      return;
    }
    if (!password) {
      setPasswordError('Please enter password.');
      return;
    }
    if (!validateEmpty(password) && password.length < 8) {
      setPasswordError('Password must be minumum 8 characters.');
      return;
    }

    let requestParam = {
      name: name,
      lastName: lastName,
      userName: userName,
      email: email,
      password: password,
      deviceToken: '',
      deviceType: 'web'
    };
    dispatch(userRegister(requestParam, navigate, id));
  };
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 10
      }}>
      <div className="contain">
        <h3 className="login-title" style={{ color: 'rgb(137 135 135)' }}>
          Create account
        </h3>
        <div className="login-form">
          <div className='row'>
            <div className='col-6'>
              <label
                className="mt-2"
                htmlFor="email  "
                style={{ float: 'left', color: 'rgb(70 69 69)' }}>
                First Name{' '}
              </label>
              <input
                name="name"
                placeholder="Enter First Name"
                onChange={(e) => {
                  if (e.target.value) {
                    setNameError('');
                  }
                  setName(e.target.value);
                }}
              />
            </div>
            {nameError && <div style={{ color: 'red' }}>{nameError}</div>}
            <div className='col-6'>
              <label
                className="mt-2"
                htmlFor="email  "
                style={{ float: 'left', color: 'rgb(70 69 69)' }}>
                Last Name{' '}
              </label>
              <input
                name="name"
                placeholder="Enter Last Name"
                onChange={(e) => {
                  if (e.target.value) {
                    setlastNameNameError('');
                  }
                  setLastName(e.target.value);
                }}
              />
            </div>
            {lastNameError && <div style={{ color: 'red' }}>{lastNameError}</div>}
          </div>
          <div>
            <label
              className="mt-2"
              htmlFor="email  "
              style={{ float: 'left', color: 'rgb(70 69 69)' }}>
              User Name{' '}
            </label>
            <input
              name="username"
              placeholder="Enter Username"
              onChange={(e) => {
                if (e.target.value) {
                  setUserNameError('');
                }
                setUserName(e.target.value);
              }}
            />
          </div>
          {userNameError && <div style={{ color: 'red' }}>{userNameError}</div>}
          <div>
            <label
              className="mt-2"
              htmlFor="email  "
              style={{ float: 'left', color: 'rgb(70 69 69)' }}>
              Email{' '}
            </label>
            <input
              name="email"
              placeholder="Enter Email"
              onChange={(e) => {
                let val = e.target.value.trim();
                if (!isEmpty(val) && !validEmail(val)) {
                  setEmailError('Please enter valid email');
                } else {
                  setEmail(val);
                  setEmailError('');
                }
              }}
            />
          </div>
          {emailError && <div style={{ color: 'red' }}>{emailError}</div>}

          <div>
            <label
              className="mt-2"
              htmlFor="password"
              style={{ float: 'left', color: 'rgb(70 69 69)' }}>
              Password
            </label>
            <div className="input-group mb-3">
              <input
                name="password"
                type={show ? 'text' : 'password'}
                className="form-control"
                aria-describedby="basic-addon1"
                placeholder="Enter Password"
                onChange={(e) => {
                  let val = e.target.value.trim();
                  if (!isEmpty(val) && val.length < 8) {
                    setPasswordError('Password must be minumum 8 characters.');
                  } else {
                    setPassword(val);
                    setPasswordError('');
                  }
                }}
              />

              <span
                onClick={() => {
                  setShow(!show);
                }}
                className="input-group-text"
                id="basic-addon1">
                {show ? (
                  ''
                ) : (
                  <span id="basic-addon2">
                    <i className="fa-light fa-eye-slash"></i>
                  </span>
                )}
                {show ? (
                  <span id="basic-addon2">
                    <i className="fa-light fa-eye"></i>
                  </span>
                ) : (
                  ''
                )}
              </span>
            </div>

            {passwordError && <div style={{ color: 'red' }}>{passwordError}</div>}
          </div>
          <WeButton text="Register" onClick={handleSubmit} isLoading={isLoading} />
          <div className="text">
            Already have an account?{' '}
            <a className="linkText" onClick={handleLogin}>
              Login now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
