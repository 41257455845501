// import { Outlet } from 'react-router-dom';
import WeInput from 'components/weInput';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eventAction, getUserData } from 'store/slice/order';
import { diffDays } from 'utils/appConstant';

export default function Users() {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.order);
  const [searchFilter, setSearchFilter] = useState({ page: 1, perPage: 10 });

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = () => {
    dispatch(getUserData(searchFilter));
  };

  useEffect(() => {
    fetchUser();
  }, [searchFilter]);

  const handleLike = (id, likeStatus) => {
    const data = {
      userTicketListId: id,
      status: likeStatus ? 1 : 0
    };
    dispatch(eventAction(data));
  };

  return (
    <div className="row">
      <div className="col-12 col-xl-6">
        <div className="mt-4">
          <WeInput
            inputLabel="Search"
            onChange={(val) => {
              setSearchFilter((searchFilter) => ({
                ...searchFilter,
                search: val
              }));
            }}
            value={searchFilter.search}
            prefix={<i className="fa-solid fa-magnifying-glass"></i>}
          />
        </div>

        <div className="notices">
          {userData ? (
            userData?.map((item) => {
              return (
                <>
                  <div className="received-block">
                    <div className="profile">
                      <img src={item.userProfile} alt="" />
                    </div>
                    <div className="content">
                      <div className="ticket-name">
                        <small className="d-block">{item.userName} received:</small>
                        <strong>{item.ticketName}</strong>
                      </div>
                      <div className="icons">
                        <a href="#" className="message">
                          <i className="fa-solid fa-messages"></i> <span>{item.comment}</span>
                        </a>
                        <a
                          className="message"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleLike(item._id, !item.like)}>
                          <i
                            className={
                              item.like ? 'fa-solid fa-heart' : 'fa-regular fa-heart'
                            }></i>{' '}
                          <span>{item.like}</span>
                        </a>
                      </div>
                    </div>
                    <div className="time">{diffDays(item.dateTime)} Days ago</div>
                  </div>
                </>
              );
            })
          ) : (
            <p className="text-center pt-5"> No Data Found</p>
          )}
        </div>
      </div>
    </div>
  );
}
