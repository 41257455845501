import React, { useEffect } from 'react';
import { useState } from 'react';
import ItemMenu from './subcomponents/itemMenu';
import EventSideHeader from './subcomponents/header';
import { useDispatch, useSelector } from 'react-redux';
import { eventSelectedActiveMenu, updateEventTitle } from 'store/slice/event';
import { EventPageType } from 'utils/appEnums';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { clearAttendeeTicketData } from 'store/slice/tickets';

function SideMenu() {
  const dispatch = useDispatch();
  const [eventDetails, seteventDetailsShow] = useState(false);
  const [attendees, setAttendeesShow] = useState(false);
  const [orderOptions, setOrderOptionsShow] = useState(false);
  const path = window.location.origin;
  const [paymentsTax, setpaymentsTaxShow] = useState(false);
  const { activeEventMenu, userPermissionData, isOwnerValue, eventDetailData } = useSelector(
    (state) => state.event
  );
  const [subMenuActive, setSubMenuActive] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  var parts = location?.pathname?.split('/');
  var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash

  const eventMenuItems = [
    {
      title: 'Event Info',
      icon: 'fa-solid fa-circle-info fa-stack-1x fa-inverse',
      path: '/event/:id/edit/basicInfo',
      path1: 'edit/basicInfo',
      id: 0,
      type: EventPageType.EVENT,
      permissionId: !isOwnerValue ? userPermissionData?.event.manageEvent : true
    },
    {
      title: 'Summary',
      icon: 'fa-solid fa-memo-pad fa-stack-1x fa-inverse',
      path: '/event/:id/edit/summary',
      path1: 'edit/summary',
      id: 1,
      type: EventPageType.EVENT,
      permissionId: !isOwnerValue ? userPermissionData?.event.manageEvent : true
    },
    {
      title: 'Tickets',
      icon: 'fa-solid fa-ticket fa-stack-1x fa-inverse',
      path: '/event/:id/edit/tickets',
      path1: 'edit/tickets',
      id: 2,
      type: EventPageType.EVENT,
      permissionId: !isOwnerValue ? userPermissionData?.event.manageTicket : true
    },
    {
      title: 'Publish',
      icon: 'fa-solid fa-rocket-launch fa-stack-1x fa-inverse',
      path: '/event/:id/edit/publish',
      path1: 'edit/publish',
      id: 3,
      type: EventPageType.EVENT,
      permissionId: !isOwnerValue ? userPermissionData?.event.manageEvent : true
    }
  ];

  const attendeeMenuItems = [
    {
      title: 'Orders',
      icon: 'fa-regular fa-receipt fa-stack-1x fa-inverse',
      path: '/event/:id/attendee/orders',
      path1: 'attendee/orders',
      id: 0,
      type: EventPageType.ATTENDEEE,
      permissionId: !isOwnerValue ? userPermissionData?.orders.manageOrders : true
    },
    {
      title: 'Add Attendees',
      icon: 'fa-solid fa-user-plus fa-stack-1x fa-inverse',
      path: '/event/:id/attendee/add',
      path1: 'attendee/add',
      id: 1,
      type: EventPageType.ATTENDEEE,
      permissionId: !isOwnerValue ? userPermissionData?.attendee.manageAttendee : true
    },
    {
      title: 'Email Reminders',
      icon: 'fa-solid fa-envelopes fa-stack-1x fa-inverse',
      path: '/event/:id/attendee/reminders',
      path1: 'attendee/reminders',
      id: 2,
      type: EventPageType.ATTENDEEE,
      permissionId: !isOwnerValue ? userPermissionData?.orders.sendEmails : true
    },
    {
      title: 'Attendee List',
      icon: 'fa-solid fa-list-check fa-stack-1x fa-inverse',
      path: '/event/:id/attendee/list',
      path1: 'attendee/list',
      id: 3,
      type: EventPageType.ATTENDEEE,
      permissionId: !isOwnerValue ? userPermissionData?.attendee.manageAttendee : true
    },
    {
      title: 'Checkin',
      icon: 'fak fa-solid-file-invoice-circle-check fa-stack-1x fa-inverse',
      path: '/event/:id/attendee/checkIn',
      path1: 'attendee/checkIn',
      id: 4,
      type: EventPageType.ATTENDEEE,
      permissionId: !isOwnerValue ? userPermissionData?.attendee.checkInAttendee : true
    }
  ];

  const orderOptionsMenuItems = [
    {
      title: 'Event Schdeule',
      icon: 'fa-solid fa-list-timeline fa-stack-1x fa-inverse',
      path: '/event/:id/order/eventSchedule',
      path1: 'order/eventSchedule',
      id: 0,
      type: EventPageType.ORDERS,
      permissionId: !isOwnerValue ? userPermissionData?.event.eventSchedule : true
    },
    {
      title: 'Checkout Forms',
      icon: 'fa-solid fa-file-invoice fa-stack-1x fa-inverse',
      path: '/event/:id/order/checkoutForm',
      path1: 'order/checkoutForm',
      id: 1,
      type: EventPageType.ORDERS,
      permissionId: true
    },
    {
      title: 'Transfer Settings',
      icon: 'fak fa-ticket-transfer fa-stack-1x fa-inverse',
      path: '/event/:id/order/ticketTransfer',
      path1: 'order/ticketTransfer',
      id: 2,
      type: EventPageType.ORDERS,
      permissionId: !isOwnerValue ? userPermissionData?.orders.manageOrders : true
    },
    {
      title: 'Guest List',
      icon: 'fa-solid fa-clipboard-list-check fa-stack-1x fa-inverse',
      path: '/event/:id/order/guests',
      path1: 'order/guests',
      id: 3,
      type: EventPageType.ORDERS,
      permissionId: !isOwnerValue ? userPermissionData?.attendee.manageGuestList : true
    },
    {
      title: 'Team',
      icon: 'fa-solid fa-users-medical fa-stack-1x fa-inverse',
      path: '/event/:id/order/manageTeam',
      path1: 'order/manageTeam',
      id: 4,
      type: EventPageType.ORDERS,
      permissionId: !isOwnerValue ? userPermissionData?.team?.manageTeam : true
    }
  ];

  const paymentsOptionsMenuItems = [
    {
      title: 'Payment Options',
      icon: 'fa-solid fa-calculator fa-stack-1x fa-inverse',
      path: '/event/:id/pay/paymentOptions',
      path1: 'pay/paymentOptions',
      id: 0,
      type: EventPageType.PAYMENT_OPTIONS,
      permissionId: !isOwnerValue ? false : true
    },
    {
      title: 'Refund Settings',
      icon: 'fak fa-refund fa-stack-1x fa-inverse',
      path: '/event/:id/pay/refundSettings',
      path1: 'pay/refundSettings',
      id: 2,
      type: EventPageType.PAYMENT_OPTIONS,
      permissionId: !isOwnerValue ? false : true
    },
    {
      title: 'Tax Settings',
      icon: 'fak fa-tax fa-stack-1x fa-inverse',
      path: '/event/:id/pay/taxSettings',
      path1: 'pay/taxSettings',
      id: 3,
      type: EventPageType.PAYMENT_OPTIONS,
      permissionId: !isOwnerValue ? false : true
    }
  ];

  useEffect(() => {
    dispatch(eventSelectedActiveMenu(lastSegment));
    // dispatch(
    //   getUserPermissionForEvent({
    //     eventUid: id
    //   })
    // );
    // dispatch(apiFetchingStart());
  }, []);

  useEffect(() => {
    openDropDownMenu();
  }, [activeEventMenu]);

  const openMenuPage = (item) => {
    let path = item.path;
    let pageName = item.path.split('/');
    var lastPart = pageName.pop() || pageName.pop();
    //Reset states for all dropdown
    // setAttendeesShow(false);
    // setOrderOptionsShow(false);
    // seteventDetailsShow(false);
    // setpaymentsTaxShow(false);
    // //dispatch state for selected menu.
    if (!path.includes('attendee/add')) {
      dispatch(clearAttendeeTicketData());
    }
    dispatch(eventSelectedActiveMenu(lastPart));
    setSubMenuActive(false);
    //event basic info
    if (!path.includes('basicInfo')) {
      dispatch(updateEventTitle(eventDetailData?.title));
    }
    if (path.includes('basicInfo')) {
      navigate(`/event/${id}/edit/basicInfo`);
    } else if (path.includes('summary')) {
      navigate(`/event/${id}/edit/summary`);
    } else if (path.includes('tickets')) {
      navigate(`/event/${id}/edit/tickets`);
    } else if (path.includes('publish')) {
      navigate(`/event/${id}/edit/publish`);
    }
    //paymentOptions
    else if (path.includes('paymentOptions')) {
      navigate(`/event/${id}/pay/paymentOptions`);
    } else if (path.includes('taxSettings')) {
      navigate(`/event/${id}/pay/taxSettings`);
    } else if (path.includes('refundSettings')) {
      navigate(`/event/${id}/pay/refundSettings`);
    } else if (path.includes('paymentMethods')) {
      navigate(`/event/${id}/pay/paymentMethods`);
    }
    //Orders
    else if (path.includes('eventSchedule')) {
      navigate(`/event/${id}/order/eventSchedule`);
    } else if (path.includes('checkoutForm')) {
      navigate(`/event/${id}/order/checkoutForm`);
    } else if (path.includes('ticketTransfer')) {
      navigate(`/event/${id}/order/ticketTransfer`);
    } else if (path.includes('manageTeam')) {
      navigate(`/event/${id}/order/manageTeam`);
    } else if (path.includes('guests')) {
      navigate(`/event/${id}/order/guests`);
    }
    //attendeee
    else if (path.includes('orders')) {
      navigate(`/event/${id}/attendee/orders`);
    } else if (path.includes('attendee/add')) {
      navigate(`/event/${id}/attendee/add`);
    } else if (path.includes('attendee/list')) {
      navigate(`/event/${id}/attendee/list`);
    } else if (path.includes('attendee/reminders')) {
      navigate(`/event/${id}/attendee/reminders`);
    } else if (path.includes('attendee/checkIn')) {
      navigate(`/event/${id}/attendee/checkIn`);
    }
  };

  const openDropDownMenu = () => {
    let urlPath = location.pathname;
    if (urlPath.includes('/order/')) {
      setOrderOptionsShow(true);
    } else if (urlPath.includes('/attendee/')) {
      setAttendeesShow(true);
    } else if (urlPath.includes('/pay/')) {
      setpaymentsTaxShow(true);
    } else if (urlPath.includes('/edit/')) {
      seteventDetailsShow(true);
    }
  };

  const openAttendeeMenu = () => {
    setAttendeesShow(!attendees);
  };

  const openOrderOptionsMenu = () => {
    setOrderOptionsShow(!orderOptions);
  };

  const openPayTaxMenu = () => {
    setpaymentsTaxShow(!paymentsTax);
  };
  const openEventDetailMenu = () => {
    seteventDetailsShow(!eventDetails);
  };

  return (
    <div>
      <EventSideHeader
        subMenuActive={() => {
          setSubMenuActive(!subMenuActive);
        }}
      />
      <div
        className={
          subMenuActive
            ? 'accordion accordion-flush accordion-menu show'
            : 'accordion accordion-flush accordion-menu'
        }
        id="accordionPanelsStayOpenExample">
        {isOwnerValue ? (
          <a
            href={`${path}/event/${id}/home`}
            onClick={(event) => {
              event.preventDefault()
              dispatch(eventSelectedActiveMenu('home'));
              navigate(`/event/${id}/home`);
            }}
            className={
              activeEventMenu === EventPageType.HOME.toLowerCase() ? 'link active' : 'link'
            }>
            <span className="fa-stack fa-1x">
              <i className="fa-solid fa-circle fa-stack-2x"></i>
              <i className="fa-solid fa-house-heart fa-stack-1x fa-inverse"></i>
            </span>{' '}
            <span className="title">Home</span>
          </a>
        ) : (
          ''
        )}
        {isOwnerValue ||
          userPermissionData?.event.manageEvent ||
          userPermissionData?.event.manageTicket ? (
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                onClick={() => openEventDetailMenu()}
                className={eventDetails ? 'accordion-button' : 'accordion-button collapsed'}
                type="button"
                data-bs-toggle="collapse"
                aria-expanded="true">
                Event Details
              </button>
            </h2>
            <ItemMenu
              show={eventDetails ? true : false}
              menuItems={eventMenuItems}
              eventId={id}
              onItemClick={(item) => openMenuPage(item)}
            />
          </div>
        ) : (
          ''
        )}

        {isOwnerValue ||
          userPermissionData?.attendee.manageAttendee ||
          userPermissionData?.attendee.checkInAttendee ||
          userPermissionData?.orders.manageOrders ||
          userPermissionData?.orders.sendEmails ||
          userPermissionData?.orders.manageOrders ? (
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={attendees ? 'accordion-button' : 'accordion-button collapsed'}
                type="button"
                data-bs-toggle="collapse"
                onClick={() => openAttendeeMenu()}>
                Attendees
              </button>
            </h2>
            <ItemMenu
              show={attendees ? true : false}
              menuItems={attendeeMenuItems}
              eventId={id}
              onItemClick={(item) => openMenuPage(item)}
            />
          </div>
        ) : (
          ''
        )}
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className={orderOptions ? 'accordion-button' : 'accordion-button collapsed'}
              type="button"
              data-bs-toggle="collapse"
              onClick={() => openOrderOptionsMenu()}>
              Options
            </button>
          </h2>
          <ItemMenu
            show={orderOptions ? true : false}
            menuItems={orderOptionsMenuItems}
            eventId={id}
            onItemClick={(item) => openMenuPage(item)}
          />
        </div>
        {isOwnerValue ? (
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={paymentsTax ? 'accordion-button' : 'accordion-button collapsed'}
                type="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                onClick={openPayTaxMenu}>
                Payments & Tax
              </button>
            </h2>
            <ItemMenu
              show={paymentsTax ? true : false}
              menuItems={paymentsOptionsMenuItems}
              eventId={id}
              onItemClick={(item) => openMenuPage(item)}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default SideMenu;
