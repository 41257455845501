import React, { useContext, useEffect, useRef } from 'react';
import EventContext from 'context/eventContext';
import WeInput from 'components/weInput';
import WeTextArea from 'components/weTextArea';
import WeToggle from 'components/weToggle';
// import WeRadio from 'components/weRadio';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { eventDetail } from 'store/slice/event';
// import FooterButtons from '../../footerButtons';
// import WeButton from 'components/button';
// import LoaderComponent from 'components/loaderComponent';

const TabSettings = () => {
  const { eventFormData, setEventFormData } = useContext(EventContext);
  const settingRef = useRef({});
  const dispatch = useDispatch();
  const { id } = useParams();
  const { eventDetailData } = useSelector((state) => state.event);

  useEffect(() => {
    if (id) {
      dispatch(eventDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (eventDetailData) {
      setEventFormData(eventDetailData);
    }
  }, [eventDetailData]);

  // const btnSaveContinue = () => {
  //   dispatch(updateEvent(eventFormData));
  // };

  return (
    <div className="mt-3">
      {/* <h1 className="notes">OPTIONS HAVENT BEEN EDITED</h1> */}
      <h4>Display Settings</h4>

      <div className="mt-3 mb-4">
        <WeToggle
          checkToogle={eventFormData && eventFormData.displayRemainingTickets}
          onChange={(checked) => {
            setEventFormData((eventFormData) => ({
              ...eventFormData,
              displayRemainingTickets: checked
            }));
          }}
          onSwitchClick={() => {
            setEventFormData((eventFormData) => ({
              ...eventFormData,
              displayRemainingTickets: !eventFormData.displayRemainingTickets
            }));
          }}
          toggleLabel="Display number of tickets remaining on event page"
        />
      </div>
      {/* <h4>Event Type</h4>
      <div className="mb-4">
        <div className="form-check mt-3">
          <WeRadio
            checked={eventFormData.eventEntryType == 'ticket' ? true : false}
            label=" Ticketed Event"
            id="ticket"
            htmlFor="ticket"
            onChange={(checked) => {
              setEventFormData((eventFormData) => ({
                ...eventFormData,
                eventEntryType: checked ? 'ticket' : ''
              }));
            }}
          />
        </div>
        <div className="form-check">
          <WeRadio
            checked={eventFormData.eventEntryType == 'registration' ? true : false}
            label="Registration Event"
            id="registration"
            htmlFor="registration"
            onChange={(checked) => {
              setEventFormData((eventFormData) => ({
                ...eventFormData,
                eventEntryType: checked ? 'registration' : ''
              }));
            }}
          />
        </div>
      </div> */}

      <h4>Checkout labels</h4>
      <div className="mb-4">
        <div className="row">
          <div className="col-12 col-lg-6">
            <WeInput
              innerRef={(el) => (settingRef.current['admissionLabelRef'] = el)}
              onChange={(val) => {
                if (val) {
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    admissionLabel: val
                  }));
                } else {
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    admissionLabel: ''
                  }));
                }
              }}
              inputLabel="Admission Label"
              value={eventFormData.admissionLabel}
            />

            <WeInput
              innerRef={(el) => (settingRef.current['addOnsLabelRef'] = el)}
              onChange={(val) => {
                if (val) {
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    addOnsLabel: val
                  }));
                } else {
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    addOnsLabel: ''
                  }));
                }
              }}
              inputLabel="Add Ons Label"
              value={eventFormData.addOnsLabel}
            />
          </div>
        </div>
      </div>

      <h4>Display a message after ticket sales end</h4>

      <div className="mb-4">
        <WeTextArea
          innerRef={(el) => (settingRef.current['addOnsLabelRef'] = el)}
          maxRows={4}
          maxLength={50}
          onChange={(val) => {
            setEventFormData((eventFormData) => ({
              ...eventFormData,
              messageAfterTicket: val
            }));
          }}
          inputLabel="Add a message"
          value={eventFormData.messageAfterTicket}
        />
      </div>

      {/* <FooterButtons
          btnSaveContinue={btnSaveContinue}
          isLoading={!id ? isLoading:''}
          isEdit={id ? true : false}
          btnSaveName={id ? 'Update' : 'Next'}
        /> */}
      {/* {id && isLoading ? (
        <div style={{ color: 'var(--bs-lt-red)' }}>
          <LoaderComponent />
        </div>
      ) : (
        <WeButton text="Update Settings" onClick={btnSaveContinue} isLoading={isLoading} />
      )} */}
    </div>
  );
};
export default React.memo(TabSettings);
