import React, { useEffect, useState } from 'react';
import SalesFilter from './subcomponents/salesFilter';
import { useDispatch, useSelector } from 'react-redux';
import { onPaymentChange, onTicketChange, onVenuesChange, salesReport } from 'store/slice/order';
import PaginatioonComponent from 'components/paginatioonComponent';

// const initialValues = {
//   page: 1,
//   perPage: 20
// };

export default function Sales() {
  const dispatch = useDispatch();
  const { salesReportData, ticketCheckData, paymentCheckData, VenuesCheckData,totalCount } = useSelector(
    (state) => state.order
  );
  let page = 1;
  let limit = 10;
  const [pageNo, setPageNo] = useState(page);
  const [filter, setFilter] = useState();

  useEffect(() => {
    getSalesReport();
  }, []);

  const handlePagination = (data) => {
    // const page = flag == 'next' ? pageNo + 1 : pageNo - 1;
    console.log('onPageChange', data.selected + 1);
    setPageNo(data.selected + 1);
  };

  useEffect(() => {
    getSalesReport();
  }, [pageNo]);

  useEffect(() => {
    getSalesReport();
  }, [filter]);

  useEffect(() => {
    getSalesReport();
  }, [ticketCheckData]);

  useEffect(() => {
    getSalesReport();
  }, [paymentCheckData]);
  useEffect(() => {
    getSalesReport();
  }, [VenuesCheckData]);

  const getSalesReport = () => {
    dispatch(
      salesReport({
        page: pageNo,
        perPage: limit,
        startDate: filter?.startDate,
        endDate: filter?.endDate,
        ticketType: ticketCheckData,
        paymentType: paymentCheckData,
        venues: VenuesCheckData
      })
    );
  };
  const onRefreshClick = () => {
    setFilter((filter) => ({
      ...filter,
      startDate: '',
      endDate: '',
      search: ''
    }));
    dispatch(onTicketChange());
    dispatch(onPaymentChange());
    dispatch(onVenuesChange());
  };

  return (
    <>
      <div className="small-container">
        <h2>Sales Reports</h2>

        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/reports">Reports</a>
              </li>
              <li className="breadcrumb-item">Sales Reports</li>
            </ol>
          </nav>
        </div>

        <SalesFilter
          onStartDateChange={(val) => {
            setFilter((filter) => ({
              ...filter,
              startDate: val
            }));
          }}
          filter={filter}
          onEndDateChange={(val) => {
            setFilter((filter) => ({
              ...filter,
              endDate: val
            }));
          }}
          handleRemoveFilter={() => {
            setFilter((filter) => ({
              ...filter,
              startDate: '',
              endDate: ''
            }));
          }}
          onRefreshClick={onRefreshClick}
        />
        <hr />

        <div className="ticket-table mt-0">
          <div className="row heading">
            <div className="col-12">
              <div className="row">
                <div className="col-4">Event Name</div>
                <div className="col-2">Event Date</div>
                <div className="col-2">Qty.</div>
                <div className="col-2">Gross Sales</div>
                <div className="col-2">Net Sales</div>
              </div>
            </div>
          </div>

          {salesReportData ? (
            salesReportData?.map((item) => {
              return (
                <>
                  <div className="row event-row">
                    <div className="col-12 mt-1 d-none d-lg-block">
                      <div className="row">
                        <div className="col-4">
                          <div className="one-line">
                            <a href="#">{item.eventName}</a>
                          </div>
                        </div>
                        <div className="col-2">{item.eventDate}</div>
                        <div className="col-2">{item.quantity}</div>
                        <div className="col-2">
                          <div className="one-line">{item.grossSales}</div>
                        </div>
                        <div className="col-2">
                          <div className="one-line">{item.netSales}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-lg-none">
                      <div className="d-flex h-100 align-items-center">
                        <div className="mobile-event-block w-100 d-block d-lg-none">
                          <div className="info mb-2">
                            <div>
                              <strong>
                                <a href="#">{item.eventName}</a>
                              </strong>
                              <div>Event Date: {item.eventDate}</div>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="info">
                              <span className="heading d-block">Qty:</span>
                              <span>{item.quantity}</span>
                            </div>
                            <div className="info">
                              <span className="heading d-block">Gross Sales:</span>
                              <div className="one-line">{item.grossSales}</div>
                            </div>
                            <div className="info">
                              <span className="heading d-block">Net Sales:</span>
                              <div className="one-line">{item.netSales}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <p className="text-center pt-5"> No Data Found</p>
          )}

{salesReportData && totalCount > limit ? (
        <div className="mt-3" style={{ float: 'right' }}>
          <PaginatioonComponent
            pageCount={
              totalCount % limit === 0
                ? Math.floor(totalCount / limit)
                : Math.floor(totalCount / limit) + 1
            }
            onPageChange={handlePagination}
            pageNo={pageNo}
          />
        </div>
      ) : (
        ''
      )}
        </div>
      </div>
    </>
  );
}
