import WeDropDown from 'components/weDropDown';
import WeInput from 'components/weInput';
import React from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
// import { selectAllAttendee, updateSpecificAttendee } from 'store/slice/event';

const CreateHoldonDialog = ({ ...props }) => {
  const {
    showDialog,
    onDialogClose,
    onNameInputChange,
    holdOnsNameError,
    btnSave,
    onCapacityInputChange,
    holdOnsCapacityError,
    onCodeInputChange,
    holdOnsCodeError,
    OnHoldName,
    OnHoldICapacity,
    onSelectTicketsChange,
    OnHoldCode,
    selectTicket,
    holdOnTicketError,
    ticket
  } = props;

  const validCoupon = (e) => [' '].includes(e.key) && e.preventDefault();

  return (
    <Modal show={showDialog} centered backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5"> Add Hold On </h1>
            <a
              className="close closeBtn"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onDialogClose}>
              <i className="fa-solid fa-xmark"></i>
            </a>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet, consectetuer</p>
        <WeInput
          onChange={onNameInputChange}
          autoFocus={true}
          inputLabel="Enter Name"
          formError={holdOnsNameError}
          value={OnHoldName ?? ''}
        />
        <WeInput
          // type="number"
          onChange={onCapacityInputChange}
          inputLabel="Capacity"
          formError={holdOnsCapacityError}
          value={OnHoldICapacity ?? ''}
        />

        <WeDropDown
          label="Select Ticket"
          selectData={selectTicket}
          defaultValue={ticket ? ticket : ''}
          emptyValue="Select a ticket"
          onSelectItem={onSelectTicketsChange}
          formError={holdOnTicketError}
        />

        <WeInput
          onChange={onCodeInputChange}
          maxLength={12}
          inputLabel="Code"
          onKeyPress={(e) => {
            validCoupon(e);
            if (e.key === 'Enter') {
              btnSave();
            }
          }}
          formError={holdOnsCodeError}
          value={OnHoldCode ?? ''}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn secondary-btn" onClick={onDialogClose}>
          Close
        </Button>
        <Button className="btn primary-btn" onClick={() => btnSave()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(CreateHoldonDialog);
