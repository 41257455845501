/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react';
import api from 'services';
import { API_RESPONSE, TESLOOMAPI } from 'services/apiConstant';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { eventDetail, updateEvent, apiLoadingStart, bannerImageUpload, extraImageUpload } from 'store/slice/event';
import EventContext from 'context/eventContext';
import { generateAppID, localImageURL, validateEmpty } from 'utils/appConstant';
import { EventTypeForm } from 'utils/appEnums';
import ImageContainer from './subComponents/ImageContainer';
import WeTextArea from 'components/weTextArea';
import TextContainer from './subComponents/TextContainer';
import VideoContainer from './subComponents/VideoContainer';
import { FileUploader } from 'react-drag-drop-files';
import EventHeader from '../eventHeader';
import FooterButtons from '../footerButtons';
import { toast } from 'react-toastify';
import PageForNoPermission from 'pages/pageForNoPermission';
import Resizer from 'react-image-file-resizer';
import PopOverSpinner from 'components/popOverSpinner';

const eventCreate = {
  eventUid: '',
  title: '',
  eventType: '',
  tags: [],
  location: {},
  venue: '',
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  timezone: '',
  eventPageLanguage: '',
  isStartTimeDisplayed: false,
  isEndTimeDisplayed: false,
  bannerImage: '',
  googleDescription: '',
  description: '',
  extraFields: [],
  eventCapacity: 0,
  displayRemainingTickets: false,
  eventEntryType: '',
  admissionLabel: '',
  addOnsLabel: '',
  messageAfterTicket: '',
  teamMembers: '',
  publishType: '',
  passcode: ''
};

let updateData = {}

const Summary = ({ ...props }) => {
  const { eventFormData, setEventFormData } = useContext(EventContext);
  const { onSave, btnPrevious, activeStep } = props;
  const dispatch = useDispatch();
  const briefMaxLength = 150;
  const [briefLength, setBriefLength] = useState(0);
  const [summaryFormError, setSummaryFormError] = useState({});
  const formRef = useRef({});
  const fileTypes = ['JPEG', 'PNG', 'GIF', 'JPG', 'SVG'];
  const [imageSelected, setImageSelected] = useState('');
  const [bannerImageFormData, setBannerImageFormData] = useState('');
  const [isExtraField, setIsExtraField] = useState('');
  let [eventData, seteventData] = useState(eventCreate);
  const [textDescription, setTextDescription] = useState('');
  const [videoLinkInput, setVideoLinkInput] = useState('');
  const { isLoading } = useSelector((state) => state.event);
  const path = window.location.pathname;
  const isCreate = path.includes('create');
  const { eventDetailData, googleDescriptionLength, userPermissionData, isOwnerValue } =
    useSelector((state) => state.event);
  const { id } = useParams();
  useEffect(() => {
    if (!id) {
      dispatch(eventDetail(eventFormData?.eventUid));
      seteventData((eventData) => ({
        ...eventData,
        ...eventFormData
      }));
    }
  }, []);
  useEffect(() => {
    if (id) {
      dispatch(eventDetail(id));
    }
  }, []);

  useEffect(() => {
    if (eventDetailData) {
      setEventFormData(eventDetailData);
      seteventData((eventData) => ({
        ...eventData,
        ...eventDetailData
      }));
    }
  }, [eventDetailData]);


  const checkExtraFields = (imageURL, eventDataWithBanner) => {
    if (imageURL) {
      setImageSelected(imageURL);
    }
    // updateData = eventDataWithBanner;
    eventData.googleDescription=eventFormData.googleDescription;
    eventData.description=eventFormData.description;
    if (isExtraField) {
      const addMoreFields = eventFormData.extraFields ? [...eventFormData.extraFields] : [];
      eventFormData.extraFields.map((item, i) => {
        if (item.formData) {
          api
            .post(
              `${TESLOOMAPI.uploadFile}` + '?previousFile=' + item.imagePath + '&folderName=' + 'extraFieldsImage',
              item.formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
            )
            .then((response) => {
              let result = response.data;
              if (result.status === API_RESPONSE.SUCCESS) {
                let filename1 = result.imageName;
                let imageURL1 = result.imagePath;
                const addMoreData = {
                  id: item.id,
                  type: item.type,
                  imageURL: imageURL1,
                  imagePath: filename1,
                  videoLink: item.videoLink,
                  textDescription: item.textDescription,
                  isRequired: item.isRequired,
                  formData: ''
                };
                addMoreFields[i] = addMoreData;
                eventData.extraFields = addMoreFields;
                if (i == eventFormData.extraFields.length - 1) {
                  setTimeout(() => {
                    dispatch(updateEvent(eventData));
                    if (!id) {
                      onSave();
                    }
                  }, 1000);
                }
              } else {
                toast.error(result.message);
              }
            })
            .catch(() => {
              ('error');
            });
        } else {
          if (i == eventFormData.extraFields.length - 1) {
            setTimeout(() => {
              dispatch(updateEvent(eventData));
              if (!id) {
                onSave();
              }
            }, 5000);
          }
        }
      });
    } else {
      dispatch(updateEvent(eventData));
      if (!id) {
        onSave();
      }
    }
  };

  const btnSaveContinue = () => {
    if (validateEmpty(eventFormData.googleDescription)) {
      setSummaryFormError((formError) => ({
        ...formError,
        briefSummary: 'Please enter brief summary for your event.'
      }));
      formRef.current['briefRef'].focus();
      return;
    }
    dispatch(apiLoadingStart());
    if (bannerImageFormData) {
      // update event with banner image
      dispatch(
        bannerImageUpload(
          eventFormData.bannerImage,
          'bannerImage',
          bannerImageFormData,
          eventData,
          checkExtraFields,
        )
      );
    } else {
      // update event without banner image
      checkExtraFields("", eventFormData);
    }
  };

  useEffect(() => {
    if (eventFormData) {
      if (eventFormData.bannerImageName && !bannerImageFormData) {
        setImageSelected(eventFormData.bannerImageName);
      }
    }
  }, [eventFormData]);

  useEffect(() => {
    if (googleDescriptionLength > 0) {
      setBriefLength(googleDescriptionLength);
    }
  }, [googleDescriptionLength]);

  const btnAddMoreItems = (type) => {
    setTextDescription('');
    setVideoLinkInput('');

    let addMoreItems = {
      id: generateAppID(5),
      type: type,
      imageURL: '',
      imagePath: '',
      videoLink: '',
      textDescription: '',
      isRequired: false
    };

    if (!eventFormData.extraFields) {
      eventFormData.extraFields = [];
    }
    setEventFormData((eventFormData) => ({
      ...eventFormData,
      extraFields: [...eventFormData.extraFields, addMoreItems]
    }));
  };

  const deleteSummaryItem = (itemData) => {
    const removeItems = eventFormData.extraFields.filter((item) => item.id !== itemData.id);
    setEventFormData((eventFormData) => ({
      ...eventFormData,
      extraFields: removeItems
    }));
    setTextDescription('');
    setVideoLinkInput('');
  };

  const addMoreItemIndex = (item) => {
    const summaryItemsIndex = eventFormData.extraFields.findIndex(
      (itemData) => itemData.id === item.id
    );
    return summaryItemsIndex;
  };

  const resizeFile = (file, width, height) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        height,
        width,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  const handleChange = (file, fieldName, itemData) => {
    let width = 0;
    let height = 0;
    const img = document.createElement('img');
    const objectURL = URL.createObjectURL(file);
    img.onload = async () => {
      width = img.width;
      height = img.height;
      const image = await resizeFile(file, width, height);
      const newFile = dataURIToBlob(image);
      const formData = new FormData();
      if (file) {
        formData.append('file', newFile);
      }
      if (fieldName == 'bannerImage') {
        setImageSelected(localImageURL(newFile));
        setBannerImageFormData(formData);
      } else {
        const data = {
          filename: itemData.imagePath,
          imageURL: localImageURL(newFile),
          itemData: itemData,
          formData: formData
        };
        updateImagePathToSummaryItems(data);
        setIsExtraField(true);
      }
    };
    img.src = objectURL;
  };

  const handleRemoveImage = (fileName, fileTypeName, itemData) => {
    const data = {
      fileName: fileName,
      eventUid: eventFormData.eventUid
    };
    api
      .post(`${TESLOOMAPI.deleteFile}`, data)
      .then((response) => {
        let result = response.data;
        if (result.status === API_RESPONSE.SUCCESS) {
          toast.success(result.message);
          if (fileTypeName == 'bannerImage') {
            setImageSelected('');
            setEventFormData((eventFormData) => ({
              ...eventFormData,
              bannerImage: '',
              bannerImageName: ''
            }));
          } else {
            const data = {
              filename: '',
              imageURL: '',
              itemData: itemData
            };
            updateImagePathToSummaryItems(data);
          }
        }
      })
      .catch(() => {
        ('error');
      });
  };

  const updateImagePathToSummaryItems = (data) => {
    const addMoreFields = eventFormData.extraFields ? [...eventFormData.extraFields] : [];
    const suspendIndex = eventFormData.extraFields?.findIndex(
      (item) => item.id === data.itemData.id
    );
    const addMoreData = {
      id: data.itemData.id,
      type: data.itemData.type,
      imageURL: data.imageURL ? data.imageURL : data.itemData.imageURL,
      imagePath: data.filename ? data.filename : data.itemData.imagePath,
      videoLink: data.videoLink ? data.videoLink : data.itemData.videoLink,
      textDescription: data.textDescription ? data.textDescription : data.itemData.textDescription,
      isRequired: data.itemData.isRequired,
      formData: data.formData
    };
    addMoreFields[suspendIndex] = addMoreData;
    setEventFormData((eventFormData) => ({
      ...eventFormData,
      extraFields: addMoreFields
    }));
  };

  const renderAddMoreItems = (item, index) => {
    if (item.type === EventTypeForm.image) {
      return (
        <>
          <ImageContainer
            key={index}
            itemData={item}
            btnDeleteItem={deleteSummaryItem}
            fileTypes={fileTypes}
            onRemoveImage={(itemData) => {
              handleRemoveImage(itemData.imagePath, 'more', itemData);
            }}
            onImageChange={(file, itemData) => {
              handleChange(file, 'more', itemData);
            }}
          />
        </>
      );
    } else if (item.type === EventTypeForm.text) {
      return (
        <>
          <TextContainer
            key={index}
            onChangeText={(val) => {
              const data = {
                textDescription: val,
                itemData: item
              };
              updateImagePathToSummaryItems(data);
            }}
            btnDeleteItem={deleteSummaryItem}
            itemData={item}
            value={item.textDescription ? item.textDescription : textDescription}
          />
        </>
      );
    } else if (item.type === EventTypeForm.video) {
      return (
        <>
          <VideoContainer
            key={index}
            btnDeleteItem={deleteSummaryItem}
            itemData={item}
            onChangeVideoLink={(val) => {
              const data = {
                videoLink: val,
                itemData: item
              };
              updateImagePathToSummaryItems(data);
            }}
            value={item.videoLink}
          />
        </>
      );
    }
  };
  const renderChild = () => {
    return (
      <div className="content w-100">
        {imageSelected ? (
          <img src={imageSelected} />
        ) : (
          <div>
            <i className="fa-light fa-image"></i>
            <div>Drag and drop an image</div>
          </div>
        )}
      </div>
    );
  };

  return isCreate || isOwnerValue || userPermissionData?.event.manageEvent ? (
    <>
      <div>
        <EventHeader
          title="Event Banner"
          subTitle="Add photo to show what your event will be about"
        />

        <div style={{ position: 'relative' }}>
          <FileUploader
            handleChange={(file) => {
              handleChange(file, 'bannerImage');
            }}
            name="file"
            types={fileTypes}
            classes="banner"
            // eslint-disable-next-line react/no-children-prop
            children={renderChild()}
          />
          {eventFormData.bannerImage ? (
            <div
              style={{
                color: '#fff',
                position: 'absolute',
                top: 10,
                right: '10px',
                cursor: 'pointer'
              }}
              onClick={() => handleRemoveImage(eventFormData.bannerImage, 'bannerImage')}>
              <i className="fa-solid fa-xmark"></i>
            </div>
          ) : (
            ''
          )}
        </div>

        <br />
        <hr />
        <h2>Brief Summary (Google Description)</h2>
        <p>Grab people's attention with a short/breif description about your event. .</p>
        <WeTextArea
          innerRef={(el) => (formRef.current['briefRef'] = el)}
          isRequired
          maxRows={3}
          maxLength={briefMaxLength}
          onChange={(val) => {
            setBriefLength(val.length);
            if (val) {
              setSummaryFormError((formError) => ({
                ...formError,
                briefSummary: ''
              }));
              setEventFormData((eventFormData) => ({
                ...eventFormData,
                googleDescription: val
              }));
            } else {
              setEventFormData((eventFormData) => ({
                ...eventFormData,
                googleDescription: ''
              }));
            }
          }}
          inputLabel="Brief Summary"
          formError={summaryFormError.briefSummary}
          value={eventFormData && eventFormData.googleDescription}
        />
        <div style={{ float: 'right' }}>{`${briefLength}/${briefMaxLength}`}</div>
        <hr />
        <h2>Description</h2>
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

        <WeTextArea
          maxRows={5}
          onChange={(val) => {
            if (val) {
              setSummaryFormError((formError) => ({
                ...formError,
                description: ''
              }));
              setEventFormData((eventFormData) => ({
                ...eventFormData,
                description: val
              }));
            } else {
              setEventFormData((eventFormData) => ({
                ...eventFormData,
                description: ''
              }));
            }
          }}
          inputLabel="Description"
          formError={summaryFormError.description}
          value={eventFormData && eventFormData.description}
        />

        {eventFormData.extraFields &&
          eventFormData.extraFields.map((item, index) => {
            return <>{renderAddMoreItems(item, index)}</>;
          })}
        <div className="row mt-3">
          <div className="col-12 col-md-12 text-center">
            <a onClick={() => btnAddMoreItems(EventTypeForm.text)} className="description-buttons">
              <i className="fak fa-solid-text-circle-plus"></i>
              <span>Add Text</span>
            </a>
            <a onClick={() => btnAddMoreItems(EventTypeForm.image)} className="description-buttons">
              <i className="fak fa-solid-image-circle-plus"></i>
              <span>Add Image</span>
            </a>
            <a onClick={() => btnAddMoreItems(EventTypeForm.video)} className="description-buttons">
              <i className="fak fa-solid-video-circle-plus"></i>
              <span>Add Video</span>
            </a>
          </div>
        </div>

        <FooterButtons
          btnSaveContinue={btnSaveContinue}
          // isLoading={!id ? isLoading : ''}
          activeStep={activeStep}
          btnBack={btnPrevious}
          isEdit={id ? true : false}
          btnSaveName={id ? 'Update' : 'Next'}
        />
      </div>
      {isLoading ? <PopOverSpinner /> : ''}
    </>
  ) : (
    <PageForNoPermission />
  );
};

export default Summary;
