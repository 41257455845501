import WeInput from 'components/weInput';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const QuesionDialog = ({ ...props }) => {
  const { showDialog, onDialogClose, onInputChange, btnSave, customData } = props;
  return (
    <Modal show={showDialog} onHide={onDialogClose} centered backdrop="static" keyboard={false}>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          {' '}
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5">Create Question </h1>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Add Custom Information</p>
        <WeInput
          type="text"
          onChange={onInputChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              btnSave();
            }
          }}
          autoFocus={true}
          inputLabel="Ticket Name"
          value={customData?.question ?? ''}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn secondary-btn" onClick={onDialogClose}>
          Close
        </Button>
        <Button className="btn primary-btn" onClick={btnSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(QuesionDialog);
