import React from 'react';
import './index.css';
const WeInput = ({ ...props }) => {
  const {
    innerRef,
    isRequired,
    onChange,
    value,
    type,
    inputLabel,
    placeholder,
    formError,
    defaultValue,
    onKeyPress,
    minVal,
    prefix,
    maxVal,
    readOnly,
    maxLength,
    suffix,
    name,
    className,
    onKeyUp,
    onBlur,
    autoFocus,
    disabled,
    style
  } = props;
  return (
    <>
      <div className={className ? className : 'input-block '}>
        <label htmlFor="keywordSearchTags" className="form-label">
          {inputLabel}
          <span style={{ color: 'red' }}>{isRequired ? '*' : ''}</span>
        </label>
        {prefix || suffix ? (
          <div className="input-group">
            {prefix ? (
              <span className="input-group-text" id="priceAddAttendee-addon">
                {prefix}
              </span>
            ) : (
              ''
            )}
            <input
              ref={innerRef}
              style={style}
              type={type}
              name={name}
              className="form-control"
              value={value ?? ''}
              readOnly={readOnly}
              disabled={disabled}
              onChange={(e) => onChange(e.target.value)}
              defaultValue={defaultValue}
              placeholder={placeholder}
              onKeyUp={onKeyUp}
              autoFocus={autoFocus}
              onBlur={onBlur}
              onKeyDown={onKeyPress}
              min={minVal}
              max={maxVal}
              maxLength={maxLength}
            />
            {suffix ? (
              <span className="input-group-text me-3 ms-0" id="organizationalURL-addon">
                {suffix ? suffix : ''}
              </span>
            ) : (
              ''
            )}
          </div>
        ) : (
          <input
            ref={innerRef}
            style={style}
            type={type}
            name={name}
            className="form-control"
            value={value ?? ''}
            onKeyUp={onKeyUp}
            readOnly={readOnly}
            onChange={(e) => onChange(e.target.value)}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onKeyDown={onKeyPress}
            autoFocus={autoFocus}
            disabled={disabled}
            onBlur={onBlur}
            min={minVal}
            max={maxVal}
            maxLength={maxLength}
          />
        )}
      </div>
      {formError && <div style={{ color: 'red' }}>{formError}</div>}
    </>
  );
};
export default React.memo(WeInput);
