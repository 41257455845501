import React, { useRef } from 'react';
// import { useOutsideAlerter } from 'utils/appConstant';

const GroupItems = ({ ...props }) => {
  const {
    key,
    openGroupMenuId,
    onItemGroupMenuClick,
    itemData,
    onDropComplete,
    // onEditGroup,
    onDeleteGroup
  } = props;


  const wrapperRef = useRef(null);

  // const handleCloseMenu=()=>{
  //   onItemGroupMenuClick(-1);
  // }

  // useOutsideAlerter(wrapperRef, handleCloseMenu);

  return (
    <>
      <div
        className="group-row"
        onDrop={() => {
          onDropComplete(itemData);
        }}
        onDragOver={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        key={key}
        style={{ zIndex: key }}>
        <div className="row">
          <div className="col-10 col-lg-6">
            <div className="d-flex align-items-center">
              <div className="move">
                <i className="fa-solid fa-layer-group"></i>
              </div>
              <div className="group-name">
                <div className="title">{itemData.groupName}</div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 d-none d-lg-block">
            <div className="text-end">
              {itemData.totalSold}/{itemData.totalCapacity}
            </div>
          </div>
          <div className="col-lg-3 d-none d-lg-block">
            <div className="text-end">
              ${itemData.min}
              {itemData.min != itemData.max ? <>—${itemData.max}</> : ''}
            </div>
          </div>
          <div className="col-2 col-lg-1">
            <div
              className={
                openGroupMenuId === itemData.id
                  ? 'dropdown justify-content-end active'
                  : 'dropdown justify-content-end'
              }
              data-dropdown>
              <a
                className="dropdown-button d-inline-block"
                data-dropdown-button
                onClick={() => {
                  if (openGroupMenuId === itemData.id) {
                    onItemGroupMenuClick(-1);
                  } else {
                    onItemGroupMenuClick(itemData.id);
                  }
                }}>
                <span className="fa-stack fa-1x">
                  <i className="fa-solid fa-circle fa-stack-2x"></i>
                  <i className="fa-solid fa-ellipsis-vertical fa-stack-1x fa-inverse"></i>
                </span>
              </a>
              <div className="dropdown-menu information-grid" ref={wrapperRef}>
                <div className="dropdown-links">
                  {/* <a className="link" onClick={() => onEditGroup(itemData)}>
                    Edit
                  </a> */}
                  <a className="link" onClick={() => onDeleteGroup(itemData)}>
                    Delete
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(GroupItems);
