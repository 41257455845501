import WeToggle from 'components/weToggle';
import PageForNoPermission from 'pages/pageForNoPermission';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { eventDetail } from 'store/slice/event';
import { refundAndTaxSettings } from 'store/slice/payment';

const TaxSettings = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { eventDetailData, isOwnerValue } = useSelector((state) => state.event);
  const [taxSettingsValue, setTaxSettingsValue] = useState({
    eventUid: eventDetailData ? eventDetailData.eventUid : id
  });

  useEffect(() => {
    if (id) {
      dispatch(eventDetail(id));
    }
  }, [id]);

  useEffect(() => {
    setTaxSettingsValue((taxSettingsValue) => ({
      ...taxSettingsValue,
      nonProfitOrganization: eventDetailData?.nonProfitOrganization,
      isLivePerformance: eventDetailData?.isLivePerformance
    }));
  }, [eventDetailData]);

  const handleSubmit = () => {
    dispatch(refundAndTaxSettings(taxSettingsValue));
  };

  return isOwnerValue ? (
    <>
      <h2>Tax Settings (Needs to change text)</h2>
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>
      <br />
      <form action="">
        <h3>Sales tax</h3>
        <p>
          <strong>
            Are you a non-profit organization under 501(c)(3) of the Internal Revenue Code?
          </strong>
        </p>
        <div className="mt-2">
          <WeToggle
            checkToogle={taxSettingsValue && taxSettingsValue.nonProfitOrganization}
            onChange={(checked) => {
              setTaxSettingsValue((taxSettingsValue) => ({
                ...taxSettingsValue,
                nonProfitOrganization: checked
              }));
            }}
            onSwitchClick={() => {
              taxSettingsValue.nonProfitOrganization = !taxSettingsValue.nonProfitOrganization;
              handleSubmit();
            }}
            toggleLabel=" Yes, I am a non-profit organization under 501(c)(3) of the Internal Revenue Code"
          />
        </div>
        <br />
        <p>
          <strong>
            Is your event a live performance of ballet, dance, choral performance, concert, play,
            opera or an exhibition of paintings, sculpture, photography, graphic or crafts?
          </strong>
        </p>
        <div className="mt-2">
          <WeToggle
            checkToogle={taxSettingsValue && taxSettingsValue.isLivePerformance}
            onChange={(checked) => {
              setTaxSettingsValue((taxSettingsValue) => ({
                ...taxSettingsValue,
                isLivePerformance: checked
              }));
            }}
            onSwitchClick={() => {
              taxSettingsValue.isLivePerformance = !taxSettingsValue.isLivePerformance;
              handleSubmit();
            }}
            toggleLabel=" Yes, my event is a live performance of ballet, dance, choral performance, concert, play,
            opera or an exhibition of paintings, sculpture, photography, graphics or crafts"
          />
        </div>
      </form>
    </>
  ) : (
    <PageForNoPermission />
  );
};

export default TaxSettings;
