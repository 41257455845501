import moment from 'moment';
import React from 'react';
import image from '../../../assets/img/placeholder.jpeg';
import './style.css';

const EventList = ({ ...props }) => {
  const { ticketData, onClickItem } = props;

  return (
    <>
      <a className="overview-block" onClick={() => onClickItem(ticketData)}>
        <div className="event-block">
          <div className="background-image">
            <img src={ticketData.bannerImageName ? ticketData.bannerImageName : image} alt="logo" />
            <div className="overlay"></div>
          </div>
          <div className="date">
            <div className="slab">
              <div className="day">{moment(ticketData.startDate).format('DD')}</div>
              <div className="month">{moment(ticketData.startDate).format('MMMM')}</div>
            </div>
          </div>
          <div className="content">
            <div className="organizer title">
              {ticketData.title}
              <span className="fa-stack fa-1x">
                <i className="fa-solid fa-badge fa-stack-2x"></i>
                <i className="fa-solid fa-check fa-stack-1x fa-inverse"></i>
              </span>
            </div>
            <div className="event-name description">{ticketData.description}</div>
            <div className="event-date">
              {moment(ticketData?.startDate).format('MMMM DD, YYYY')}
            </div>
            <div className="event-info">Price: $99 - $1,545 | Attendees: 85</div>
          </div>
        </div>
      </a>
    </>
  );
};

export default React.memo(EventList);
