import { createSlice } from '@reduxjs/toolkit';
import api from '../../services';
import { API_RESPONSE, TESLOOMAPI } from '../../services/apiConstant';
import { toast } from 'react-toastify';

const slice = createSlice({
  name: 'addons',
  initialState: {
    listAllAddOnsData: [],
    viewAddOnsData: null,
    message: '',
    addOnItem: null
  },
  reducers: {
    listAllAddOnsSuccess(state, action) {
      state.listAllAddOnsData = action.payload.data;
    },
    viewAddOnsSuccess(state, action) {
      state.viewAddOnsData = action.payload;
    },
    addAddOnsSuccess(state, action) {
      state.listAllAddOnsData = state.listAllAddOnsData.concat(action.payload);
    },
    deleteAddOnsSuccess(state, action) {
      let reqData = action.payload.reqData;
      state.listAllAddOnsData = state.listAllAddOnsData.filter((item) => item._id !== reqData);
    },
    editAddOnsSuccess(state, action) {
      const newObjId = action.payload._id;
      const suspendIndex = state.listAllAddOnsData?.findIndex((item) => item._id === newObjId);
      let currentUser = action.payload;
      state.listAllAddOnsData[suspendIndex] = currentUser;
    },
    selectAddOnData(state, action) {
      state.addOnItem = action.payload;
    },
    clearStatesDataSuccess(state) {
      state.listAllAddOnsData = [];
    }
  }
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

const {
  listAllAddOnsSuccess,
  viewAddOnsSuccess,
  addAddOnsSuccess,
  deleteAddOnsSuccess,
  editAddOnsSuccess,
  selectAddOnData,
  clearStatesDataSuccess
} = slice.actions;

export const addAddOns = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.addAddOns}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(addAddOnsSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const listAllAddOns = (id) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.listAllAddOns}`, id)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(listAllAddOnsSuccess(result));
      } else {
        // toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const deleteAddOns = (id) => async (dispatch) => {
  api
    .delete(`${TESLOOMAPI.deleteAddOns}` + id)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(deleteAddOnsSuccess({ reqData: id }));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const viewAddOns = (id) => async (dispatch) => {
  api
    .get(`${TESLOOMAPI.viewAddOns}` + id)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(viewAddOnsSuccess(result));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const editAddOns = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.editAddOns}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(editAddOnsSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const selectedAddonData = (data) => async (dispatch) => {
  dispatch(selectAddOnData(data));
};

export const clearAddonData = () => async (dispatch) => {
  dispatch(selectAddOnData());
};

export const clearAddonStates = () => async (dispatch) => {
  dispatch(clearStatesDataSuccess());
};
