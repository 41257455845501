import React from 'react';

const ticketsTab = [
  {
    id: 0,
    icon: 'fa-solid fa-ticket',
    title: 'Tickets'
  },
  {
    id: 1,
    icon: 'fa-solid fa-stars',
    title: 'Add Ons'
  },
  {
    id: 2,
    icon: 'fa-solid fa-tags',
    title: 'Coupons'
  },
  {
    id: 3,
    icon: 'fa-solid fa-hand',
    title: 'On Hold'
  },
  {
    id: 4,
    icon: 'fa-solid fa-gears',
    title: 'Settings'
  }
];
const TicketsTopHeader = ({ ...props }) => {
  const { onTabSelected, activeTab } = props;

  return (
    <div className="ticket-menu">
      <ul>
        {ticketsTab.map((item, index) => {
          return (
            <li
              key={index}
              onClick={() => {
                onTabSelected(item);
              }}>
              <a href="#" className={item.id === activeTab ? 'active' : ''}>
                <span className="icon">
                  <i className={item.icon}></i>
                </span>
                <span className="title">{item.title}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default React.memo(TicketsTopHeader);
