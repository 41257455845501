// import { Outlet } from 'react-router-dom';
import React from 'react';

export default function PageForNoPermission() {
  return (
    <div style={{ position: 'absolute', top: '45%', right: '10%' }}>
      <div style={{ fontSize: '2rem', textAlign: 'center' }}>
        You don't have permission to access this page
      </div>
    </div>
  );
}
