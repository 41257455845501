import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from 'components/dialogs/confirmDialog';
// import CreateHoldonDialog from 'components/dialogs/createHoldonDialog';
import EventContext from 'context/eventContext';
import EmptyView from 'components/emptyView';
import CreateAddonDialog from 'components/dialogs/createAddonDialog';
import {
  addAddOns,
  clearAddonData,
  deleteAddOns,
  editAddOns,
  listAllAddOns,
  selectedAddonData
} from 'store/slice/addons';
import { useParams } from 'react-router-dom';
// import { useOutsideAlerter } from 'utils/appConstant';

const TabAddOns = () => {
  const { eventFormData } = useContext(EventContext);

  const dispatch = useDispatch();
  const { listAllAddOnsData } = useSelector((state) => state.addons);
  // const [isactive, setActive] = useState(false);
  const [addOnsName, setAddOnsName] = useState('');
  const [addOnsNameError, setAddOnsNameError] = useState('');
  const [capacity, setCapacity] = useState('');
  const [capacityError, setCapacityError] = useState('');
  var [price, setPrice] = useState('');
  const [priceError, setPriceError] = useState('');
  const [show, setShow] = useState(false);
  // const { viewAddOnsData } = useSelector((state) => state.addons);
  const [openGroupMenuId, setGroupMenuId] = useState(-1);
  const [confirmDialogData, setConfirmDialogData] = useState(null);

  const { id } = useParams();

  const handleClose = () => {
    setShow(false);
    setAddOnsName('');
    setCapacity('');
    setPrice('');
    setGroupMenuId(-1);
    dispatch(clearAddonData());
  };
  const handleShow = () => setShow(true);
  const { addOnItem } = useSelector((state) => state.addons);

  useEffect(() => {
    const reqData = {
      eventUid: eventFormData.eventUid ?? id
    };
    dispatch(listAllAddOns(reqData));
  }, []);

  const deleteAddOn = (_id) => {
    dispatch(deleteAddOns(_id));
  };

  const editAddOn = (item) => {
    dispatch(selectedAddonData(item));
    setAddOnsName(item.name);
    setCapacity(item.capacity);
    setPrice(item.price);
    handleShow();
  };

  const handleSubmit = (item) => {
    // const strAfterDot = price.split('.', 2)[1];
    if (!addOnsName) {
      setAddOnsNameError('Please add add-ons name');
      return;
    }
    if (!capacity) {
      setCapacityError('Please add capacity');
      return;
    }
    if (!price) {
      setPriceError('Please add price');
      return;
    }
    // if (strAfterDot?.length < 2) {
    //   price = price + '0';
    // }

    if (item) {
      const addonsData = {
        addOnsId: item._id ? item._id : openGroupMenuId,
        name: addOnsName,
        capacity: capacity,
        sold: item.sold,
        price: price
      };
      dispatch(editAddOns(addonsData));
    } else {
      const addonsData = {
        eventUid: eventFormData.eventUid ?? id,
        name: addOnsName,
        capacity: capacity,
        price: price
      };
      dispatch(addAddOns(addonsData));
    }

    handleClose();
  };

  const wrapperRef = useRef(null);

  // const handleCloseMenu=()=>{
  //   setGroupMenuId(-1);
  // }

  // useOutsideAlerter(wrapperRef, handleCloseMenu);

  const renderListView = () => {
    return (
      <>
        <div className="group-heading d-none d-lg-block">
          <div className="row">
            <div className="col-lg-6">
              <div className="d-flex align-items-center">
                <div className="group-name">
                  <div className="title">Add On</div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="text-end">Sold</div>
            </div>
            <div className="col-lg-3">
              <div className="text-end">Price</div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
        <div className="group-section coupon-section">
          {listAllAddOnsData?.map((item, index) => {
            return (
              <>
                <div className="group-row mt-2" key={index} style={{position:"initial"}}>
                  <div className="row">
                    <div className="col-10 col-lg-6">
                      <div className="d-flex align-items-center">
                        <div className="move">
                          <i className="fa-solid fa-up-down-left-right"></i>
                        </div>
                        <div className="group-name">
                          <div className="title">{item.name}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 d-none d-lg-block">
                      <div className="text-end">
                        {item.sold}/{item.capacity}
                      </div>
                    </div>
                    <div className="col-lg-3 d-none d-lg-block">
                      <div className="text-end">${item.price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                    </div>
                    <div className="col-2 col-lg-1">
                      <div
                        className={
                          openGroupMenuId === item._id
                            ? 'dropdown justify-content-end active'
                            : 'dropdown justify-content-end'
                        }
                        data-dropdown>
                        <a
                          className="dropdown-button d-inline-block"
                          data-dropdown-button
                          onClick={() => {
                            if (openGroupMenuId === item._id) {
                              setGroupMenuId(-1);
                            } else {
                              setGroupMenuId(item._id);
                            }
                          }}>
                          <span className="fa-stack fa-1x">
                            <i className="fa-solid fa-circle fa-stack-2x"></i>
                            <i className="fa-solid fa-ellipsis-vertical fa-stack-1x fa-inverse"></i>
                          </span>
                        </a>
                        <div className="dropdown-menu information-grid" ref={wrapperRef}>
                          <div className="dropdown-links">
                            <a
                              className="link"
                              onClick={() => {
                                setGroupMenuId(-1);
                                editAddOn(item);
                              }}>
                              Edit
                            </a>
                            <a
                              className="link"
                              onClick={() => {
                                setGroupMenuId(-1);
                                setConfirmDialogData({
                                  ...confirmDialogData,
                                  show: true,
                                  type: 'Ticket',
                                  id: item._id,
                                  item: item._id,
                                  title: 'Delete Addon',
                                  message: 'Are you sure, you want to delete addon?'
                                });
                              }}>
                              Delete
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="mt-3">
        <div className="mb-3">
          <a className="btn primary-btn mt-2" onClick={handleShow}>
            Create Add On
          </a>
        </div>

        {listAllAddOnsData.length === 0 ? (
          <>
            <EmptyView
              text="No Add-Ons Created"
              message="Create your Add-Ons ticket for your event."
            />
          </>
        ) : (
          <> {renderListView()}</>
        )}
      </div>

      <CreateAddonDialog
        showDialog={show}
        onDialogClose={handleClose}
        onNameInputChange={(val) => {
          if (val) {
            setAddOnsNameError('');
          }
          setAddOnsName(val);
        }}
        onCapacityInputChange={(val) => {
          if (val) {
            setCapacityError('');
          }
          setCapacity(val.replace(/[^\d]/, ''));
        }}
        onPriceInputChange={(val) => {
          const cleanNumber = val.replace(/[^0-9.]/g, '');
          const strAfterDot = cleanNumber.split('.', 2)[1];
          if (!strAfterDot || strAfterDot.length <= 2) {
            setPriceError('');
            setPrice(val);
          }
        }}
        addOnsNameError={addOnsNameError}
        capacityError={capacityError}
        priceError={priceError}
        addOnName={addOnsName}
        addOnCapacity={capacity}
        addOnPrice={price}
        btnSave={() => {
          handleSubmit(addOnItem);
        }}
      />

      {confirmDialogData && (
        <ConfirmDialog
          showDialog={confirmDialogData.show}
          title={confirmDialogData.title}
          message={confirmDialogData.message}
          onDialogClose={() => {
            setConfirmDialogData((confirmDialogData) => ({
              ...confirmDialogData,
              show: false
            }));
            setGroupMenuId(-1);
          }}
          btnConfirm={() => {
            deleteAddOn(confirmDialogData.item);
            setConfirmDialogData((confirmDialogData) => ({
              ...confirmDialogData,
              show: false
            }));
            setGroupMenuId(-1);
          }}
        />
      )}
    </>
  );
};
export default React.memo(TabAddOns);
