import ConfirmDialog from 'components/dialogs/confirmDialog';
import CreateReminderDialog from 'components/dialogs/createReminderDialog';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteReminder } from 'store/slice/tickets';

const EmailReminderItem = (props) => {
  const dispatch = useDispatch();
  const {
    listData,
    key,
    onEmailSelectItem,
    onTimingSelect,
    onEmailDropDownClick,
    onTimingDropDownClick,
    senderNameChange,
    ReplyToChange,
    SubjectChange,
    MessageChange,
    formEmailData,
    setFormEmailData,
    showEmailTo,
    showTiming,
    showSpecificAteendee,
    onSpecificDropDownClick,
    onSpecificAttendeeSelect,
    onTicketSelect,
    customTimeChange,
    handleSubmit,
    isNotAction,
    func
  } = props;
  const [isactive, setActive] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [reminderDialog, setReminderDialog] = useState(false);
  const [title, setTitle] = useState('');
  const handlecloseDialog = () => {
    setDeleteDialog(false);
    setReminderDialog(false);
    setFormEmailData({});
  };

  const handleDelete = () => {
    setDeleteDialog(true);
  };
  const handleConfirmDelete = () => {
    dispatch(deleteReminder(listData));
    setDeleteDialog(false);
  };
  const handleDuplicate = (data) => {
    setTitle('Duplicate');
    func('Duplicate');
    setFormEmailData(data);
    setReminderDialog(true);
  };
  const handleUpdate = (data) => {
    setTitle('Update');
    func('Update');
    setFormEmailData(data);
    setReminderDialog(true);
  };

  return (
    <div className="row event-row" key={key}>
      <ConfirmDialog
        showDialog={deleteDialog}
        onDialogClose={handlecloseDialog}
        message={'Are you sure You Want to delete this event?'}
        btnConfirm={handleConfirmDelete}
      />
      <CreateReminderDialog
        title={title}
        showReminderDialog={reminderDialog}
        handleClose={handlecloseDialog}
        handlecloseDialog={handlecloseDialog}
        showEmailTo={showEmailTo}
        showTiming={showTiming}
        formEmailData={formEmailData}
        showSpecificAteendee={showSpecificAteendee}
        SubjectChange={SubjectChange}
        MessageChange={MessageChange}
        senderNameChange={senderNameChange}
        ReplyToChange={ReplyToChange}
        onEmailSelectItem={onEmailSelectItem}
        onTimingSelect={onTimingSelect}
        onTicketSelect={onTicketSelect}
        onSpecificAttendeeSelect={onSpecificAttendeeSelect}
        onEmailDropDownClick={onEmailDropDownClick}
        onTimingDropDownClick={onTimingDropDownClick}
        onSpecificDropDownClick={onSpecificDropDownClick}
        customTimeChange={customTimeChange}
        handleSubmit={handleSubmit}
      />
      <div className="col-12 mt-1">
        <div className="row">
          <div className="col-12 col-lg-1 d-none d-lg-flex align-items-center">
            {listData?.status === 'scheduled' ? (
              <a
                href="#"
                onClick={() => {
                  setActive(false);
                  handleUpdate(listData);
                }}>
                <i className="fa-solid fa-pen-to-square"></i>
              </a>
            ) : (
              ''
            )}
          </div>
          <div className="col-10 col-lg-10 d-none d-lg-block align-self-center">
            <div className="row">
              <div className="col-12 col-lg-4 d-flex align-items-center">
                <div className="ticket-type-name">{listData?.subject}</div>
              </div>
              <div className="col-12 col-lg-8">
                <div className="row">
                  <div className="col-12 col-lg-6 d-flex align-items-center">
                    {listData?.attendees === true ? 'Yes' : 'No'}
                  </div>
                  <div className="col-12 col-lg-6">{listData?.date}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-10 d-lg-none">
            <div className="d-flex h-100 align-items-center">
              <span className="heading align-self-center me-lg-0 me-4">
                <a href="#" data-bs-toggle="modal" data-bs-target="#ticketTypeCustomSettingModal">
                  <i className="bi bi-pencil-square" style={{ color: 'var(--bs-lt-red)' }}></i>
                </a>
              </span>
              <div className="mobile-event-block w-100 d-block d-lg-none">
                <div className="info mb-2">
                  <div>
                    <strong>{listData?.subject}</strong>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="info me-4">
                    <span className="heading d-block">Attendees:</span>
                    <span>{listData?.attendees === true ? 'Yes' : 'No'}</span>
                  </div>
                  <div className="info">
                    <span className="heading d-block">Scheduled:</span>
                    <span>{listData?.date}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!isNotAction ? (
            <div className="col-2 col-lg-1">
              <div className="d-flex align-items-center h-100 justify-content-end">
                <div className={isactive ? 'dropdown active' : 'dropdown'} data-dropdown>
                  <a
                    href="#a"
                    className="dropdown-button d-inline-block"
                    data-dropdown-button
                    onClick={() => setActive(!isactive)}>
                    <span className="fa-stack fa-1x">
                      <i className="fa-solid fa-circle fa-stack-2x"></i>
                      <i className="fa-solid fa-ellipsis-vertical fa-stack-1x fa-inverse"></i>
                    </span>
                  </a>
                  <div className="dropdown-menu information-grid">
                    <div className="dropdown-links">
                      {/* {listData?.status === 'scheduled' ? (
                        <>
                          <a
                            onClick={() => {
                              setActive(false);
                              handleUpdate(listData);
                            }}
                            className="link">
                            Edit
                          </a>
                        </>
                      ) : (
                        ''
                      )} */}
                      <a
                        onClick={() => {
                          setActive(false);
                          handleDuplicate(listData);
                        }}
                        className="link">
                        Duplicate
                      </a>
                      <a
                        onClick={() => {
                          setActive(false);
                          handleDelete();
                        }}
                        className="link">
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};
export default React.memo(EmailReminderItem);
