import PaginatioonComponent from 'components/paginatioonComponent';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganiserPayouts } from 'store/slice/order';

export default function FuturePayout() {
  const dispatch = useDispatch();
  const { payoutData,totalCount } = useSelector((state) => state.order);
  let page = 1;
  let limit = 10;
  const [pageNo, setPageNo] = useState(page);

  const handlePagination = (data) => {
    // const page = flag == 'next' ? pageNo + 1 : pageNo - 1;
    console.log('onPageChange', data.selected + 1);
    setPageNo(data.selected + 1);
  };

  useEffect(() => {
    fetchAllTickets();
  }, [pageNo]);

  useEffect(() => {
    fetchAllTickets();
  }, []);

  const fetchAllTickets = () => {
    const filter = {
      page: pageNo,
      perPage: limit,
      type: 2
    };
    dispatch(getOrganiserPayouts(filter));
  };

  return (
    <>
      <h2>Future Payouts</h2>

      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/finance">Finance</a>
            </li>
            <li className="breadcrumb-item">Future Payouts</li>
          </ol>
        </nav>
      </div>

      <div className="ticket-table">
        <div className="row heading d-block">
          <div className="col-12">
            <div className="row">
              <div className="col-7">Event</div>
              <div className="col-5">Next Payout</div>
            </div>
          </div>
        </div>
        {payoutData ? (
          payoutData?.map((item) => {
            return (
              <>
                <div className="row event-row">
                  <div className="col-12 mt-1">
                    <div className="row">
                      <div className="col-7">
                        <div className="one-line">
                          <a href="#">{item.eventName}</a> <br />
                          <small>
                            Schedule:{' '}
                            {item.schedule == 'afterEvent'
                              ? 'After Event'
                              : item.schedule == 'biWeekly'
                              ? 'Bi Weekly'
                              : item.schedule == 'weekly'
                              ? 'Weekly'
                              : ''}
                          </small>
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="one-line">{item.paid}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <p className="text-center pt-5"> No Data Found</p>
        )}
        {payoutData && totalCount > limit ? (
        <div className="mt-3" style={{ float: 'right' }}>
          <PaginatioonComponent
            pageCount={
              totalCount % limit === 0
                ? Math.floor(totalCount / limit)
                : Math.floor(totalCount / limit) + 1
            }
            onPageChange={handlePagination}
            pageNo={pageNo}
          />
        </div>
      ) : (
        ''
      )}
      </div>
    </>
  );
}
