import WeInput from 'components/weInput';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { editOrganizationInfo } from '../../store/slice/event';
import { toast } from 'react-toastify';

const EditOrganizationInfoDialog = ({ ...props }) => {
  const { showDialog, onDialogClose, organizationData } = props;

  const dispatch = useDispatch();
  const [organizationInfo, setOrganizationInfo] = useState({});
  const [infoError, setInfoError] = useState({});

  useEffect(() => {
    setOrganizationInfo((organizationInfo) => ({
      ...organizationInfo,
      ...organizationData
    }));
  }, [organizationData]);

  const handleSubmit = () => {
    if (!organizationInfo.organizationName) {
      setInfoError((infoError) => ({
        ...infoError,
        organizationName: 'Please enter organizationName'
      }));
      return;
    }
    if (!organizationInfo.organizationBio) {
      setInfoError((infoError) => ({
        ...infoError,
        organizationBio: 'Please enter organizationBio'
      }));
      return;
    }
    if (!organizationInfo.website) {
      setInfoError((infoError) => ({
        ...infoError,
        website: 'Please enter Website'
      }));
      return;
    }
    if (
      !organizationInfo.website.includes('https://') &&
      !organizationInfo.website.includes('http://')
    ) {
      return toast.error('Website must include "https://" or "http://"');
    }
    if (!organizationInfo.organzationUrl) {
      setInfoError((infoError) => ({
        ...infoError,
        organzationUrl: 'Please enter Organization URL'
      }));
      return;
    }
    dispatch(editOrganizationInfo(organizationInfo));
    onDialogClose();
  };

  return (
    <Modal
      show={showDialog}
      onHide={() => {
        setOrganizationInfo((organizationInfo) => ({
          ...organizationInfo,
          ...organizationData
        }));
        setInfoError({});
        onDialogClose();
      }}
      centered
      backdrop="static"
      keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5">Edit Organization</h1>
            <a
              className="close closeBtn"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onDialogClose}>
              <i className="fa-solid fa-xmark"></i>
            </a>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <WeInput
          onChange={(val) => {
            if (val) {
              setInfoError((infoError) => ({
                ...infoError,
                organizationName: ''
              }));
            }
            setOrganizationInfo((organizationInfo) => ({
              ...organizationInfo,
              organizationName: val
            }));
          }}
          inputLabel="Name"
          autoFocus={true}
          formError={infoError.organizationName}
          value={organizationInfo.organizationName ?? ''}
        />

        <WeInput
          onChange={(val) => {
            if (val) {
              setInfoError((infoError) => ({
                ...infoError,
                organizationBio: ''
              }));
            }
            setOrganizationInfo((organizationInfo) => ({
              ...organizationInfo,
              organizationBio: val
            }));
          }}
          inputLabel="Bio"
          formError={infoError.organizationBio}
          value={organizationInfo.organizationBio ?? ''}
        />
        <WeInput
          onChange={(val) => {
            if (val) {
              setInfoError((infoError) => ({
                ...infoError,
                website: ''
              }));
            }
            setOrganizationInfo((organizationInfo) => ({
              ...organizationInfo,
              website: val
            }));
          }}
          inputLabel="Website"
          formError={infoError.website}
          value={organizationInfo.website ?? ''}
        />
        <div style={{ color: 'var(--bs-red)', fontSize: '13px' }} className="mt-2">
          <b>Note:-</b> Website must inclue "https://" or "http://"
        </div>
        <WeInput
          onChange={(val) => {
            if (val) {
              setInfoError((infoError) => ({
                ...infoError,
                organzationUrl: ''
              }));
            }
            setOrganizationInfo((organizationInfo) => ({
              ...organizationInfo,
              organzationUrl: val
            }));
          }}
          style={{ paddingLeft: '0px' }}
          inputLabel="Organization URL"
          prefix={'tesloom.com/'}
          formError={infoError.organzationUrl}
          value={organizationInfo.organzationUrl ?? ''}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="btn secondary-btn"
          onClick={() => {
            setOrganizationInfo((organizationInfo) => ({
              ...organizationInfo,
              ...organizationData
            }));
            setInfoError({});
            onDialogClose();
          }}>
          Close
        </Button>
        <Button className="btn primary-btn" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(EditOrganizationInfoDialog);
