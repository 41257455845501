import React, { useContext, useEffect, useState } from 'react';
import TicketsTopHeader from './subcomponents/topHeader';
import TabTickets from './sections/tabTickets';
import TabAddons from './sections/tabAddon';
import TabOnHold from './sections/tabOnHold';
import TabCoupons from './sections/tabCoupons';
import TabSettings from './sections/tabSettings';
import EventHeader from '../eventHeader';
import EventContext from 'context/eventContext';
import { useDispatch, useSelector } from 'react-redux';
import { eventDetail, updateEvent } from 'store/slice/event';
import { useParams } from 'react-router-dom';
import FooterButtons from '../footerButtons';
import PageForNoPermission from 'pages/pageForNoPermission';

const Tickets = ({ ...props }) => {
  const { btnPrevious, onSave, activeStep } = props;
  const { eventFormData, setEventFormData } = useContext(EventContext);
  const [activeTab, setSelectedActiveTab] = useState(0);
  const dispatch = useDispatch();
  const { id } = useParams();
  const path = window.location.pathname;
  const isCreate = path.includes('create');
  const { eventDetailData, userPermissionData, isOwnerValue } = useSelector((state) => state.event);

  useEffect(() => {
    if (id) {
      dispatch(eventDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (!id) {
      dispatch(eventDetail(eventFormData?.eventUid));
    }
  }, []);

  useEffect(() => {
    if (eventDetailData) {
      setEventFormData(eventDetailData);
    }
  }, [eventDetailData]);

  const handleTabSelected = (item) => {
    setSelectedActiveTab(item.id);
  };

  const renderTabView = () => {
    if (activeTab === 0) {
      return <TabTickets />;
    } else if (activeTab === 1) {
      return <TabAddons />;
    } else if (activeTab === 2) {
      return <TabCoupons />;
    } else if (activeTab === 3) {
      return <TabOnHold />;
    } else if (activeTab === 4) {
      return <TabSettings />;
    }
  };

  const btnSaveContinue = () => {
    console.log("eventFormData == ",eventFormData);
    dispatch(updateEvent(eventFormData));
    if (!id) {
      onSave();
    }
  };

  return isCreate || isOwnerValue || userPermissionData?.event.manageTicket ? (
    <>
      <EventHeader
        title="Tickets"
        subTitle="Here you can make tickets, group them, add extras, use coupons, and tweak settings for your event."
      />

      <TicketsTopHeader onTabSelected={handleTabSelected} activeTab={activeTab} />
      {renderTabView()}

      <br />
      <FooterButtons
        isEdit={id ? true : false}
        btnSaveContinue={btnSaveContinue}
        isLoading={false}
        btnBack={btnPrevious}
        activeStep={activeStep}
        btnNotShow={id && activeTab != 4 ? true : false}
        btnSaveName={id ? 'Update' : 'Next'}
      />
    </>
  ) : (
    <PageForNoPermission />
  );
};

export default Tickets;
