import WeTextArea from 'components/weTextArea';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addNotesInOrder } from 'store/slice/order';

const AddNewNotesDialog = ({ ...props }) => {
  const { showDialog, onDialogClose } = props;
  const { _id } = useParams();
  const dispatch = useDispatch();
  // const briefMaxLength = 150;
  const [notes, setNotes] = useState({
    orderId: _id
  });
  const [formError, setFormError] = useState();

  const btnSave = () => {
    if (!notes) {
      setFormError('Please enter note');
    }
    dispatch(addNotesInOrder(notes));
    setNotes({
      orderId: _id
    });
    onDialogClose();
  };

  return (
    <Modal
      show={showDialog}
      onHide={() => {
        setNotes({});
        setFormError('');
        onDialogClose();
      }}
      centered
      backdrop="static"
      keyboard={false}>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          {' '}
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5">New Note</h1>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <WeTextArea
          maxRows={3}
          className={'input-block mt-1'}
          autoFocus={true}
          onChange={(val) => {
            setNotes((notes) => ({
              ...notes,
              note: val
            }));
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              btnSave();
            }
          }}
          inputLabel="Write note"
          value={notes.note}
          formError={formError}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="btn secondary-btn"
          onClick={() => {
            setNotes({});
            setFormError('');
            onDialogClose();
          }}>
          Close
        </Button>
        <Button className="btn primary-btn" onClick={btnSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(AddNewNotesDialog);
