import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import navConfig from './config';
import { Button } from 'react-bootstrap';
import secureLocalStorage from 'react-secure-storage';
import image from 'assets/img/wedancemo_logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { clearEventTitle, clearEventStates } from 'store/slice/event';
import { clearOrderStates } from 'store/slice/order';
import { clearAttendeeInOrder, clearticketStates } from 'store/slice/tickets';
import { clearOnHoldStates } from 'store/slice/onHolds';
import { clearCouponStates } from 'store/slice/coupon';
import { clearAddonStates } from 'store/slice/addons';
import { clearOptionsStates } from 'store/slice/options';

function Drawer() {
  const [matches, setMatches] = useState(window.matchMedia('(min-width: 768px)').matches);
  const [show, setShow] = useState(false);
  const path = window.location.origin;
  const navigate = useNavigate();
  const [isActive, setActive] = useState('');
  const dispatch = useDispatch();
  const { activeSideMenu } = useSelector((state) => state.event);
  useEffect(() => {
    window
      .matchMedia('(min-width: 768px)')
      .addEventListener('change', (e) => setMatches(e.matches));
    activeMenu();
  }, []);

  useEffect(() => {
    if (activeSideMenu) {
      activeMenu();
    }
  }, [activeSideMenu]);

  const activeMenu = () => {
    const pathName = window.location.pathname;

    if (pathName.includes('event')) {
      setActive('/event/dashboard');
    } else if (pathName.includes('orders')) {
      setActive('/orders');
    } else if (pathName.includes('reports')) {
      setActive('/reports');
    } else if (pathName.includes('finance')) {
      setActive('/finance');
    } else if (pathName.includes('settings')) {
      setActive('/settings');
    } else {
      setActive('/dashboard');
    }
  };

  const handleOpen = () => {
    setShow(!show);
  };

  const handleClearEvent = () => {
    dispatch(clearEventTitle());
    dispatch(clearticketStates());
    dispatch(clearAddonStates());
    dispatch(clearCouponStates());
    dispatch(clearOnHoldStates());
    dispatch(clearOptionsStates());
    dispatch(clearAttendeeInOrder());
  };

  const btnLogout = () => {
    secureLocalStorage.clear();
    dispatch(clearEventStates());
    dispatch(clearOrderStates());
    navigate('/login');
  };

  return (
    <div className="sidebar-nav">
      <div className="row pt-2 pt-lg-4 pb-2 pb-lg-2">
        <div className="col-6 col-lg-12 d-flex d-lg-block align-items-center">
          <a className="navbar-brand d-flex d-lg-block mb-0 mb-lg-4" href="/">
            <img src={image} alt="Logo" className="d-inline-block align-text-top" />
            <span className="d-flex align-items-center d-lg-block mt-1 mt-lg-3 ms-2 ms-lg-0">
            Tesloom
            </span>
          </a>
        </div>
        <div className="col-6 col-lg-12 d-flex justify-content-end d-lg-none align-items-center">
          <div className={show ? 'hamburger change' : 'hamburger '} onClick={handleOpen}>
            <div className="bar-line bar1" />
            <div className="bar-line bar2" />
            <div className="bar-line bar3" />
          </div>
        </div>
      </div>
      <ul className={!matches && show ? 'show' : ''}>
        {navConfig.map((item, index) => {
          return (
            <li key={index}>
              <a
                className={isActive === item.path ? 'active' : 'none'}
                href={`${path}${item.path}`}
                onClick={(event) => {
                  event.preventDefault()
                  handleClearEvent();
                  setActive(item.path);
                  navigate(item.path);
                  setShow(!show);
                }} >
                <span className="fa-stack fa-1x">
                  <i className="fa-solid fa-circle fa-stack-2x"></i>
                  <i className={item.icon}></i>
                </span>{' '}
                <span className="title">{item.title}</span>
              </a>
            </li>
          );
        })}
        {/* <hr />
        {bottomNavConfig.map((item, index) => {
          return (
            <li key={index}>
              <a
                className={isActive === item.path ? 'active' : 'none'}
                onClick={() => {
                  setActive(item.path);
                  navigate(item.path);
                  setShow(!show);
                }}>
                <span className="fa-stack fa-1x">
                  <i className="fa-solid fa-circle fa-stack-2x"></i>
                  <i className={item.icon}></i>
                </span>{' '}
                <span className="title">{item.title}</span>
              </a>
            </li>
          );
        })} */}
      </ul>
      <Button
        variant="dark"
        style={{ position: 'fixed', bottom: '0', marginBottom: '30px' }}
        onClick={btnLogout}>
        <i
          className="bi bi-box-arrow-left"
          style={{ color: 'var(--bs-lt-red)', marginRight: '5px' }}></i>
        Logout
      </Button>
    </div>
  );
}

export default Drawer;
