import LineChart from 'components/weCharts/LineChart';
import WeDropDown from 'components/weDropDown';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportAnalytics } from 'store/slice/order';
const NewUsers = ({ ...props }) => {
  const { btnData } = props;
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('day');
  const { reportAnalyticsData } = useSelector((state) => state.order);

  const rangeData = [
    {
      label: '14 Days',
      id: 'day'
    },
    {
      label: 'Last Month',
      id: 'month'
    },
    {
      label: 'This Year',
      id: 'year'
    }
  ];

  useEffect(() => {
    getSalesReport();
  }, []);

  useEffect(() => {
    getSalesReport();
  }, [filter]);
  useEffect(() => {
    // if (btnData != 'attendees') {
    getSalesReport();
    // }
  }, [btnData]);

  const getSalesReport = () => {
    dispatch(
      reportAnalytics({
        dates: filter,
        type: btnData == 'attendees' ? 'revenue' : btnData
      })
    );
  };

  const salesData = {
    labels: reportAnalyticsData?.map((data) => moment(data.date).format('MMM DD')),
    datasets: [
      {
        label: 'Users',
        data: reportAnalyticsData?.map((data) => data.count),
        fill: false,
        borderColor: 'red',
        tension: 0.2
      }
    ]
  };

  return (
    <>
      {/* {btnData == 'attendees' ? (
        <h3>Coming Soon...</h3>
      ) : ( */}
      <div
        className="tab-pane fade show active"
        id="home-tab-pane"
        role="tabpanel"
        aria-labelledby="home-tab"
        tabIndex="0">
        <div className="row">
          <div className="col-12 col-xl-4">
            <WeDropDown
              label="Range"
              selectData={rangeData}
              defaultValue={filter}
              emptyValue="Select Range"
              onSelectItem={(item) => {
                setFilter(item);
              }}
            />
          </div>
        </div>
        <div className="mt-4">
          {' '}
          <LineChart chartData={salesData} />
        </div>
      </div>
      {/* )} */}
    </>
  );
};
export default React.memo(NewUsers);
