import { createSlice } from '@reduxjs/toolkit';
import api from '../../services';
import { TESLOOMAPI } from '../../services/apiConstant';
import { toast } from 'react-toastify';

const slice = createSlice({
  name: 'onHolds',
  initialState: {
    listAllOnHoldsData: [],
    viewOnHoldData: null,
    message: '',
    OnHoldItem: null
  },
  reducers: {
    listAllOnHoldsSuccess(state, action) {
      state.listAllOnHoldsData = action.payload.data;
    },
    viewOnHoldSuccess(state, action) {
      state.viewOnHoldData = action.payload;
    },
    addOnHoldSuccess(state, action) {
      state.listAllOnHoldsData = state.listAllOnHoldsData.concat(action.payload);
    },
    deleteOnHoldSuccess(state, action) {
      let reqData = action.payload.reqData;
      state.listAllOnHoldsData = state.listAllOnHoldsData.filter((item) => item._id !== reqData);
    },
    editOnHoldSuccess(state, action) {
      const newObjId = action.payload._id;
      const suspendIndex = state.listAllOnHoldsData?.findIndex((item) => item._id === newObjId);
      let currentUser = action.payload;
      state.listAllOnHoldsData[suspendIndex] = currentUser;
    },
    selectOnHoldData(state, action) {
      state.OnHoldItem = action.payload;
    },
    clearStatesDataSuccess(state) {
      state.listAllOnHoldsData = [];
    }
  }
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

const {
  listAllOnHoldsSuccess,
  viewOnHoldSuccess,
  deleteOnHoldSuccess,
  addOnHoldSuccess,
  editOnHoldSuccess,
  selectOnHoldData,
  clearStatesDataSuccess
} = slice.actions;
const { deletedSuccess } = slice.actions;

export const addOnHold = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.addOnHold}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(addOnHoldSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const listAllOnHolds = (id) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.listAllOnHolds}`, id)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(listAllOnHoldsSuccess(result));
      } else {
        // toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const deleteOnHold = (id) => async (dispatch) => {
  api
    .delete(`${TESLOOMAPI.deleteOnHold}` + id)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(deleteOnHoldSuccess({ reqData: id }));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const deleteApp = (appID) => async (dispatch) => {
  api
    .delete(`${TESLOOMAPI.deleteOnHold}/${appID}`)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(deletedSuccess(true));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const viewOnHold = (id) => async (dispatch) => {
  api
    .get(`${TESLOOMAPI.viewOnHold}` + id)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(viewOnHoldSuccess(result));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const editOnHold = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.editOnHold}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(editOnHoldSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const selectedOnHoldData = (data) => async (dispatch) => {
  dispatch(selectOnHoldData(data));
};

export const clearOnHoldData = () => async (dispatch) => {
  dispatch(selectOnHoldData());
};

export const clearOnHoldStates = () => async (dispatch) => {
  dispatch(clearStatesDataSuccess());
};
