import WeInput from 'components/weInput';
import WeTimePicker from 'components/weTimePicker';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { duplicateEvent } from 'store/slice/event';
import { currentTime, isFirefox, todayDate } from 'utils/appConstant';

const initialValues = {
  startDate: todayDate(),
  startTime: currentTime()
};

const DuplicateDialog = ({ ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showDialog, onDialogClose, data } = props;
  const [showInput, setShowInput] = useState(false);
  const [eventDateAndTime, setEventDateAndTime] = useState(initialValues);
  const [eventDateAndTimeError, setEventDateAndTimeError] = useState({});

  useEffect(() => {
    setEventDateAndTime((eventDateAndTime) => ({
      ...eventDateAndTime,
      eventUid: data?.eventUid
    }));
  }, [data]);

  const onModleClose = () => {
    onDialogClose();
    setShowInput(false);
    setEventDateAndTimeError(initialValues);
  };

  const onSubmit = () => {
    if (!eventDateAndTime.endDate) {
      setEventDateAndTimeError((eventDateAndTimeError) => ({
        ...eventDateAndTimeError,
        endDate: 'Please enter event end date'
      }));
      return;
    }
    if (!eventDateAndTime.endTime) {
      setEventDateAndTimeError((eventDateAndTimeError) => ({
        ...eventDateAndTimeError,
        endTime: 'Please enter event end time'
      }));
      return;
    }
    if (
      eventDateAndTime.startDate == eventDateAndTime.endDate &&
      eventDateAndTime.startTime >= eventDateAndTime.endTime
    ) {
      return toast.error('Event start date can not be greater then end date');
    }
    dispatch(duplicateEvent(eventDateAndTime, navigate));
    onModleClose();
  };
  return (
    <Modal show={showDialog} onHide={onModleClose} centered backdrop="static">
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          {' '}
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5">Duplicate Event </h1>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          {showInput
            ? 'Please enter event start date and end date'
            : 'Are you sure you want to duplicate this event'}
        </p>
        {showInput ? (
          <>
            <div className="row">
              <div className="col-6">
                <WeInput
                  type="date"
                  minVal={todayDate()}
                  autoFocus={true}
                  maxVal={eventDateAndTime?.endDate ? eventDateAndTime?.endDate : ''}
                  onChange={(val) => {
                    setEventDateAndTime((eventDateAndTime) => ({
                      ...eventDateAndTime,
                      startDate: val
                    }));
                  }}
                  inputLabel="Start Date"
                  value={eventDateAndTime.startDate}
                />
              </div>
              <div className="col-6">
                {isFirefox ?
                  <WeTimePicker
                    type="time"
                    onChange={(val) => {
                      setEventDateAndTime((eventDateAndTime) => ({
                        ...eventDateAndTime,
                        startDate: val
                      }));
                    }}
                    inputLabel="Start Time"
                    value={eventDateAndTime.startTime}
                  />
                  :
                  <WeInput
                    type="time"
                    onChange={(val) => {
                      setEventDateAndTime((eventDateAndTime) => ({
                        ...eventDateAndTime,
                        startDate: val
                      }));
                    }}
                    inputLabel="Start Time"
                    value={eventDateAndTime.startTime}
                  />
                }
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <WeInput
                  type="date"
                  minVal={eventDateAndTime?.startDate ? eventDateAndTime?.startDate : todayDate()}
                  onChange={(val) => {
                    if (val) {
                      setEventDateAndTimeError((eventDateAndTimeError) => ({
                        ...eventDateAndTimeError,
                        endDate: ''
                      }));
                    }
                    setEventDateAndTime((eventDateAndTime) => ({
                      ...eventDateAndTime,
                      endDate: val
                    }));
                  }}
                  inputLabel="End Date"
                  value={eventDateAndTime.endDate}
                  formError={eventDateAndTimeError.endDate}
                />
              </div>
              <div className="col-6">
                {isFirefox ?
                  <WeTimePicker
                    type="time"
                    onChange={(val) => {
                      if (val) {
                        setEventDateAndTimeError((eventDateAndTimeError) => ({
                          ...eventDateAndTimeError,
                          endTime: ''
                        }));
                      }
                      setEventDateAndTime((eventDateAndTime) => ({
                        ...eventDateAndTime,
                        endTime: val
                      }));
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        onSubmit();
                      }
                    }}
                    inputLabel="End Time"
                    value={eventDateAndTime.endTime}
                    formError={eventDateAndTimeError.endTime}
                  />
                  :
                  <WeInput
                    type="time"
                    onChange={(val) => {
                      if (val) {
                        setEventDateAndTimeError((eventDateAndTimeError) => ({
                          ...eventDateAndTimeError,
                          endTime: ''
                        }));
                      }
                      setEventDateAndTime((eventDateAndTime) => ({
                        ...eventDateAndTime,
                        endTime: val
                      }));
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        onSubmit();
                      }
                    }}
                    inputLabel="End Time"
                    value={eventDateAndTime.endTime}
                    formError={eventDateAndTimeError.endTime}
                  />
                }
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn secondary-btn" onClick={onModleClose}>
          {showInput ? 'Close' : 'NO'}
        </Button>
        <Button
          className="btn primary-btn"
          onClick={() => {
            !showInput ? setShowInput(true) : onSubmit();
          }}>
          {showInput ? 'Submit' : 'Yes'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(DuplicateDialog);
