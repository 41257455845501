import WeDropDown from 'components/weDropDown';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { deleteRoom } from 'store/slice/event';

const DeleteRoomDialog = ({ ...props }) => {
  const { showDialog, onDialogClose } = props;
  const dispatch=useDispatch();
  const { eventScheduleList } = useSelector((state) => state.event);
  const [formData, setFormData] = useState();

  const btnConfirm = () => {
    if(!formData?.roomId){
      setFormData((formData) => ({
        ...formData,
        roomError:'Please select room.'
      }));
      return;
    }
    onDialogClose();
    setFormData({})
    dispatch(deleteRoom(formData))
  };

  return (
    <Modal show={showDialog} centered>
      <Modal.Header>
        <Modal.Title>
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5"> Delete Room </h1>
            <a
              className="close closeBtn"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onDialogClose}>
              <i className="fa-solid fa-xmark"></i>
            </a>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <WeDropDown
          label="Select Room"
          selectData={
            eventScheduleList &&
            eventScheduleList?.map((item) => ({
              label: `${item.roomTitle}`,
              id: item._id
            }))
          }
          defaultValue={formData?.roomId ? formData?.roomId : ''}
          emptyValue="Select a Room"
          onSelectItem={(val)=>{
            if(val){
              setFormData((formData) => ({
                ...formData,
                roomError:''
              }));
            }
            setFormData((formData) => ({
              ...formData,
              roomId:val
            }));
          }}
        />
        {formData?.roomError && <div style={{ color: 'red' }}>{formData?.roomError}</div>}
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn secondary-btn" onClick={onDialogClose}>
          Close
        </Button>
        <Button className="btn primary-btn" variant="primary" onClick={btnConfirm}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(DeleteRoomDialog);
