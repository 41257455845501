import Autocomplete from 'components/autocomplete';
import WeInput from 'components/weInput';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAttendeeInOrder,
  checkIfAttendeeExists,
  clearAttendeeEmailList,
  removeAttendeeInOrder,
  searchAttendee,
  updateAttendeeTicket
} from 'store/slice/tickets';
import WeButton from 'components/button';
import image from 'assets/img/default.jpg';
import WeCheckbox from 'components/weCheckbox';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const AddAttendeeComponent = ({ ...props }) => {
  const { key, buyerData } = props;
  const dispatch = useDispatch();
  const { id } = useParams();
  const [autoFocus, setautoFocus] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState(-1);
  const { attendeesData, EmailData, ticketArray } = useSelector((state) => state.tickets);

  const setAttendeesData = (type, val, i) => {
    const email = {
      type: type,
      value: val,
      index: i
    };
    dispatch(updateAttendeeTicket(email));
  };

  const handleSearch = (val, i) => {
    setAutocompleteValue(i);
    setAttendeesData('email', val, i);
    setAttendeesData('showAddBtn', val.length > 1 ? true : false, i);
    setAttendeesData('emailError', '', i);
    setAttendeesData('isBuyer', false, i);
    if (val.length > 1) {
      setautoFocus(true);
      const data = {
        searchKey: val
      };
      dispatch(searchAttendee(data));
    }
  };

  const clearAttendeeData = () => {
    dispatch(clearAttendeeEmailList({ data: null }));
  };
  const editAttendees = (index, id) => {
    dispatch(removeAttendeeInOrder(id));
    setAttendeesData('addedAttendee', false, index);
  };

  const addBuyer = (status, index) => {
    const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
    if (status) {
      if (!buyerData.buyerFirstName || !buyerData.buyerLastName || !buyerData.buyerEmail) {
        setAttendeesData('isBuyer', false, index);
        return toast.error(
          `Please enter buyer's ${
            !buyerData.buyerFirstName
              ? 'first name'
              : !buyerData.buyerLastName
              ? 'last name'
              : 'email'
          }`
        );
      }
      if (
        !buyerData.buyerEmail.includes('@') ||
        !buyerData.buyerEmail.includes('.') ||
        !validEmail.test(buyerData.buyerEmail)
      ) {
        setAttendeesData('isBuyer', false, index);
        return toast.error(`Please enter a valid email address`);
      }

      const addAttendeeData = {
        ticketId: attendeesData[index].ticketId,
        firstName: buyerData.buyerFirstName,
        lastName: buyerData.buyerLastName,
        email: buyerData.buyerEmail
      };
      addAttendeeInTheList(addAttendeeData, index, 'buyer');
      // checkIsAttendeeExists(addAttendeeData, index, 'buyer');
      // dispatch(addAttendeeInOrder(addAttendeeData));
      // setAttendeesData('addedAttendee', true, index);
    } else {
      setAttendeesData('isBuyer', false, index);
      setAttendeesData('firstName', '', index);
      setAttendeesData('lastName', '', index);
      setAttendeesData('email', '', index);
      editAttendees(index, attendeesData[index].ticketId);
    }
  };

  const AddAttendees = (index) => {
    const data = attendeesData[index];
    const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
    if (!data.firstName) {
      setAttendeesData('firstNameError', 'Please Enter Your First Name', index);
      setAttendeesData('isNewAttendee', true, index);
      return;
    }
    if (!data.lastName) {
      setAttendeesData('lastNameError', 'Please Enter Your Last Name', index);
      setAttendeesData('isNewAttendee', true, index);
      return;
    }
    if (!data.email) {
      setAttendeesData('emailError', 'Please Enter Your Email Address', index);
      clearAttendeeData();
      return;
    } else if (!data.email.includes('@')) {
      setAttendeesData('emailError', 'Please enter a valid email address', index);
      clearAttendeeData();
      return;
    } else if (!data.email.includes('.')) {
      setAttendeesData('emailError', 'Please enter a valid email address', index);
      clearAttendeeData();
      return;
    } else if (!validEmail.test(data.email)) {
      setAttendeesData('emailError', 'Please enter a valid email address', index);
      clearAttendeeData();
      return;
    }

    checkIsAttendeeExists(data, index, 'attendee');
  };

  const addAttendeeInTheList = (data,index, isBuyer) => {
    if (isBuyer == 'buyer') {
      setAttendeesData('isBuyer', true, index);
      setAttendeesData('firstName', buyerData.buyerFirstName, index);
      setAttendeesData('lastName', buyerData.buyerLastName, index);
      setAttendeesData('email', buyerData.buyerEmail, index);
    }
    // const data = attendeesData[index];
    const addAttendeeData = {
      ticketId: data.ticketId,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email
    };
    dispatch(addAttendeeInOrder(addAttendeeData));
    setAttendeesData('addedAttendee', true, index);
  };

  const checkIsAttendeeExists = (data, index, isBuyer) => {
    const isPresent = ticketArray.some(
      (ele) => ele.email == data.email && ele.ticketId == data.ticketId
    );
    if (isPresent) {
      return toast.error(
        `You have already added ${data.email} for ${attendeesData[index].ticketItem?.name} ticket`
      );
    } else {
      const dataForCheck = {
        eventUid: id,
        email: data.email,
        ticketId: data.ticketId
      };
      dispatch(
        checkIfAttendeeExists(
          dataForCheck,
          data,
          attendeesData[index].ticketItem?.name,
          addAttendeeInTheList,
          index,
          isBuyer
        )
      );
    }
  };

  return (
    <>
      {attendeesData?.map((item, i) => {
        return (
          <div className="block" key={i}>
            <h4>{item?.ticketItem?.name}</h4>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

            <div className="mt-3">
              <WeCheckbox
                checkToogle={item.isBuyer}
                style={{marginLeft:"5px"}}
                toggleLabel={'Same as buyer'}
                onChange={(checked) => {
                  addBuyer(checked, i);
                }}
              />
            </div>

            {!item.addedAttendee ? (
              <>
                {item.isNewAttendee ? (
                  <>
                    <a
                      className="mt-1 d-block"
                      onClick={() => {
                        setAttendeesData('isNewAttendee', false, i);
                      }}>
                      Existing Attendee?
                    </a>
                    <br />
                    <h5>New Attendee</h5>
                    <p>Lorem ipsum dolor sit amet.</p>

                    <div className="row">
                      <div className="col-12 col-md-6">
                        <WeInput
                          type="text"
                          name="firstName"
                          onChange={(value) => {
                            setAttendeesData('isBuyer', false, i);
                            setAttendeesData('firstName', value, i);
                            setAttendeesData('firstNameError', '', i);
                          }}
                          inputLabel="First Name"
                          value={item.firstName}
                          formError={item.firstNameError}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <WeInput
                          type="text"
                          name="lastName"
                          onChange={(value) => {
                            setAttendeesData('isBuyer', false, i);
                            setAttendeesData('lastName', value, i);
                            setAttendeesData('lastNameError', '', i);
                          }}
                          inputLabel="Last Name"
                          value={item.lastName}
                          formError={item?.lastNameError}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <div className="col-12">
                  <Autocomplete
                    key={key}
                    inputLabel="UserName/Email Address"
                    autoFocus={autoFocus ? 'autoFocus' : ''}
                    onSearchChange={(val) => handleSearch(val, i)}
                    clearValue={() => {
                      setAttendeesData('firstName', '', i);
                      setAttendeesData('lastName', '', i);
                      setAttendeesData('email', '', i);
                      setAttendeesData('showAddBtn', false, i);
                      setAttendeesData('isBuyer', false, i);
                      clearAttendeeData();
                      setAutocompleteValue(i);
                    }}
                    value={item.email}
                    searchEmail={autocompleteValue == i ? EmailData : ''}
                    onItemSelected={(item1) => {
                      setAttendeesData('firstName', item1.firstName, i);
                      setAttendeesData('lastName', item1.lastName, i);
                      setAttendeesData('email', item1.email, i);
                      dispatch(clearAttendeeEmailList({ data: null }));
                    }}
                    formError={item.emailError}
                  />
                </div>
                <div className="d-flex flex-row justify-content-between">
                  {!item.isNewAttendee ? (
                    <a
                      className="mt-3 d-block"
                      onClick={() => {
                        setAttendeesData('isNewAttendee', true, i);
                      }}>
                      New Attendee?
                    </a>
                  ) : (
                    ''
                  )}
                  <WeButton onClick={() => AddAttendees(i)} text={'Confirm'} />
                </div>
              </>
            ) : (
              <>
                <div className="item-profile block">
                  <div className="edit d-flex align-items-center">
                    <a className="d-block p-3" onClick={() => editAttendees(i, item.ticketId)}>
                      <i className="bi bi-pencil-square" style={{ color: 'var(--bs-lt-red)' }}></i>
                    </a>
                  </div>
                  <div className="profile-image">
                    <img src={image} alt="logo" height={'px'} />
                  </div>
                  <div className="profile-content">
                    <div className="name">
                      {item.firstName} {item.lastName}
                    </div>
                    <div className="handle">{item.email}</div>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};
export default React.memo(AddAttendeeComponent);
