// import { Outlet } from 'react-router-dom';
import React from 'react';

export default function Search() {
  return (
    <div className="col-12 col-lg-12 col-xl-12 board">
      <h1>Coming Soon.....</h1>
    </div>
  );
}
