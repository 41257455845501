import { Navigate, useRoutes } from 'react-router-dom';

import secureLocalStorage from 'react-secure-storage';
// layouts
import AuthLayout from 'layout/authLayout';
import DashboardLayout from 'layout/dashboardLayout';
import EventLayout from 'layout/eventLayout';
//pages
import LoginPage from 'pages/auth/signIn';
import Dashboard from 'pages/dashboard';
import Register from 'pages/auth/signUp';
//event Details
import EventDashboard from 'pages/events/dashboard';
import CreateEvent from 'pages/events/create';
import EventHome from 'pages/events/manage/home';
import EventInfo from 'pages/events/manage';
//event order options mnu
import EventSchedule from 'pages/events/manage/orderOptions/eventSchedule';
import CheckoutForm from 'pages/events/manage/orderOptions/checkoutForm';
import TicketTransfer from 'pages/events/manage/orderOptions/ticketTransfer';
import ManageTeam from 'pages/events/manage/orderOptions/manageTeam';
import GuestList from 'pages/events/manage/orderOptions/guestList';
//event attendee menu
//import AddAttendees from 'pages/events/manage/attendee/addattendee/addAttendees';
import EmailReminders from 'pages/events/manage/attendee/emailReminders';
import AttendeeList from 'pages/events/manage/attendee/attendeeList';
import CheckIn from 'pages/events/manage/attendee/checkIn';
import PaymentOptions from 'pages/events/manage/paymentAndTax/paymentOptions';
import TaxSettings from 'pages/events/manage/paymentAndTax/taxSettings';
//other menu
import Finance from 'pages/finance';
import Orders from 'pages/orders';
import Reports from 'pages/reports';
import Settings from 'pages/settings';
import ForgotPassword from 'pages/auth/forgotPassword';
import PaymentMethods from 'pages/events/manage/paymentAndTax/paymentMethods';
import RefundSettings from 'pages/events/manage/paymentAndTax/refundSettings';
import Order from 'pages/events/manage/attendee/orders';
import Summary from 'pages/events/eventSections/summary';
import Tickets from 'pages/events/eventSections/tickets';
import Publish from 'pages/events/eventSections/publish';
import Attendee from 'pages/events/manage/attendee/addattendee';
import Page404 from 'pages/page404';
import Search from 'pages/search';
import Users from 'pages/users';
import MyOrders from 'pages/myOrders';
import Profile from 'pages/profile';
import AllOrderDetail from 'pages/orders/allOrderDetail';
import OrdersDetail from 'pages/subcomponents/orders/ordersDetail';
import PastPayouts from 'pages/finance/pastPayouts';
import FuturePayout from 'pages/finance/futurePayout';
import Banking from 'pages/finance/banking';
import TaxInfo from 'pages/finance/taxInfo';
import PayoutSchedule from 'pages/finance/payoutSchedule';
import Sales from 'pages/reports/sales';
import AttendeeReports from 'pages/reports/attendeeReports';
import Analytics from 'pages/reports/analytics';
import InviteAccept from 'pages/events/inviteAccept';
import ViewEvent from 'pages/landingPages/eventsPages/viewEvent';
import Test from 'pages/test';

export default function Router() {
  let isLogin = secureLocalStorage.getItem(process.env.REACT_APP_AUTH_STORAGE_KEY);

  const routes = useRoutes([
    { path: 'inviteAccept/:id', element: isLogin ? <InviteAccept /> : <LoginPage /> },
    { path: '/register/inviteAccept/:id', element: <Register /> },
    { path: 'forgotPassword/inviteAccept/:id', element: <ForgotPassword /> },
    { path: 'test', element: <Test /> },
    { path: ':id', element: <ViewEvent /> },
    {
      path: '/',
      element: isLogin ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: '/dashboard', element: <Dashboard /> },
        { path: '/event/dashboard', element: <EventDashboard /> },
        { path: '/event/create', element: <CreateEvent /> },
        // { path: '*', element: <Page404 /> },
        //even
        {
          path: '/event/:id',
          element: <EventLayout />,
          children: [
            { element: <Navigate to="/event/:id/edit/basicInfo" />, index: true },
            { path: '/event/:id/home', element: <EventHome /> },
            { path: '/event/:id/edit/basicInfo', element: <EventInfo /> },

            //attendee
            { path: '/event/:id/edit/summary', element: <Summary /> },
            { path: '/event/:id/edit/tickets', element: <Tickets /> },
            { path: '/event/:id/edit/publish', element: <Publish /> },

            //attendee
            { path: '/event/:id/attendee/orders', element: <Order /> },
            { path: '/event/:id/attendee/orders/:_id/details', element: <OrdersDetail /> },
            { path: '/event/:id/attendee/add', element: <Attendee /> },
            { path: '/event/:id/attendee/list', element: <AttendeeList /> },
            { path: '/event/:id/attendee/reminders', element: <EmailReminders /> },
            { path: '/event/:id/attendee/checkIn', element: <CheckIn /> },
            // { path: '/event/:id/test', element: <Test /> },
            //eventorder
            { path: '/event/:id/order/eventSchedule', element: <EventSchedule /> },
            { path: '/event/:id/order/checkoutForm', element: <CheckoutForm /> },
            { path: '/event/:id/order/ticketTransfer', element: <TicketTransfer /> },
            { path: '/event/:id/order/manageTeam', element: <ManageTeam /> },
            { path: '/event/:id/order/guests', element: <GuestList /> },
            // PaymentAndTax
            { path: '/event/:id/pay/paymentOptions', element: <PaymentOptions /> },
            { path: '/event/:id/pay/taxSettings', element: <TaxSettings /> },
            { path: '/event/:id/pay/refundSettings', element: <RefundSettings /> },
            { path: '/event/:id/pay/paymentMethods', element: <PaymentMethods /> }
          ]
        },
        //other pages
        { path: 'orders', element: <Orders /> },
        { path: '/orders/:_id/details', element: <AllOrderDetail /> },
        { path: 'finance', element: <Finance /> },
        { path: '/finance/pastPayouts', element: <PastPayouts /> },
        { path: '/finance/futurePayout', element: <FuturePayout /> },
        { path: '/finance/banking', element: <Banking /> },
        { path: '/finance/taxInfo', element: <TaxInfo /> },
        { path: '/finance/payoutSchedule', element: <PayoutSchedule /> },
        { path: 'reports', element: <Reports /> },
        { path: '/reports/sales', element: <Sales /> },
        { path: '/reports/attendeeReports', element: <AttendeeReports /> },
        { path: '/reports/analytics', element: <Analytics /> },
        { path: 'settings', element: <Settings /> },
        { path: 'search', element: <Search /> },
        { path: 'users', element: <Users /> },
        { path: 'myorders', element: <MyOrders /> },
        { path: 'profile', element: <Profile /> },
        { path: 'inviteAccept/:id', element: <InviteAccept /> }
      ]
    },
    {
      path: '/',
      element: !isLogin ? <AuthLayout /> : <Navigate to="/dashboard" />,
      children: [
        { path: 'login', element: <LoginPage /> },
        { path: 'register', element: <Register /> },
        { path: 'forgotPassword', element: <ForgotPassword /> },
        { path: '/', element: <Navigate to="/login" /> },
        // { path: '*', element: <Page404 /> }
      ]
    },
    { path: "*", element: isLogin ? <Page404 /> : <Navigate to="/login" /> }
  ]);

  return routes;
}
