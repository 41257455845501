import WeDropDown from 'components/weDropDown';
import WeInput from 'components/weInput';
import React, { useEffect, useState } from 'react';
import { exemptPayeeCode, fedTaxClassData, stateData } from './config';
import WeRadio from 'components/weRadio';
import { selectValue } from 'utils/appConstant';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserTaxInfo, saveUserTaxInfo } from 'store/slice/order';

export default function TaxInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userTaxInfo } = useSelector((state) => state.order);
  const [formStatus, setFormStatus] = useState(0);
  const [taxInfo, setTaxInfo] = useState({ tinType: 'SSN' });
  const [taxInfoError, setTaxInfoError] = useState({});
  const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

  useEffect(() => {
    dispatch(getUserTaxInfo());
  }, []);

  useEffect(() => {
    setTaxInfo((taxInfo) => ({
      ...taxInfo,
      ...userTaxInfo
    }));
  }, [userTaxInfo]);

  const handleSubmit = () => {
    if (formStatus == 0) {
      if (!taxInfo.name) {
        setTaxInfoError((taxInfoError) => ({
          ...taxInfoError,
          name: 'Please Enter Name'
        }));
        return;
      }
      if (!taxInfo.fedTaxClass) {
        setTaxInfoError((taxInfoError) => ({
          ...taxInfoError,
          fedTaxClass: 'Please Select Classification'
        }));
        return;
      }
      if (!taxInfo.exPayeeCode) {
        setTaxInfoError((taxInfoError) => ({
          ...taxInfoError,
          exPayeeCode: 'Please Select Classification'
        }));
        return;
      }
      if (!taxInfo.address) {
        setTaxInfoError((taxInfoError) => ({
          ...taxInfoError,
          address: 'Please Enter Address'
        }));
        return;
      }
      if (!taxInfo.city) {
        setTaxInfoError((taxInfoError) => ({
          ...taxInfoError,
          city: 'Please Enter City'
        }));
        return;
      }
      if (!taxInfo.state) {
        setTaxInfoError((taxInfoError) => ({
          ...taxInfoError,
          state: 'Please Enter State'
        }));
        return;
      }
      if (!taxInfo.zipCode) {
        setTaxInfoError((taxInfoError) => ({
          ...taxInfoError,
          zipCode: 'Please Enter Zip Code'
        }));
        return;
      }
      return setFormStatus(1);
    }
    if (formStatus == 1) {
      if (!taxInfo.tinNumber) {
        setTaxInfoError((taxInfoError) => ({
          ...taxInfoError,
          tinNumber: 'Please Enter TIN Number'
        }));
        return;
      }
      return setFormStatus(2);
    }
    if (!taxInfo.signature) {
      setTaxInfoError((taxInfoError) => ({
        ...taxInfoError,
        signature: 'Please Enter Signature'
      }));
      return;
    }
    if (!taxInfo.signDate) {
      setTaxInfoError((taxInfoError) => ({
        ...taxInfoError,
        signDate: 'Please Enter Sign Date'
      }));
      return;
    }
    dispatch(saveUserTaxInfo(taxInfo, navigate));
  };

  const taxInfoForm = () => {
    return (
      <>
        <WeInput
          type="text"
          onChange={(val) => {
            if (val) {
              setTaxInfoError((taxInfoError) => ({
                ...taxInfoError,
                name: ''
              }));
            }
            setTaxInfo((taxInfo) => ({
              ...taxInfo,
              name: val
            }));
          }}
          inputLabel="Name"
          value={taxInfo.name}
          formError={taxInfoError.name}
        />
        <WeDropDown
          label="Federal Tax Classification"
          selectData={fedTaxClassData}
          defaultValue={taxInfo.fedTaxClass ? taxInfo.fedTaxClass : ''}
          emptyValue="Select Classification"
          onSelectItem={(item) => {
            if (item) {
              setTaxInfoError((taxInfoError) => ({
                ...taxInfoError,
                fedTaxClass: ''
              }));
            }
            setTaxInfo((taxInfo) => ({
              ...taxInfo,
              fedTaxClass: item
            }));
          }}
          formError={taxInfoError.fedTaxClass}
        />
        <WeDropDown
          label="Exempt Payee Code"
          selectData={exemptPayeeCode}
          onSelectItem={(item) => {
            if (item) {
              setTaxInfoError((taxInfoError) => ({
                ...taxInfoError,
                exPayeeCode: ''
              }));
            }
            setTaxInfo((taxInfo) => ({
              ...taxInfo,
              exPayeeCode: exemptPayeeCode[item].id
            }));
          }}
          defaultValue={
            taxInfo.exPayeeCode ? selectValue(exemptPayeeCode, taxInfo.exPayeeCode, true) : ''
          }
          emptyValue="Select Payee Code"
          formError={taxInfoError.exPayeeCode}
        />
        <WeInput
          type="text"
          onChange={(val) => {
            if (val) {
              setTaxInfoError((taxInfoError) => ({
                ...taxInfoError,
                address: ''
              }));
            }
            setTaxInfo((taxInfo) => ({
              ...taxInfo,
              address: val
            }));
          }}
          inputLabel="Address"
          value={taxInfo.address}
          formError={taxInfoError.address}
        />
        <div className="row">
          <div className="col-12 col-lg-4 col-xxl-5">
            <WeInput
              type="text"
              onChange={(val) => {
                if (val) {
                  setTaxInfoError((taxInfoError) => ({
                    ...taxInfoError,
                    city: ''
                  }));
                }
                setTaxInfo((taxInfo) => ({
                  ...taxInfo,
                  city: val
                }));
              }}
              inputLabel="City"
              value={taxInfo.city}
              formError={taxInfoError.city}
            />
          </div>
          <div className="col-12 col-lg-8 col-xxl-7">
            <div className="row">
              <div className="col-12 col-lg-6">
                <WeDropDown
                  label="State"
                  selectData={stateData}
                  onSelectItem={(item) => {
                    if (item) {
                      setTaxInfoError((taxInfoError) => ({
                        ...taxInfoError,
                        state: ''
                      }));
                    }
                    setTaxInfo((taxInfo) => ({
                      ...taxInfo,
                      state: item
                    }));
                  }}
                  defaultValue={taxInfo.state ? selectValue(stateData, taxInfo.state, true) : ''}
                  emptyValue="Select state"
                  formError={taxInfoError.state}
                />
              </div>
              <div className="col-12 col-lg-6">
                <WeInput
                  type="number"
                  onChange={(val) => {
                    if (val) {
                      setTaxInfoError((taxInfoError) => ({
                        ...taxInfoError,
                        zipCode: ''
                      }));
                    }
                    setTaxInfo((taxInfo) => ({
                      ...taxInfo,
                      zipCode: val
                    }));
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                  inputLabel="Zip Code"
                  onKeyPress={(e) => blockInvalidChar(e)}
                  value={taxInfo.zipCode}
                  formError={taxInfoError.zipCode}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const taxInfoEinForm = () => {
    return (
      <>
        <WeRadio
          checked={taxInfo.tinType == 'SSN' ? true : false}
          label=" SSN"
          id="SSN"
          htmlFor="SSN"
          onChange={(checked) => {
            if (checked) {
              setTaxInfo((taxInfo) => ({
                ...taxInfo,
                tinType: 'SSN'
              }));
            }
          }}
        />
        <WeRadio
          checked={taxInfo.tinType == 'EIN' ? true : false}
          label=" EIN"
          id="EIN"
          htmlFor="EIN"
          onChange={(checked) => {
            if (checked) {
              setTaxInfo((taxInfo) => ({
                ...taxInfo,
                tinType: 'EIN'
              }));
            }
          }}
        />
        <div className="row">
          <div className="col-12 col-lg-6">
            <WeInput
              type="number"
              min={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
              onChange={(val) => {
                if (val) {
                  setTaxInfoError((taxInfoError) => ({
                    ...taxInfoError,
                    tinNumber: ''
                  }));
                }
                setTaxInfo((taxInfo) => ({
                  ...taxInfo,
                  tinNumber: val
                }));
              }}
              inputLabel="TIN Number"
              onKeyPress={(e) => blockInvalidChar(e)}
              value={taxInfo.tinNumber}
              formError={taxInfoError.tinNumber}
            />
          </div>
        </div>
      </>
    );
  };

  const taxInfoSignatureForm = () => {
    return (
      <>
        <div className="row">
          <div className="col-12 col-lg-7">
            <WeInput
              type="text"
              onChange={(val) => {
                if (val) {
                  setTaxInfoError((taxInfoError) => ({
                    ...taxInfoError,
                    signature: ''
                  }));
                }
                setTaxInfo((taxInfo) => ({
                  ...taxInfo,
                  signature: val
                }));
              }}
              inputLabel="Your Signature"
              value={taxInfo.signature}
              formError={taxInfoError.signature}
            />
          </div>
          <div className="col-12 col-lg-5">
            <WeInput
              type="date"
              onChange={(val) => {
                if (val) {
                  setTaxInfoError((taxInfoError) => ({
                    ...taxInfoError,
                    signDate: ''
                  }));
                }
                setTaxInfo((taxInfo) => ({
                  ...taxInfo,
                  signDate: val
                }));
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
              inputLabel="Sign Date"
              value={taxInfo.signDate}
              formError={taxInfoError.signDate}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <h2>Tax Info {formStatus == 1 ? 'EIN' : formStatus == 2 ? 'Signature' : ''}</h2>
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/finance">Finance</a>
            </li>
            <li className="breadcrumb-item">Tax Info</li>
          </ol>
        </nav>
      </div>

      {formStatus == 0 ? (
        <>{taxInfoForm()}</>
      ) : formStatus == 1 ? (
        <> {taxInfoEinForm()}</>
      ) : (
        <> {taxInfoSignatureForm()}</>
      )}

      <div className="mt-2">
        <a href="#" className="btn primary-btn mt-2 ml-auto" onClick={handleSubmit}>
          {formStatus == 2 ? 'Submit' : 'Next'}
        </a>
      </div>
    </>
  );
}
