import React, { useEffect, useState } from 'react';
import './index.css';
import { useNavigate, useParams } from 'react-router-dom';
import { validateEmpty, validEmail } from 'utils/appConstant';
import { login } from 'store/slice/auth';
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import WeButton from 'components/button';

const Login = () => {
  const navigate = useNavigate('');
  const { id } = useParams();
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [email, setEmail] = useState('');
  const [show, setShow] = useState(false);
  let [emailError, setEmailError] = useState('');

  let isLogin = secureLocalStorage.getItem(process.env.REACT_APP_AUTH_STORAGE_KEY);
  const { isLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isLogin) {
      navigate('/', true);
    }
  }, []);

  const handleRegister = () => {
    if (id) {
      navigate(`/register/inviteAccept/${id}`);
    } else {
      navigate(`/register`);
    }
  };

  const handleForgotPassword = () => {
    if (id) {
      navigate(`/forgotPassword/inviteAccept/${id}`);
    } else {
      navigate(`/forgotPassword`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmpty(email)) {
      setEmailError('Please enter email.');
      return;
    }
    if (!validateEmpty(email) && !validEmail(email)) {
      setEmailError('Please enter valid email.');
      return;
    }
    if (validateEmpty(password)) {
      setPasswordError('Please enter password.');
      return;
    }
    if (!validateEmpty(password) && password.length < 8) {
      setPasswordError('Password must be minumum 8 characters.');
      return;
    }

    let requestParam = {
      email: email,
      password: password
    };
    // const navigateUrl = id ? `/inviteAccept/${id}` : '/';
    dispatch(login(requestParam, navigate, id));
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 10
      }}>
      <div className="contain">
        <h2 className="login-title" style={{ color: 'rgb(137 135 135)' }}>
          Log in
        </h2>
        <div className="login-form">
          <div>
            <label
              className="mt-2"
              htmlFor="email  "
              style={{ float: 'left', color: 'rgb(70 69 69)' }}>
              Email
            </label>
            <input
              name="email"
              placeholder="me@example.com"
              onChange={(e) => {
                let val = e.target.value.trim();
                if (!validateEmpty(val) && !validEmail(val)) {
                  setEmailError('Please enter valid email');
                } else {
                  setEmail(val);
                  setEmailError('');
                }
              }}
            />
          </div>
          {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
          <div>
            <label
              className="mt-2"
              htmlFor="password"
              style={{ float: 'left', color: 'rgb(70 69 69)' }}>
              Password
            </label>
            <div className="input-group mb-3">
              <input
                name="password"
                type={show ? 'text' : 'password'}
                className="form-control"
                aria-describedby="basic-addon1"
                placeholder="password"
                onChange={(e) => {
                  let val = e.target.value.trim();
                  if (!validateEmpty(val) && val.length < 8) {
                    setPasswordError('Password must be minumum 8 characters.');
                  } else {
                    setPassword(val);
                    setPasswordError('');
                  }
                }}
              />

              <span
                onClick={() => {
                  setShow(!show);
                }}
                className="input-group-text"
                id="basic-addon1">
                {show ? (
                  ''
                ) : (
                  <span id="basic-addon2">
                    <i className="fa-light fa-eye-slash"></i>
                  </span>
                )}
                {show ? (
                  <span id="basic-addon2">
                    <i className="fa-light fa-eye"></i>
                  </span>
                ) : (
                  ''
                )}
              </span>
            </div>

            {passwordError && <div style={{ color: 'red' }}>{passwordError}</div>}
          </div>
          <a className="linkText rightText" onClick={handleForgotPassword}>
            Forgot Password?
          </a>
          <WeButton text="Login" onClick={handleSubmit} isLoading={isLoading} />
          <div className="text mt-2">
            Don't have an account?{' '}
            <a className="linkText" onClick={handleRegister}>
              Register now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
