import { createSlice } from '@reduxjs/toolkit';
import api from '../../services';
import { TESLOOMAPI } from '../../services/apiConstant';
import { toast } from 'react-toastify';

const slice = createSlice({
  name: 'payment',
  initialState: {
    message: '',
    checkOutFormData: null,
    ticketTransferData: null,
    stateListData: null,
    stripeBankAccountList: null
  },
  reducers: {
    checkOutFormSuccess(state, action) {
      state.checkOutFormData = action.payload;
    },
    ticketTransferSuccess(state, action) {
      state.ticketTransferData = action.payload;
    },
    getStateListSuccess(state, action) {
      state.stateListData = action.payload;
    },
    getStripeBankAccountSuccess(state, action) {
      state.stripeBankAccountList = action.payload;
    },
    deleteStripeBankAccountSuccess(state, action) {
      let objId = action.payload;
      state.stripeBankAccountList = state.stripeBankAccountList.filter((item) => item.id !== objId);
    },
    addBanckAccountSuccess(state, action) {
      state.stripeBankAccountList = state.stripeBankAccountList.concat(action.payload);
    },
    setDefaultBankAccountSuccess(state, action) {
      const newObjId = action.payload.id;
      const suspendIndex = state.stripeBankAccountList?.findIndex((item) => item.id === newObjId);
      let currentUser = action.payload;
      state.stripeBankAccountList[suspendIndex] = currentUser;
    }
  }
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

const {
  checkOutFormSuccess,
  ticketTransferSuccess,
  getStateListSuccess,
  getStripeBankAccountSuccess,
  deleteStripeBankAccountSuccess,
  addBanckAccountSuccess,
  setDefaultBankAccountSuccess
} = slice.actions;

export const refundAndTaxSettings = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.refundAndTaxSettings}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status == 0) {
        toast.success(result.message);
        dispatch(checkOutFormSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const getEventCheckOutForm = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.getEventCheckOutForm}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(checkOutFormSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const editTicketTransferForEvent = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.editTicketTransferForEvent}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(ticketTransferSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const getStateList = () => async (dispatch) => {
  api
    .get(`${TESLOOMAPI.stateList}`)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getStateListSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const addBanckAccountOnStripe = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.addBanckAccountOnStripe}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        toast.success(result.message);
        dispatch(addBanckAccountSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const getStripeBankAccount = (data) => async (dispatch) => {
  api
    .get(`${TESLOOMAPI.getStripeBankAccount}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(getStripeBankAccountSuccess(result.data));
      } else {
        // toast.error(result.message);
        console.log('data == ', result.message);
      }
    })
    .catch(() => {});
};

export const deleteStripeBankAccount = (requestParam, objId) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.deleteStripeBankAccount}`, requestParam)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(deleteStripeBankAccountSuccess(objId));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const setDefaultBankAccount = (requestParam, objId) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.setDefaultBankAccount}`, requestParam)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        toast.success(result.message);
        dispatch(setDefaultBankAccountSuccess(objId));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
