import WeButton from 'components/button';
import MultipleSelectDropDown from 'components/multipleSelectDropDown';
import WeDropDown from 'components/weDropDown';
import WeInput from 'components/weInput';
import WeTextArea from 'components/weTextArea';
import { attendeesData, timingData } from 'pages/events/manage/attendee/subcomponent/config';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { sendTestEmailRemainder } from 'store/slice/tickets';

const CreateReminderDialog = ({ ...props }) => {
  const { attendeeListData, specificAttendeeData, ticketTypeData } = useSelector(
    (state) => state.event
  );
  const { ticketListData } = useSelector((state) => state.tickets);
  const {
    showReminderDialog,
    onEmailSelectItem,
    onTimingSelect,
    onEmailDropDownClick,
    onTimingDropDownClick,
    handleClose,
    senderNameChange,
    ReplyToChange,
    SubjectChange,
    MessageChange,
    formEmailData,
    showEmailTo,
    showTiming,
    onSpecificAttendeeSelect,
    onTicketSelect,
    customTimeChange,
    handleSubmit,
    title,
    formError,
    handlecloseDialog
  } = props;

  const dispatch = useDispatch();
  const [all, setAll] = useState(attendeesData[0]);
  const [attendees, setAttendees] = useState(attendeesData[1]);
  const [ticket, setTicket] = useState(attendeesData[2]);
  const [sendTestEmailValue, setSendTestEmailValue] = useState({});
  const [sendTestEmailError, setSendTestEmailError] = useState({});

  useEffect(() => {
    if (attendeeListData) {
      setAll((all) => ({
        ...all,
        notShow: false
      }));
      setAttendees((attendees) => ({
        ...attendees,
        notShow: false
      }));
    }
  }, [attendeeListData]);

  useEffect(() => {
    if (ticketListData && !!ticketListData.length) {
      setTicket((ticket) => ({
        ...ticket,
        notShow: false
      }));
    }
  }, [ticketListData]);

  attendeesData[0] = all;
  attendeesData[1] = attendees;
  attendeesData[2] = ticket;

  // const selectedGroup = (data, value) => {
  //   const valueIndex = data?.findIndex((item) => item.value === value);
  //   const valueAtIndex = data[valueIndex].label;
  //   return valueAtIndex;
  // };

  const handleSendEmail = () => {
    const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
    if (!formEmailData?.subject) {
      setSendTestEmailError((sendTestEmailError) => ({
        ...sendTestEmailError,
        subject: 'Please enter subject to send testing email'
      }));
      return;
    }
    if (!formEmailData?.message) {
      setSendTestEmailError((sendTestEmailError) => ({
        ...sendTestEmailError,
        message: 'Please enter message to send testing email'
      }));
      return;
    }
    if (!sendTestEmailValue?.email) {
      setSendTestEmailError((sendTestEmailError) => ({
        ...sendTestEmailError,
        email: 'Please enter email to send testing email'
      }));
      return;
    }
    if (!sendTestEmailValue?.email.includes('.')) {
      setSendTestEmailError((sendTestEmailError) => ({
        ...sendTestEmailError,
        email: 'Please enter a valid email address'
      }));
      return;
    }
    if (!validEmail.test(sendTestEmailValue?.email)) {
      setSendTestEmailError((sendTestEmailError) => ({
        ...sendTestEmailError,
        email: 'Please enter a valid email address'
      }));
      return;
    }
    const data = {
      subject: formEmailData?.subject,
      message: formEmailData?.message,
      email: sendTestEmailValue?.email
    };
    dispatch(sendTestEmailRemainder(data));
  };

  return (
    <Modal show={showReminderDialog} centered backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5">{`${title} Email Reminder`} </h1>
            <a
              className="close closeBtn"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setSendTestEmailValue({});
                handleClose();
              }}>
              <i className="fa-solid fa-xmark"></i>
            </a>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p>
        <WeInput
          inputLabel="Sender Name"
          onChange={senderNameChange}
          autoFocus={true}
          formError={formError?.senderName}
          value={formEmailData?.senderName ?? ''}
        />
        <WeInput
          inputLabel=" Reply To"
          onChange={ReplyToChange}
          formError={formError?.replyTo}
          value={formEmailData?.replyTo ?? ''}
        />
        <WeDropDown
          label=" Email To"
          openCloseDropdown={showEmailTo}
          onDropDownClick={onEmailDropDownClick}
          selectData={attendeesData}
          btnWidth={true}
          onSelectItem={(item) => {
            onEmailSelectItem(item); //attendeesData[item].id);
          }}
          defaultValue={formEmailData?.emailSendTo ? formEmailData?.emailSendTo : ''}
          emptyValue="Select Attendee"
          formError={formError?.emailTo}
        />
        {formEmailData?.emailSendTo === 'attendees' ? (
          // <WeDropDown
          //   label="Specific Attendee"
          //   selectData={
          //     attendeeListData &&
          //     attendeeListData?.map((item) => ({
          //       label: `${item.attendee}`,
          //       value: item._id
          //     }))
          //   }
          //   // defaultValue={
          //   //   formEmailData.attendeeIdArray
          //   //     ? selectedGroup(attendeeListData, formEmailData.attendeeIdArray)
          //   //     : ''
          //   // }
          //   emptyValue="Select Sepcific Attendee"
          //   onSelectItem={(item) => {
          //     onSpecificAttendeeSelect(item);//attendeeListData[item]._id);
          //   }}
          // />
          <MultipleSelectDropDown
            label="Specific Attendee"
            emptyValue="Select Attendee"
            selectData={
              attendeeListData &&
              attendeeListData?.map((item) => ({
                label: `${item.attendee}`,
                id: item._id
              }))
            }
            onSelectItem={onSpecificAttendeeSelect}
            selectDataLength={specificAttendeeData.length}
            defaultSelectValue={specificAttendeeData}
          />
        ) : (
          ''
        )}
        {formEmailData?.emailSendTo === 'ticket' ? (
          // <WeDropDown
          //   label="By Ticket Type"
          //   selectData={
          //     ticketListData &&
          //     ticketListData?.map((item) => ({
          //       label: `${item.name}`,
          //       value: item._id
          //     }))
          //   }
          //   defaultValue={
          //     formEmailData.ticketIdArray
          //       ? selectedGroup(attendeeListData, ticketListData.ticketIdArray)
          //       : ''
          //   }
          //   emptyValue="Select Ticket Type"
          //   onSelectItem={(item) => {
          //     onTicketSelect(item); //ticketListData[item]._id);
          //   }}
          // />
          <MultipleSelectDropDown
            label="By Ticket Type"
            emptyValue="Select Ticket Type"
            selectData={
              ticketListData &&
              ticketListData?.map((item) => ({
                label: `${item.name}`,
                id: item._id
              }))
            }
            onSelectItem={onTicketSelect}
            selectDataLength={ticketTypeData.length}
            defaultSelectValue={ticketTypeData}
          />
        ) : (
          ''
        )}
        <WeInput
          inputLabel="Subject"
          onChange={(val) => {
            if (val) {
              setSendTestEmailError((sendTestEmailError) => ({
                ...sendTestEmailError,
                subject: ''
              }));
            }
            SubjectChange(val)
          }}
          formError={
            formError?.subject
              ? formError?.subject
              : sendTestEmailError?.subject
                ? sendTestEmailError?.subject
                : ''
          }
          value={formEmailData?.subject ?? ''}
        />
        <WeTextArea
          inputLabel="Message"
          onChange={(val) => {
            if (val) {
              setSendTestEmailError((sendTestEmailError) => ({
                ...sendTestEmailError,
                message: ''
              }));
            }
            MessageChange(val)
          }}
          formError={
            formError?.message
              ? formError?.message
              : sendTestEmailError.message
                ? sendTestEmailError.message
                : ''
          }
          value={formEmailData?.message ?? ''}
        />
        <WeInput
          inputLabel=" Send Test Message To"
          onChange={(val) => {
            if (val) {
              setSendTestEmailError((sendTestEmailError) => ({
                ...sendTestEmailError,
                email: ''
              }));
              setSendTestEmailValue((sendTestEmailValue) => ({
                ...sendTestEmailValue,
                email: val
              }));
            }
          }}
          formError={sendTestEmailError?.email}
          value={sendTestEmailValue.email ?? ''}
        />
        <WeButton text="Send Test" onClick={handleSendEmail} />
        <WeDropDown
          label="Timing"
          openCloseDropdown={showTiming}
          onDropDownClick={onTimingDropDownClick}
          selectData={timingData}
          btnWidth={true}
          onSelectItem={(item) => {
            onTimingSelect(item); //timingData[item].id);
          }}
          defaultValue={formEmailData?.date}
          emptyValue="Select Timing"
          formError={formError?.date}
        />
        {formEmailData?.date === 'Custom' ? (
          <WeInput
            inputLabel="Custom"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
            type="datetime-local"
            onChange={customTimeChange}
            value={formEmailData?.date ?? ''}
          />
        ) : (
          ''
        )}
        <div className="form-check mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckChecked"
            checked
          />
          <label className="form-check-label" htmlFor="flexCheckChecked">
            CHANGE TEXT
            <s style={{ textDecoration: 'auto' }}>
              By checking this box, I certify that the email campaign being sent is transactional in
              nature and is not being used to market, advertise, or otherwise promote any event,
              product, service or other offering of Organizer. I acknowledge and agree that this
              tool is to be used solely for transactional emails related to event registrations and
              use of this tool is governed by the Tesloom Terms of Service.
            </s>
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn secondary-btn" onClick={handleClose}>
          Close
        </Button>
        <Button
          className="btn primary-btn"
          variant="primary"
          onClick={() => {
            handleSubmit(handlecloseDialog);
          }}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(CreateReminderDialog);
