import WeButton from 'components/button';
import ConfirmDialog from 'components/dialogs/confirmDialog';
import QuesionDialog from 'components/dialogs/quesionDialog';
import WeToggle from 'components/weToggle';
import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

const initialCustomValue = {
  question: "",
  include: true,
  required: false,
  answer: ""
}

const CustomInfo = (props) => {
  const { func, attendeeQuestions } = props;
  let customData = attendeeQuestions ? [...attendeeQuestions] : [];
  const [showQuesionDialog, setShowQuesionDialog] = useState(false);
  const [confirmDialogData, setConfirmDialogData] = useState(null);
  const [openGroupMenuId, setGroupMenuId] = useState(-1);
  const [customValue, setCustomValue] = useState(initialCustomValue);
  const [customIndex, setCustomIndex] = useState(-1);

  const btnAddGroup = () => {
    if(!customValue.question){
      return toast.error('Please fill question');
    }
    if (customValue._id) {
      customData[customIndex] = customValue;
    }
    else {
      customData.push(customValue);
    }
    func(customData);
    setShowQuesionDialog(false)
    setCustomValue(initialCustomValue);
  }

  return (
    <>
      <h4>Added Fields:</h4>
      <div className="group-heading mt-3">
        <div className="row">
          <div className="col-5 col-lg-6">
            <strong>Information</strong>
          </div>
          <div className="col-5 col-lg-5">
            <div className="row">
              <div className="col-6">
                <strong>Include</strong>
              </div>
              <div className="col-6">
                <strong>Required</strong>
              </div>
            </div>
          </div>
          <div className="col-2 col-lg-1"></div>
        </div>
      </div>
      <div className="attendee-information-table">
        {customData?.map((item, index) => {
          return (
            <>
              <div className="row mt-2">
                <div className="col-5 col-lg-6 d-flex">
                  <div className="move">
                    <a href="#">
                      <i className="fa-solid fa-up-down-left-right"></i>
                    </a>
                  </div>
                  <div className="d-inline-flex align-items-center overflow-hidden">
                    {item.question}
                  </div>
                </div>
                <div className="col-5 col-lg-5">
                  <div className="row">
                    <div className="col-6">
                      <WeToggle
                        checkToogle={item.include}
                        onChange={() => {
                          let data = {
                            question: item.question,
                            include: !item.include,
                            required: item.required,
                            answer: item.answer
                          }
                          customData[index] = data;
                          func(customData);
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <WeToggle
                        checkToogle={item.required}
                        onChange={() => {
                          let data = {
                            question: item.question,
                            include: item.include,
                            required: !item.required,
                            answer: item.answer
                          }
                          customData[index] = data;
                          func(customData);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-2 col-lg-1 d-flex justify-content-center align-items-center">
                  <div className={
                    openGroupMenuId === item._id
                      ? 'dropdown justify-content-end active'
                      : 'dropdown justify-content-end'
                  } data-dropdown>
                    <a className="dropdown-button d-inline-block" data-dropdown-button onClick={() => {
                      if (openGroupMenuId === -1) {
                        setGroupMenuId(item._id);
                      } else {
                        setGroupMenuId(-1);
                      }
                    }}>
                      <span className="fa-stack fa-1x">
                        <i className="fa-solid fa-circle fa-stack-2x"></i>
                        <i className="fa-solid fa-ellipsis-vertical fa-stack-1x fa-inverse"></i>
                      </span>
                    </a>
                    <div className="dropdown-menu information-grid">
                      <div className="dropdown-links">
                        <a className="link" onClick={() => {
                          setCustomValue((customValue) => ({
                            ...customValue,
                            ...item
                          }));
                          setShowQuesionDialog(true)
                          setGroupMenuId(-1);
                          setCustomIndex(index)
                        }}>
                          Edit
                        </a>
                        <a className="link"
                          onClick={() => {
                            setConfirmDialogData(true)
                            setGroupMenuId(-1);
                            setCustomIndex(index)
                          }}
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>

      <WeButton className="mt-5" text="Add Custom Information" onClick={() => setShowQuesionDialog(!showQuesionDialog)} />

      <QuesionDialog
        showDialog={showQuesionDialog}
        btnSave={btnAddGroup}
        customData={customValue}
        onInputChange={(val) =>
          setCustomValue((customValue) => ({
            ...customValue,
            question: val
          }))
        }
        onDialogClose={() => {
          setCustomValue(initialCustomValue);
          setShowQuesionDialog(false)
        }}
      />

      {confirmDialogData && (
        <ConfirmDialog
          showDialog={confirmDialogData}
          title={"Delete Question"}
          message={"Are you sure you want to delete this question?"}
          onDialogClose={() => setConfirmDialogData(false)}
          btnConfirm={() => {
            customData.splice(customIndex, 1);
            func(customData);
            setConfirmDialogData(false)
          }}
        />
      )}
    </>
  );
};
export default React.memo(CustomInfo);
