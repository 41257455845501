import React from 'react';

const EmailReminderHeader = () => {
  return (
    // <div className="ticket-table">
      <div className="row heading text-left ">
        <div className="col-12 col-lg-1"></div>
        <div className="col-12 col-lg-10 d-none d-lg-block">
          <div className="row">
            <div className="col-12 col-lg-4">Email Subject</div>
            <div className="col-12 col-lg-8">
              <div className="row">
                <div className="col-12 col-lg-6">Attendees</div>
                <div className="col-12 col-lg-6">Scheduled</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2 col-lg-1"></div>
      </div>
    // </div>
  );
};
export default React.memo(EmailReminderHeader);
