/* eslint-disable no-unused-vars */
import WeButton from 'components/button';
import React from 'react';

const EmptyView = ({ ...props }) => {
  const { text, message, showButton, onBtnClick, btnText } = props;
  return (
    <div className="emptyContainer">
      <div className="child">
        <h4>{text}</h4>
        <p>{message}</p>
        {showButton && <WeButton text={btnText} onClick={onBtnClick}></WeButton>}
      </div>
    </div>
  );
};

export default EmptyView;
