import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import LineChart from 'components/weCharts/LineChart';
import DoughnutChart from 'components/weCharts/doughnutChart';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getEventHomeScreenData, updateCustomUrl } from 'store/slice/event';
import moment from 'moment';
import { toast } from 'react-toastify';
import NewWindow from 'react-new-window';
import { socialShare } from './config';
import './style.css';
// import { useOutsideAlerter } from 'utils/appConstant';
Chart.register(CategoryScale);

const Home = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { eventHomeScreenData } = useSelector((state) => state.event);
  const finalAddOnSold = eventHomeScreenData?.finalAddOnSold;
  // const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e) && e.preventDefault();
  const sales = eventHomeScreenData?.sales;
  const ticketSold = eventHomeScreenData?.ticketSold;
  const viewData = eventHomeScreenData?.viewData;
  const ticketOrder = eventHomeScreenData?.ticketOrder;
  const [openGroupMenuId, setGroupMenuId] = useState(-1);
  const { userPermissionData, isOwnerValue } = useSelector((state) => state.event);
  const [openWindow, setOpenWindow] = useState(null);
  const [eventURL, setEventURL] = useState(null);
  // const [eventURLError, setEventURLError] = useState(null);
  const path = window.location.origin;
  // const socialShareData = socialShare;
  const wrapperRef = useRef(null);

  useEffect(() => {
    dispatch(
      getEventHomeScreenData({
        eventUid: id
      })
    );
  }, []);

  useEffect(() => {
    setEventURL(eventHomeScreenData?.eventUrl);
  }, [eventHomeScreenData]);

  const finalAddOnSoldData = {
    labels: ['Sold Add On', 'Remaining Add On'],
    datasets: [
      {
        data: [finalAddOnSold?.addOnSold, finalAddOnSold?.addOnRemaining],
        borderWidth: 0,
        backgroundColor: ['#FF5C5C', '#606060'],
        hoverOffset: 4
      }
    ]
  };

  const salesData = {
    labels: sales?.map((data) => moment(data.date).format('MMMM')),
    datasets: [
      {
        label: 'Amount made',
        data: sales?.map((data) => data.sales),
        tension: 0.2,
        borderColor: '#FF5C5C'
      }
    ]
  };

  const ticketSoldData = {
    labels: ['Sold Tickets', 'Remaining Tickets'],
    datasets: [
      {
        data: [ticketSold?.ticketSold, ticketSold?.ticketRemaining],
        borderWidth: 0,
        backgroundColor: ['#FF5C5C', '#606060'],
        hoverOffset: 4
      }
    ]
  };

  const pageViewData = {
    labels: viewData?.map((data) => moment(data.date).format('DD-MM')),
    datasets: [
      {
        label: 'Page Views:',
        data: viewData?.map((data) => data.count),
        tension: 0.2,
        borderColor: '#FF5C5C'
      }
    ]
  };

  const handleNavigate = (_id) => {
    navigate(`/event/${id}/attendee/orders/${_id}/details`);
  };

  // const handleValidUrl = (val) => {
  //   var format = /[ `!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/;
  //   if (!val) {
  //     setEventURLError('Url should not be empty.');
  //     return;
  //   }
  //   if (val.includes(' ')) {
  //     setEventURLError('Sapces are not allowed.');
  //     return;
  //   }
  //   if (val.match(format)) {
  //     setEventURLError('Special characters are not allowed.');
  //     return;
  //   }
  //   setEventURLError('');
  //   setEventURL(val);
  // };

  const handleChangeUrl = () => {
    // if (eventURLError) {
    //   return;
    // }
    const data = {
      eventUid: id,
      customUrl: eventURL
    };
    if (eventURL != eventHomeScreenData?.eventUrl) {
      dispatch(updateCustomUrl(data));
    }
  };
  const handleOpenViewEvent = () => {
    window.open(`${path}/${eventHomeScreenData?.eventUrl}`, '_blank');
  };

  // const handleClose = () => {
  //   setGroupMenuId(-1);
  // };

  // useOutsideAlerter(wrapperRef, handleClose);

  return (
    <>
      <h2>Home</h2>
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

      <div className="mt-3">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-12 col-xl-6">
                <div className="home-block revenue">
                  <div className="content">
                    <div className="title">Sales</div>
                    <div className="number">${eventHomeScreenData?.totalSales}</div>
                  </div>
                  <div className="chart-container">
                    <LineChart chartData={salesData} />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-12 col-xl-3">
                <div className="home-block tickets-sold">
                  <div className="content">
                    <div className="title">Tickets Sold</div>
                    <div className="number">
                      {ticketSold?.ticketSold}/{ticketSold?.ticketCapacity}
                    </div>
                  </div>
                  <div className="chart-container">
                    <div className="row">
                      <div className="col-4 col-sm-5 col-md-4 col-xl-12 d-flex align-items-center">
                        <div className="legend mb-3">
                          <div className="complete">
                            <span className="marker"></span> <span className="text">Sold</span>
                          </div>
                          <div className="incomplete">
                            <span className="marker"></span> <span className="text">Remaining</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-8 col-sm-7 col-md-8 col-xl-12">
                        <DoughnutChart chartData={ticketSoldData} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-12 col-xl-3">
                <div className="home-block add-ons-sold">
                  <div className="content">
                    <div className="title">Add On Sold</div>
                    <div className="number">
                      {finalAddOnSold?.addOnSold}/{finalAddOnSold?.addOnCapacity}
                    </div>
                  </div>
                  <div className="chart-container">
                    <div className="row">
                      <div className="col-4 col-sm-5 col-md-4 col-xl-12 d-flex align-items-center">
                        <div className="legend mb-3">
                          <div className="complete">
                            <span className="marker"></span> <span className="text">Sold</span>
                          </div>
                          <div className="incomplete">
                            <span className="marker"></span> <span className="text">Remaining</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-8 col-sm-7 col-md-8 col-xl-12">
                        <DoughnutChart chartData={finalAddOnSoldData} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-12 col-xl-6">
                <div className="home-block total-views">
                  <div className="content">
                    <div className="title">Total Page Views</div>
                    <div className="number">
                      {viewData?.totalPageViews ? viewData?.totalPageViews : 0}
                    </div>
                  </div>
                  <div className="chart-container">
                    <LineChart chartData={pageViewData} />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-12 col-xl-6">
                <div className="home-block shareable">
                  <div className="content">
                    <div className="title">Custom Shareable URL</div>
                    <div className="number">Event URL</div>
                    {/* <div className="d-flex mt-4 share-block">
                      <div className="url">
                        tesloom.com/
                        <span
                          contentEditable="true"
                          style={{ textDecoration: 'underline' }}
                          onKeyUp={(e) => {
                            handleValidUrl(e.currentTarget.textContent);
                          }}
                          onBlur={handleChangeUrl}>
                          {eventHomeScreenData?.eventUrl}
                        </span>
                      </div>
                      <div className="icon ms-2">
                        <a
                          href="#"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${path}/${eventHomeScreenData?.eventUrl}`
                            );
                            toast.success('Event URL Copied');
                          }}>
                          <i className="fa-regular fa-clone icon"></i>
                        </a>
                      </div>

                      <div className="icon ms-2">
                        <a href="#" onClick={handleOpenViewEvent}>
                          <i className="fa-solid fa-eye icon"></i>
                        </a>
                      </div>
                    </div> */}
                    <div className="d-flex share-block mt-4">
                      {/* <WeInput
                        prefix={'tesloom.com/'}
                        value={eventURL}
                        onChange={(val) => {
                          setEventURL(
                            val
                              .replace(/\s/g, '-')
                              .replace(/\s[ `!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/g, '')
                              .toLowerCase()
                          );
                        }}
                        onBlur={handleChangeUrl}
                      /> */}
                      <div className="input-block" style={{ border: '2px solid transparent' }}>
                        <div className="input-group">
                          <span className="input-group-text" id="priceAddAttendee-addon">
                            tesloom.com/
                          </span>
                          <input
                            type="text"
                            className="form-control urlInput"
                            style={{
                              backgroundColor: 'var(--bs-lt-black)'
                            }}
                            onChange={(e) => {
                              setEventURL(
                                e.target.value
                                  .replace(/\s/g, '-')
                                  .replace(/[&/\\#, `!@^_=[|;+()$~%.'":*?<>{}]/g, '')
                                  .toLowerCase()
                              );
                            }}
                            value={eventURL}
                            onBlur={handleChangeUrl}
                          />
                        </div>
                      </div>
                      <div className="icon ms-">
                        <a
                          href="#"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${path}/${eventHomeScreenData?.eventUrl}`
                            );
                            toast.success('Event URL Copied');
                          }}>
                          <i className="fa-regular fa-clone icon"></i>
                        </a>
                      </div>
                      <div className="icon ms-2">
                        <a href={`${path}/${eventHomeScreenData?.eventUrl}`} onClick={handleOpenViewEvent}>
                          <i className="fa-solid fa-eye icon"></i>
                        </a>
                      </div>
                    </div>

                    {/* <div style={{ color: 'red', fontSize: '13px' }} className="mt-2">
                      {eventURLError}
                    </div> */}
                    {/* <div style={{ color: 'var(--bs-red)', fontSize: '13px' }} className="mt-2">
                      <b>Note:-</b> Sapces and special characters are not allowed.
                    </div> */}
                    <div className="mt-4">Share on Social Media</div>
                    <div className="social-icons">
                      {socialShare.map((item) => (
                        <>
                          <a
                            href="#"
                            onClick={() => {
                              if (item.type == 'email') {
                                const url = `mailto:?subject=You are invited to attend the event&body=Hi please checkout out new event:-<br/><a href=${
                                  item.url + eventHomeScreenData?.eventUrl
                                } style="cursor:pointer">View Event </a>`;
                                window.location.href = url;
                              } else {
                                const url = item.url + eventHomeScreenData?.eventUrl;
                                setOpenWindow(url);
                              }
                            }}>
                            <i className={item.icon}></i>
                          </a>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {openWindow && (
              <NewWindow
                url={openWindow}
                features={{
                  width: 566,
                  height: 594
                }}
                copyStyles={false}
                center="screen" // DEFAULT = parent
                onUnload={() => {
                  // onUnload
                  setOpenWindow(null);
                }}
                onBlock={() => {
                  //onBlock
                  setOpenWindow(null);
                }}
                onOpen={() => {
                  //onOpen
                }}
              />
            )}

            {isOwnerValue || userPermissionData?.orders.manageOrders ? (
              <>
                <div className="mt-3">
                  <h4>Recent Orders</h4>
                </div>
                <div className="ticket-table mt-0">
                  <div className="row heading">
                    <div className="col-10 col-md-11">
                      <div className="row">
                        <div className="col-12 col-md-3">Order #</div>
                        <div className="col-12 col-md-9 d-none d-md-block">
                          <div className="row">
                            <div className="col-12 col-md-6">Name</div>
                            <div className="col-12 col-md-2">Qty.</div>
                            <div className="col-12 col-md-4">Price</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-2 col-md-1"></div>
                  </div>

                  {ticketOrder ? (
                    ticketOrder?.map((item) => {
                      return (
                        <>
                          <div className="row event-row">
                            <div className="col-10 col-md-11 mt-1">
                              <div className="row">
                                <div className="col-12 col-md-3">
                                  <span className="heading d-block d-md-none">Order Number:</span>
                                  <span className="heading">
                                    <a href="#" onClick={() => handleNavigate(item._id)}>
                                      {item.orderId} <span className="d-md-none">{item.name}</span>
                                    </a>
                                  </span>
                                  <div className="mobile-event-block d-block d-md-none mt-3">
                                    <div className="info mb-0 d-inline-block me-4">
                                      <span className="heading d-block">Price:</span>
                                      <span>${item.price}</span>
                                    </div>
                                    <div className="info mb-0 d-inline-block">
                                      <span className="heading d-block">Quantity:</span>
                                      <span>{item.quantity}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-md-9 align-self-center d-none d-md-block">
                                  <div className="row">
                                    <div className="col-12 col-md-6">{item.name}</div>
                                    <div className="col-12 col-md-2">
                                      <div>{item.quantity}</div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                      <div>${item.price}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-2 col-md-1 align-self-center">
                              <div
                                className={
                                  openGroupMenuId === item._id
                                    ? 'dropdown justify-content-end active'
                                    : 'dropdown justify-content-end'
                                }
                                data-dropdown
                                ref={wrapperRef}>
                                <a
                                  href="#a"
                                  className="dropdown-button d-inline-block"
                                  data-dropdown-button
                                  onClick={() => {
                                    if (openGroupMenuId === item._id) {
                                      setGroupMenuId(-1);
                                    } else {
                                      setGroupMenuId(item._id);
                                    }
                                  }}>
                                  <span className="fa-stack fa-1x">
                                    <i className="fa-solid fa-circle fa-stack-2x"></i>
                                    <i className="fa-solid fa-ellipsis-vertical fa-stack-1x fa-inverse"></i>
                                  </span>
                                </a>
                                <div className="dropdown-menu information-grid">
                                  <div className="dropdown-links">
                                    <a
                                      href="#"
                                      className="link"
                                      onClick={() => handleNavigate(item._id)}>
                                      View
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <p className="text-center pt-5"> No Data Found</p>
                  )}
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
