import React, { useState } from 'react';
import WeInput from 'components/weInput';
import { useNavigate, useParams } from 'react-router-dom';
import AddAttendeeComponent from './subcomponent/addAttendeeComponent';
import WeButton from 'components/button';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { addAttendeesData, eventSelectedActiveMenu } from 'store/slice/event';
import { clearAttendeeInOrder } from 'store/slice/tickets';

const AddAttendeesCheckout = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const [formError, setFormError] = useState({});
  const [buyerFirstName, setBuyerFirstName] = useState('');
  const [buyerLastname, setBuyerLastname] = useState('');
  const [buyerEmail, setBuyerEmail] = useState('');
  const { attendeesTicketData, ticketArray, attendeesData } = useSelector((state) => state.tickets);

  const handleSubmit = () => {
    const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
    if (!buyerFirstName) {
      setFormError((formError) => ({
        ...formError,
        firstname: 'Please Enter Your First Name'
      }));
      return;
    }
    if (!buyerLastname) {
      setFormError((formError) => ({
        ...formError,
        lastname: 'Please Enter Your last Name'
      }));
      return;
    }
    if (!buyerEmail) {
      setFormError((formError) => ({
        ...formError,
        email: 'Please Enter Your Email Address'
      }));
      return;
    } else if (!buyerEmail.includes('@')) {
      setFormError((formError) => ({
        ...formError,
        email: 'Please enter a valid email address'
      }));
      return;
    } else if (!buyerEmail.includes('.')) {
      setFormError((formError) => ({
        ...formError,
        email: 'Please enter a valid email address'
      }));
      return;
    } else if (!validEmail.test(buyerEmail)) {
      setFormError((formError) => ({
        ...formError,
        email: 'Please enter a valid email address'
      }));
      return;
    }
    if (ticketArray?.length == 0) {
      toast.error('Please add attendees');
      return;
    }
    if (ticketArray?.length != attendeesData.length) {
      toast.error('Please add all attendees');
      return;
    }
    const requestParams = {
      eventUid: id,
      type: '1',
      paidBy: attendeesTicketData.paidBy,
      couponCode: attendeesTicketData.couponName,
      buyerFirstName: buyerFirstName,
      buyerLastName: buyerLastname,
      buyerEmail: buyerEmail,
      ticketArray: ticketArray,
      totalAmount: attendeesTicketData.total,
      couponName:attendeesTicketData.CouponData?.couponName,
      onHoldName:attendeesTicketData.CouponData?.onHoldName,
    };
    dispatch(addAttendeesData(requestParams, navigate, id));
    dispatch(clearAttendeeInOrder());
    dispatch(eventSelectedActiveMenu('list'));
  };

  return (
    <>
      <h2>Add Attendees - Checkout</h2>
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>
      <form action="">
        <div className="row">
          <div className="col-12 col-lg-12 col-xl-9">
            <div className="block">
              <h4>Buyer</h4>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>
              <div className="row">
                <div className="col-12 col-md-6 ">
                  <WeInput
                    type="text"
                    onChange={(val) => {
                      if (val) {
                        setFormError((formError) => ({
                          ...formError,
                          firstname: ''
                        }));
                        setBuyerFirstName(val);
                      } else {
                        setBuyerFirstName('');
                      }
                    }}
                    inputLabel="First Name"
                    formError={formError.firstname}
                    value={buyerFirstName}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <WeInput
                    type="text"
                    onChange={(val) => {
                      if (val) {
                        setFormError((formError) => ({
                          ...formError,
                          lastname: ''
                        }));
                        setBuyerLastname(val);
                      } else {
                        setBuyerLastname('');
                      }
                    }}
                    inputLabel="Last Name"
                    formError={formError.lastname}
                    value={buyerLastname}
                  />
                </div>
                <div className="col-12">
                  <WeInput
                    type="text"
                    onChange={(val) => {
                      if (val) {
                        setFormError((formError) => ({
                          ...formError,
                          email: ''
                        }));
                        setBuyerEmail(val);
                      } else {
                        setBuyerEmail('');
                      }
                    }}
                    inputLabel="Email Address"
                    formError={formError.email}
                    value={buyerEmail}
                  />
                </div>
              </div>
            </div>
            <AddAttendeeComponent
              buyerData={{
                buyerFirstName: buyerFirstName,
                buyerLastName: buyerLastname,
                buyerEmail: buyerEmail
              }}
            />

            <div className="p-4 pt-2">
              <WeButton text={'Place Order'} onClick={handleSubmit} />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddAttendeesCheckout;
