import WeInput from 'components/weInput';
import React, { useEffect } from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addRole, updateRole } from 'store/slice/options';

const AddRoleDialog = ({ ...props }) => {
  const { showDialog, setshowAddRoleDialog, action, roleId } = props;
  const { roleDetailsData } = useSelector((state) => state.options);
  const [event, setEvent] = useState({
    manageEvent: false,
    manageTicket: false,
    eventSchedule: false
  });
  const [attendee, setAttendee] = useState({
    manageAttendee: false,
    checkInAttendee: false,
    manageGuestList: false
  });
  const [orders, setOrders] = useState({
    manageOrders: false,
    sendEmails: false
  });
  const [billing, setBilling] = useState({
    eventBalance: false,
    managePayment: false,
    payoutSummary: false
  });
  const [team, setTeam] = useState({
    manageTeam: false
  });
  const [roleData, setRoleData] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (roleDetailsData) {
      setRoleData(roleDetailsData);
      setEvent((event) => ({
        ...event,
        ...roleDetailsData?.event
      }));
      setAttendee((attendee) => ({
        ...attendee,
        ...roleDetailsData?.attendee
      }));
    }
  }, [roleDetailsData]);
  useEffect(() => {
    setRoleData((roleData) => ({
      ...roleData,
      event: event
    }));
  }, [event]);
  useEffect(() => {
    setRoleData((roleData) => ({
      ...roleData,
      attendee: attendee
    }));
  }, [attendee]);
  useEffect(() => {
    setRoleData((roleData) => ({
      ...roleData,
      orders: orders
    }));
  }, [orders]);
  useEffect(() => {
    setRoleData((roleData) => ({
      ...roleData,
      billing: billing
    }));
  }, [billing]);
  useEffect(() => {
    setRoleData((roleData) => ({
      ...roleData,
      team: team
    }));
  }, [team]);
  const onDialogClose = () => {
    setshowAddRoleDialog(false);
    setRoleData({});
  };
  const onEventChange = (val, type) => {
    if (type === 'event') {
      setEvent({
        manageEvent: val.target.checked,
        manageTicket: event?.manageTicket,
        eventSchedule: event?.eventSchedule
      });
    } else if (type === 'ticket') {
      setEvent({
        manageTicket: val.target.checked,
        manageEvent: event?.manageEvent,
        eventSchedule: event?.eventSchedule
      });
    } else {
      setEvent({
        manageTicket: event?.manageTicket,
        manageEvent: event?.manageEvent,
        eventSchedule: val.target.checked
      });
    }
  };
  const onAttendeeChange = (val, type) => {
    if (type === 'manage') {
      setAttendee({
        manageAttendee: val.target.checked,
        checkInAttendee: attendee?.checkInAttendee,
        manageGuestList: attendee?.manageGuestList
      });
    } else if (type === 'check') {
      setAttendee({
        checkInAttendee: val.target.checked,
        manageAttendee: attendee?.manageAttendee,
        manageGuestList: attendee?.manageGuestList
      });
    } else {
      setAttendee({
        manageGuestList: val.target.checked,
        manageAttendee: attendee?.manageAttendee,
        checkInAttendee: attendee?.checkInAttendee
      });
    }
  };
  const onOrderChange = (val, type) => {
    if (type === 'manage') {
      setOrders({
        manageOrders: val.target.checked,
        sendEmails: orders?.sendEmails
      });
    } else {
      setOrders({
        sendEmails: val.target.checked,
        manageOrders: orders?.manageOrders
      });
    }
  };
  const onBilingChange = (val, type) => {
    if (type === 'balance') {
      setBilling({
        eventBalance: val.target.checked,
        managePayment: billing?.managePayment,
        payoutSummary: billing?.payoutSummary
      });
    } else if (type === 'payment') {
      setBilling({
        managePayment: val.target.checked,
        payoutSummary: billing?.payoutSummary,
        eventBalance: billing?.eventBalance
      });
    } else {
      setBilling({
        payoutSummary: val.target.checked,
        eventBalance: billing?.eventBalance,
        managePayment: billing?.managePayment
      });
    }
  };
  const onTeamChange = (val) => {
    setTeam({
      manageTeam: val.target.checked
    });
  };

  let addROLE = {
    eventUid: id,
    roleName: roleData?.roleName,
    event: {
      manageEvent: roleData?.event?.manageEvent ? roleData?.event?.manageEvent : false,
      manageTicket: roleData?.event?.manageTicket ? roleData?.event?.manageTicket : false,
      eventSchedule: roleData?.event?.eventSchedule ? roleData?.event?.eventSchedule : false
    },
    attendee: {
      manageAttendee: roleData?.attendee?.manageAttendee
        ? roleData?.attendee?.manageAttendee
        : false,
      checkInAttendee: roleData?.attendee?.checkInAttendee
        ? roleData?.attendee?.checkInAttendee
        : false,
      manageGuestList: roleData?.attendee?.manageGuestList
        ? roleData?.attendee?.manageGuestList
        : false
    },
    orders: {
      manageOrders: roleData?.orders?.manageOrders ? roleData?.orders?.manageOrders : false,
      sendEmails: roleData?.orders?.sendEmails ? roleData?.orders?.sendEmails : false
    },
    billing: {
      eventBalance: roleData?.billing?.eventBalance ? roleData?.billing?.eventBalance : false,
      managePayment: roleData?.billing?.managePayment ? roleData?.billing?.managePayment : false,
      payoutSummary: roleData?.billing?.payoutSummary ? roleData?.billing?.payoutSummary : false
    },
    team: {
      manageTeam: roleData?.team?.manageTeam ? roleData?.team?.manageTeam : false
    }
  };
  let updateROLE = {
    roleId: roleId,
    roleName: roleData?.roleName,
    event: {
      manageEvent: roleData?.event?.manageEvent ? roleData?.event?.manageEvent : false,
      manageTicket: roleData?.event?.manageTicket ? roleData?.event?.manageTicket : false,
      eventSchedule: roleData?.event?.eventSchedule ? roleData?.event?.eventSchedule : false
    },
    attendee: {
      manageAttendee: roleData?.attendee?.manageAttendee
        ? roleData?.attendee?.manageAttendee
        : false,
      checkInAttendee: roleData?.attendee?.checkInAttendee
        ? roleData?.attendee?.checkInAttendee
        : false,
      manageGuestList: roleData?.attendee?.manageGuestList
        ? roleData?.attendee?.manageGuestList
        : false
    },
    orders: {
      manageOrders: roleData?.orders?.manageOrders ? roleData?.orders?.manageOrders : false,
      sendEmails: roleData?.orders?.sendEmails ? roleData?.orders?.sendEmails : false
    },
    billing: {
      eventBalance: roleData?.billing?.eventBalance ? roleData?.billing?.eventBalance : false,
      managePayment: roleData?.billing?.managePayment ? roleData?.billing?.managePayment : false,
      payoutSummary: roleData?.billing?.payoutSummary ? roleData?.billing?.payoutSummary : false
    },
    team: {
      manageTeam: roleData?.team?.manageTeam ? roleData?.team?.manageTeam : false
    }
  };
  const handleSave = () => {
    if (!roleData?.roleName) {
      setRoleData((roleData) => ({
        ...roleData,
        roleNameErr: 'Please enter role name'
      }));
      return;
    }
    if (action === 'AddRole') {
      dispatch(addRole(addROLE));
    } else {
      dispatch(updateRole(updateROLE));
    }
    setRoleData({});
    setshowAddRoleDialog(false);
  };
  return (
    <Modal show={showDialog} onHide={onDialogClose} centered backdrop="static" keyboard={false}>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5">Add Role </h1>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <WeInput
          type="text"
          inputLabel="Role Name"
          autoFocus={true}
          value={roleData?.roleName}
          onChange={(val) =>
            setRoleData((roleData) => ({
              ...roleData,
              roleName: val,
              roleNameErr: ''
            }))
          }
          formError={roleData?.roleNameErr}
        />
        <h5 className="mt-3">Event</h5>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="manageEvent"
            checked={roleData?.event?.manageEvent}
            onChange={(val) => onEventChange(val, 'event')}
          />
          <label className="form-check-label" htmlFor="manageEvent">
            Create, edit, manage Event
          </label>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="manageTickets"
            checked={roleData?.event?.manageTicket}
            onChange={(val) => onEventChange(val, 'ticket')}
          />
          <label className="form-check-label" htmlFor="manageTickets">
            Create, edit, manage Tickets
          </label>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="eventSchedule"
            checked={roleData?.event?.eventSchedule}
            onChange={(val) => onEventChange(val, 'eventSchedule')}
          />
          <label className="form-check-label" htmlFor="eventSchedule">
            Add room, schedule event
          </label>
        </div>
        <h5 className="mt-4">Attendees</h5>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="manageAttendees"
            checked={roleData?.attendee?.manageAttendee}
            onChange={(val) => onAttendeeChange(val, 'manage')}
          />
          <label className="form-check-label" htmlFor="manageAttendees">
            Add and manage Attendees
          </label>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="checkInAttendees"
            checked={roleData?.attendee?.checkInAttendee}
            onChange={(val) => onAttendeeChange(val, 'check')}
          />
          <label className="form-check-label" htmlFor="checkInAttendees">
            Check-in Attendees
          </label>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="manageGuestlist"
            checked={roleData?.attendee?.manageGuestList}
            onChange={(val) => onAttendeeChange(val, 'guest')}
          />
          <label className="form-check-label" htmlFor="manageGuestlist">
            Add Guests and manage guestlist
          </label>
        </div>
        <h5 className="mt-4">Orders</h5>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="manageOrdersRefundsTransfers"
            checked={roleData?.orders?.manageOrders}
            onChange={(val) => onOrderChange(val, 'manage')}
          />
          <label className="form-check-label" htmlFor="manageOrdersRefundsTransfers">
            Manage orders, refunds, and transfers{' '}
          </label>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="sendConfirmationEmails"
            checked={roleData?.orders?.sendEmails}
            onChange={(val) => onOrderChange(val, 'emails')}
          />
          <label className="form-check-label" htmlFor="sendConfirmationEmails">
            Send confirmation emails{' '}
          </label>
        </div>
        <h5 className="mt-4">Billing</h5>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="ViewEventBalance"
            checked={roleData?.billing?.eventBalance}
            onChange={(val) => onBilingChange(val, 'balance')}
          />
          <label className="form-check-label" htmlFor="ViewEventBalance">
            View event balance
          </label>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="ManagePayoutsAndPayments"
            checked={roleData?.billing?.managePayment}
            onChange={(val) => onBilingChange(val, 'payment')}
          />
          <label className="form-check-label" htmlFor="ManagePayoutsAndPayments">
            Manage payouts and payments
          </label>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="viewEventAndPayoutSummary"
            checked={roleData?.billing?.payoutSummary}
            onChange={(val) => onBilingChange(val, 'payout')}
          />
          <label className="form-check-label" htmlFor="viewEventAndPayoutSummary">
            View event and payout summary
          </label>
        </div>
        <h5 className="mt-4">team</h5>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="manageteam"
            checked={roleData?.team?.manageTeam}
            onChange={(val) => onTeamChange(val)}
          />
          <label className="form-check-label" htmlFor="manageteam">
            Add and manage team
          </label>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn secondary-btn" onClick={onDialogClose}>
          Close
        </Button>
        <Button className="btn primary-btn" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(AddRoleDialog);
