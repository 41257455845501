import WeDropDown from 'components/weDropDown';
import WeInput from 'components/weInput';
import React from 'react';
import PreviousTeam from './previousTeam';
const PublishForm = ({ ...props }) => {
  const {
    eventFormData,
    teamMembersDropdown,
    teamMembersDropdownToggle,
    teamMembers,
    onSelectTeamMembers,
    visibilityDropdown,
    visibilityDropdownToggle,
    visibility,
    onSelectItem,
    onPassCodeInputChange,
    onPreviousTeam,
    value
  } = props;
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6">
          <WeDropDown
            value={value}
            label="Visibility"
            openCloseDropdown={visibilityDropdown}
            onDropDownClick={visibilityDropdownToggle}
            selectData={visibility}
            btnWidth={true}
            // defaultValue={'Private'}
            // defaultValue={eventFormData.publishType}
            // value={
            //   eventFormData.publishType && eventFormData.publishType != 'draft'
            //     ? eventFormData?.publishType
            //     : ''
            // }
            emptyValue="Select Visibility"
            onSelectItem={(item) => {
              onSelectItem(item); //visibility[item].id);
            }}
          />
          {eventFormData.publishType == 'private' ? (
            <WeInput
              inputLabel="Pass Code"
              maxLength={12}
              onChange={onPassCodeInputChange}
              value={eventFormData.passcode ?? ''}
            />
          ) : (
            ''
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <WeDropDown
            label="Team Members"
            openCloseDropdown={teamMembersDropdown}
            onDropDownClick={teamMembersDropdownToggle}
            selectData={teamMembers}
            btnWidth={true}
            defaultValue={eventFormData.teamMemberValue ?? ''}
            emptyValue="Choose Team"
            onSelectItem={(item) => {
              onSelectTeamMembers(item); //teamMembers[item].id);
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          {eventFormData.teamMemberValue == 'previousEvent' ? (
            <PreviousTeam func={onPreviousTeam} />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};
export default React.memo(PublishForm);
