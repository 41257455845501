import WeInput from 'components/weInput';
import WeToggle from 'components/weToggle';
import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import WeDropDown from 'components/weDropDown';
import WeTimePicker from 'components/weTimePicker';
import { isFirefox } from 'utils/appConstant';

const AddEntryDialog = ({ ...props }) => {
  const { eventScheduleList } = useSelector((state) => state.event);

  const {
    showDialog,
    onDialogClose,
    btnConfirm,
    date,
    formScheduleData,
    onStartTimeChange,
    onEndTimeChange,
    onEventTitleChange,
    // onDescriptionChange,
    onChange,
    allDayChange,
    allDaywitch,
    roomError
  } = props;

  return (
    <Modal show={showDialog} centered>
      <Modal.Header>
        <Modal.Title>
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5"> Add Entry</h1>
            <a
              className="close closeBtn"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onDialogClose}>
              <i className="fa-solid fa-xmark"></i>
            </a>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Submit entry for event.</p>

        {/* <div className="dropdown input-block">
          <label className="form-label">Edit All Types</label>
          <div className="select-block">
            <select defaultValue="" onChange={onChange}>
              <option value="" selected disabled>
                Select Room
              </option>
              {eventScheduleList &&
                eventScheduleList?.map((item, i) => {
                  if (!item.notShow) {
                    return (
                      <option key={i} value={i}>
                        {item.roomTitle}
                      </option>
                    );
                  }
                })}
            </select>
          </div>
        </div> */}
        <WeDropDown
          label="Select Room"
          selectData={
            eventScheduleList &&
            eventScheduleList?.map((item) => ({
              label: `${item.roomTitle}`,
              id: item._id
            }))
          }
          defaultValue={formScheduleData.roomId ? formScheduleData.roomId : ''}
          emptyValue="Select a Room"
          onSelectItem={onChange}
        />
        {roomError && <div style={{ color: 'red' }}>{roomError}</div>}
        <WeInput
          onChange={onEventTitleChange}
          inputLabel="Title"
          formError={formScheduleData.eventTitleError}
          value={formScheduleData.eventTitle ?? ''}
        />
        <div className="mt-4">{moment(date).format('dddd, MMMM DD')}</div>
        {/* <p style={{ marginTop: '1.5rem', marginBottom: '-0.50rem' }}>
          {moment(date).format('dddd, MMMM DD')}
        </p> */}

        {!formScheduleData.allDay ? (
          <>
            <Row>
              <Col>
                {isFirefox ?
                  <WeTimePicker
                    onChange={onStartTimeChange}
                    maxVal={formScheduleData.endTime}
                    inputLabel="Start Time"
                    formError={formScheduleData.startTimeError}
                    value={formScheduleData.startTime ?? ''}
                    type="time"
                  />
                  :
                  <WeInput
                    onChange={onStartTimeChange}
                    maxVal={formScheduleData.endTime}
                    inputLabel="Start Time"
                    formError={formScheduleData.startTimeError}
                    value={formScheduleData.startTime ?? ''}
                    type="time"
                  />
                }
              </Col>
              <Col>
                {isFirefox ?
                  <WeTimePicker
                    onChange={onEndTimeChange}
                    minVal={formScheduleData.startTime}
                    inputLabel="End Time"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        btnConfirm();
                      }
                    }}
                    formError={formScheduleData.endTimeError}
                    type="time"
                    value={formScheduleData.endTime ?? ''}
                  />
                  :
                  <WeInput
                    onChange={onEndTimeChange}
                    minVal={formScheduleData.startTime}
                    inputLabel="End Time"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        btnConfirm();
                      }
                    }}
                    formError={formScheduleData.endTimeError}
                    type="time"
                    value={formScheduleData.endTime ?? ''}
                  />
                }
              </Col>
            </Row>
          </>
        ) : (
          ''
        )}
        <div className="mt-2">
          <div className="mt-3 ">
            <WeToggle
              checkToogle={formScheduleData && formScheduleData.allDay}
              onChange={allDayChange}
              onSwitchClick={allDaywitch}
              toggleLabel="All Day"
            />
          </div>
        </div>
        {/* 
        <WeInput
          onChange={onDescriptionChange}
          inputLabel=" Description"
          value={formScheduleData.description ?? ''}
        /> */}
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn secondary-btn" onClick={onDialogClose}>
          Close
        </Button>
        <Button className="btn primary-btn" onClick={btnConfirm}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(AddEntryDialog);
