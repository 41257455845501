import { createSlice } from '@reduxjs/toolkit';
import api from '../../services';
import { TESLOOMAPI } from '../../services/apiConstant';
import { toast } from 'react-toastify';

const slice = createSlice({
  name: 'coupon',
  initialState: {
    listAllCouponsData: [],
    viewCouponData: null,
    message: '',
    couponItem: null
  },
  reducers: {
    listAllCouponsSuccess(state, action) {
      state.listAllCouponsData = action.payload.data;
    },
    viewCouponSuccess(state, action) {
      state.viewCouponData = action.payload;
    },
    addCouponSuccess(state, action) {
      state.listAllCouponsData = state.listAllCouponsData.concat(action.payload);
    },
    deleteCouponSuccess(state, action) {
      let reqData = action.payload.reqData;
      state.listAllCouponsData = state.listAllCouponsData.filter((item) => item._id !== reqData);
    },
    editCouponSuccess(state, action) {
      const newObjId = action.payload._id;
      const suspendIndex = state.listAllCouponsData?.findIndex((item) => item._id === newObjId);
      let currentUser = action.payload;
      state.listAllCouponsData[suspendIndex] = currentUser;
    },
    selectCouponData(state, action) {
      state.couponItem = action.payload;
    },
    clearStatesDataSuccess(state) {
      state.listAllCouponsData = [];
    }
  }
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

const {
  listAllCouponsSuccess,
  viewCouponSuccess,
  deleteCouponSuccess,
  addCouponSuccess,
  editCouponSuccess,
  selectCouponData,
  clearStatesDataSuccess
} = slice.actions;

export const addCoupon = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.addCoupon}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(addCouponSuccess(result.data));
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const listAllCoupons = (id) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.listAllCoupons}`, id)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(listAllCouponsSuccess(result));
      } else {
        // toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const deleteCoupon = (id) => async (dispatch) => {
  api
    .delete(`${TESLOOMAPI.deleteCoupon}` + id)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(deleteCouponSuccess({ reqData: id }));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const viewCoupon = (id) => async (dispatch) => {
  api
    .get(`${TESLOOMAPI.viewCoupon}` + id)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(viewCouponSuccess(result));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const editCoupon = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.editCoupon}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(editCouponSuccess(result.data));
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const selectedCouponData = (data) => async (dispatch) => {
  dispatch(selectCouponData(data));
};

export const clearCouponData = () => async (dispatch) => {
  dispatch(selectCouponData());
};

export const clearCouponStates = () => async (dispatch) => {
  dispatch(clearStatesDataSuccess());
};
