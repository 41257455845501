import PageForNoPermission from 'pages/pageForNoPermission';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { apiFatchingStart, apiLoadingStart, attendeeList } from 'store/slice/event';
import PaginationComponent from './../../../../components/paginatioonComponent'

const AttendeeList = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  let page = 1;
  let limit = 9;
  const [pageNo, setPageNo] = useState(page);
  const { attendeeListData, userPermissionData, isOwnerValue, isFetching, totalData, isLoading } =
    useSelector((state) => state.event);
  useEffect(() => {
    getRoleAndTeamDetails();
    dispatch(apiLoadingStart());
  }, []);

  const handlePagination = (data) => {
    dispatch(apiFatchingStart());
    // const page = flag == 'next' ? pageNo + 1 : pageNo - 1;
    console.log('onPageChange', data.selected + 1);
    setPageNo(data.selected + 1);
  };

  useEffect(() => {
    getRoleAndTeamDetails();
  }, [pageNo]);

  const getRoleAndTeamDetails = () => {
    let data = {
      eventUid: id,
      page: pageNo,
      perPage: limit
    };
    dispatch(attendeeList(data));
  };

  return isOwnerValue || userPermissionData?.attendee.manageAttendee ? (
    isLoading ? (
      <div
        className="position-absolute top-50 start-50 translate-middle"
        style={{ color: 'var(--bs-lt-red)', zIndex: 1000, opacity: 0.5 }}>
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    ) : (
      <>
        <h2>Attendee List</h2>
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>
        <div className="ticket-table attendee-list">
          <div className="row heading d-block">
            <div className="col-12">
              <div className="row">
                <div className="col-1 col-lg-2 col-xxl-1">Order #</div>
                <div className="col-2">Attendee</div>
                <div className="col-2">Email</div>
                <div className="col-2">
                  <div className="one-line">Ticket Name</div>
                </div>
                <div className="col-2">Buyer</div>
                <div className="col-2">Paid?</div>
              </div>
            </div>
          </div>
          <div style={{ height: 'auto', minHeight: '35rem' }}>
            {attendeeListData && attendeeListData.length != 0 ? (
              attendeeListData?.map((item, key) => {
                return (
                  <div className="row event-row" key={key}>
                    <div className="col-12 mt-1">
                      <div className="row">
                        <div className="col-1 col-lg-2 col-xxl-1">
                          <a href="#">{item.orderId}</a>
                        </div>
                        <div className="col-2">
                          <div className="one-line">{item.attendee}</div>
                        </div>
                        <div className="col-2">
                          <div className="one-line">{item.email}</div>
                        </div>
                        <div className="col-2">
                          <div className="one-line">{item.ticketName}</div>
                        </div>
                        <div className="col-2">
                          <div className="one-line">{item.buyer} </div>
                        </div>
                        <div className="col-3 col-lg-2 col-xxl-3">
                          <div className="one-line">Paid ({item.paidBy}) </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : !isFetching ? (
              <p className="text-center pt-5"> No Data Found</p>
            ) : (
              ''
            )}
          </div>
        </div>
        {/* {isLoading ? (
        <div
          className="position-absolute top-50 start-50 translate-middle"
          style={{ color: 'var(--bs-lt-red)' }}>
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        ''
      )} */}

        {attendeeListData && totalData >limit ? (
          <div className="mt-3" style={{ float: 'right' }}>
            {/* {isFetching ? (
              <div className="text-center" style={{ color: 'var(--bs-lt-red)' }}>
                <div className="spinner-grow" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <a onClick={() => (pageNo > 1 ? handlePagination('pre') : '')}>
                  <i
                    className={
                      pageNo > 1
                        ? 'fa-solid fa-circle-chevron-left fa-2xl paginationBtn'
                        : 'fa-solid fa-circle-chevron-left fa-2xl paginationDisableBtn'
                    }></i>
                </a>
                <a
                  className="px-1"
                  onClick={() => {
                    if (
                      (totalData % limit === 0
                        ? Math.floor(totalData / limit)
                        : Math.floor(totalData / limit) + 1) > pageNo
                    ) {
                      handlePagination('next');
                    }
                  }}>
                  <i
                    className={
                      (totalData % limit === 0
                        ? Math.floor(totalData / limit)
                        : Math.floor(totalData / limit) + 1) > pageNo
                        ? 'fa-solid fa-circle-chevron-right fa-2xl paginationBtn'
                        : 'fa-solid fa-circle-chevron-right fa-2xl paginationDisableBtn'
                    }></i>
                </a>
              </>
            )} */}
            <PaginationComponent
              pageCount={totalData % limit === 0?Math.floor(totalData / limit):Math.floor(totalData / limit) + 1}
              onPageChange={handlePagination}
              pageNo={pageNo}
            />
          </div>
        ) : (
          ''
        )}
      </>
    )
  ) : (
    <PageForNoPermission />
  );
};

export default AttendeeList;
