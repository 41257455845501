export const attendeesData = [
  {
    label: 'All Attendees',
    id: 'all',
    notShow: true
  },
  {
    label: 'Specific Attendees',
    id: 'attendees',
    notShow: true
  },
  {
    label: 'By Ticket Type',
    id: 'ticket',
    notShow: true
  }
];
export const timingData = [
  {
    label: '1 Day',
    id: '1'
  },
  {
    label: '2 Day',
    id: '2'
  },
  {
    label: '3 Day',
    id: '3'
  },
  {
    label: '4 Day',
    id: '4'
  },
  {
    label: '5 Day',
    id: '5'
  },
  {
    label: '1 Week',
    id: '7'
  },
  {
    label: '2 Week',
    id: '14'
  },
  {
    label: 'Custom',
    id: 'Custom'
  }
];
