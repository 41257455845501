import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { eventSelectedActiveMenu } from 'store/slice/event';
import { useDispatch, useSelector } from 'react-redux';
import { allTicketList, apiTicketFatchingStart, getEventOrders } from 'store/slice/tickets';
import OrderFilter from 'pages/subcomponents/orders/orderFilter';
import OrderList from 'pages/subcomponents/orders/orderList';
import { toast } from 'react-toastify';
import ConfirmDialog from 'components/dialogs/confirmDialog';
import {
  clearAllCheckList,
  clearPaymentChange,
  clearTicketChange,
  clearVenuesChange,
  refundUserByOrderId,
  sendEmailOrNotificationToOrder
} from 'store/slice/order';
import PageForNoPermission from 'pages/pageForNoPermission';
import SpinnerComponent from 'components/spinnerComponent';
import PaginatioonComponent from 'components/paginatioonComponent';
// import { getEventOrders } from 'store/slice/tickets';

const Order = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  let page = 1;
  let limit = 10;
  const [pageNo, setPageNo] = useState(page);
  const { getEventOrdersData, ticketListData, isFetching,totalData } = useSelector((state) => state.tickets);
  const [filter, setFilter] = useState();
  const [actionValue, setActionValue] = useState();
  const { getCheckListData, ticketCheckData, paymentCheckData, VenuesCheckData } = useSelector(
    (state) => state.order
  );
  const { userPermissionData, isOwnerValue } = useSelector((state) => state.event);
  const [showConfirmDialog, setShowConfirmDialog] = useState();
  const [checkListBox, setCheckListBox] = useState(false);

  useEffect(() => {
    fetchAllOrders();
    dispatch(apiTicketFatchingStart(true));
  }, []);

  const handlePagination = (data) => {
    // const page = flag == 'next' ? pageNo + 1 : pageNo - 1;
    console.log('onPageChange', data.selected + 1);
    setPageNo(data.selected + 1);
  };

  useEffect(() => {
    fetchAllOrders();
  }, [pageNo]);

  const fetchAllOrders = () => {
    dispatch(
      getEventOrders({
        eventUid: id,
        search: filter?.search,
        startDate: filter?.startDate,
        endDate: filter?.endDate,
        ticketIds: ticketCheckData,
        paymentType: paymentCheckData,
        venues: VenuesCheckData,
        page: pageNo,
        perPage: limit
      })
    );
    dispatch(allTicketList({ eventUid: id }));
  };
  // const fetchAllTickets = () => {
  //   // dispatch(getEventOrders(filter));
  //   dispatch(allTicketList({ eventUid: id }));
  // };

  useEffect(() => {
    fetchAllOrders();
  }, [filter]);

  useEffect(() => {
    fetchAllOrders();
  }, [ticketCheckData]);

  useEffect(() => {
    fetchAllOrders();
  }, [paymentCheckData]);
  useEffect(() => {
    fetchAllOrders();
  }, [VenuesCheckData]);

  const handleNeworder = () => {
    navigate(`/event/${id}/attendee/add`);
    dispatch(eventSelectedActiveMenu('add'));
  };

  const handleAction = (val) => {
    if (getCheckListData.length == 0) {
      setActionValue('');
      toast.error('Please select order');
      return;
    }
    if (val == 'notification') {
      setShowConfirmDialog((showConfirmDialog) => ({
        ...showConfirmDialog,
        show: true,
        title: 'Send Resend Order Notification',
        message: 'Are you sure you want to resend order notification?',
        type: val
      }));
    } else {
      setShowConfirmDialog((showConfirmDialog) => ({
        ...showConfirmDialog,
        show: true,
        title: 'Refund Order',
        message: 'Are you sure you want to refund this order?',
        type: val
      }));
    }
  };

  const handleActionConfirm = (type) => {
    if (type == 'notification') {
      const data = {
        orderIds: getCheckListData,
        status: 2
      };
      dispatch(sendEmailOrNotificationToOrder(data));
    } else {
      const data = {
        orderIds: getCheckListData
      };
      dispatch(refundUserByOrderId(data));
    }
    setActionValue('');
    setCheckListBox(false);
    dispatch(clearAllCheckList());
  };

  return isFetching ? <SpinnerComponent /> : isOwnerValue || userPermissionData?.orders.manageOrders ? (
    <>
      <h2>Orders</h2>
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>
      <a onClick={handleNeworder} className="btn primary-btn">
        New Order
      </a>

      <OrderFilter
        onSearchOrderChange={(val) => {
          console.log("val -- ",val)
          setFilter((filter) => ({
            ...filter,
            search: val
          }));
          // filter.search = val; 
        }}
        filter={filter}
        onStartDateChange={(val) => {
          setFilter((filter) => ({
            ...filter,
            startDate: val
          }));
        }}
        onEndDateChange={(val) => {
          setFilter((filter) => ({
            ...filter,
            endDate: val
          }));
        }}
        handleRemoveFilter={() => {
          setFilter((filter) => ({
            ...filter,
            startDate: '',
            endDate: ''
          }));
        }}
        // onTicketTypeChange={(val) => {
        //   setFilter((filter) => ({
        //     ...filter,
        //     ticketType: val
        //   }));
        // }}
        // onPaymentChange={(val) => {
        //   setFilter((filter) => ({
        //     ...filter,
        //     paymentType: val
        //   }));
        // }}
        // onVenuesChange={(val) => {
        //   setFilter((filter) => ({
        //     ...filter,
        //     venues: val
        //   }));
        // }}
        onActionChange={(val) => {
          setActionValue(val);
          handleAction(val);
        }}
        actionValue={actionValue}
        onAllCheckChange={() => setCheckListBox(!checkListBox)}
        checkListBox={checkListBox}
        // onOrderTypeChange={(item) => {
        //   setFilter((filter) => ({
        //     ...filter,
        //     orderType: item
        //   }));
        //   fetchAllTickets();
        // }}
        ticketListData={ticketListData}
        // onTicketNameChange={(item) => {
        //   setFilter((filter) => ({
        //     ...filter,
        //     ticketId: item
        //   }));
        // }}
        // onDateChange={(item) => {
        //   setFilter((filter) => ({
        //     ...filter,
        //     date: item
        //   }));
        // }}
        onCheckListChange={(item) => {
          setFilter((filter) => ({
            ...filter,
            checkList: item.id
          }));
        }}
        onRefreshClick={() => {
          setFilter((filter) => ({
            ...filter,
            page: 1,
            perPage: 20,
            search: '',
            startDate: '',
            endDate: ''
          }));
          dispatch(clearTicketChange());
          dispatch(clearPaymentChange());
          dispatch(clearVenuesChange());
        }}
        getEventOrdersData={getEventOrdersData}
      />

      <OrderList getEventOrdersData={getEventOrdersData} />

      {getEventOrdersData && totalData >limit ? (
          <div className="mt-3" style={{ float: 'right' }}>
            <PaginatioonComponent
              pageCount={totalData % limit === 0?Math.floor(totalData / limit):Math.floor(totalData / limit) + 1}
              onPageChange={handlePagination}
              pageNo={pageNo}
            />
          </div>
        ) : (
          ''
        )}

      <ConfirmDialog
        showDialog={showConfirmDialog?.show}
        title={showConfirmDialog?.title}
        message={showConfirmDialog?.message}
        onDialogClose={() => {
          setActionValue('');
          setShowConfirmDialog((showConfirmDialog) => ({
            ...showConfirmDialog,
            show: false
          }));
        }}
        btnConfirm={() => {
          setShowConfirmDialog((showConfirmDialog) => ({
            ...showConfirmDialog,
            show: false
          }));
          handleActionConfirm(showConfirmDialog.type);
        }}
      />
    </>
  ) : (
    <PageForNoPermission />
  );
};

export default Order;
