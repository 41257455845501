export const devUrl = 'https://dev-app.wedancemo.com/';

export const socialShare = [
  {
    url: 'https://www.facebook.com/sharer/sharer.php?u=' + devUrl,
    icon: 'fa-brands fa-facebook-f icon',
    type: 'facebook'
  },
  {
    url:
      'https://www.facebook.com/login.php?api_key=0&skip_api_login=1&&next=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fsend%3F_path%3D' +
      devUrl,
    icon: 'fa-brands fa-facebook-messenger icon',
    type: 'messenger'
  },
  {
    url: 'https://twitter.com/intent/tweet?url=' + devUrl,
    icon: 'fa-brands fa-twitter icon',
    type: 'twitter'
  },
  {
    url: devUrl,
    icon: 'fa-solid fa-envelope icon',
    type: 'email'
  },
  {
    url: 'https://www.linkedin.com/shareArticle?url=' + devUrl,
    icon: 'fa-brands fa-linkedin-in icon',
    type: 'linkedin'
  },
  {
    url: 'https://web.whatsapp.com/send?text=' + devUrl,
    icon: 'fa-brands fa-whatsapp icon',
    type: 'whatsapp'
  }
];
