import React from 'react';

const WeCheckbox = ({ ...props }) => {
  const { innerRef, onChange, toggleLabel, checkToogle, style } = props;
  return (
    <label className="form-check-label" style={style}>
      <input
        ref={innerRef}
        className="form-check-input order-checkbox"
        type="checkbox"
        checked={checkToogle}
        onChange={(e) => {
          let checked = e.target.checked;
          onChange(checked);
        }}
      />
      <span>{toggleLabel}</span>
    </label>
  );
};
export default React.memo(WeCheckbox);
