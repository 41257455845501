import React from 'react';
import './style.css';

export default function Reports() {
  const data = [
    {
      title: 'Sales',
      message: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
      routeUrl: '/reports/sales'
    },
    {
      title: 'Analytics',
      message: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
      routeUrl: '/reports/analytics'
    },
    {
      title: 'Attendees',
      message: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
      routeUrl: '/reports/attendeeReports'
    }
  ];

  return (
    <>
      <h2>Reports</h2>

      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="">Reports</a>
            </li>
          </ol>
        </nav>
      </div>

      {data.map((item, index) => {
        return (
          <>
            <a
              href={item.routeUrl}
              className={index == 0 ? 'block d-flex white mt-0' : 'block d-flex white'}>
              <div className="content flex-fill">
                <h5>{item.title}</h5>
                <p>{item.message}</p>
              </div>
              <div className="arrow d-flex align-items-center">
                <i className="fa-solid fa-angle-right"></i>
              </div>
            </a>
          </>
        );
      })}
    </>
  );
}
