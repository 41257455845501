import { createSlice } from '@reduxjs/toolkit';
import api from '../../services';
import { TESLOOMAPI } from '../../services/apiConstant';
import { toast } from 'react-toastify';

const slice = createSlice({
  name: 'options',
  initialState: {
    message: '',
    checkOutFormData: null,
    ticketTransferData: null,
    guestsListData: [],
    roleListData: null,
    teamMemberList: null,
    roleDetailsData: null,
    attendeeInfoList: [],
    isAttendeeInfoSave: true,
    invitesData: [],
    totalCount:0
  },
  reducers: {
    checkOutFormSuccess(state, action) {
      state.checkOutFormData = action.payload;
    },
    // ticketTransferSuccess(state, action) {
    //   state.ticketTransferData = action.payload;
    // },
    addGuestSuccess(state, action) {
      state.guestsListData = state.guestsListData.concat(action.payload);
    },
    getRoleAndTeamDeatilsSuccess(state, action) {
      state.roleListData = action.payload.data.roleData;
      // if (action.payload == undefined) {  
      //   state.teamMemberList = null;
      // } else {
      //   if (action.payload.page == 1) {
      //     state.teamMemberList = [];
      //   }
      //   state.teamMemberList = state.teamMemberList.concat(action.payload.data.teamMemberData);
      //   state.isLoading = false;
      // }

      if (action.payload == null) {
        state.teamMemberList = [];
        state.isFetching = false;
        state.isLoading = false;
      } else {
        state.teamMemberList = action.payload.data.teamMemberData;
        state.isFetching = false;
        state.isLoading = false;
        state.totalCount = action.payload ? action.payload.totalCount : 0;
      }
    },
    getRoleSuccess(state, action) {
      state.roleDetailsData = action.payload;
    },
    addRoleSuccess(state, action) {
      state.roleListData = state.roleListData.concat(action.payload);
    },
    updateRoleSuccess(state, action) {
      let objId = action.payload._id;
      const valueIndex = state.roleListData?.findIndex((item) => item._id === objId);
      state.roleListData[valueIndex] = action.payload;
    },
    deleteRoleSuccess(state, action) {
      let objId = action.payload;
      state.roleListData = state.roleListData.filter((item) => item._id !== objId);
    },
    deleteTeamMemberSuccess(state, action) {
      let objId = action.payload;
      state.teamMemberList = state.teamMemberList.filter((item) => item._id !== objId);
    },
    getGuestListSuccess(state, action) {
      // state.guestsListData = action.payload.data;
      if (action.payload == null) {
        state.guestsListData = [];
        state.isFetching = false;
        state.isLoading = false;
      } else {
        state.guestsListData = action.payload.data;
        state.isFetching = false;
        state.isLoading = false;
        state.totalData = action.payload ? action.payload.totalCount : 0;
      }
    },
    deleteGuestSuccess(state, action) {
      let reqData = action.payload.reqData;
      state.guestsListData = state.guestsListData.filter((item) => item._id !== reqData);
    },
    addAttendeeInfoSuccess(state, action) {
      const objId = action.payload.name;
      const index = state.attendeeInfoList?.findIndex((data) => data.name === objId);
      if (index > -1) {
        state.attendeeInfoList[index] = action.payload;
      } else {
        state.attendeeInfoList = state.attendeeInfoList.concat(action.payload);
      }
    },
    addAttendeeInfoSaveSuccess(state, action) {
      state.isAttendeeInfoSave = action.payload;
    },
    clearCheckOutFormDataSuccess(state) {
      state.checkOutFormData = null;
    },
    getAllInvitesSuccess(state, action) {
      state.invitesData = action.payload;
    },
    sendTeamInviteSuccess(state, action) {
      // const data = {
      //   invitedTo: action.payload.email,
      //   invitationStatus: 'pending'
      // };
      state.teamMemberList = state.teamMemberList.concat(action.payload);
    },
    editGuestSuccess(state, action) {
      const newObjId = action.payload._id;
      const suspendIndex = state.guestsListData?.findIndex((item) => item._id === newObjId);
      let currentUser = action.payload;
      state.guestsListData[suspendIndex] = currentUser;
    },
    clearStatesDataSuccess(state) {
      state.checkOutFormData = null;
      state.guestsListData = [];
      state.teamMemberList = [];
      state.roleListData = [];
    }
  }
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

const {
  checkOutFormSuccess,
  // ticketTransferSuccess,
  addGuestSuccess,
  getRoleAndTeamDeatilsSuccess,
  getRoleSuccess,
  addRoleSuccess,
  updateRoleSuccess,
  deleteRoleSuccess,
  deleteTeamMemberSuccess,
  getGuestListSuccess,
  deleteGuestSuccess,
  addAttendeeInfoSaveSuccess,
  clearCheckOutFormDataSuccess,
  getAllInvitesSuccess,
  sendTeamInviteSuccess,
  editGuestSuccess,
  clearStatesDataSuccess
} = slice.actions;

export const eventCheckOutForm = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.eventCheckOutForm}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(checkOutFormSuccess(result.data));
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const getEventCheckOutForm = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.getEventCheckOutForm}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(checkOutFormSuccess(result.data));
      } else {
        // toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const addGuest = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.addGuest}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message);
        dispatch(addGuestSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const addRole = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.addRole}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message);
        dispatch(addRoleSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const  getRoleAndTeamDeatils = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.getRoleAndTeamDeatils}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        // dispatch(getRoleAndTeamDeatilsSuccess(result.data));
        // dispatch(addGuestSuccess(result.data));
        // dispatch(getRoleAndTeamDeatilsSuccess({ data: result.data, page: requestParams.page }));
        dispatch(
          getRoleAndTeamDeatilsSuccess({
            data: result.data,
            page: requestParams.page,
            totalCount: result.data.totalCount
          })
        );
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const getRole = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.getRole}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getRoleSuccess(result.data));
        // dispatch(addGuestSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const updateRole = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.updateRole}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(updateRoleSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const deleteRole = (requestParams, objId) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.deleteRole}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(deleteRoleSuccess(objId));
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const sendTeamInvite = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.sendTeamInvite}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message);
        dispatch(sendTeamInviteSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const deleteTeamMember = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.deleteTeamMember}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(deleteTeamMemberSuccess(requestParams.teamMemberId));
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const getGuestList = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.getGuestList}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        // dispatch(getGuestListSuccess(result));
        dispatch(
          getGuestListSuccess({
            data: result.data,
            page: requestParams.page,
            totalCount: result.totalCount
          })
        );
      } else {
        // toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const deleteGuest = (id) => async (dispatch) => {
  api
    .delete(`${TESLOOMAPI.deleteGuest}` + id)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(deleteGuestSuccess({ reqData: id }));
        //dispatch(getGuestListSuccess(result));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const addAttendeeInfoSave = (data) => (dispatch) => {
  dispatch(addAttendeeInfoSaveSuccess(data));
};

export const clearCheckOutFormData = () => (dispatch) => {
  dispatch(clearCheckOutFormDataSuccess());
};

export const getAllInvites = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.getAllInvites}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getAllInvitesSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const editGuest = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.editGuest}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message);
        dispatch(editGuestSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const clearOptionsStates = () => async (dispatch) => {
  dispatch(clearStatesDataSuccess());
};
