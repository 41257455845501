import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addOnHold,
  clearOnHoldData,
  deleteOnHold,
  listAllOnHolds,
  selectedOnHoldData,
  editOnHold
} from 'store/slice/onHolds';
import ConfirmDialog from 'components/dialogs/confirmDialog';
import CreateHoldonDialog from 'components/dialogs/createHoldonDialog';
import EventContext from 'context/eventContext';
import EmptyView from 'components/emptyView';
import { useParams } from 'react-router-dom';
import { allTicketList } from 'store/slice/tickets';
// import { useOutsideAlerter } from 'utils/appConstant';

const TabOnHold = () => {
  const { eventFormData } = useContext(EventContext);
  const dispatch = useDispatch();
  const { listAllOnHoldsData } = useSelector((state) => state.onHolds);
  const { ticketListData } = useSelector((state) => state.tickets);
  // const { specificAttendeeData } = useSelector((state) => state.event);
  const [holdOnName, setHoldOnName] = useState('');
  const [holdOnNameError, setHoldOnNameError] = useState('');
  const [capacity, setCapacity] = useState('');
  const [capacityError, setCapacityError] = useState('');
  const [ticket, setTicket] = useState('');
  const [ticketError, setTicketError] = useState('');
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [show, setShow] = useState(false);
  const [openGroupMenuId, setGroupMenuId] = useState(-1);
  const [confirmDialogData, setConfirmDialogData] = useState(null);
  const handleClose = () => {
    setShow(false);
    setHoldOnName('');
    setCapacity('');
    setCode('');
    setTicketError('');
    setTicket("")
    setGroupMenuId(-1);
    dispatch(clearOnHoldData());
  };
  const handleShow = () => setShow(true);

  const { OnHoldItem } = useSelector((state) => state.onHolds);

  const { id } = useParams();

  useEffect(() => {
    const reqData = {
      eventUid: eventFormData.eventUid ?? id
    };
    dispatch(listAllOnHolds(reqData));
    dispatch(allTicketList(reqData));
  }, []);

  const deleteHoldOnById = (_id) => {
    dispatch(deleteOnHold(_id));
  };

  const editHoldOn = (item) => {
    dispatch(selectedOnHoldData(item));
    setHoldOnName(item.name);
    setCapacity(item.capacity);
    setCode(item.code);
    setTicket(item.ticket);
    handleShow();
  };

  const handleSubmit = (item) => {
    if (!holdOnName) {
      setHoldOnNameError('Please add hold on name');
      return;
    }
    if (!capacity) {
      setCapacityError('Please add capacity');
      return;
    }
    if (!ticket) {
      setTicketError('Please select tickets');
      return;
    }
    if (!code) {
      setCodeError('Please add code');
      return;
    }

    if (item) {
      const onHoldData = {
        onHoldId: item?._id ? item?._id : item.onHoldId,
        name: holdOnName,
        capacity: capacity,
        ticket: ticket,
        used: item.used,
        code: code
      };
      dispatch(editOnHold(onHoldData));
    } else {
      const onHoldData = {
        eventUid: eventFormData.eventUid ?? id,
        name: holdOnName,
        capacity: capacity,
        ticket: ticket,
        code: code
      };
      dispatch(addOnHold(onHoldData));
    }
    handleClose();
  };

  const wrapperRef = useRef(null);

  const renderListView = () => {
    return (
      <>
        <div className="group-heading d-none d-lg-block">
          <div className="row">
            <div className="col-lg-7">
              <div className="d-flex align-items-center">
                <div className="group-name">
                  <div className="title">Hold Name</div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="text-end">Holds</div>
            </div>
            <div className="col-lg-2">
              <div className="text-end">Code</div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
        <div className="group-section coupon-section">
          {listAllOnHoldsData?.map((item) => {
            return (
              <>
                <div className="group-row mt-2" style={{position:"initial"}}>
                  <div className="row">
                    <div className="col-10 col-lg-7">
                      <div className="d-flex align-items-center">
                        <div className="group-name">
                          <div className="title">{item.name}</div>
                          <small>
                            <div className="d-block d-lg-none">Discount: $99</div>
                            <div className="d-block d-lg-none">
                              Uses: {item.used}/{item.capacity}
                            </div>
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 d-none d-lg-block">
                      <div className="text-end">
                        {item.used} / {item.capacity}
                      </div>
                    </div>
                    <div className="col-lg-2 d-none d-lg-block">
                      <div className="text-end">{item.code}</div>
                    </div>
                    <div className="col-2 col-lg-1">
                      <div
                        className={
                          openGroupMenuId === item._id
                            ? 'dropdown justify-content-end active'
                            : 'dropdown justify-content-end'
                        }
                        data-dropdown>
                        <a
                          className="dropdown-button d-inline-block"
                          data-dropdown-button
                          onClick={() => {
                            if (openGroupMenuId === item._id) {
                              setGroupMenuId(-1);
                            } else {
                              setGroupMenuId(item._id);
                            }
                          }}>
                          <span className="fa-stack fa-1x">
                            <i className="fa-solid fa-circle fa-stack-2x"></i>
                            <i className="fa-solid fa-ellipsis-vertical fa-stack-1x fa-inverse"></i>
                          </span>
                        </a>
                        <div className="dropdown-menu information-grid" ref={wrapperRef}>
                          <div className="dropdown-links">
                            <a
                              className="link"
                              onClick={() => {
                                setGroupMenuId(-1);
                                editHoldOn(item);
                              }}>
                              Edit
                            </a>
                            <a
                              className="link"
                              onClick={() => {
                                setGroupMenuId(-1);
                                setConfirmDialogData({
                                  ...confirmDialogData,
                                  show: true,
                                  id: item._id,
                                  item: item._id,
                                  title: 'Delete Holdon',
                                  message: 'Are you sure, you want to delete Holdon?'
                                });
                              }}>
                              Delete
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      {ticketListData?.length === 0 ? (
        <>
          <EmptyView text="No Ticket Created" message="Please create ticket for adding on holds." />
        </>
      ) : (
        <div className="mt-3">
          <div className="mb-3">
            <a className="btn primary-btn mt-2" onClick={handleShow}>
              Create Hold
            </a>
          </div>

          {listAllOnHoldsData.length === 0 ? (
            <>
              <EmptyView text="No On Hold Created" message="Create on hold for your event." />
            </>
          ) : (
            <> {renderListView()}</>
          )}
        </div>
      )}
      <CreateHoldonDialog
        showDialog={show}
        onDialogClose={handleClose}
        onNameInputChange={(val) => {
          if (val) {
            setHoldOnNameError('');
          }
          setHoldOnName(val);
        }}
        onCapacityInputChange={(val) => {
          if (val) {
            setCapacityError('');
          }
          setCapacity(val.replace(/[^\d]/, ''));
        }}
        onCodeInputChange={(val) => {
          if (val) {
            setCodeError('');
          }
          setCode(val.toUpperCase());
        }}
        selectTicket={
          ticketListData &&
          ticketListData?.map((item) => ({
            label: `${item.name}`,
            id: item.id
          }))
        }
        // specificAttendeeData={specificAttendeeData}
        onSelectTicketsChange={(val)=>setTicket(val)}
        couponTicketError={ticketError}
        holdOnsNameError={holdOnNameError}
        holdOnsCapacityError={capacityError}
        holdOnsCodeError={codeError}
        OnHoldName={holdOnName}
        OnHoldICapacity={capacity}
        OnHoldCode={code}
        ticket={ticket}
        btnSave={() => {
          handleSubmit(OnHoldItem);
        }}
      />

      {confirmDialogData && (
        <ConfirmDialog
          showDialog={confirmDialogData.show}
          title={confirmDialogData.title}
          message={confirmDialogData.message}
          onDialogClose={() => {
            setConfirmDialogData((confirmDialogData) => ({
              ...confirmDialogData,
              show: false
            }));
            setGroupMenuId(-1);
          }}
          btnConfirm={() => {
            deleteHoldOnById(confirmDialogData.item);
            setConfirmDialogData((confirmDialogData) => ({
              ...confirmDialogData,
              show: false
            }));
            setGroupMenuId(-1);
          }}
        />
      )}
    </>
  );
};
export default React.memo(TabOnHold);
