// import { Outlet } from 'react-router-dom';
import React from 'react';

export default function Page404() {
  return (
    <div className="col-12 col-lg-12 col-xl-12 board">
    <div className="position-absolute top-50 start-50 translate-middle">
      <div style={{ fontSize: '2rem', textAlign: 'center' }}>
        The page you are looking has been moved, deleted or possibly never existed.
      </div>
      <h1 style={{ textAlign: 'center', fontSize: '12rem' , color:'#ff8282'}}>404</h1>
    </div>
    </div>
  );
}
