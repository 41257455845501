import WeButton from 'components/button';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'rxjs';
import { forgotPassword } from 'store/slice/auth';
import { validEmail } from 'utils/appConstant';

const ForgotPassword = () => {
  const navigate = useNavigate('login');
  const dispatch = useDispatch();
  const { id } = useParams();
  const [email, setEmail] = useState('');
  let [emailError, setEmailError] = useState('');

  const { isLoading } = useSelector((state) => state.auth);

  const handleLogin = () => {
    if (id) {
      navigate(`/inviteAccept/${id}`);
    } else {
      navigate(`/`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setEmailError('Please enter email.');
      return;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Please enter valid email.');
      return;
    }

    let requestParam = {
      email: email
    };
    dispatch(forgotPassword(requestParam, navigate, id));
  };
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 10
      }}>
      <div className="contain">
        <h3 className="login-title" style={{ color: 'rgb(137 135 135)' }}>
          Forgot Password
        </h3>
        <div className="login-form">
          <div>
            <label
              className="mt-2"
              htmlFor="email  "
              style={{ float: 'left', color: 'rgb(70 69 69)' }}>
              Email{' '}
            </label>
            <input
              name="email"
              placeholder="me@example.com"
              onChange={(e) => {
                let val = e.target.value.trim();
                if (!isEmpty(val) && !validEmail(val)) {
                  setEmailError('Please enter valid email');
                } else {
                  setEmail(val);
                  setEmailError('');
                }
              }}
            />
          </div>
          {emailError && <div style={{ color: 'red' }}>{emailError}</div>}

          <a className="linkText rightText" onClick={handleLogin}>
            <i className="fa-solid fa-arrow-left"></i> Back to login
          </a>
          <WeButton text="Submit" onClick={handleSubmit} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
