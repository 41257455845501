import React, { useEffect, useState } from 'react';
// import { Stepper } from 'react-form-stepper';
import { useNavigate } from 'react-router-dom';
// import { WeDanceColors } from '../../../services/colors';
import './styles.css';
// import stepConfig from './stepConfig';
import BasicInfo from '../eventSections/basicInfo';
import Summary from '../eventSections/summary';
import Tickets from '../eventSections/tickets';
import Publish from '../eventSections/publish';
import { toast } from 'react-toastify';
import EventContext from 'context/eventContext';

const CreateEvent = () => {
  const [eventFormData, setEventFormData] = useState({});
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);

  useEffect(() => {
    setEventFormData({})
  }, []);

  const goBackToDashboard = () => {
    navigate('/event/dashboard');
  };

  const btnPublishEvent = () => {
    toast.success('Congratulations, Your event is published successfully.');
    navigate('/event/dashboard');
  };

  const btnSaveNext = () => {
    let newStep = activeStep + 1;
    setActiveStep(newStep);

    setCompletedSteps((completedSteps) => [...completedSteps, activeStep]);
  };
  const btnPrevious = () => {
    let newStep = activeStep - 1;
    setActiveStep(newStep);
    const removeCompletedStep = completedSteps.filter((val) => val !== newStep);
    setCompletedSteps(removeCompletedStep);
    renderStepperView();
  };
  const renderStepperView = () => {
    if (activeStep === 0) {
      return <BasicInfo onSave={btnSaveNext} btnPrevious={btnPrevious} activeStep={activeStep} />;
    } else if (activeStep === 1) {
      return <Summary onSave={btnSaveNext} btnPrevious={btnPrevious} activeStep={activeStep} />;
    } else if (activeStep === 2) {
      return <Tickets onSave={btnSaveNext} btnPrevious={btnPrevious} activeStep={activeStep} />;
    } else if (activeStep === 3) {
      return <Publish onSave={btnPublishEvent} btnPrevious={btnPrevious} activeStep={activeStep} />;
    }
  };
  return (
    <>
      <EventContext.Provider value={{ eventFormData, setEventFormData }}>
        <h1>Create Event</h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item" onClick={goBackToDashboard}>
              <a href="#">Event Dashboard</a>
            </li>
          </ol>
        </nav>
        <div className="progress-container">
          <ul className="progress-steps">
            <li
              className={
                activeStep === 0 ? 'active' : completedSteps.includes(0) ? 'complete' : ''
              }>
              Event Info
            </li>
            <li
              className={
                activeStep === 1 ? 'active' : completedSteps.includes(1) ? 'complete' : ''
              }>
              Summary
            </li>
            <li
              className={
                activeStep === 2 ? 'active' : completedSteps.includes(2) ? 'complete' : ''
              }>
              Tickets
            </li>
            <li
              className={
                activeStep === 3 ? 'active' : completedSteps.includes(3) ? 'complete' : ''
              }>
              Publish
            </li>
          </ul>
        </div>
        <div>{renderStepperView()}</div>
        {/* </div> */}
        {/* <div className="col-12 col-lg-9 col-xl-9">
          <Stepper
            steps={stepConfig}
            activeStep={activeStep}
            styleConfig={{
              activeBgColor: WeDanceColors.black,
              activeTextColor: WeDanceColors.bsRed,
              inactiveBgColor: WeDanceColors.black,
              inactiveTextColor: WeDanceColors.white,
              completedBgColor: WeDanceColors.bsRed,
              completedTextColor: WeDanceColors.black,
              size: '1.8em',
              circleFontSize: '0.8em',
              labelFontSize: '0.9em'
            }}
            stepClassName="stepper__step"
            connectorStyleConfig={{
              size: 2,
              color: 'white'
            }}
          />

          <div>{renderStepperView()}</div>
        </div> */}
      </EventContext.Provider>
    </>
  );
};

export default CreateEvent;
