import React from 'react';
import './style.css';
//import { useOutsideAlerter } from 'utils/appConstant';

const WeDropDown = ({ ...props }) => {
  const {
    innerRef,
    isRequired,
    label,
    onSelectItem,
    selectData,
    formError,
    defaultValue,
    emptyValue,
    selectState,
    selectRoom,
    className,
    value,
    autoFocus,
    onKeyPress
  } = props;

  return (
    <>
      <div
        className={className ? className : 'dropdown input-block'}
        autoFocus={autoFocus}
        ref={innerRef}
        onKeyDown={onKeyPress}>
        <label className="form-label">
          {label}
          <span style={{ color: 'red' }}>{isRequired ? '*' : ''}</span>
        </label>
        <div className="select-block">
          <select
            defaultValue={defaultValue}
            value={value}
            onChange={(e) => onSelectItem(e.target.value)}>
            <option value="" disabled selected={defaultValue == ''}>
              {emptyValue}
            </option>
            {selectData &&
              selectData.map((item, i) => {
                if (!item.notShow) {
                  return (
                    <option
                      key={i}
                      value={item.id}
                      selected={item.label == defaultValue ? true : false}>
                      {item.label}
                    </option>
                  );
                }
              })}
            {selectState &&
              selectState.map((item, i) => {
                if (!item.notShow) {
                  return (
                    <option key={i} value={i} selected={item == defaultValue ? true : false}>
                      {item}
                    </option>
                  );
                }
              })}{' '}
            {selectRoom &&
              selectRoom.map((item, i) => {
                if (!item.notShow) {
                  return (
                    <option
                      key={i}
                      value={i}
                      selected={item.roomTitle == defaultValue ? true : false}>
                      {item?.roomTitle}
                    </option>
                  );
                }
              })}
          </select>
        </div>
      </div>
      {formError && <div style={{ color: 'red' }}>{formError}</div>}
    </>
  );
};
export default React.memo(WeDropDown);
