import React, { useEffect, useState } from 'react';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { createTimeList } from 'store/slice/tickets';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

const weTimePicker = ({ ...props }) => {
  const { value, inputLabel, formError, onChange } = props;
  const dispatch = useDispatch();
  const { hour, min } = useSelector((state) => state.tickets);
  const [isDropDownShow, setIsDropDownShow] = useState(false);
  const [selectedTime, setSelectedTime] = useState({});

  useEffect(() => {
    dispatch(createTimeList({ start: 1, end: 12, flag: 'hour' }));
    dispatch(createTimeList({ start: 0, end: 59, flag: 'min' }));
  }, []);

  useEffect(() => {
    const number = moment(value, ["HH:mm"]).format("hh:mm A");
    setSelectedTime((selectedTime) => ({
      ...selectedTime,
      hour: moment(number, 'HH:mm').hours(),
      min: moment(number, 'HH:mm').minutes(),
      isAmOrPm: number.includes("PM") ? 'PM' : 'AM'
    }))
  }, [value]);

  const saveDate = () => {
    const time = `${selectedTime.hour ? selectedTime.hour : 0}:${selectedTime.min ? selectedTime.min : 0} ${selectedTime.isAmOrPm ? selectedTime.isAmOrPm : 'AM'}`
    const number = moment(time, ["h:mm A"]).format("HH:mm");
    onChange(number);
    setIsDropDownShow(false);
  }

  return (
    <>
      <div className="input-block">
        <label className="form-label">{inputLabel}</label>
        <button className='inputValue' onClick={() => setIsDropDownShow(!isDropDownShow)}>
          {value ? moment(value, ["HH:mm"]).format("hh:mm A") : "hh:mm"}
        </button>
      </div>
      {formError && <div style={{ color: 'red' }}>{formError}</div>}




      <Modal show={isDropDownShow} onHide={() => setIsDropDownShow(false)} centered backdrop="static">
        <div className='timeContainer'>
          <div style={{ padding: '5px', borderBottom: ' 2px solid var(--bs-lt-black)', opacity: 1 }}>
            Set Time
          </div>
          <div className='timeList'>
            <ul>
              {hour?.map((item, i) => {
                return (
                  <span key={i}>
                    <li className={selectedTime.hour == item ? "isActiveItem" : ""}
                      onClick={() => {
                        setSelectedTime((selectedTime) => ({
                          ...selectedTime,
                          hour: item
                        }))
                      }}>{item}</li>
                  </span>
                );
              })}
            </ul>
            <ul>
              {min?.map((item, i) => {
                return (
                  <span key={i}>
                    <li className={selectedTime.min == item ? "isActiveItem" : ""}
                      onClick={() => {
                        setSelectedTime((selectedTime) => ({
                          ...selectedTime,
                          min: item
                        }))
                      }}>{item}</li>
                  </span>
                );
              })}
            </ul>
            <ul>
              <li className={selectedTime.isAmOrPm == 'AM' ? "isActiveItem" : ""}
                onClick={() => {
                  setSelectedTime((selectedTime) => ({
                    ...selectedTime,
                    isAmOrPm: 'AM'
                  }))
                }}>AM</li>
              <li className={selectedTime.isAmOrPm == 'PM' ? "isActiveItem" : ""}
                onClick={() => {
                  setSelectedTime((selectedTime) => ({
                    ...selectedTime,
                    isAmOrPm: 'PM'
                  }))
                }}>PM</li>
            </ul>
          </div>
          {/* <hr /> */}
          <div style={{ display: "flex", borderTop: ' 2px solid var(--bs-lt-black)', opacity: 1 }}>
            <a onClick={() => setIsDropDownShow(false)} style={{ padding: '12px 5px 5px 5px', cursor: 'pointer' }}>Close</a>
            <a onClick={saveDate} style={{ padding: '12px 5px 5px 5px', marginLeft: "auto", cursor: 'pointer' }}>Save</a>
          </div>
        </div>
        {/* </Modal.Body> */}
      </Modal>
    </>
  );
};
export default React.memo(weTimePicker);
