import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

export default function Test() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <section className="">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-3 col-xl-2 pt-lg-3 admin-left-column">
              <div className="sidebar-nav">
                <div className="row pt-2 pt-lg-4 pb-2 pb-lg-2"></div>
              </div>
            </div>
            <div className="col-12 col-lg-10 col-xl-9">
              <div className="row">
                <div className="col-12 col-lg-4 col-xxl-3 event-sidebar-nav">
                  <div className="row pt-2 pt-lg-1 pb-2 pb-lg-2"></div>
                  <div
                    className="accordion accordion-flush accordion-menu"
                    id="accordionPanelsStayOpenExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                          onClick={() => setOpen(!open)}>
                          Payments & Tax
                        </button>
                      </h2>
                      <Collapse in={open}>
                        <div className="accordion-body">
                          <ul className="sub-menu">
                            <li>
                              <a href="payment-options.html">
                                <span className="fa-stack fa-1x">
                                  <i className="fa-solid fa-circle fa-stack-2x"></i>
                                  <i className="fa-solid fa-calculator fa-stack-1x fa-inverse"></i>
                                </span>{' '}
                                <span className="title">Payment Options</span>
                              </a>
                            </li>
                            <li>
                              <a href="payout-method.html">
                                <span className="fa-stack fa-1x">
                                  <i className="fa-solid fa-circle fa-stack-2x"></i>
                                  <i className="fa-solid fa-building-columns fa-stack-1x fa-inverse"></i>
                                </span>{' '}
                                <span className="title">Payout Method</span>
                              </a>
                            </li>
                            <li>
                              <a href="refund-settings.html">
                                <span className="fa-stack fa-1x">
                                  <i className="fa-solid fa-circle fa-stack-2x"></i>
                                  <i className="fak fa-refund fa-stack-1x fa-inverse"></i>
                                </span>{' '}
                                <span className="title">Refund Settings</span>
                              </a>
                            </li>
                            <li>
                              <a href="tax-settings.html" className="active">
                                <span className="fa-stack fa-1x">
                                  <i className="fa-solid fa-circle fa-stack-2x"></i>
                                  <i className="fak fa-tax fa-stack-1x fa-inverse"></i>
                                </span>{' '}
                                <span className="title">Tax Settings</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-8 col-xxl-9 board"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
