/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import './styles.css';
const AutoComplete = ({ ...props }) => {
  const {
    isRequired,
    innerRef,
    onSearchChange,
    value,
    inputLabel,
    searchData,
    onItemSelected,
    formError,
    noBorder,
    clearValue,
    onKeyPress,
    autoFocus,
    searchEmail,
    searchTicket,
    // onKeyDown,
    placeholder
  } = props;

  const [active, setActive] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const selectRef = useRef(null);
  var targetElm = document.querySelector('.venue-location')


  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      // enter key
      setActive(0);
      setScrollHeight(0);
      onItemSelected(searchData[active]);
    } else if (e.keyCode === 38) {
      // up arrow
      if (active < searchData?.length - 2 && active != 0) {
        targetElm?.scrollTo({ top: scrollHeight < 50 ? 50 - scrollHeight : scrollHeight - 50, behavior: 'smooth' });
        setScrollHeight(scrollHeight < 50 ? 50 - scrollHeight : scrollHeight - 50)
      }
      else if (active == 0) {
        targetElm?.scrollTo({ top: (searchData?.length - 3) * 50, behavior: 'smooth' });
        // setScrollHeight((searchData?.length - 3) * 50)
      }
      return active === 0 ? setActive(searchData?.length - 1) : setActive(active - 1);
    } else if (e.keyCode === 40) {
      // down arrow
      if (active > 1 && active + 1 != searchData?.length) {
        targetElm?.scrollTo({ top: scrollHeight + 50, behavior: 'smooth' });
        setScrollHeight(scrollHeight + 50)
      }
      else if (active + 1 === searchData?.length) {
        targetElm?.scrollTo({ top: 0, behavior: 'smooth' });
        setScrollHeight(0)
      }
      return active + 1 === searchData?.length ? setActive(0) : setActive(active + 1);
    }
  };

  return (
    <>
      <div className="input-block clearable-input" key={searchData}>
        <label htmlFor="keywordSearchTags" className="form-label">
          {inputLabel}
          <span style={{ color: 'red' }}>{isRequired ? '*' : ''}</span>
        </label>
        <input
          ref={innerRef}
          type="text"
          onKeyDown={onKeyDown}
          value={value ?? ''}
          autoFocus={autoFocus}
          className="form-control autoPlaceHolder"
          placeholder={placeholder}
          onChange={(e) => {
            onSearchChange(e.target.value);
          }}
          onKeyUp={onKeyPress}
        />
        {value && (
          <span data-clear-input onClick={() => {
            setActive(0);
            setScrollHeight(0);
            clearValue()
          }}>
            <i className="fa fa-remove"></i>
          </span>
        )}
      </div>
      {formError && <div style={{ color: 'red' }}>{formError}</div>}
      {searchData && searchData?.length > 0 && (
        <>
          <ul className="list-group venue-location" ref={selectRef}>
            {searchData.map((item, i) => {
              return (
                <>
                  <div key={`${item.id}-${i}`}>
                    {noBorder ? (
                      <li
                        className={i === active ? "list-group-item" : "list-group-item active"}
                        key={item.id}
                        style={{ border: 0 }}
                        onClick={() => onItemSelected(item)}>
                        {item.value}
                      </li>
                    ) : (
                      <li
                        className={i === active ? "list-group-item" : "list-group-item active"}
                        key={item.id}
                        onClick={() => onItemSelected(item)}>
                        {item.value}
                      </li>
                    )}
                  </div>
                </>
              );
            })}
          </ul>
        </>
      )}
      {searchEmail && searchEmail?.length > 0 && (
        <>
          <ul className="list-group">
            {searchEmail?.map((item, i) => {
              return (
                <>
                  <div key={`${item?._id}-${i}`}>
                    {noBorder ? (
                      <li
                        className="list-group-item"
                        key={item._id}
                        style={{ border: 0 }}
                        onClick={() => onItemSelected(item)}>
                        {item?.value}
                      </li>
                    ) : (
                      <li
                        className="list-group-item"
                        key={item._id}
                        onClick={() => onItemSelected(item)}>
                        {item.email}
                      </li>
                    )}
                  </div>
                </>
              );
            })}
          </ul>
        </>
      )}
      {searchTicket && searchTicket?.length > 0 && (
        <>
          <ul className="list-group">
            {searchTicket?.map((item, i) => {
              return (
                <>
                  <div key={`${item?.ticketId}-${i}`}>
                    <li
                      className="list-group-item"
                      key={item.ticketId}
                      onClick={() => onItemSelected(item)}>
                      {item.ticketName}
                    </li>
                  </div>
                </>
              );
            })}
          </ul>
        </>
      )}
    </>
  );
};
export default React.memo(AutoComplete);
