import AddRoleDialog from 'components/dialogs/addRoleDialog';
import ConfirmDialog from 'components/dialogs/confirmDialog';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRole, getRole } from 'store/slice/options';

const RoleList = () => {
  const dispatch = useDispatch();
  const [showAddRoleDialog, setshowAddRoleDialog] = useState(false);
  const { roleListData } = useSelector((state) => state.options);
  const [action, setAction] = useState('');
  const [roleId, setRoleId] = useState();
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const handleAdd = () => {
    setAction('AddRole');
    setshowAddRoleDialog(true);
  };
  const handleUpdate = (data) => {
    setRoleId(data);
    setAction('updateRole');
    let requestParam = {
      roleId: data
    };
    dispatch(getRole(requestParam));
    setshowAddRoleDialog(true);
  };
  const handleDelete = () => {
    let requestParam = {
      roleId: roleId
    };
    dispatch(deleteRole(requestParam, roleId));
    setShowConfirmDeleteDialog(false);
  };
  return (
    <>
      <ConfirmDialog
        message={'Are you sure, you want to delete this Role?'}
        showDialog={showConfirmDeleteDialog}
        onDialogClose={() => {
          setShowConfirmDeleteDialog(false);
        }}
        btnConfirm={handleDelete}
      />
      <div className="col-12 col-lg-5">
        <div className="company-team-info-overview">
          <h4>Roles</h4>
          {roleListData?.map((data, index) => {
            return data?.roleName != 'Event Manager' && data?.roleName != 'Volunteer' ? (
              <a href="#" className="item-profile dark role mt-3" key={index}>
                <div className="icon d-flex align-items-center">
                  <span
                    className="d-block p-3"
                    onClick={() =>
                      data?.roleName != 'Event Manager' && data?.roleName != 'Volunteer'
                        ? handleUpdate(data._id)
                        : ''
                    }>
                    <i
                      className={
                        data?.roleName != 'Event Manager' && data?.roleName != 'Volunteer'
                          ? 'fa-solid fa-pen-to-square'
                          : ''
                      }></i>
                  </span>
                </div>
                <div className="profile-content">
                  <div className="role">{data?.roleName}</div>
                </div>
                <div className="icon d-flex align-items-center ms-auto">
                  <span
                    className="d-block p-2 pe-3"
                    onClick={() => {
                      if (data?.roleName != 'Event Manager' && data?.roleName != 'Volunteer') {
                        setRoleId(data._id);
                        setShowConfirmDeleteDialog(true);
                      }
                    }}>
                    <i
                      className={
                        data?.roleName != 'Event Manager' && data?.roleName != 'Volunteer'
                          ? 'fa-solid fa-trash-can'
                          : ''
                      }></i>
                  </span>
                </div>
              </a>
            ) : (
              ''
            );
          })}

          <a onClick={handleAdd} className="btn primary-btn mt-3">
            Add Role
          </a>
        </div>
      </div>

      <AddRoleDialog
        showDialog={showAddRoleDialog}
        setshowAddRoleDialog={setshowAddRoleDialog}
        action={action}
        roleId={roleId}
      />
    </>
  );
};
export default React.memo(RoleList);
