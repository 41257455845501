export const TESLOOMAPI = {
  login: '/user/userLogin',
  userRegister: '/user/userRegister',
  forgotPassword: '/user/forgotPassword',
  addEvent: '/event/addEvent',
  userDashboard: '/event/userDashboard',
  editOrganizationInfo: '/user/editOrganizationInfo',
  updateEvent: '/event/updateEvent',
  eventListAll: '/event/listAllUserEvent',
  timezoneList: '/event/timezoneList',
  uploadFile: '/event/uploadFile',
  eventDetail: '/event/detail',
  eventTypeList: '/event/getGenre',
  hashTagList: '/event/getHashTags',
  createTicketGroup: '/event/createTicketGroup',
  addTicket: '/event/addTicket',
  listAllTickets: '/event/listAllTickets',
  listAllAddOns: '/event/listAllAddOns',
  listAllCoupons: '/event/listAllCoupons',
  listAllOnHolds: '/event/listAllOnHolds',
  allTickets: '/event/allTickets',
  addAddOns: '/event/addAddOns',
  addCoupon: '/event/addCoupon',
  addOnHold: '/event/addOnHold',
  deleteOnHold: '/event/deleteOnHold/',
  deleteCoupon: '/event/deleteCoupon/',
  deleteAddOns: '/event/deleteAddOns/',
  deleteGroup: '/event/deleteGroup/',
  deleteTicket: '/event/deleteTicket/',
  viewOnHold: '/event/viewOnHold/',
  viewCoupon: '/event/viewCoupon/',
  viewAddOns: '/event/viewAddOns/',
  addTicketInGroup: '/event/addTicketInGroup',
  viewTicket: '/event/viewTicket/',
  editTicket: '/event/editTicket',
  editAddOns: '/event/editAddOns',
  editCoupon: '/event/editCoupon',
  editOnHold: '/event/editOnHold',
  searchAddress: 'event/v2/getEventVenueSuggestion',
  searchAddressDetail: 'event/v2/getVenueInfoByMapBoxId',
  deleteEvent: '/event/delete/',
  duplicateEvent: '/event/duplicateEvent',
  resetPassword: '/user/resetPassword',
  attendeeList: '/event/attendeeList',
  searchAttendee: '/event/searchAttendee',
  addAttendees: '/event/addAttendee',
  applyCoupon: '/event/applyPromoCode',
  createReminder: '/event/addEmailReminder',
  sendTestEmailRemainder: '/event/sendTestEmailRemainder',
  scheduledEmailList: '/event/getScheduledEmailList',
  sentEmailList: '/event/getSentEmailList',
  updateReminder: '/event/editEmailReminder',
  duplicateReminder: '/event/duplicateEmailReminder',
  deleteReminder: '/event/deleteEmailReminder',
  checkIn: 'event/changeCheckInStatus',
  addRoomForEvent: '/eventOptions/addRoomForEvent',
  eventScheduleList: '/eventOptions/getEventSchduleByDate',
  addActivityForEvent: '/eventOptions/addActivityInRoom',
  eventCheckOutForm: '/eventOptions/eventCheckOutForm',
  getEventCheckOutForm: '/eventOptions/getEventCheckOutForm',
  editTicketTransferForEvent: '/eventOptions/editTicketTransferForEvent',
  refundAndTaxSettings: '/event/updateRefundAndTaxSettings',
  addGuest: '/eventOptions/addGuest',
  addRole: '/user/addRole',
  getRole: '/user/getRole',
  updateRole: '/user/updateRole',
  deleteRole: '/user/deleteRole',
  sendTeamInvite: '/user/sendTeamInvite',
  deleteTeamMember: '/user/removeUserFromTeam',
  getRoleAndTeamDeatils: '/user/getAllRoleAndTeamMembers',
  getGuestList: '/eventOptions/getGuestList',
  deleteGuest: '/eventOptions/deleteGuest/',
  editTicketTransferForTicket: '/eventOptions/editTicketTransferForTicket',
  editTicketTransferForAllTicket: '/eventOptions/editTicketTransferForAllTicket',
  getEventOrders: '/event/getEventOrders',
  stateList: '/payment/statesList',
  addBanckAccountOnStripe: '/payment/addBanckAccountOnStripe',
  getStripeBankAccount: '/payment/getStripeBankAccount',
  deleteStripeBankAccount: '/payment/deleteBankAccount',
  getOrganiserOrders: '/event/getOrganiserOrders',
  searchTicket: '/user/getOrganiserAllTickets',
  getOrderDetails: 'event/getOrderDetails/',
  getUserData: '/user/getFollowingUserTicketList',
  eventAction: '/event/eventAction',
  saveUserTaxInfo: '/user/saveUserTaxInfo',
  getUserTaxInfo: '/user/getUserTaxInfo',
  setDefaultBankAccount: '/payment/setDefaultBankAccount',
  updateUserPayout: '/user/updateUserPayout',
  getUserPayout: '/user/getUserPayoutSchedule',
  getPreviousTeamList: '/event/getPreviousTeamList',
  getAllInvites: '/user/getAllInvites',
  updatePreviousEventTeam: '/event/updatePreviousEventTeam',
  deleteFile: '/event/deleteFile',
  salesReport: '/eventOptions/salesReport',
  attendeeReport: 'eventOptions/attendeeReport',
  editGuest: '/eventOptions/updateGuest',
  getOrganiserPayouts: '/user/getOrganiserPayouts',
  getEventHomeScreenData: '/event/getEventHomeScreenData',
  reportAnalytics: '/eventOptions/reportAnalytics',
  getUserEventVenue: '/eventOptions/getUserEventVenue',
  getUserPermissionForEvent: '/user/getUserPermissionForEvent',
  checkInvite: '/user/checkIfUserHasTeamInvite',
  updateTeamInviteStatus: '/user/updateTeamInviteStatus',
  addHastag: '/event/addHastag',
  addNotesInOrder: '/event/addNotesInOrder',
  updateCustomUrl: '/event/updateCustomUrl',
  eventDetailsByUrl: '/event/eventDetailsByUrl/',
  refundUserByOrderId: '/payment/refundUserByOrderId',
  transferTickets: '/payment/transferTickets',
  sendEmailOrNotificationToOrder: '/payment/sendEmailOrNotificationToOrder',
  previewOrderPdf: '/payment/previewOrderPdf',
  deleteActivityInRoom: '/eventOptions/deleteActivityInRoom',
  editActivityInRoom: '/eventOptions/editActivityInRoom',
  checkIfAttendeeExists: '/event/checkIfAttendeeExists',
  deleteRoom:'/eventOptions/deleteRoom'
};

export const API_RESPONSE = {
  SUCCESS: 1,
  FAILURE: 0
};
