/* eslint-disable no-unused-vars */
import WeButton from 'components/button';
import LoaderComponent from 'components/loaderComponent';
import React from 'react';

const FooterButtons = ({ ...props }) => {
  const {
    btnSaveContinue,
    btnBack,
    isLoading,
    activeStep,
    btnNotShow,
    isEdit,
    btnSaveName,
    teamSubmitBtn,
    btnSaveTeam
  } = props;

  const showPreviewButton = () => {
    if (!isEdit && !btnNotShow && activeStep !== 0) {
      return (
        <>
          <WeButton
            text="Prev"
            isLoading={isLoading}
            onClick={btnBack}
            classes="btn secondary-btn mt-2 me-2 ml-auto"
          />
          <div style={{ marginRight: 5, marginLeft: 5 }}></div>
        </>
      );
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        {showPreviewButton()}
        {teamSubmitBtn ? (
          isLoading ? (
            <div style={{ color: 'var(--bs-lt-red)' }}>
              <LoaderComponent />
            </div>
          ) : (
            <WeButton
              text={'Save Team'}
              classes="btn primary-btn mt-2 mx-1"
              // isLoading={isLoading}
              onClick={btnSaveTeam}
            />
          )
        ) : (
          ''
        )}

        {!btnNotShow ? (
          isLoading ? (
            <div style={{ color: 'var(--bs-lt-red)' }}>
              <LoaderComponent />
            </div>
          ) : (
            <WeButton
              text={btnSaveName ?? 'Save & Continue'}
              // isLoading={isLoading}
              onClick={btnSaveContinue}
            />
          )
        ) : (
          ''
        )}
      </div>
    </>
  );
};
export default React.memo(FooterButtons);
