import { createSlice } from '@reduxjs/toolkit';
import api from '../../services';
import { TESLOOMAPI } from '../../services/apiConstant';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';

const slice = createSlice({
  name: 'auth',
  initialState: {
    userData: null,
    message: '',
    isLoading: false,
    eventData: null
  },
  reducers: {
    apiFetching: (state) => {
      state.isLoading = true;
    },
    userDataSuccess: (state, action) => {
      state.userData = action.payload.data;
      state.isLoading = false;
    },
    userDataFailed: (state, action) => {
      state.message = action.payload;
      state.isLoading = false;
    },
    apiFailed: (state) => {
      state.isLoading = false;
    },
    eventDetailSuccess: (state, action) => {
      state.eventData = action.payload;
    }
  }
});

export default slice.reducer;

/**********************ACTIONS************************************************ */
export const login = (requestParams, navigate, id) => async (dispatch) => {
  dispatch(apiFetching());
  api
    .post(`${TESLOOMAPI.login}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        secureLocalStorage.setItem(process.env.REACT_APP_TOKEN_STORAGE_KEY, result.token);
        secureLocalStorage.setItem(process.env.REACT_APP_USER_STORAGE_KEY, result.data);
        secureLocalStorage.setItem(process.env.REACT_APP_AUTH_STORAGE_KEY, true);
        dispatch(userDataSuccess(result.data));
        if (id) {
          navigate(`/inviteAccept/${id}`);
        } else {
          navigate('/', true);
        }
      } else {
        dispatch(userDataFailed(result.error));
        toast.error(result.message);
      }
    })
    .catch(() => {
      dispatch(apiFailed());
    });
};

export const userRegister = (requestParams, navigate, id) => async (dispatch) => {
  dispatch(apiFetching());
  api
    .post(`${TESLOOMAPI.userRegister}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success('Your account has been registered successfully.');
        dispatch(apiFailed());
        if (id) {
          navigate(`/inviteAccept/${id}`);
        } else {
          navigate('/', true);
        }
      } else {
        toast.error(result.message);
        dispatch(apiFailed());
      }
    })
    .catch(() => {
      dispatch(apiFailed());
    });
};

export const forgotPassword = (requestParams, navigate, id) => async (dispatch) => {
  dispatch(apiFetching());
  api
    .post(`${TESLOOMAPI.forgotPassword}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message);
        dispatch(apiFailed());
        if (id) {
          navigate(`/inviteAccept/${id}`);
        } else {
          navigate('/', true);
        }
      } else {
        dispatch(apiFailed());
        toast.error(result.message);
      }
    })
    .catch(() => {
      dispatch(apiFailed());
    });
};
export const resetPassword = (requestParams) => async () => {
  api
    .post(`${TESLOOMAPI.resetPassword}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const eventDetailsByUrl = (requestParams) => async (dispatch) => {
  api
    .get(`${TESLOOMAPI.eventDetailsByUrl}` + requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(eventDetailSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

const { apiFetching, userDataFailed, userDataSuccess, apiFailed, eventDetailSuccess } =
  slice.actions;
