import React from 'react';
import ReactPaginate from 'react-paginate';
import './index.css';

const index = ({ ...props }) => {
  const { pageCount, onPageChange,pageNo } = props;

  return (
    <>
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        pageCount={pageCount}
        onPageChange={onPageChange}
        breakLinkClassName="page-link"
        containerClassName="pagination justify-content-center"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName={pageNo == 1 ?"page-item invisible":"page-item"}
        previousLinkClassName="page-link"
        nextClassName={pageNo == pageCount ?"page-item invisible":"page-item"}
        nextLinkClassName="page-link"
        activeClassName="active"
      />
    </>
  );
};
export default React.memo(index);
