const navConfig = [
  {
    title: 'Overview',
    path: '/dashboard',
    icon: 'fa-solid fa-gauge-high fa-stack-1x fa-inverse'
  },
  {
    title: 'Events',
    path: '/event/dashboard',
    icon: 'fa-regular fa-calendar-days fa-stack-1x fa-inverse'
  },
  {
    title: 'Orders',
    path: '/orders',
    icon: 'fa-regular fa-receipt fa-stack-1x fa-inverse'
  },

  {
    title: 'Reports',
    path: '/reports',
    icon: 'fa-solid fa-chart-line fa-stack-1x fa-inverse'
  },
  {
    title: 'Finance',
    path: '/finance',
    icon: 'fa-solid fa-building-columns fa-stack-1x fa-inverse'
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: 'fa-duotone fa-gear fa-stack-1x fa-inverse'
  }
];

export const bottomNavConfig = [
  {
    title: 'Search',
    path: '/search',
    icon: 'fa-solid fa-magnifying-glass fa-stack-1x fa-inverse'
  },
  {
    title: 'Users',
    path: '/users',
    icon: 'fa-solid fa-users fa-stack-1x fa-inverse'
  },
  {
    title: 'My Orders (2)',
    path: '/myorders',
    icon: 'fa-kit fa-tickets fa-stack-1x fa-inverse'
  },
  {
    title: 'Profile',
    path: '/profile',
    icon: 'fa-solid fa-user fa-stack-1x fa-inverse'
  }
];

export default navConfig;
