/* eslint-disable no-unused-vars */
import EventContext from 'context/eventContext';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eventDetail, updateEvent, updatePreviousEventTeam } from 'store/slice/event';
import EventHeader from '../eventHeader';
import PublishForm from './subComponents/publishForm';
import ConfirmDialog from 'components/dialogs/confirmDialog';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import FooterButtons from '../footerButtons';
import image from '../../../../assets/img/placeholder.jpeg';
import PageForNoPermission from 'pages/pageForNoPermission';
import PopOverSpinner from 'components/popOverSpinner';

const Publish = ({ ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate('');
  const { onSave, activeStep, btnPrevious } = props;
  const { eventFormData, setEventFormData } = useContext(EventContext);
  const { isLoading, previousTeamData, userPermissionData, isOwnerValue, eventDetailData } =
    useSelector((state) => state.event);
  const [showPublishDialog, setShowPublishDialog] = useState(false);

  const visibilityData = [
    {
      label: 'Public',
      id: 'public'
    },
    {
      label: 'Private',
      id: 'private'
    },
    {
      label: 'Unlisted',
      id: 'unlisted'
    }
  ];

  const teamMembersData = [
    {
      label: 'Previous Event',
      id: 'previousEvent'
    },
    {
      label: 'New Team',
      id: 'newTeam'
    }
  ];

  const [showTeamMembers, setShowTeamMembers] = useState(false);
  const [visibility, setVisibility] = useState('');
  const [showVisibility, setShowVisibility] = useState(false);
  const path = window.location;
  const isCreate = path.pathname.includes('create');
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(eventDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (eventDetailData) {
      setEventFormData(eventDetailData);
    }
  }, [eventDetailData]);
  const handlePublishEvent = (type) => {
    if (type == 'NO') {
      setShowPublishDialog(false);
    } else {
      // if (eventFormData.publishType == 'private' && !eventFormData.passcode) {
      //   toast.error('Please add passcode for your private event.');
      //   return;
      // }
      dispatch(updateEvent(eventFormData));
      if (!id) {
        onSave();
        navigate('/event/dashboard');
      }
      setShowPublishDialog(false);
    }
  };

  const handleTeamSubmit = (teamData) => {
    const data = {
      eventUid: id,
      teamArray: teamData
    };
    dispatch(updatePreviousEventTeam(data));
  };

  return isCreate || isOwnerValue || userPermissionData?.event.manageEvent ? (
    <>
      <EventHeader title="Publish Your Event" subTitle="Publish your event and invite attendees." />

      <div className="mt-3">
        <div className="row">
          <div className="col-12 col-lg-10 col-xl-10">
            <div className="event-block publish">
              <div className="background-image">
                <img
                  src={eventFormData.bannerImageName ? eventFormData.bannerImageName : image}
                  alt=""
                />
                <div className="overlay"></div>
              </div>
              <div className="date">
                <div className="slab">
                  <div className="day">{moment(eventFormData.startDate).format('DD')}</div>
                  <div className="month">{moment(eventFormData.startDate).format('MMM')}</div>
                </div>
              </div>
              <div className="content">
                <div className="organizer">
                  {eventFormData.title ?? ''}
                  <span className="fa-stack fa-1x">
                    <i className="fa-solid fa-badge fa-stack-2x"></i>
                    <i className="fa-solid fa-check fa-stack-1x fa-inverse"></i>
                  </span>
                </div>
                <div className="event-name"> {eventFormData.googleDescription ?? ''}</div>
                <div className="event-date">{eventFormData.startDate ?? ''}</div>
                <div className="event-info">Price: {eventFormData.priceRange} | Attendees: 0</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <a href={`${path.origin}/${eventFormData?.eventUrl}`} target='_blank' rel="noreferrer">
          View Event <i className="fa-solid fa-arrow-up-right-from-square"></i>
        </a>
      </div>

      <PublishForm
        value={eventFormData?.publishType === 'draft' ? '' : eventFormData?.publishType}
        eventFormData={eventFormData}
        teamMembersDropdown={showTeamMembers}
        teamMembersDropdownToggle={() => setShowTeamMembers(!showTeamMembers)}
        teamMembers={teamMembersData}
        onSelectTeamMembers={(item) => {
          setEventFormData((eventFormData) => ({
            ...eventFormData,
            teamMembers: item,
            teamMemberValue: item
          }));
          setShowTeamMembers(false);
        }}
        visibilityDropdown={showVisibility}
        visibilityDropdownToggle={() => setShowVisibility(!showVisibility)}
        visibility={visibilityData}
        onSelectItem={(item) => {
          setEventFormData((eventFormData) => ({
            ...eventFormData,
            publishType: item,
            publishTypeVal: item
          }));
          setVisibility(item.label);
          setShowVisibility(false);
        }}
        onPassCodeInputChange={(val) => {
          setEventFormData((eventFormData) => ({
            ...eventFormData,
            passcode: val.toUpperCase()
          }));
        }}
        onPreviousTeam={(data) => {
          setEventFormData((eventFormData) => ({
            ...eventFormData,
            teamList: data
          }));
        }}
      />

      <br />
      <FooterButtons
        isEdit={id ? true : false}
        btnSaveContinue={() => {
          if (eventFormData?.publishType === 'draft') {
            toast.error('Please select visibility type');
          } else if (eventFormData.publishType == 'private' && !eventFormData.passcode) {
            toast.error('Please add passcode for your private event.');
          } else {
            setShowPublishDialog(true);
          }
        }}
        // isLoading={isLoading}
        btnBack={btnPrevious}
        activeStep={activeStep}
        teamSubmitBtn={previousTeamData ? true : false}
        btnSaveTeam={() => handleTeamSubmit(previousTeamData)}
        btnSaveName={eventFormData.publishType == 'draft' ? 'Publish' : 'Update'}
      />
      {isLoading ? <PopOverSpinner /> : ''}
      <ConfirmDialog
        title="Publish Event"
        message="Are you sure, you want to publish this event?"
        showDialog={showPublishDialog}
        onDialogClose={() => {
          handlePublishEvent('NO');
        }}
        btnConfirm={() => {
          handlePublishEvent('YES');
        }}
      />
    </>
  ) : (
    <PageForNoPermission />
  );
};

export default Publish;
