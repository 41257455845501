/* eslint-disable no-unused-vars */
import WeButton from 'components/button';
import React from 'react';

const EventHeader = ({ ...props }) => {
  const {
    title,
    subTitle,
    btnSaveContinue,
    btnBack,
    isLoading,
    activeStep,
    btnNotShow,
    isEdit,
    btnSaveName
  } = props;

  const showPreviewButton = () => {
    if (!isEdit && !btnNotShow && activeStep !== 0) {
      return (
        <>
          <WeButton
            text="Previous"
            isLoading={isLoading}
            onClick={btnBack}
            classes="btn secondary-btn mt-2"
          />
          <div style={{ marginRight: 5, marginLeft: 5 }}></div>
        </>
      );
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h2>{title}</h2>
          <p>{subTitle}</p>
        </div>
        {/* <div className={btnSaveName == 'Publish' ? 'col-12' : 'col-4'}>
          <div style={{ display: 'flex' }}>
            {showPreviewButton()}

            {!btnNotShow ? (
              <WeButton text={btnSaveName ?? "Save & Continue"} isLoading={isLoading} onClick={btnSaveContinue} />
            ) : (
              ''
            )}
          </div>
        </div> */}
      </div>
    </>
  );
};
export default React.memo(EventHeader);
