import AddRoomDialog from 'components/dialogs/addRoomDialog';
import React, { useEffect, useState } from 'react';
import AddEntry from 'components/dialogs/addEntryDialog';
import EventCalendar from './eventCalendar';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import {
  addActivityForEvent,
  addRoomForEvent,
  apiFatchingStart,
  deleteActivityInRoom,
  editActivityInRoom,
  eventDetail,
  getEventSchdeuleDate
} from 'store/slice/event';
import { useParams } from 'react-router-dom';
import PageForNoPermission from 'pages/pageForNoPermission';
import EntryDetailDialog from 'components/dialogs/entryDetailDialog';
import ConfirmDialog from 'components/dialogs/confirmDialog';
import SpinnerComponent from 'components/spinnerComponent';
import DeleteRoomDialog from 'components/dialogs/deleteRoomDialog';
//import { convertUTCToLocalTime } from 'utils/appConstant';

const EventSchedule = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { eventDetailData, eventScheduleList, userPermissionData, isOwnerValue, isFetching } =
    useSelector((state) => state.event);

  const [showRoomDialog, setShowRoomDialog] = useState(false);
  const [formScheduleData, setFormScheduleData] = useState({});
  const [roomError, setRoomError] = useState('');
  const [showEntryDialog, setShowEntryDialog] = useState(false);
  const [showDeleteEntryDialog, setShowDeleteEntryDialog] = useState(false);
  const [showEntryDetailDialog, setShowEntryDetailDialog] = useState({});
  const [showRooms, setShowRooms] = useState(false);
  const [showDeleteRoomDialog, setDeleteRoomDialog] = useState(false);
  const eventStartDate = new Date(eventDetailData?.startDate);
  const eventEndDate = new Date(eventDetailData?.endDate);
  let eventScheduleDates = getDatesBetween(eventStartDate, eventEndDate);

  const [selectedDate, setSelectedDate] = useState(eventScheduleDates[0]);

  function getDatesBetween(startDate, endDate) {
    const currentDate = new Date(startDate.getTime());
    const dates = [];
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  useEffect(() => {
    dispatch(apiFatchingStart());
    let lastSelectedDate = localStorage.getItem('calSelectedDate');
    console.log('lastSelectedDate == ', lastSelectedDate);
    if (lastSelectedDate) {
      lastSelectedDate = moment(lastSelectedDate).format('YYYY-MM-DD');
    } else {
      lastSelectedDate = moment(eventScheduleDates[0]).format('YYYY-MM-DD');
      localStorage.setItem('calSelectedDate', lastSelectedDate);
    }
    setSelectedDate(lastSelectedDate);
    dispatch(eventDetail(id));
  }, [id]);

  useEffect(() => {
    if (eventDetailData) {
      fetchEventScheduleList();
    }
  }, [eventDetailData]);

  const fetchEventScheduleList = () => {
    let lastSelectedDate = localStorage.getItem('calSelectedDate');
    lastSelectedDate = moment(lastSelectedDate).format('YYYY-MM-DD');
    let obj = {
      eventUid: eventDetailData?.eventUid,
      date: lastSelectedDate ?? eventDetailData?.startDate
    };
    dispatch(getEventSchdeuleDate(obj));
    setFormScheduleData({});
  };

  //onDate change
  const handleChangeDate = () => {
    // let obj = {
    //   eventUid: eventDetailData?.eventUid,
    //   date: data
    // };
    // dispatch(getEventSchdeuleDate(obj));
    window.location.reload();
  };

  const handleOpenDetailDialog = (event) => {
    const roomIndex = eventScheduleList?.findIndex((item) => item._id === event.resourceId);
    const entryIndex = eventScheduleList[roomIndex].activityData?.findIndex(
      (item) => item._id === event._id
    );
    const eventData = eventScheduleList[roomIndex].activityData[entryIndex];
    const data = {
      eventTitle: eventData.activityTitle,
      startTime: eventData.startTime,
      endTime: eventData.endTime,
      roomId: eventScheduleList[roomIndex]._id,
      roomName: eventScheduleList[roomIndex].roomTitle,
      allDay: eventData.allDay,
      entryId: eventData._id
    };
    setShowEntryDetailDialog((showEntryDetailDialog) => ({
      ...showEntryDetailDialog,
      show: true,
      data: data
    }));
  };

  const handleOpenEnteryDialog = (event) => {
    const data = {
      startTime: moment(event.start).format('HH:mm'),
      endTime: moment(event.end).format('HH:mm'),
      roomId: event.resourceId
    };
    setFormScheduleData((formScheduleData) => ({
      ...formScheduleData,
      ...data
    }));
    setShowEntryDialog(true);
  };

  const handleEditEntry = (event) => {
    const data = {
      eventTitle: event.eventTitle,
      startTime: event.startTime ? moment(event.startTime).format('HH:mm') : '',
      endTime: event.endTime ? moment(event.endTime).format('HH:mm') : '',
      roomId: event.roomId,
      allDay: event.allDay,
      activityId: event.entryId
    };
    setFormScheduleData((formScheduleData) => ({
      ...formScheduleData,
      ...data
    }));
    setShowEntryDetailDialog((showEntryDetailDialog) => ({
      ...showEntryDetailDialog,
      show: false,
      data: {}
    }));
    setShowEntryDialog(true);
  };

  const handleCloseDialog = () => {
    setShowRoomDialog(false);
    setShowEntryDialog(false);
    setDeleteRoomDialog(false);
    setFormScheduleData({});
  };

  const onRoomInputChange = (val) => {
    setFormScheduleData((formScheduleData) => ({
      ...formScheduleData,
      roomTitle: val,
      roomTitleError: ''
    }));
  };
  const onStartTimeChange = (val) => {
    setFormScheduleData((formScheduleData) => ({
      ...formScheduleData,
      startTime: val
    }));
  };
  const onEndTimeChange = (val) => {
    setFormScheduleData((formScheduleData) => ({
      ...formScheduleData,
      endTime: val
    }));
  };
  const onEventTitleChange = (val) => {
    setFormScheduleData((formScheduleData) => ({
      ...formScheduleData,
      eventTitle: val
    }));
  };

  const onAllDayChange = () => {
    setFormScheduleData((formScheduleData) => ({
      ...formScheduleData,
      allDay: !formScheduleData.allDay
    }));
  };

  const handleSubmitAddRoom = () => {
    if (!formScheduleData.roomTitle) {
      setFormScheduleData((formScheduleData) => ({
        ...formScheduleData,
        roomTitleError: 'Please Enter Room Title'
      }));
      return;
    }
    let data = {
      eventUid: eventDetailData?.eventUid,
      roomTitle: formScheduleData.roomTitle,
      date: selectedDate ? selectedDate : eventScheduleDates[0]
    };
    dispatch(addRoomForEvent(data));

    setShowRoomDialog(false);
    setFormScheduleData((formScheduleData) => ({
      ...formScheduleData,
      roomTitle: ''
    }));
  };
  const handleSelectRooms = () => {
    setShowRooms(!showRooms);
  };
  // const onSelectRoom = (item) => {
  //   setShowRooms(!showRooms);
  //   setFormScheduleData((formScheduleData) => ({
  //     ...formScheduleData,
  //     roomId: eventScheduleList[item.target.value]?._id,
  //     roomLabel: eventScheduleList[item.target.value]?.roomTitle
  //   }));
  // };

  const handleDelete = (id) => {
    const data = {
      activityId: id
    };
    dispatch(deleteActivityInRoom(data, fetchEventScheduleList));
  };

  const handleSubmitEntry = () => {
    if (!formScheduleData?.roomId) {
      setRoomError('Please select room');
      return;
    }

    let eventDate = moment(selectedDate).format('YYYY-MM-DD');
    let data = {
      roomId: formScheduleData.roomId,
      activityTitle: formScheduleData.eventTitle,
      // startTime: formScheduleData.allDay
      //   ? ''
      //   : getUTCDateTime(eventDate + ' ' + formScheduleData.startTime),
      // endTime: formScheduleData.allDay
      //   ? ''
      //   : getUTCDateTime(eventDate + ' ' + formScheduleData.endTime),
      startTime: formScheduleData.allDay ? '' : eventDate + ' ' + formScheduleData.startTime,
      endTime: formScheduleData.allDay ? '' : eventDate + ' ' + formScheduleData.endTime,
      allDay: formScheduleData.allDay ?? '',
      description: formScheduleData.description ?? '',
      activityId: formScheduleData.activityId
    };
    if (formScheduleData.activityId) {
      dispatch(editActivityInRoom(data, fetchEventScheduleList));
    } else {
      dispatch(addActivityForEvent(data, fetchEventScheduleList));
    }
    setShowEntryDialog(!showEntryDialog);
    setFormScheduleData({});
  };

  return isFetching ? (
    <SpinnerComponent />
  ) : isOwnerValue || userPermissionData?.event.eventSchedule ? (
    <>
      <h2>Event Schedule</h2>
      <p>
        Lorem ipsum dolor sit AMet, consectetuer adipiscing elit. Donec odio. Quisque volutpat
        mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper
        suscipit, posuere a, pede.
      </p>

      <EntryDetailDialog
        showDialog={showEntryDetailDialog.show}
        onDialogClose={() => {
          setShowEntryDetailDialog((showEntryDetailDialog) => ({
            ...showEntryDetailDialog,
            show: false
          }));
        }}
        data={showEntryDetailDialog?.data}
        btnConfirm={() => {
          handleEditEntry(showEntryDetailDialog?.data);
        }}
        btnDelete={() => {
          setShowDeleteEntryDialog((showDeleteEntryDialog) => ({
            ...showDeleteEntryDialog,
            show: true,
            id: showEntryDetailDialog?.data.entryId
          }));
          setShowEntryDetailDialog((showEntryDetailDialog) => ({
            ...showEntryDetailDialog,
            show: false
          }));
        }}
      />

      <ConfirmDialog
        title={'Delete Entry'}
        message={'Are you sure you want to delete this entry?'}
        showDialog={showDeleteEntryDialog?.show}
        onDialogClose={() => {
          setShowDeleteEntryDialog((showDeleteEntryDialog) => ({
            ...showDeleteEntryDialog,
            show: false
          }));
        }}
        btnConfirm={() => {
          handleDelete(showDeleteEntryDialog?.id);
          setShowDeleteEntryDialog((showDeleteEntryDialog) => ({
            ...showDeleteEntryDialog,
            show: false
          }));
        }}
      />

      <AddRoomDialog
        showDialog={showRoomDialog}
        onDialogClose={handleCloseDialog}
        onRoomInputChange={onRoomInputChange}
        btnConfirm={handleSubmitAddRoom}
        formScheduleData={formScheduleData}
      />

      <DeleteRoomDialog
        showDialog={showDeleteRoomDialog}
        onDialogClose={handleCloseDialog}
      />

      <AddEntry
        roomError={roomError}
        showDialog={showEntryDialog}
        onDialogClose={handleCloseDialog}
        showRooms={showRooms}
        allDayChange={(checked) => {
          setFormScheduleData((formScheduleData) => ({
            ...formScheduleData,
            allDay: checked
          }));
        }}
        allDaywitch={onAllDayChange}
        handleSelectRooms={handleSelectRooms}
        onChange={(item) => {
          setRoomError('');
          setFormScheduleData((formScheduleData) => ({
            ...formScheduleData,
            roomId: item
          }));
        }}
        date={selectedDate}
        formScheduleData={formScheduleData}
        onStartTimeChange={onStartTimeChange}
        onEndTimeChange={onEndTimeChange}
        onEventTitleChange={onEventTitleChange}
        //   onDescriptionChange={(val)=>{
        //   setFormScheduleData((formScheduleData) => ({
        //     ...formScheduleData,
        //     description: val
        //   }));
        //  }}
        btnConfirm={handleSubmitEntry}
      />

      <ul className="nav nav-tabs schedule-tabs  mt-4" id="myTab" role="tablist">
        {eventScheduleDates?.map((date, index) => {
          return (
            <li className="nav-item" role="presentation" key={index}>
              <div className="text-center">
                <strong>{moment(date).format('ddd')}</strong>
              </div>
              <button
                className={
                  moment(date).format('YYYY-MM-DD') ===
                    moment(localStorage.getItem('calSelectedDate')).format('YYYY-MM-DD')
                    ? 'nav-link active'
                    : 'nav-link'
                }
                id={date}
                type="button"
                role="tab"
                onClick={() => {
                  localStorage.setItem('calSelectedDate', date);
                  setSelectedDate(date);
                  handleChangeDate(date);
                }}
                aria-controls="home-tab-pane"
                aria-selected="false">
                <span className="day">{moment(date).format('DD')}</span>
                <span className="month">{moment(date).format('MMM')}</span>
              </button>
            </li>
          );
        })}
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="home-tab-pane"
          role="tabpanel"
          aria-labelledby="home-tab"
          tabIndex="0">
          <div className="mb-3">
            <a className="btn secondary-btn mt-2" onClick={() => setShowRoomDialog(true)}>
              Add Room
            </a>
            <a onClick={() => setShowEntryDialog(true)} className="btn primary-btn mt-2">
              Add Entry
            </a>
            {eventScheduleList && eventScheduleList?.length > 1 ?
              <a
                className="btn secondary-btn mt-2"
                style={{ float: 'right' }}
                onClick={() => setDeleteRoomDialog(true)}>
                Delete Room
              </a>
              : ""}
          </div>
          <h4>{moment(selectedDate ?? eventStartDate).format('dddd, MMMM DD')}</h4>
        </div>
        <div className="mt-4">
          {eventDetailData && (
            <EventCalendar
              date={selectedDate ?? eventScheduleDates[0]}
              onEventSelect={handleOpenDetailDialog}
              onCalendarSlotSelect={handleOpenEnteryDialog}
            />
          )}
        </div>
      </div>
    </>
  ) : (
    <PageForNoPermission />
  );
};

export default EventSchedule;
