import WeDropDown from 'components/weDropDown';
import WeInput from 'components/weInput';
import WeTextArea from 'components/weTextArea';
import WeTimePicker from 'components/weTimePicker';
import WeToggle from 'components/weToggle';
import React from 'react';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import './style.css'
import Modal from 'react-bootstrap/Modal';
import { currentTime, isFirefox, selectValue, todayDate } from 'utils/appConstant';
import MultipleSelectDropDown from 'components/multipleSelectDropDown';
import { updateSpecificAttendee } from 'store/slice/event';
import { useDispatch, useSelector } from 'react-redux';

let selectGroupList = []
// let addedCouponData = []
const AddTicketDialog = ({ ...props }) => {
  const dispatch = useDispatch();
  const { specificAttendeeData } = useSelector((state) => state.event);
  const {
    showDialog,
    onDialogClose,
    basicBtnActive,
    basic,
    advanced,
    eventFormData,
    typeDropdown,
    typeDropdownToggle,
    type,
    typeError,
    addTicketvalues,
    setTypeData,
    onTicketNameInputChange,
    selectGroupDropdown,
    selectGroupDropdownToggle,
    setSelectGroupData,
    onQuantityInputChange,
    onPriceInputChange,
    feeSubtractedChange,
    couponsData,
    feeSubtractedSwitch,
    availability,
    setAvailabilityData,
    onStartDateInputChange,
    onStartTimeInputChange,
    onEndDateInputChange,
    onEndTimeInputChange,
    selectTicketDropdown,
    selectTicketDropdownToggle,
    selectTicket,
    setSelectTicketData,
    briefMaxLength,
    onticketDescriptionInputChange,
    visibilityDropdown,
    visibilityDropdownToggle,
    visibility,
    onMinTicketsInputChange,
    onMaxTicketsInputChange,
    visibilityData,
    btnSave,
    calPrice,
    setFeeToggleChecked,
    selectGroup,
    // selectedCouponData
  } = props;

  // let { selectGroup } = props;

  useEffect(() => {
    if (addTicketvalues.feeSubtraction) {
      setFeeToggleChecked(addTicketvalues.feeSubtraction);
    } else {
      setFeeToggleChecked(addTicketvalues.feeSubtraction);
    }
  }, [addTicketvalues]);
  const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

  useEffect(() => {
    if (selectGroup) {
      const data = [{
        label: 'No group',
        id: 'no_group'
      }];
      selectGroupList = [...data, ...selectGroup];
    }
  }, [selectGroup]);

  // const handleCouponSelect = (val) => {
  //   const found = addedCouponData.findIndex((element) => element === val);
  //   if (found < 0) {
  //     addedCouponData.push(val);
  //   } else {
  //     addedCouponData.splice(found, 1);
  //   }
  //   selectedCouponData(addedCouponData)
  // }

  const onSpecificAttendeeSelect = (item) => {
    dispatch(updateSpecificAttendee(item.id));
  };

  const basicForm = () => {
    return (
      <>
        <WeDropDown
          label="Type"
          autoFocus={true}
          openCloseDropdown={typeDropdown}
          btnWidth={true}
          onDropDownClick={typeDropdownToggle}
          selectData={type}
          formError={typeError}
          defaultValue={addTicketvalues.type ? selectValue(type, addTicketvalues.type) : ''}
          emptyValue="Select Type"
          onSelectItem={(item) => {
            setTypeData(item); //type[item].id);
          }}
        />

        <WeInput
          type="text"
          onChange={onTicketNameInputChange}
          inputLabel="Ticket Name"
          value={addTicketvalues.name ?? ''}
        />

        {selectGroupList && selectGroupList.length > 1 && (
          <WeDropDown
            label="Select Group"
            openCloseDropdown={selectGroupDropdown}
            onDropDownClick={selectGroupDropdownToggle}
            btnWidth={true}
            selectData={selectGroupList}
            defaultValue={addTicketvalues.groupId ? addTicketvalues.groupId : 'no_group'}
            emptyValue="Select a Group"
            onSelectItem={setSelectGroupData}
          />
        )}

        <WeInput
          type="number"
          minVal={addTicketvalues.sold?addTicketvalues.sold:0}
          onChange={onQuantityInputChange}
          onKeyPress={(e) => blockInvalidChar(e)}
          inputLabel="Quantity"
          value={addTicketvalues.capacity ?? ''}
        />

        {addTicketvalues.type != 'free' ? (
          <>
            <WeInput
              type="number"
              minVal={0}
              prefix="$"
              onChange={onPriceInputChange}
              onKeyPress={(e) => blockInvalidChar(e)}
              inputLabel="Price"
              value={addTicketvalues.price ?? ''}
            />
            {addTicketvalues.price ? (
              <>
                <small className="d-block mt-3"> Service Fee: $ {calPrice.serviceFee}</small>{' '}
                <small className="d-block mt-1"> Buyer Price: $ {calPrice.costToBuyer}</small>
              </>
            ) : (
              ''
            )}

            <div className="mt-2">
              <WeToggle
                checkToogle={addTicketvalues.feeSubtraction}
                // id={'feesubtracted'}
                // htmlFor={'feesubtracted'}
                onChange={feeSubtractedChange}
                onSwitchClick={feeSubtractedSwitch}
                toggleLabel="Ticketing Fee subtracted from your ticket revenue"
              />
            </div>
          </>
        ) : (
          ''
        )}

        {selectTicket && !!selectTicket.length && (
          <WeDropDown
            label="Availability"
            selectData={availability}
            defaultValue={addTicketvalues.availability ?? ''}
            emptyValue="Select Availability"
            onSelectItem={(item) => {
              setAvailabilityData(item); //availability[item].id);
            }}
          />
        )}

        {addTicketvalues.availability == 'dateAndTime' || selectTicket?.length == 0 ? (
          <>
            <div className="row">
              <div className="col-6">
                <WeInput
                  type="date"
                  minVal={todayDate()}
                  maxVal={addTicketvalues?.endDate ? addTicketvalues?.endDate : ''}
                  onChange={onStartDateInputChange}
                  inputLabel="Start Date"
                  value={addTicketvalues.startDate ? addTicketvalues.startDate : todayDate()}
                />
              </div>
              <div className="col-6">
                {isFirefox ?
                  <WeTimePicker
                    type="time"
                    onChange={onStartTimeInputChange}
                    inputLabel="Start Time"
                    value={addTicketvalues.startTime ? addTicketvalues.startTime : currentTime()}
                  />
                  :
                  <WeInput
                    type="time"
                    onChange={onStartTimeInputChange}
                    inputLabel="Start Time"
                    value={addTicketvalues.startTime ? addTicketvalues.startTime : currentTime()}
                  />
                }
              </div>
            </div>
          </>
        ) : addTicketvalues.availability == 'endOfSaleOf' ? (
          <>
            <WeDropDown
              label="Select Ticket"
              openCloseDropdown={selectTicketDropdown}
              onDropDownClick={selectTicketDropdownToggle}
              btnWidth={true}
              selectData={selectTicket}
              defaultValue={addTicketvalues.endOfSaleOf ? addTicketvalues.endOfSaleOf : ''}
              emptyValue="Select a Ticket"
              onSelectItem={(item) => {
                setSelectTicketData(item); //selectTicket[item].value);
              }}
            />
          </>
        ) : (
          ''
        )}

        {addTicketvalues.availability || selectTicket?.length == 0 ? (
          <>
            <div className="row">
              <div className="col-6">
                <WeInput
                  type="date"
                  minVal={addTicketvalues?.startDate ? addTicketvalues?.startDate : todayDate()}
                  onChange={onEndDateInputChange}
                  inputLabel="End Date"
                  value={addTicketvalues.endDate ? addTicketvalues.endDate : eventFormData.endDate}
                />
              </div>
              <div className="col-6">
                {isFirefox ?
                  <WeTimePicker
                    type="time"
                    onChange={onEndTimeInputChange}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        btnSave();
                      }
                    }}
                    inputLabel="End Time"
                    value={addTicketvalues.endTime ? addTicketvalues.endTime : eventFormData.endTime}
                  />
                  :
                  <WeInput
                    type="time"
                    onChange={onEndTimeInputChange}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        btnSave();
                      }
                    }}
                    inputLabel="End Time"
                    value={addTicketvalues.endTime ? addTicketvalues.endTime : eventFormData.endTime}
                  />
                }
              </div>
            </div>
          </>
        ) : (
          ''
        )}

        {couponsData.length > 0 ?
          <>
            < MultipleSelectDropDown
              label="Coupons"
              emptyValue="Select Coupons"
              selectData={couponsData}
              onSelectItem={(item) => {
                onSpecificAttendeeSelect(item);
              }}
              selectDataLength={specificAttendeeData?.length}
              defaultSelectValue={specificAttendeeData}
            />

            <div className="pills">
              {specificAttendeeData && specificAttendeeData?.length > 0 &&
                specificAttendeeData?.map((val, index) => {
                  const suspendIndex = couponsData?.findIndex((item) => item.id === val);
                  console.log("suspendIndex == ", suspendIndex);
                  return (
                    <>
                      <div className="pil alert alert-dismissible" style={{ cursor: "pointer" }} key={index}>
                        <span className="text">{couponsData[suspendIndex].label}</span>
                      </div>
                    </>
                  );
                })}
            </div>
          </>
          : ""}

      </>
    );
  };
  const advancedForm = () => {
    return (
      <>
        <WeTextArea
          maxRows={3}
          maxLength={briefMaxLength}
          onChange={onticketDescriptionInputChange}
          inputLabel="Ticket Description"
          value={addTicketvalues.description ?? ''}
        />

        <WeDropDown
          label="Visibility"
          openCloseDropdown={visibilityDropdown}
          onDropDownClick={visibilityDropdownToggle}
          selectData={visibility}
          btnWidth={true}
          defaultValue={
            addTicketvalues.visibility ? selectValue(visibility, addTicketvalues.visibility) : ''
          }
          emptyValue="Select Visibility"
          onSelectItem={(item) => {
            visibilityData(item); //;visibility[item].id);
          }}
        />

        <div className="row">
          <div className="col-12 col-sm-6">
            <WeInput
              type="number"
              minVal={1}
              onChange={onMinTicketsInputChange}
              onKeyPress={(e) => blockInvalidChar(e)}
              inputLabel="
              Min. Tickets Per Order
              "
              value={addTicketvalues.minTicket ?? ''}
            />
          </div>
          <div className="col-12 col-sm-6">
            <WeInput
              type="number"
              minVal={1}
              onChange={onMaxTicketsInputChange}
              onKeyPress={(e) => {
                blockInvalidChar(e);
                if (e.key === 'Enter') {
                  btnSave();
                }
              }}
              inputLabel="Max. Tickets Per Order"
              value={addTicketvalues.maxTicket ?? ''}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal show={showDialog} onHide={onDialogClose} centered backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>
            <div style={{ display: 'flex' }}>
              <h1 className="fs-5"> Add Ticket </h1>
              <a
                className="close closeBtn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onDialogClose}>
                <i className="fa-solid fa-xmark"></i>
              </a>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <nav>
            <div className="nav nav-tabs ticket-modal-tab" id="nav-tab" role="tablist">
              <button className={basicBtnActive ? 'nav-link active' : 'nav-link'} onClick={basic}>
                Basic
              </button>
              <button
                className={!basicBtnActive ? 'nav-link active' : 'nav-link'}
                onClick={advanced}>
                Advanced
              </button>
            </div>

            {basicBtnActive ? <>{basicForm()}</> : <> {advancedForm()}</>}
          </nav>
        </Modal.Body>

        <Modal.Footer>
          <Button className="btn secondary-btn" onClick={onDialogClose}>
            Close
          </Button>
          <Button className="btn primary-btn" onClick={() => btnSave()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(AddTicketDialog);
