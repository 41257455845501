import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const CreateGroupDialog = ({ ...props }) => {
  const { formError, showDialog, onDialogClose, onInputChange, btnSave } = props;
  return (
    <Modal show={showDialog} onHide={onDialogClose} centered backdrop="static" keyboard={false}>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          {' '}
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5">Create Group </h1>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Enter a group name to organize your event tickets.</p>
        <div className="input-block">
          <label htmlFor="searchEvents" className="form-label">
            Enter Group Name
          </label>
          <input type="text" className="form-control" 
          autoFocus onKeyDown={(e) => {
            if (e.key === 'Enter') {
              btnSave();
            }
          }} onChange={onInputChange} />
        </div>
        {formError && <div style={{ color: 'red' }}>{formError}</div>}
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn secondary-btn" onClick={onDialogClose}>
          Close
        </Button>
        <Button className="btn primary-btn" onClick={btnSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(CreateGroupDialog);
