import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import auth from './slice/auth';
import event from './slice/event';
import tickets from './slice/tickets';
import addons from './slice/addons';
import coupon from './slice/coupon';
import onHolds from './slice/onHolds';
import options from './slice/options';
import payment from './slice/payment';
import order from './slice/order';
const rootReducer = combineReducers({
  auth,
  event,
  tickets,
  addons,
  coupon,
  onHolds,
  options,
  payment,
  order
});
const store = configureStore({
  reducer: rootReducer
});
export default store;
