// import { Outlet } from 'react-router-dom';
import React from 'react';

export default function MyOrders() {
  return (
    <>
      <div className="row">
        <div className="col-12 col-xl-6">
          <h2>My Orders</h2>
          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>
          <a href="my-orders-ticket.html" className="event-block publish">
            <div className="background-image">
              <img src="img/lion.jpg" alt="" />
              <div className="overlay"></div>
            </div>
            <div className="date">
              <div className="slab">
                <div className="day">03</div>
                <div className="month">Mar</div>
              </div>
            </div>
            <div className="content">
              <div className="organizer">
                Sensual Movement
                <span className="fa-stack fa-1x">
                  <i className="fa-solid fa-badge fa-stack-2x"></i>
                  <i className="fa-solid fa-check fa-stack-1x fa-inverse"></i>
                </span>
              </div>
              <div className="event-name">Sensual Movement 7th Anniversary</div>
              <div className="event-date">October 23, 2023</div>
              <div className="event-info">Tickets: 2</div>
            </div>
          </a>
          <a href="my-orders-ticket.html" className="event-block publish">
            <div className="background-image">
              <img src="img/lion.jpg" alt="" />
              <div className="overlay"></div>
            </div>
            <div className="date">
              <div className="slab">
                <div className="day">03</div>
                <div className="month">Mar</div>
              </div>
            </div>
            <div className="content">
              <div className="organizer">
                Sensual Movement
                <span className="fa-stack fa-1x">
                  <i className="fa-solid fa-badge fa-stack-2x"></i>
                  <i className="fa-solid fa-check fa-stack-1x fa-inverse"></i>
                </span>
              </div>
              <div className="event-name">Sensual Movement 7th Anniversary</div>
              <div className="event-date">October 23, 2023</div>
              <div className="event-info">Tickets: 2</div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
}
