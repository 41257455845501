import React from 'react';

const LoaderComponent = ({ ...props }) => {
  const { className } = props;
  return (
    <>
      <div className={className ? className : 'spinner-grow'} role="status">
        <span className="sr-only"></span>
      </div>
    </>
  );
};
export default React.memo(LoaderComponent);
