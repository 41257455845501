import WeInput from 'components/weInput';
import WeToggle from 'components/weToggle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import TicketTransferSettings from './subComponents/ticketTransferSettings';
import { allTicketList, apiTicketFatchingStart, editTicketTransferForEvent } from 'store/slice/tickets';
import PageForNoPermission from 'pages/pageForNoPermission';
import EmptyView from 'components/emptyView';
import { eventSelectedActiveMenu } from 'store/slice/event';
import SpinnerComponent from 'components/spinnerComponent';

const TicketTransfer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [transferTicketValues, setTransferTicketValues] = useState({
    eventUid: id
  });
  const { ticketData,isFetching } = useSelector((state) => state.tickets);
  const { userPermissionData, isOwnerValue } = useSelector((state) => state.event);
  const { ticketListData } = useSelector((state) => state.tickets);

  useEffect(() => {
    dispatch(apiTicketFatchingStart(true));
    fetchAllTickets();
  }, []);

  const fetchAllTickets = () => {
    const requestParam = {
      eventUid: id
    };
    dispatch(allTicketList(requestParam));
  };

  useEffect(() => {
    setTransferTicketValues((transferTicketValues) => ({
      ...transferTicketValues,
      ticketTransfer: ticketData?.ticketTransfer,
      standardTransferFee: ticketData?.standardTransferFee
    }));
  }, [ticketData]);

  const handleSubmit = () => {
    dispatch(editTicketTransferForEvent(transferTicketValues));
  };

  const handleAddTicket = () => {
    navigate(`/event/${id}/edit/tickets`);
    dispatch(eventSelectedActiveMenu('tickets'));
  };

  return isFetching ? <SpinnerComponent /> :  isOwnerValue || userPermissionData?.orders.manageOrders ? (
    <>
      <h2>Ticket Transfer Settings</h2>
      <p>
        Lorem ipsum dolor sit AMet, consectetuer adipiscing elit. Donec odio. Quisque volutpat
        mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper
        suscipit, posuere a, pede.
      </p>
      {ticketListData?.length === 0 || ticketListData == null ? (
        <>
          <EmptyView
            text="No Tickets Created"
            message="Create Tickets for Ticket Transfer Setting."
            showButton={true}
            btnText={'Add Ticket'}
            onBtnClick={handleAddTicket}
          />
        </>
      ) : (
        <>
          <div className="mt-4">
            <WeToggle
              checkToogle={transferTicketValues && transferTicketValues.ticketTransfer}
              onChange={() => {
                transferTicketValues.ticketTransfer = !transferTicketValues.ticketTransfer;
                if (!transferTicketValues.ticketTransfer) {
                  transferTicketValues.standardTransferFee = '';
                }
                handleSubmit();
              }}
              id={'feesubtracted'}
              htmlFor={'feesubtracted'}
              toggleLabel="Ticket Transfer"
            />
          </div>
          {transferTicketValues.ticketTransfer ? (
            <>
              <div className="row mb-2">
                <div className="col-12 col-lg-6">
                  <WeInput
                    type={'number'}
                    minVal={0}
                    onChange={(val) => {
                      setTransferTicketValues((transferTicketValues) => ({
                        ...transferTicketValues,
                        standardTransferFee: val
                      }));
                      // transferTicketValues.standardTransferFee = val;
                      // handleSubmit();
                    }}
                    onBlur={handleSubmit}
                    prefix={'$'}
                    inputLabel="Standard Transfer Fee"
                    value={transferTicketValues.standardTransferFee}
                  />
                </div>
              </div>
              <br />
              <br />
              <TicketTransferSettings />
            </>
          ) : (
            ''
          )}
        </>
      )}
    </>
  ) : (
    <PageForNoPermission />
  );
};

export default TicketTransfer;
