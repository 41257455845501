import Autocomplete from 'components/autocomplete';
import WeDropDown from 'components/weDropDown';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const OrderFilterDialog = ({ ...props }) => {
  const {
    showDialog,
    onDialogClose,
    onTicketNameChange,
    dateData,
    onDateChange,
    btnSave,
    isAutoComplete,
    key,
    autoFocus,
    onTicketSearch,
    clearSearch,
    searchEmail,
    ticketData,
    onSearchSelect,
    ticketListData
    // isOrderType,
    // orderTypeData,
    // onOrderTypeChange
  } = props;

  return (
    <Modal show={showDialog} onHide={onDialogClose} centered backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5"> Filters </h1>
            <a
              className="close closeBtn"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onDialogClose}>
              <i className="fa-solid fa-xmark"></i>
            </a>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* {isOrderType ? (
          <WeDropDown
            label="Order Type"
            selectData={orderTypeData}
            short={true}
            onSelectItem={(item) => {
              onOrderTypeChange(orderTypeData[item].value);
            }}
            defaultValue={''}
            emptyValue="Select Type"
          />
        ) : (
          ''
        )} */}
        {isAutoComplete ? (
          <Autocomplete
            key={key}
            inputLabel="Ticket Name"
            autoFocus={autoFocus ? 'autoFocus' : ''}
            onSearchChange={onTicketSearch}
            clearValue={clearSearch}
            value={searchEmail}
            searchTicket={ticketData}
            onItemSelected={onSearchSelect}
            placeholder={'Search Ticket'}
          />
        ) : (
          <WeDropDown
            label="Ticket Name"
            btnWidth={true}
            selectData={ticketListData}
            onSelectItem={(item) => {
              onTicketNameChange(ticketListData[item].value);
            }}
            defaultValue={''}
            emptyValue="Select Ticket"
          />
        )}
        <WeDropDown
          label="Date"
          btnWidth={true}
          selectData={dateData}
          onSelectItem={(item) => {
            onDateChange(dateData[item].id);
          }}
          defaultValue={''}
          emptyValue="Select Date"
        />
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn primary-btn" onClick={() => btnSave()}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(OrderFilterDialog);
