// import { Outlet } from 'react-router-dom';
import React from 'react';

export default function Profile() {
  return (
    <>
      <div className="row">
        <div className="col-12 col-xl-6">
          <div className="user-profile">
            <div className="account">
              <img src="img/deer.jpg" alt="" />
              <h4>Wade Warren</h4>
              <div className="handle">@wadewarren</div>
              <div className="bio">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque
                volutpat mattis eros. Nullam malesuada erat ut turpis.
              </div>
            </div>
            <a href="#" className="user-tickets">
              Tickets
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
