import EditAlltransferDialog from 'components/dialogs/editAlltransferDialog';
import TransferSetingDialog from 'components/dialogs/transferSetingDialog';
import WeDropDown from 'components/weDropDown';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { editTicketTransferForAllTicket } from 'store/slice/tickets';
import { allTicketList } from 'store/slice/tickets';

const TicketTransferSettings = () => {
  const { id } = useParams();
  const [editValue, setEditValue] = useState({ eventUid: id });
  const [formError, setFormError] = useState();
  const dispatch = useDispatch();
  const { ticketListData } = useSelector((state) => state.tickets);
  const [showQuesionDialog, setShowQuesionDialog] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState();

  const editData = [
    {
      label: 'Allow Transfer',
      id: '1'
    },
    {
      label: 'Fee',
      id: '2'
    }
  ];

  useEffect(() => {
    fetchAllTickets();
  }, []);

  const fetchAllTickets = () => {
    const requestParam = {
      eventUid: id
    };
    dispatch(allTicketList(requestParam));
  };

  const editAllTicket = () => {
    if (editValue.editAllType == 2 && !editValue.transferFee) {
      setFormError((formError) => ({
        ...formError,
        transferFee: 'Please enter transfer fee'
      }));
      return;
    }
    if (editValue.editAllType == 1 && editValue.allowTransfer == undefined) {
      editValue.allowTransfer = false;
    }
    setEditValue({ eventUid: id });
    setShowConfirmDialog((showConfirmDialog) => ({
      ...showConfirmDialog,
      show: false
    }));
    dispatch(editTicketTransferForAllTicket(editValue));
  };

  return (
    <>
      <h3>Custom Transfer Settings</h3>
      <p className="mb-1">
        Lorem ipsum dolor sit AMet, consectetuer adipiscing elit. Donec odio. Quisque volutpat
        mattis eros.
      </p>
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-4">
          <WeDropDown
            label="Edit All"
            selectData={editData}
            defaultValue={editValue.editAllType ? editValue.editAllType : ''}
            emptyValue="Select Transfer Type"
            onSelectItem={(item) => {
              setEditValue((editValue) => ({
                ...editValue,
                editAllType: item//editData[item].id
              }));
              setShowConfirmDialog((showConfirmDialog) => ({
                ...showConfirmDialog,
                show: true
              }));
            }}
          />
        </div>
      </div>
      <div className="ticket-table mt-3">
        <div className="row heading text-left">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-lg-1"></div>
              <div className="col-12 col-lg-11 d-none d-lg-block">
                <div className="row">
                  <div className="col-12 col-lg-4">Ticket Type</div>
                  <div className="col-12 col-lg-8">
                    <div className="row">
                      <div className="col-12 col-lg-4">Allow</div>
                      {/* <div className="col-12 col-lg-4">Cost Diff.</div> */}
                      <div className="col-12 col-lg-4">Fee</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {ticketListData?.map((item) => {
          return (
            <>
              <div className="row event-row">
                <div className="col-12 mt-1">
                  <div className="row">
                    <div className="col-12 col-lg-1">
                      <div className="d-flex">
                        <span className="heading align-self-center me-lg-0 me-4">
                          <a
                            href="#"
                            onClick={() =>
                              setShowQuesionDialog((showQuesionDialog) => ({
                                ...showQuesionDialog,
                                show: true,
                                item: item
                              }))
                            }>
                            <i className="fa-solid fa-pen-to-square"></i>
                          </a>
                        </span>
                        <div className="mobile-event-block d-block d-lg-none w-100">
                          <div className="info mb-2">
                            <div>
                              <strong>{item.name}</strong>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="info">
                              <span className="heading d-block">Allow:</span>
                              <span>{item.allowTransfer ? 'Yes' : 'No'}</span>
                            </div>
                            {/* <div className="info">
                              <span className="heading d-block">Cost Difference:</span>
                              <span>{item.costDiffrence ? 'Yes' : 'No'}</span>
                            </div> */}
                            <div className="info">
                              <span className="heading d-block">Fee:</span>
                              <span>${item.transferFee}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-11 align-self-center d-none d-lg-block">
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <div className="ticket-type-name">{item.name}</div>
                        </div>
                        <div className="col-12 col-lg-8">
                          <div className="row">
                            <div className="col-12 col-lg-4">
                              {item.allowTransfer ? 'Yes' : 'No'}
                            </div>
                            {/* <div className="col-12 col-lg-4">
                              {item.transferFee > 0 ? 'Yes' : 'No'}
                            </div> */}
                            <div className="col-12 col-lg-4">${item.transferFee}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>

      <TransferSetingDialog
        showDialog={showQuesionDialog?.show}
        onDialogClose={() => {
          setShowQuesionDialog((showQuesionDialog) => ({
            ...showQuesionDialog,
            show: false
          }));
        }}
        customData={showQuesionDialog?.item}
      />

      <EditAlltransferDialog
        showDialog={showConfirmDialog?.show}
        onDialogClose={() => {
          setShowConfirmDialog((showConfirmDialog) => ({
            ...showConfirmDialog,
            show: false
          }));
          setEditValue({ eventUid: id });
        }}
        editValue={editValue}
        formError={formError}
        allowTransferChange={(checked) => {
          setEditValue((editValue) => ({
            ...editValue,
            allowTransfer: checked
          }));
        }}
        allowTransferToggle={() => {
          setEditValue((editValue) => ({
            ...editValue,
            allowTransfer: !editValue.allowTransfer
          }));
        }}
        onStandardFee={(val) => {
          if (val) {
            setEditValue((editValue) => ({
              ...editValue,
              transferFee: val
            }));
            setFormError((formError) => ({
              ...formError,
              transferFee: ''
            }));
          }
        }}
        handeleSubmit={editAllTicket}
      />
    </>
  );
};
export default React.memo(TicketTransferSettings);
