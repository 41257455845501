import WeInput from 'components/weInput';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const CreateAddonDialog = ({ ...props }) => {
  const {
    showDialog,
    onDialogClose,
    onNameInputChange,
    onCapacityInputChange,
    onPriceInputChange,
    addOnsNameError,
    capacityError,
    priceError,
    btnSave,
    addOnName,
    addOnCapacity,
    addOnPrice
  } = props;

  const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  return (
    <Modal show={showDialog} onHide={onDialogClose} centered backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5"> Add Add-Ons </h1>
            <a
              className="close closeBtn"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onDialogClose}>
              <i className="fa-solid fa-xmark"></i>
            </a>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Customize your event by adding and managing extras within your event.</p>

        <WeInput
          onChange={onNameInputChange}
          inputLabel="Enter Name"
          autoFocus={true}
          formError={addOnsNameError}
          value={addOnName ?? ''}
        />

        <WeInput
          type="number"
          minVal={0}
          onChange={onCapacityInputChange}
          onKeyPress={(e) => blockInvalidChar(e)}
          inputLabel="Capacity"
          formError={capacityError}
          value={addOnCapacity ?? ''}
        />
        <WeInput
          type="number"
          minVal={0}
          onChange={onPriceInputChange}
          // onKeyPress={(e) => blockInvalidChar(e)}
          prefix="$"
          inputLabel="Price"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              btnSave();
            }
          }}
          formError={priceError}
          value={addOnPrice ?? ''}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn secondary-btn" onClick={onDialogClose}>
          Close
        </Button>
        <Button className="btn primary-btn" onClick={() => btnSave()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(CreateAddonDialog);
