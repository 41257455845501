import { Outlet, useParams } from 'react-router-dom';
import Drawer from './drawer';

export default function DashboardLayout() {
  const { id } = useParams();
  const path = window.location.pathname;
  const isEventComponent = path.includes('event');
  const isdashboardComponent = path.includes('dashboard');

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-3 col-xl-2 pt-lg-3 admin-left-column">
            <Drawer />
          </div>
          <div
            className={
              isEventComponent && id
                ? 'col-12 col-lg-9 col-xl-9'
                : 'col-12 col-lg-9 col-xl-8 board'
            }>
            <div
              className={isdashboardComponent || isEventComponent || id ? '' : 'small-container'}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
