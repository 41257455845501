// import WeDropDown from 'components/weDropDown';
import MultipleSelectDropDown from 'components/multipleSelectDropDown';
import WeDropDown from 'components/weDropDown';
import WeInput from 'components/weInput';
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { selectAllAttendee, updateSpecificAttendee } from 'store/slice/event';

let selectGroupList = [];
const CreateCouponDialog = ({ ...props }) => {
  const dispatch = useDispatch();
  const {
    showDialog,
    onDialogClose,
    onNameInputChange,
    couponNameError,
    btnSave,
    onTypeInputChange,
    couponTypeError,
    onDiscountInputChange,
    couponDiscountError,
    onCapacityInputChange,
    couponCapacityError,
    couponName,
    couponType,
    couponDiscount,
    couponCapacity,
    buttonLabel,
    status,
    setEventFormData,
    statusError,
    selectTicket,
    specificAttendeeData,
    // setSelectTicketData,
    couponTicketError,
    // ticketData,
    type
  } = props;
  const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  const validCoupon = (e) => [' '].includes(e.key) && e.preventDefault();

  const onSpecificAttendeeSelect = (item) => {
    dispatch(updateSpecificAttendee(item.id));
  };

  const onAllSpecificAttendeeSelect = (item) => {
    dispatch(updateSpecificAttendee(item.id));
    selectGroupList &&
      selectGroupList?.map((item) => {
        const data = {
          id: item.id,
          checkStatus: specificAttendeeData.includes('all') ? true : false
        };
        dispatch(selectAllAttendee(data));
      });
  };

  useEffect(() => {
    if (selectTicket?.length > 0) {
      const data = [
        {
          label: 'All',
          id: 'all'
        }
      ];
      selectGroupList = [...data, ...selectTicket];
    }
  }, [selectTicket]);

  return (
    <Modal show={showDialog} centered backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5"> Add Coupon </h1>
            <a
              className="close closeBtn"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onDialogClose}>
              <i className="fa-solid fa-xmark"></i>
            </a>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet, consectetuer</p>
        <WeInput
          onChange={onNameInputChange}
          maxLength={12}
          autoFocus={true}
          onKeyPress={(e) => validCoupon(e)}
          inputLabel="Coupon Code Name"
          formError={couponNameError}
          value={couponName ?? ''}
        />

        <div className="row">
          <div className="col-6">
            <WeDropDown
              label="Type"
              selectData={type}
              defaultValue={couponType}
              emptyValue="Select type"
              onSelectItem={(item) => {
                onTypeInputChange(item); //type[item].id);
              }}
              formError={couponTypeError}
            />
          </div>
          <div className="col-6">
            <WeInput
              type="number"
              minVal={0}
              onChange={onDiscountInputChange}
              onKeyPress={(e) => blockInvalidChar(e)}
              inputLabel="Discount"
              formError={couponDiscountError}
              value={couponDiscount ?? ''}
            />
          </div>
        </div>

        <WeInput
          type="number"
          minVal={0}
          onChange={onCapacityInputChange}
          onKeyPress={(e) => blockInvalidChar(e)}
          inputLabel="Capacity"
          formError={couponCapacityError}
          value={couponCapacity ?? ''}
        />

        <MultipleSelectDropDown
          label="Tickets"
          emptyValue="Select Tickets"
          selectData={selectGroupList}
          onSelectItem={(item) => {
            if (item.id == 'all') {
              onAllSpecificAttendeeSelect(item);
            } else {
              onSpecificAttendeeSelect(item);
            }
          }}
          selectDataLength={
            specificAttendeeData.includes('all')
              ? specificAttendeeData.length - 1
              : specificAttendeeData.length
          }
          defaultSelectValue={specificAttendeeData}
          formError={couponTicketError}
        />

        <WeDropDown
          label="Status"
          selectData={status}
          defaultValue={buttonLabel ? buttonLabel : ''}
          emptyValue="Select Status"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              btnSave();
            }
          }}
          onSelectItem={setEventFormData}
        />
        {statusError && <div style={{ color: 'red' }}>{statusError}</div>}
      </Modal.Body>
      <Modal.Footer>
        {/* <a className="btn secondary-btn" onClick={onDialogClose}>
          Close
        </a>
        <a className="btn primary-btn" onClick={() => btnSave()}>
          Save
        </a> */}
        <Button className="btn secondary-btn" onClick={onDialogClose}>
          Close
        </Button>
        <Button className="btn primary-btn" onClick={() => btnSave()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(CreateCouponDialog);
