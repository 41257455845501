import WeButton from 'components/button';
import './style.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { checkInvite, updateTeamInviteStatus } from 'store/slice/event';
import SpinnerComponent from 'components/spinnerComponent';
import LoaderComponent from 'components/loaderComponent';
const InviteAccept = () => {
  const { id } = useParams();
  const navigate = useNavigate('');
  const dispatch = useDispatch();
  const { checkInviteData, isApiLoading, inviteError } = useSelector((state) => state.event);
  const [status, setstatus] = useState();

  useEffect(() => {
    if (id) {
      dispatch(
        checkInvite({
          inviteUid: id
        })
      );
    }
  }, [id]);

  useEffect(() => {
    if (inviteError) {
      const timer = setTimeout(() => {
        navigate('/');
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [inviteError]);

  useEffect(() => {
    if (status) {
      updateStatus();
    }
  }, [status]);

  const updateStatus = () => {
    const data = {
      inviteUid: id,
      status: status
    };
    dispatch(updateTeamInviteStatus(data, navigate));
  };

  return isApiLoading ? (
    <SpinnerComponent />
  ) : (
    <>
      <div className="col-12 col-lg-12 col-xl-12 board">
        <div className="position-absolute top-50 start-50 translate-middle">
          {checkInviteData ? (
            <>
              <div style={{ fontSize: '2rem', textAlign: 'center' }}>
                {checkInviteData?.userName} has invited you to join {checkInviteData?.eventTitle} as
                a {checkInviteData?.roleName}.
              </div>
              <div className="row mt-5">
                <div className="col-6" style={{ textAlign: 'end' }}>
                  <WeButton classes={'btnAccept'} text="Accept" onClick={() => setstatus(1)} />
                </div>
                <div className="col-6" style={{ textAlign: 'start' }}>
                  <WeButton classes={'btnReject'} text="Reject" onClick={() => setstatus(2)} />
                </div>
              </div>
              <div className="col-12" style={{ textAlign: 'center' }}>
                <WeButton
                  text="Back to dashboard"
                  classes={'btn primary-btn btnBack mt-5 ml-auto'}
                  onClick={() => navigate(`/`)}
                />
              </div>
            </>
          ) : (
            <>
              <div style={{ fontSize: '2rem', textAlign: 'center' }}>
                {inviteError == 'differentEmail' ? (
                  <>You have no invites.</>
                ) : (
                  <>Link has been expired</>
                )}
              </div>
              <div className="row mt-5 ">
                <div
                  className="col-1"
                  style={{ fontSize: '1rem', textAlign: 'center', color: 'var(--bs-lt-red)' }}>
                  <LoaderComponent className={'spinner-grow spinner-grow-sm'} />
                </div>
                <div
                  className="col-11"
                  style={{ fontSize: '1rem', textAlign: 'center', color: 'var(--bs-lt-red)' }}>
                  You are redirecting to the home page.
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InviteAccept;
