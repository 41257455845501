import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  apiFatchingStart,
  apiLoadingStart,
  clearEventTitle,
  clearOldEventData,
  listEvent,
  userDashboard
} from '../../../store/slice/event';
import WeButton from '../../../components/button';
import { useNavigate } from 'react-router-dom';
import { eventTypes, timezoneList } from 'store/slice/event';
import EventListHeader from './subComponents/eventListHeader';
import EventListItem from './subComponents/eventListItem';
import { getUserName, todayDate } from 'utils/appConstant';
import { eventDelete, eventSelectedActiveMenu } from 'store/slice/event';
import ConfirmDialog from 'components/dialogs/confirmDialog';
import { EventPageType } from 'utils/appEnums';
import { toast } from 'react-toastify';
import { clearCheckOutFormData, clearOptionsStates } from 'store/slice/options';
import DuplicateDialog from 'components/dialogs/duplicateDialog';
import { clearticketStates } from 'store/slice/tickets';
import { clearAddonStates } from 'store/slice/addons';
import { clearCouponStates } from 'store/slice/coupon';
import { clearOnHoldStates } from 'store/slice/onHolds';
import PaginatioonComponent from '../../../components/paginatioonComponent';

const EventDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDashboardData } = useSelector((state) => state.event);
  const path = window.location.origin;
  const [searchInput, setSearchInput] = useState('');
  const [isactiveTwo, setActiveTwo] = useState(false);
  const [selectedEventType, setSelectedEventType] = useState('');
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [currentActiveEventData, setActiveEventData] = useState(null);
  const { eventListData, totalData, isLoading } = useSelector((state) => state.event);
  const [openGroupMenuId, setGroupMenuId] = useState(-1);

  let page = 1;
  let limit = 5;
  // const [isFetching, setIsFetching] = useState(false);
  const [pageNo, setPageNo] = useState(page);
  let searchData = '';

  useEffect(() => {
    if (eventListData) {
      // setIsFetching(false);
    }
  }, [eventListData]);

  const handlePagination = (data) => {
    dispatch(apiFatchingStart());
    // const page = flag == 'next' ? pageNo + 1 : pageNo - 1;
    console.log('onPageChange', data.selected + 1);
    setPageNo(data.selected + 1);
  };

  useEffect(() => {
    listEventData();
  }, [pageNo]);

  useEffect(() => {
    listEventData();
    handleClearEvent();
    dispatch(timezoneList());
    dispatch(eventTypes());
    dispatch(userDashboard());
    dispatch(apiLoadingStart());
  }, []);

  /***This API called event listing */
  const listEventData = () => {
    let requestParams = {
      search: searchInput,
      eventType: selectedEventType,
      latitude: 0,
      longitude: 0,
      startDate: '',
      endDate: ''
    };
    dispatch(listEvent(pageNo, limit, requestParams));
  };
  // useEffect(() => {
  //   var startDate = Date.parse(userDashboardData?.nextEvent);
  //   var endDate = Date.parse(todayDate());
  //   var timeDiff = Math.abs(startDate - endDate);
  //   var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  // }, [userDashboardData]);

  const diffDays = () => {
    var startDate = Date.parse(userDashboardData?.nextEvent);
    var endDate = Date.parse(todayDate());
    var timeDiff = Math.abs(startDate - endDate);
    var result = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return result ? 'Your next event is in ' + result + ' days.' : 'No next event.';
  };

  const btnCreateEvent = () => {
    //clear old event data
    dispatch(clearOldEventData());
    navigate('/event/create');
  };
  const btnClearFilter = () => {
    setSelectedEventType('');
    setSearchInput('');
    page = 1;
    setPageNo(page);
    let requestParams = {
      search: '',
      eventType: '',
      latitude: 0,
      longitude: 0,
      startDate: '',
      endDate: ''
    };
    dispatch(listEvent(page, limit, requestParams));
  };
  const handleSearch = (e) => {
    searchData = e.target.value;
    setSearchInput(searchData);
    page = 1;
    setPageNo(page);
    let requestParams = {
      search: searchData,
      eventType: selectedEventType,
      latitude: 0,
      longitude: 0,
      startDate: '',
      endDate: ''
    };
    dispatch(listEvent(page, limit, requestParams));
  };
  // const handleFilter = (eventType) => {
  //   page = 1;
  //   setPageNo(page);
  //   let requestParams = {
  //     search: searchInput,
  //     eventType: eventType,
  //     latitude: 0,
  //     longitude: 0,
  //     startDate: '',
  //     endDate: ''
  //   };
  //   dispatch(listEvent(page, limit, requestParams));
  // };

  const handleClearEvent = () => {
    dispatch(clearEventTitle());
    dispatch(clearticketStates());
    dispatch(clearAddonStates());
    dispatch(clearCouponStates());
    dispatch(clearOnHoldStates());
    dispatch(clearOptionsStates());
  };

  const handleConfirmDelete = () => {
    dispatch(eventDelete(currentActiveEventData.id));
    setShowConfirmDeleteDialog(false);
  };

  const handleUpdate = (data) => {
    if (data) {
      const menuItem = {
        title: 'Event Info',
        icon: 'fa-solid fa-circle-info fa-stack-1x fa-inverse',
        path: 'eventInfo',
        id: 0,
        type: EventPageType.EVENT
      };
      dispatch(eventSelectedActiveMenu(menuItem));
      navigate(`/event/${data.eventUid}/edit/basicInfo`);
    }
  };

  // const handleConfirmDuplicate = () => {
  //   let requestParams = {
  //     eventUid: currentActiveEventData.eventUid
  //   };
  //   dispatch(duplicateEvent(requestParams));
  //   setDuplicateDialog(false);
  // };

  // const eventDuplicate = (itemData) => {
  //   const data = {
  //     eventUid: itemData.eventUid
  //   };
  //   dispatch(duplicateEvent(data, navigate));
  // };

  return isLoading ? (
    <div
      className="position-absolute top-50 start-50 translate-middle"
      style={{ color: 'var(--bs-lt-red)' }}>
      <div className="spinner-border" role="status">
        <span className="sr-only"></span>
      </div>
    </div>
  ) : (
    <>
      <h1>Hey, {getUserName()}</h1>
      <h3> {diffDays()}</h3>
      <br />

      <WeButton text="Create Event" onClick={btnCreateEvent} />

      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="input-block">
            <label htmlFor="searchEvents" className="form-label">
              Search
            </label>
            <input
              type="text"
              className="form-control"
              value={searchInput}
              onChange={handleSearch}
            />
          </div>
        </div>

        {/* <div className="col-12 col-lg-4">
          <WeDropDown
            innerRef={(el) => (summaryRef.current['eventTypeRef'] = el)}
            label="Filter Events"
            isRequired
            openCloseDropdown={showEventType}
            onDropDownClick={() => {
              setShowEventType(!showEventType);
            }}
            selectData={
              eventTypeData &&
              eventTypeData.map((item) => ({
                label: item.eventType,
                value: item._id
              }))
            }
            defaultValue={selectedEventType ? selectedEventType : ''}
            emptyValue=" Make a selection"
            onSelectItem={(item) => {
              handleFilter(item); //eventTypeData[item].eventType);
              setSelectedEventType(item); //eventTypeData[item].eventType);
              setShowEventType(false);
            }}
          />
        </div> */}
        <div className="col-12 col-lg-4" style={{ marginTop: '1rem' }}>
          <WeButton text="Clear Filter" onClick={btnClearFilter} />
        </div>
      </div>

      <div className="ticket-table mt-3">
        <EventListHeader />
        <div className='eventlistheight'>
          {eventListData.length > 0 ? (
            eventListData?.map((item, key) => {
              return (
                <EventListItem
                  itemData={item}
                  key={key}
                  isactiveTwo={isactiveTwo}
                  setActiveTwo={setActiveTwo}
                  onEditMenu={(itemData) => {
                    setActiveEventData(itemData);
                    handleUpdate(itemData);
                    dispatch(clearCheckOutFormData());
                  }}
                  onCopyEvent={(itemData) => {
                    navigator.clipboard.writeText(`${path}/${itemData?.eventUrl}`);
                    toast.success('Event URL Copied');
                    setGroupMenuId(-1);
                  }}
                  onDuplicateEvent={(itemData) => {
                    setShowDuplicateDialog((showDuplicateDialog) => ({
                      ...showDuplicateDialog,
                      show: true,
                      data: itemData
                    }));
                    // eventDuplicate(itemData);
                    setGroupMenuId(-1);
                  }}
                  onDeleteEvent={(itemData) => {
                    setActiveEventData(itemData);
                    setShowConfirmDeleteDialog(true);
                    setGroupMenuId(-1);
                  }}
                  onEventClick={(itemData) => {
                    localStorage.setItem('calSelectedDate', itemData.startDate);
                    setActiveEventData(itemData);
                    handleUpdate(itemData);
                    dispatch(clearCheckOutFormData());
                  }}
                  openGroupMenuId={openGroupMenuId == item?.id}
                  handlemenuOpen={() => {
                    if (openGroupMenuId === item?.id) {
                      setGroupMenuId(-1);
                    } else {
                      setGroupMenuId(item?.id);
                    }
                  }}
                />
              );
            })
          ) : !isLoading ? (
            <p className="text-center pt-5"> No Data Found</p>
          ) : (
            ''
          )}
        </div>

        {eventListData && totalData >limit ? (
          <div className='mt-3' style={{ float: 'right' }}>
            {/* {isFetching ? (
              <div className="text-center" style={{ color: 'var(--bs-lt-red)' }}>
                <div className="spinner-grow" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <a onClick={() => (pageNo > 1 ? handlePagination('pre') : '')}>
                  <i
                    className={
                      pageNo > 1
                        ? 'fa-solid fa-circle-chevron-left fa-2xl paginationBtn'
                        : 'fa-solid fa-circle-chevron-left fa-2xl paginationDisableBtn'
                    }></i>
                </a>
                <a
                  className="px-1"
                  onClick={() => {
                    if ((totalData % limit === 0 ?  Math.floor(totalData / limit): Math.floor(totalData / limit) +1) > pageNo) {
                      handlePagination('next');
                    }
                  }}>
                  <i
                    className={
                      ((totalData % limit === 0 ?  Math.floor(totalData / limit): Math.floor(totalData / limit) +1) > pageNo)
                        ? 'fa-solid fa-circle-chevron-right fa-2xl paginationBtn'
                        : 'fa-solid fa-circle-chevron-right fa-2xl paginationDisableBtn'
                    }></i>
                </a>
              </>
            )} */}
             <PaginatioonComponent
              pageCount={totalData % limit === 0?Math.floor(totalData / limit):Math.floor(totalData / limit) + 1}
              onPageChange={handlePagination}
              pageNo={pageNo}
            />
          </div>
        ) : (
          ''
        )}

        <ConfirmDialog
        title="Delete Event"
          message={'Are you sure, you want to delete this event?'}
          showDialog={showConfirmDeleteDialog}
          onDialogClose={() => {
            setShowConfirmDeleteDialog(false);
          }}
          btnConfirm={handleConfirmDelete}
        />

        <DuplicateDialog
          showDialog={showDuplicateDialog.show}
          onDialogClose={() => {
            setShowDuplicateDialog((showDuplicateDialog) => ({
              ...showDuplicateDialog,
              show: false,
              data: {}
            }));
          }}
          data={showDuplicateDialog.data}
        />
      </div>
    </>
  );
};

export default EventDashboard;
