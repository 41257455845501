import React from 'react';

export default function Finance() {
  const data = [
    {
      title: 'Past Payouts',
      message: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
      routeUrl: '/finance/pastPayouts'
    },
    {
      title: 'Future Payouts',
      message: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
      routeUrl: '/finance/futurePayout'
    },
    {
      title: 'Banking',
      message: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
      routeUrl: '/finance/banking'
    },
    {
      title: 'Tax Info',
      message: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
      routeUrl: '/finance/taxInfo'
    },
    {
      title: 'Payout Schedule',
      message: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
      routeUrl: '/finance/payoutSchedule'
    }
  ];

  return (
    <>
      <h2>Finance</h2>

      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="">Finance</a>
            </li>
          </ol>
        </nav>
      </div>

      {data.map((item, index) => {
        return (
          <>
            <a
              href={item.routeUrl}
              className={index == 0 ? 'block d-flex white mt-0' : 'block d-flex white'}>
              <div className="content flex-fill">
                <h5>{item.title}</h5>
                <p>{item.message}</p>
              </div>
              <div className="arrow d-flex align-items-center">
                <i className="fa-solid fa-angle-right"></i>
              </div>
            </a>
          </>
        );
      })}
    </>
  );
}
