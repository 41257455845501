import Autocomplete from 'components/autocomplete';
import WeDropDown from 'components/weDropDown';
import WeInput from 'components/weInput';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addGuest, editGuest } from 'store/slice/options';
import { allTicketList, clearAttendeeEmailList, searchAttendee } from 'store/slice/tickets';

const AddGuestDialog = ({ ...props }) => {
  const { showDialog, onDialogClose, data, key } = props;
  const [isNewAttendee, setIsNewAttendee] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [autoFocus, setautoFocus] = useState(true);
  const [searchEmail, setSearchEmail] = useState('');
  const { EmailData, ticketListData } = useSelector((state) => state.tickets);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [numberOfGuests, setNumberOfGuests] = useState('');
  const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  const [formError, setFormError] = useState({});

  useEffect(() => {
    fetchAllTickets();
  }, []);

  useEffect(() => {
    if (data) {
      // const findTicketId = selectedGroup(ticketListData, data?.ticketName);
      setFirstName(data?.firstName);
      setLastName(data?.lastName);
      setSearchEmail(data?.email);
      setNumberOfGuests(data?.numberOfGuests);
      setTicketId(
        data?.ticketName ? selectedGroup(ticketListData, data?.ticketName) : data?.ticketId
      );
    }
  }, [data]);

  const fetchAllTickets = () => {
    const requestParam = {
      eventUid: id
    };
    dispatch(allTicketList(requestParam));
  };

  const handleSearch = (val) => {
    setFormError((formError) => ({
      ...formError,
      email: ''
    }));
    setSearchEmail(val);
    setautoFocus(true);
    if (val.length > 1) {
      const data = {
        searchKey: val
      };
      dispatch(searchAttendee(data));
    }
  };

  const dialogClose = () => {
    onDialogClose();
    setSearchEmail('');
    setFirstName('');
    setLastName('');
    setNumberOfGuests('');
    setTicketId('');
    setFormError({});
  };

  const selectedGroup = (data, value) => {
    const valueIndex = data?.findIndex((item) => item.name === value);
    const valueAtIndex = data[valueIndex]._id;
    return valueAtIndex;
  };

  const handeleSubmit = () => {
    const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
    if (!firstName) {
      setIsNewAttendee(true);
      setFormError((formError) => ({
        ...formError,
        firstname: 'Is it new attendee? please Enter Your First Name'
      }));
      return;
    }
    if (!lastName) {
      setIsNewAttendee(true);
      setFormError((formError) => ({
        ...formError,
        lastname: 'Is it new attendee? Please Enter Your last Name'
      }));
      return;
    }
    if (!searchEmail) {
      setFormError((formError) => ({
        ...formError,
        email: 'Please Enter Your Email Address'
      }));
      return toast.error('Please Enter Your Email Name');
    } else if (!searchEmail.includes('@')) {
      setFormError((formError) => ({
        ...formError,
        email: 'Please enter a valid email address'
      }));
      return;
    } else if (!searchEmail.includes('.')) {
      setFormError((formError) => ({
        ...formError,
        email: 'Please enter a valid email address'
      }));
      return;
    } else if (!validEmail.test(searchEmail)) {
      setFormError((formError) => ({
        ...formError,
        email: 'Please enter a valid email address'
      }));
      return;
    }
    if (!numberOfGuests) {
      setFormError((formError) => ({
        ...formError,
        numberOfGuests: 'Please add number of guests'
      }));
      return;
    }
    if (!ticketId) {
      setFormError((formError) => ({
        ...formError,
        ticketId: 'Please select ticket'
      }));
      return;
    }
    let attendeeData = {
      guestId: data?._id,
      firstName: firstName,
      lastName: lastName,
      userEmail: searchEmail,
      ticketId: ticketId,
      eventUid: id,
      numberOfGuests: Number(numberOfGuests)
    };
    if (data) {
      dispatch(editGuest(attendeeData));
    } else {
      dispatch(addGuest(attendeeData));
    }
    dialogClose();
  };

  return (
    <Modal
      show={showDialog}
      onHide={() => {
        setFormError((formError) => ({
          ...formError,
          email: ''
        }));
        dialogClose();
      }}
      centered
      backdrop="static"
      keyboard={false}>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          {' '}
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5">Add Guest</h1>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isNewAttendee ? (
          <>
            <a
              onClick={() => {
                setIsNewAttendee(false);
              }}
              className="mt-3 d-block">
              Existing Attendee?
            </a>

            <div className="row">
              <div className="col-12 col-md-6">
                <WeInput
                  type="text"
                  name="firstName"
                  onChange={(val) => {
                    if (val) {
                      setFormError((formError) => ({
                        ...formError,
                        firstname: ''
                      }));
                      setFirstName(val);
                    } else {
                      setFirstName('');
                    }
                  }}
                  inputLabel="First Name"
                  value={firstName}
                  formError={formError.firstname}
                />
              </div>
              <div className="col-12 col-md-6">
                <WeInput
                  type="text"
                  name="lastName"
                  onChange={(val) => {
                    if (val) {
                      setFormError((formError) => ({
                        ...formError,
                        lastname: ''
                      }));
                      setLastName(val);
                    } else {
                      setLastName('');
                    }
                  }}
                  inputLabel="Last Name"
                  formError={formError.lastname}
                  value={lastName}
                />
              </div>
            </div>
          </>
        ) : (
          ''
        )}
        <Autocomplete
          key={key}
          inputLabel="UserName/Email Address"
          autoFocus={autoFocus ? 'autoFocus' : ''}
          onSearchChange={handleSearch}
          clearValue={() => {
            setSearchEmail('');
            dispatch(clearAttendeeEmailList({ data: null }));
          }}
          value={searchEmail}
          searchEmail={searchEmail ? EmailData : ''}
          onItemSelected={(item) => {
            setSearchEmail(item.email);
            dispatch(clearAttendeeEmailList({ data: null }));
            setFirstName(item.firstName);
            setLastName(item.lastName);
            setFormError((formError) => ({
              ...formError,
              email: ''
            }));
          }}
          formError={formError.email}
        />

        <div className="d-flex flex-row justify-content-between">
          {isNewAttendee ? (
            ''
          ) : (
            <a
              onClick={() => {
                setIsNewAttendee(true);
              }}
              className="mt-3 d-block">
              New Attendee?
            </a>
          )}
        </div>

        <div className="mt-2">
          <WeInput
            type="number"
            minVal={0}
            onChange={(val) => {
              if (val) {
                setFormError((formError) => ({
                  ...formError,
                  numberOfGuests: ''
                }));
              }
              setNumberOfGuests(val);
            }}
            onKeyPress={(e) => blockInvalidChar(e)}
            inputLabel="Number of guests"
            value={numberOfGuests ?? ''}
            formError={formError.numberOfGuests}
          />
        </div>

        <div className="mt-2">
          <WeDropDown
            label="Ticket"
            selectData={
              ticketListData &&
              ticketListData?.map((item) => ({
                label: `${item.name}`,
                id: item.id
              }))
            }
            onSelectItem={(val) => {
              if (val) {
                setFormError((formError) => ({
                  ...formError,
                  ticketId: ''
                }));
              }
              setTicketId(val);
            }}
            value={ticketId}
            formError={formError.ticketId}
            emptyValue="Select Ticket"
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="btn secondary-btn"
          onClick={() => {
            setFormError((formError) => ({
              ...formError,
              email: ''
            }));
            dialogClose();
          }}>
          Close
        </Button>
        <Button className="btn primary-btn" onClick={handeleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(AddGuestDialog);
