import WeButton from 'components/button';
import AddPayout from 'components/dialogs/addPayout';
import ConfirmDialog from 'components/dialogs/confirmDialog';
import WeToggle from 'components/weToggle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  deleteStripeBankAccount,
  getStripeBankAccount,
  setDefaultBankAccount
} from 'store/slice/payment';

export default function Banking() {
  const dispatch = useDispatch();
  const { stripeBankAccountList } = useSelector((state) => state.payment);
  const [showAddPayoutDialog, setshowAddPayoutDialog] = useState(false);
  const [defaultToggle, setDefaultToggle] = useState();
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState({});

  useEffect(() => {
    dispatch(getStripeBankAccount());
  }, []);

  useEffect(() => {
    if (stripeBankAccountList) {
      handleDefaultSet();
    }
  }, [stripeBankAccountList]);

  const handleDefaultSet = () => {
    for (let i = 0; i < stripeBankAccountList?.length; i++) {
      if (stripeBankAccountList[i].default_for_currency) {
        setDefaultToggle(i);
      }
    }
  };

  const handleDelete = (id) => {
    let requestParam = {
      bankAccountId: id
    };
    dispatch(deleteStripeBankAccount(requestParam, id));
    setShowConfirmDeleteDialog(false);
  };

  const handleToggle = (id) => {
    if (id) {
      let requestParam = {
        bankAccountId: id
      };
      dispatch(setDefaultBankAccount(requestParam));
    } else {
      toast.error('You have to select at least one default bank account');
    }
  };

  return (
    <>
      <h2>Banking</h2>

      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/finance">Finance</a>
            </li>
            <li className="breadcrumb-item">Banking</li>
          </ol>
        </nav>
      </div>

      <WeButton
        className="mt-4"
        text="Add Payout Method"
        onClick={() => setshowAddPayoutDialog(true)}
      />
      {/* <div className="row">
        <div className="col-12 col-xl-6"> */}
      <div className="ticket-table">
        <div className="row heading d-block">
          <div className="col-12">
            <div className="row">
              <div className="col-8">Account</div>
              <div className="col-2">Default</div>
              <div className="col-2">Delete</div>
            </div>
          </div>
        </div>

        {stripeBankAccountList ? (
          stripeBankAccountList?.map((item, index) => {
            return (
              <>
                <div className="row event-row">
                  <div className="col-12 mt-1">
                    <div className="row">
                      <div className="col-8">
                        <a href="#" className="white">
                          <div className="one-line">
                            <div className="numbers">XXXXX{item?.last4}</div>
                            <small className="bank">{item?.bank_name}</small>
                          </div>
                        </a>
                      </div>
                      <div className="col-2 d-flex align-items-center">
                        <WeToggle
                          checkToogle={defaultToggle == index ? true : false}
                          onChange={(checked) => {
                            if (checked) {
                              setDefaultToggle(index);
                              handleToggle(item.id);
                            } else {
                              handleToggle('');
                            }
                          }}
                          name="flexRadioDefault"
                          // onSwitchClick={() => {
                          //   handleToggle(item.id);
                          // }}
                        />
                      </div>
                      <div className="col-2 d-flex align-items-center">
                        <a
                          href="#"
                          onClick={() => {
                            setShowConfirmDeleteDialog((showConfirmDeleteDialog) => ({
                              ...showConfirmDeleteDialog,
                              show: true,
                              title: 'Delete bank account',
                              message: item.default_for_currency
                                ? 'You can not delete default bank account'
                                : 'Are you sure, you want to remove this account?',
                              id: item.id,
                              isShowSave: item.default_for_currency ? true : false
                            }));
                          }}>
                          <i className="fa-solid fa-trash-can"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <p className="text-center pt-5"> No Data Found</p>
        )}
      </div>
      {stripeBankAccountList ? (
        <div className="text-center m-5">
          <p>
            <b>Note :-</b> You have to select at least one default bank account.
          </p>
        </div>
      ) : (
        ''
      )}
      {/* </div>
      </div> */}
      <AddPayout
        showDialog={showAddPayoutDialog}
        onDialogClose={() => setshowAddPayoutDialog(false)}
      />

      <ConfirmDialog
        showDialog={showConfirmDeleteDialog.show}
        title={showConfirmDeleteDialog.title}
        message={showConfirmDeleteDialog.message}
        saveBtnHide={showConfirmDeleteDialog.isShowSave}
        onDialogClose={() => {
          setShowConfirmDeleteDialog((showConfirmDeleteDialog) => ({
            ...showConfirmDeleteDialog,
            show: false
          }));
        }}
        btnConfirm={() => handleDelete(showConfirmDeleteDialog.id)}
      />
    </>
  );
}
