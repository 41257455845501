/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import BasicInfo from '../eventSections/basicInfo';
import EventContext from 'context/eventContext';
import { toast } from 'react-toastify';
import Publish from '../eventSections/publish';
import Tickets from '../eventSections/tickets';
import Summary from '../eventSections/summary';
import { useDispatch, useSelector } from 'react-redux';
import { eventDetail } from 'store/slice/event';
import { useParams } from 'react-router-dom';

const ManageEvent = () => {
  const dispatch = useDispatch();
  const [eventFormData, setEventFormData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const { eventDetailData, activeEventMenu } = useSelector((state) => state.event);

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(eventDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (eventDetailData) {
      setEventFormData(eventDetailData);
    }
  }, [eventDetailData]);

  const btnSaveContinue = () => {
    let newStep = activeStep + 1;
    setActiveStep(newStep);
  };

  const btnPrevious = () => {
    let newStep = activeStep - 1;
    setActiveStep(newStep);
  };

  useEffect(() => {
    if (activeEventMenu) {
      setActiveStep(activeEventMenu.id);
    }
  }, [activeEventMenu]);

  const btnPublishEvent = () => {
    toast.success('Congratulations, Your event is published successfully.');
  };

  const renderEventDetailView = () => {
    if (activeStep === 1) {
      return <Summary onSave={btnSaveContinue} activeStep={activeStep} btnPrevious={btnPrevious} />;
    } else if (activeStep === 2) {
      return <Tickets onSave={btnSaveContinue} activeStep={activeStep} btnPrevious={btnPrevious} />;
    } else if (activeStep === 3) {
      return <Publish onSave={btnPublishEvent} activeStep={activeStep} btnPrevious={btnPrevious} />;
    } else {
      return <BasicInfo onSave={btnSaveContinue} activeStep={activeStep} />;
    }
  };

  return (
    <>
      <EventContext.Provider value={{ eventFormData, setEventFormData }}>
        {renderEventDetailView()}
      </EventContext.Provider>
    </>
  );
};

export default ManageEvent;
