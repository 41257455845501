import WeCheckbox from 'components/weCheckbox';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addCheckListData } from 'store/slice/order';

// let checkListData = [];
const OrderList = ({ ...props }) => {
  const { getEventOrdersData } = props;
  // const [listCheckBox, setListCheckBox] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  // const ispresent = getEventOrdersData?.includes(id);
  const { getCheckListData } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const handleNavigate = (_id) => {
    if (id) {
      navigate(`/event/${id}/attendee/orders/${_id}/details`);
    } else {
      navigate(`/orders/${_id}/details`);
    }
  };

  const handleCheckListData = (id) => {
    dispatch(addCheckListData(id));
  };

  return (
    <div className="ticket-table pt-1 pt-lg-3">
      <div className="row heading text-left">
        {id ? <div className="col-12 col-lg-1"></div> : ""}
        <div className="col-12 col-lg-9 d-none d-lg-block">
          <div className="row">
            <div className="col-12 col-lg-4">Orders</div>
            <div className="col-12 col-lg-8">
              <div className="row">
                <div className="col-12 col-lg-4">Date</div>
                <div className="col-12 col-lg-8">Email</div>
              </div>
            </div>
          </div>
        </div>
        <div className={id ? "col-2 col-lg-2 d-flex justify-content-end" : "col-2 col-lg-3 d-flex justify-content-end"}>Amount</div>
      </div>

      <div style={{ height: 'auto', minHeight: '35rem' }}>
      {getEventOrdersData ? (
        getEventOrdersData?.map((item) => {
          return (
            <>
              <div className="row event-row">
                <div className="col-12 mt-1">
                  <div className="row">
                    {id ? (
                      <div className="col-12 col-lg-1 d-none d-lg-flex align-items-center">
                        <WeCheckbox
                          style={{ marginLeft: '5px' }}
                          checkToogle={getCheckListData.includes(item._id) ? true : false}
                          onChange={() => handleCheckListData(item._id)}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="col-10 col-lg-9 d-none d-lg-block align-self-center">
                      <div className="row">
                        <div className="col-12 col-lg-4 d-flex align-items-center">
                          <a href="#" className="one-line" onClick={() => handleNavigate(item._id)}>
                            {item.referenceId} ({item.ticketIds})
                          </a>
                        </div>
                        <div className="col-12 col-lg-8">
                          <div className="row h-100">
                            <div className="col-12 col-lg-4 d-flex align-items-center">
                              {/* {id?moment(item.date).format('DD/MM/yyyy'):moment(item.date).format('MM/DD/yyyy')} */}
                              {moment(item.date).format('MM/DD/yyyy')}
                            </div>
                            <div className="col-12 col-lg-8 d-flex align-items-center">
                              <div className="one-line">{item.email}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-lg-none">
                      <div className="d-flex h-100 align-items-center">
                        <span className="heading align-self-center me-lg-0 me-4">
                          <input
                            className="form-check-input order-checkbox"
                            type="checkbox"
                            value=""
                            id=""
                          />
                        </span>
                        <div className="mobile-event-block w-100 d-block d-lg-none">
                          <div className="info mb-2">
                            <div>
                              <strong>
                                <a href="#" onClick={() => handleNavigate(item._id)}>{item.referenceId} ({item.ticketIds})</a>
                              </strong>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="info">
                              <span className="heading d-block">Date:</span>
                              <span>{item.date}</span>
                            </div>
                            <div className="info">
                              <span className="heading d-block">Email:</span>
                              <span className="one-line">{item.email}</span>
                            </div>
                            <div className="info">
                              <span className="heading d-block">Amount:</span>
                              <span className="one-line">${item.amount}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={id ? "col-12 col-lg-2 d-none d-lg-flex align-items-center justify-content-end" : "col-12 col-lg-3 d-none d-lg-flex align-items-center justify-content-end"}>
                      ${item.amount}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <p className="text-center pt-5"> No Data Found</p>
      )}
      </div>
    </div>
  );
};
export default React.memo(OrderList);
