import React from 'react';
import { Collapse } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { clearAttendeeInOrder } from 'store/slice/tickets';

function ItemMenu({ ...props }) {
  const { menuItems, onItemClick, show } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const { activeEventMenu } = useSelector((state) => state.event);
  const path = window.location.origin;

  return (
    <Collapse in={show}>
      <div className="accordion-body">
        <ul className="sub-menu">
          {menuItems.map((item, index) =>
            item.permissionId ? (
              <li
                key={index}
                onClick={() => {
                  if (!item.path.includes('attendee/add')) {
                    dispatch(clearAttendeeInOrder());
                  }
                  onItemClick(item);
                }}>
                <a className={item.path.includes(activeEventMenu) ? 'active' : 'none'}
                  href={`${path}/event/${id}/${item.path1}`} onClick={(event) => event.preventDefault()}>
                  <span className="fa-stack fa-1x">
                    <i className="fa-solid fa-circle fa-stack-2x"></i>
                    <i className={item.icon}></i>
                  </span>{' '}
                  <span className="title">{item.title}</span>
                </a>
              </li>
            ) : (
              ''
            )
          )}
        </ul>
      </div>
    </Collapse>
  );
}

export default ItemMenu;
