import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCoupon,
  clearCouponData,
  deleteCoupon,
  editCoupon,
  listAllCoupons,
  selectedCouponData
} from 'store/slice/coupon';
import ConfirmDialog from 'components/dialogs/confirmDialog';
import CreateCouponDialog from 'components/dialogs/createCouponDialog';
import EventContext from 'context/eventContext';
import EmptyView from 'components/emptyView';
import { useParams } from 'react-router-dom';
import { selectValue } from 'utils/appConstant';
import { allTicketList } from 'store/slice/tickets';
import { clearSpecificAttendee, updateSpecificAttendee } from 'store/slice/event';

const TabCoupons = () => {
  const { eventFormData } = useContext(EventContext);

  const dispatch = useDispatch();
  const { listAllCouponsData } = useSelector((state) => state.coupon);
  const { ticketListData } = useSelector((state) => state.tickets);
  const { specificAttendeeData } = useSelector((state) => state.event);
  const [show, setShow] = useState(false);
  const [couponName, setCouponName] = useState('');
  const [couponNameError, setCouponNameError] = useState('');
  const [type, setType] = useState('');
  const [typeError, setTypeError] = useState('');
  var [discount, setDiscount] = useState('');
  const [discountError, setDiscountError] = useState('');
  const [capacity, setCapacity] = useState('');
  const [capacityError, setCapacityError] = useState('');
  // const [ticket, setTicket] = useState('');
  const [ticketError, setticketError] = useState('');
  const [status, setStatus] = useState('');
  const [statusError, setStatusError] = useState('');
  const [showEventType, setShowEventType] = useState(false);
  const [openGroupMenuId, setGroupMenuId] = useState(-1);
  const [confirmDialogData, setConfirmDialogData] = useState(null);
  const handleClose = () => {
    setShow(false);
    setCouponName('');
    setType('');
    setDiscount('');
    setCapacity('');
    setStatus('');
    setGroupMenuId(-1);
    dispatch(clearCouponData());
    setCouponNameError('');
    setTypeError('');
    setDiscountError('');
    setCapacityError('');
    setticketError('');
    setStatusError('')
    dispatch(clearSpecificAttendee([]));
  };

  const typeData = [
    {
      label: 'Percentage',
      id: 'percentage'
    },
    {
      label: 'Flat',
      id: 'flat'
    }
  ];

  const statusData = [
    {
      label: 'Active',
      id: 'active'
    },
    {
      label: 'Deactive',
      id: 'deactive'
    }
  ];
  const handleShow = () => setShow(true);
  const { couponItem } = useSelector((state) => state.coupon);

  const { id } = useParams();

  useEffect(() => {
    const reqParam = {
      eventUid: eventFormData.eventUid ?? id
    };
    dispatch(listAllCoupons(reqParam));
    dispatch(allTicketList(reqParam));
  }, []);

  const deleteCouponById = (_id) => {
    dispatch(deleteCoupon(_id));
  };

  const editHoldOn = (item) => {
    dispatch(selectedCouponData(item));
    setCouponName(item.name);
    setType(item.type);
    setDiscount(item.discount);
    setCapacity(item.capacity);
    setStatus(item.status);
    // setTicket(item.ticketIdArray);
    item.ticketIdArray && item.ticketIdArray?.map((item) => {
      dispatch(updateSpecificAttendee(item));
    });
    if (item.ticketIdArray?.length == ticketListData?.length) {
      dispatch(updateSpecificAttendee('all'));
    }
    handleShow();
  };

  const wrapperRef = useRef(null);

  // const handleCloseMenu=()=>{
  //   setGroupMenuId(-1);
  // }

  // useOutsideAlerter(wrapperRef, handleCloseMenu);

  const handleSubmit = (item) => {
    // const strAfterDot = discount.split('.', 2)[1];
    if (!couponName) {
      setCouponNameError('Please enter coupon name');
      return;
    }
    if (!type) {
      setTypeError('Please add type');
      return;
    }
    if (!discount) {
      setDiscountError('Please add discount');
      return;
    }
    if (!capacity) {
      setCapacityError('Please add capacity');
      return;
    }
    if (specificAttendeeData.length < 1) {
      setticketError('Please select tickets');
      return;
    }
    if (!status) {
      setStatusError('Please select status');
      return;
    }

    let attendeeData = []
    attendeeData = specificAttendeeData.includes("all") ? specificAttendeeData.filter((item) => item !== 'all') : specificAttendeeData;

    if (item) {
      const couponData = {
        couponId: item._id ? item._id : item.couponId,
        name: couponName,
        type: type,
        discount: discount,
        capacity: capacity,
        used: item.used,
        ticket: attendeeData,
        status: status
      };
      dispatch(editCoupon(couponData));
    } else {
      const couponData = {
        eventUid: eventFormData.eventUid ?? id,
        name: couponName,
        type: type,
        discount: discount,
        capacity: capacity,
        ticket: attendeeData,
        status: status
      };
      dispatch(addCoupon(couponData));
    }
    handleClose();
  };

  return (
    <>
      {ticketListData?.length === 0 ? (
        <>
          <EmptyView text="No Ticket Created" message="Please create ticket for adding coupons." />
        </>
      ) : (
        <div className="mt-3">
          <div className="mb-3">
            <a className="btn primary-btn mt-2" onClick={handleShow}>
              Create Coupon
            </a>
          </div>

          {listAllCouponsData.length === 0 ? (
            <>
              <EmptyView text="No Coupons Created" message="Create coupons for your event." />
            </>
          ) : (
            <>
              <div className="group-heading d-none d-lg-block">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="d-flex align-items-center">
                      <div className="group-name">
                        <div className="title">Coupon</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="text-end">Type</div>
                  </div>
                  <div className="col-lg-2">
                    <div className="text-end">Discount</div>
                  </div>
                  <div className="col-lg-2">
                    <div className="text-end">Use</div>
                  </div>
                  <div className="col-lg-2">
                    <div className="text-end">Status</div>
                  </div>
                  <div className="col-lg-1"></div>
                </div>
              </div>
              <div className="group-section coupon-section">
                {listAllCouponsData?.map((item) => {
                  return (
                    <>
                      <div className="group-row mt-2" style={{position:"initial"}}>
                        <div className="row">
                          <div className="col-10 col-lg-3">
                            <div className="d-flex align-items-center">
                              <div className="group-name">
                                <div className="title">{item.name}</div>
                                <small>
                                  <div className="d-block d-lg-none">Discount:{item.type == 'flat' ? '$' : ''} {item.discount} {item.type == 'flat' ? '' : '%'}</div>
                                  <div className="d-block d-lg-none">
                                    Uses: 0 /{' '}
                                    <i className="fa-solid fa-infinity" title="Unlimited"></i>
                                  </div>
                                </small>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 d-none d-lg-block">
                            <div className="text-end">{item.type == 'flat' ? 'Flat' : 'Percentage'}</div>
                          </div>
                          <div className="col-lg-2 d-none d-lg-block">
                            <div className="text-end">{item.type == 'flat' ? '$' : ''}{item.discount}{item.type == 'flat' ? '' : '%'}</div>
                          </div>
                          <div className="col-lg-2 d-none d-lg-block">
                            <div className="text-end">
                              {item.used} /{item.capacity}
                            </div>
                          </div>
                          <div className="col-lg-2 d-none d-lg-block">
                            <div className="text-end">{item.status == 'active' ? 'Active' : 'Deactive'}</div>
                          </div>
                          <div className="col-2 col-lg-1">
                            <div
                              className={
                                openGroupMenuId === item._id
                                  ? 'dropdown justify-content-end active'
                                  : 'dropdown justify-content-end'
                              }
                              data-dropdown>
                              <a
                                className="dropdown-button d-inline-block"
                                data-dropdown-button
                                onClick={() => {
                                  if (openGroupMenuId === item._id) {
                                    setGroupMenuId(-1);
                                  } else {
                                    setGroupMenuId(item._id);
                                  }
                                }}>
                                <span className="fa-stack fa-1x">
                                  <i className="fa-solid fa-circle fa-stack-2x"></i>
                                  <i className="fa-solid fa-ellipsis-vertical fa-stack-1x fa-inverse"></i>
                                </span>
                              </a>
                              <div className="dropdown-menu information-grid" ref={wrapperRef}>
                                <div className="dropdown-links">
                                  <a
                                    className="link"
                                    onClick={() => {
                                      setGroupMenuId(-1);
                                      editHoldOn(item);
                                    }}>
                                    Edit
                                  </a>
                                  <a
                                    className="link"
                                    onClick={() => {
                                      setGroupMenuId(-1);
                                      setConfirmDialogData({
                                        ...confirmDialogData,
                                        show: true,
                                        type: 'Ticket',
                                        id: item._id,
                                        item: item._id,
                                        title: 'Delete Coupon',
                                        message: 'Are you sure, you want to delete Coupon?'
                                      });
                                    }}>
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          )}
        </div>
      )}
      <CreateCouponDialog
        showDialog={show}
        onDialogClose={handleClose}
        onNameInputChange={(val) => {
          if (val) {
            setCouponNameError('');
          }
          setCouponName(val.toUpperCase());
        }}
        type={typeData}
        onTypeInputChange={(item) => {
          if (item) {
            setTypeError('');
          }
          setType(item);
        }}
        onDiscountInputChange={(val) => {
          const cleanNumber = val.replace(/[^0-9.]/g, '');
          const strAfterDot = cleanNumber.split('.', 2)[1];
          if (!strAfterDot || strAfterDot.length <= 2) {
            setDiscountError('');
            if (type != 'percentage' || val <= 100) {
              setDiscount(val);
            }
          }
        }}
        onCapacityInputChange={(val) => {
          if (val) {
            setCapacityError('');
          }
          setCapacity(val);
        }}
        selectTicket={
          ticketListData &&
          ticketListData?.map((item) => ({
            label: `${item.name}`,
            id: item.id
          }))
        }
        specificAttendeeData={specificAttendeeData}
        couponTicketError={ticketError}
        couponNameError={couponNameError}
        couponTypeError={typeError}
        couponDiscountError={discountError}
        couponCapacityError={capacityError}
        couponName={couponName}
        couponType={type}
        couponDiscount={discount}
        couponCapacity={capacity}
        status={statusData}
        statusError={statusError}
        defaultValue={status ? selectValue(statusData, status) : 'Make a Selection'}
        setEventFormData={(item) => {
          if (item) {
            setStatusError('');
          }
          setStatus(item);
        }}
        statusDropdown={showEventType}
        statusDropdownToggle={() => setShowEventType(!showEventType)}
        buttonLabel={status}
        btnSave={() => {
          handleSubmit(couponItem);
        }}
      />

      {confirmDialogData && (
        <ConfirmDialog
          showDialog={confirmDialogData.show}
          title={confirmDialogData.title}
          message={confirmDialogData.message}
          onDialogClose={() => {
            setConfirmDialogData((confirmDialogData) => ({
              ...confirmDialogData,
              show: false
            }));
            setGroupMenuId(-1);
          }}
          btnConfirm={() => {
            deleteCouponById(confirmDialogData.item);
            setConfirmDialogData((confirmDialogData) => ({
              ...confirmDialogData,
              show: false
            }));
            setGroupMenuId(-1);
          }}
        />
      )}
    </>
  );
};
export default React.memo(TabCoupons);
