import WeDropDown from 'components/weDropDown';
import WeInput from 'components/weInput';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sendTeamInvite } from 'store/slice/options';
import { validEmail } from 'utils/appConstant';

const AddTeamMemberDialog = ({ ...props }) => {
  const { showDialog, onDialogClose } = props;
  const { roleListData } = useSelector((state) => state.options);
  const [teamData, setTeamData] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();
  const path = window.location.origin;
  const handleSave = () => {
    if (!teamData?.email) {
      setTeamData((teamData) => ({
        ...teamData,
        emailErr: 'Please enter email'
      }));
      return;
    }
    if (!validEmail(teamData?.email)) {
      setTeamData((teamData) => ({
        ...teamData,
        emailErr: 'Please enter valid email'
      }));
      return;
    }
    if (!teamData?.roleId) {
      setTeamData((teamData) => ({
        ...teamData,
        roleIdErr: 'Please select role'
      }));
      return;
    }
    let data = {
      eventUid: id,
      email: teamData?.email,
      roleId: teamData?.roleId,
      // link: 'http://localhost:3000/inviteAccept'
      link: path + '/inviteAccept'
    };
    dispatch(sendTeamInvite(data));
    setTeamData({});
    onDialogClose();
  };

  return (
    <Modal show={showDialog} onHide={()=>{onDialogClose()
      setTeamData({})}} centered backdrop="static" keyboard={false}>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          {' '}
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5">Add Team Member </h1>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <WeInput
          type="text"
          autoFocus={true}
          value={teamData?.email}
          onChange={(val) =>
            setTeamData((teamData) => ({
              ...teamData,
              email: val,
              emailErr: ''
            }))
          }
          inputLabel="Username / Email Address"
        />
        {teamData?.emailErr && <div style={{ color: 'red' }}>{teamData?.emailErr}</div>}
        {/* <div className="dropdown input-block">
          <label className="form-label">Role</label>
          <div className="select-block">
            <select
              defaultValue=""
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}
              onChange={(e) =>
                setTeamData((teamData) => ({
                  ...teamData,
                  roleId: roleListData[e.target.value]?._id,
                  roleIdErr: ''
                }))
              }>
              <option value="" selected disabled>
                Role
              </option>
              {roleListData &&
                roleListData.map((item, i) => {
                  if (!item.notShow) {
                    return (
                      <option key={i} value={i}>
                        {item.roleName}
                      </option>
                    );
                  }
                })}
            </select>
          </div>
        </div> */}
        <WeDropDown
          label="Role"
          selectData={
            roleListData &&
            roleListData?.map((item) => ({
              label: `${item.roleName}`,
              id: item._id
            }))
          }
          onSelectItem={(val) => {
            setTeamData((teamData) => ({
              ...teamData,
              roleId: val,
              roleIdErr: ''
            }));
          }}
          defaultValue={teamData?.roleId ? teamData?.roleId : ''}
          emptyValue="Select Role"
        />
        {teamData?.roleIdErr && <div style={{ color: 'red' }}>{teamData?.roleIdErr}</div>}
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn secondary-btn" onClick={()=>{onDialogClose()
      setTeamData({})}}>
          Close
        </Button>
        <Button className="btn primary-btn" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(AddTeamMemberDialog);
