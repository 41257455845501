import React from 'react';

const WeToggle = ({ ...props }) => {
  const { innerRef, onChange, toggleLabel, checkToogle, onSwitchClick, id, htmlFor } = props;
  return (
    <div className="form-check form-switch" onClick={onSwitchClick}>
      <input
        ref={innerRef}
        style={{cursor:"pointer"}}
        className="form-check-input"
        type="checkbox"
        role="switch"
        id={id}
        checked={checkToogle}
        onChange={(e) => {
          let checked = e.target.checked;
          onChange(checked);
        }}
      />
      <label className="form-check-label" htmlFor={htmlFor}>
        {toggleLabel}
      </label>
    </div>
  );
};
export default React.memo(WeToggle);
