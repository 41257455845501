export const TOAST_TYPES = {
    warning: "warning",
    error: "error",
    success: "success",
    copy: "copy",
    thanks: "thanks",
    fav: "fav",
    report: "report",
  };
  
  export const EventTypeForm = {
    image:"image",
    text:"text",
    video:"video"
  }

  export const EventPageType = {
    HOME : 'HOME',
    EVENT : 'EVENT',
    ATTENDEEE: 'ATTENDEE',
    ORDERS:'ORDERS',
    PAYMENT_OPTIONS:"PAYMENT_OPTIONS"
  }