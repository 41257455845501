import React, { useEffect, useRef } from 'react';
import image from '../../../assets/img/placeholder.jpeg';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { eventDetailsByUrl } from 'store/slice/auth';
import moment from 'moment';
import ReactPlayer from 'react-player';
import WeMap from 'components/weMap';
import SpinnerComponent from 'components/spinnerComponent';
import { apiFetchingEnd } from 'store/slice/event';

let extraFields = []
const ViewEvent = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { eventData } = useSelector((state) => state.auth);
  const { isApiLoading } = useSelector((state) => state.event);
  // const [extraFields, setExtraFields] = useState({});
  // const [mapLocation, setMapLocation] = useState(defaultMapLocation);
  const mapLocation = {
    latitude: 40.639199,
    longitude: -74.014557,
    zoom: 5,
    transitionDuration: 100
  };
  const mapRef = useRef();

  useEffect(() => {
    if (id) {
      dispatch(eventDetailsByUrl(id));
    }
  }, [id]);

  useEffect(() => {
    if (eventData) {
      // setExtraFields((extraFields) => ({
      //   ...extraFields,
      //   ...eventData.extraFields
      // }));
      extraFields = eventData.extraFields
      dispatch(apiFetchingEnd());
    }
  }, [eventData]);

  console.log("eventData -- ", extraFields);

  return isApiLoading ? (
    <SpinnerComponent />
  ) : (
    <>
      {/* <div className="navbar">
        <a href="index.html" className="navbar-logo">
          <img src={image} alt="" />
          <div className="name">Tesloom</div>
        </a>
        <ul className="main-menu">
          <li>
            <a href="search.html">Events</a>
          </li>
          <li>
            <a href="login.html" className="btn primary-btn">
              Log In
            </a>
          </li>
          <li>
            <a href="sign-up.html" className="btn secondary-btn">
              Sign Up
            </a>
          </li>
        </ul>

        <div className="hamburgerEvent" onClick="eventHamburgerToggle(this)">
          <div className="bar-line bar1"></div>
          <div className="bar-line bar2"></div>
          <div className="bar-line bar3"></div>
        </div>
      </div> */}

      <div className="hero single-event">
        <div className="background-image">
          <img src={eventData?.bannerImageName ? eventData?.bannerImageName : image} alt="" />
        </div>
        <div className="overlay"></div>
        <div className="container">
          <div className="slider mb-3">
            <div className="img-container">
              <img src={eventData?.bannerImageName ? eventData?.bannerImageName : image} alt="" />
            </div>
          </div>
          <div className="content">
            <a href="#" className="organizer">
              {eventData?.title}
              {eventData?.isVerifiedOrganizer ? (
                <span className="fa-stack fa-1x">
                  <i className="fa-solid fa-badge fa-stack-2x"></i>
                  <i className="fa-solid fa-check fa-stack-1x fa-inverse"></i>
                </span>
              ) : (
                ''
              )}
            </a>
            <h1>{eventData?.title}</h1>
            <p>
              <span className="date">
                Date: {moment(eventData?.startDate).format('MMM DD')} (
                {moment(eventData?.endTime, 'HH:mm:ss').format('HH:mm A')}) -
                {moment(eventData?.endDate).format(' MMM DD')} (
                {moment(eventData?.endTime, 'HH:mm:ss').format('HH:mm A')})
              </span>
              <span className="location">
                Location: <a href="#">{eventData?.venue}</a>
              </span>
              <span className="price">Price: {eventData?.priceRange}</span>
              <span className="price">Refund Policy: The organizer will review refund requests on a ${eventData?.refundPolicyValue} basis</span>
            </p>
            {/* <a className="btn primary-btn" data-bs-toggle="modal" data-bs-target="#buyTicketsModal">
              Buy
            </a> */}
          </div>
        </div>
      </div>
      <section className="single-event-body">
        <div className="container">
          <div className="content">
            <hr />
            <h3>About</h3>
            <p>{eventData?.googleDescription}</p>

            {/* {extraFields?.type == 'image' ? (
              <>
                <img src={image} alt="" />
              </>
            ) : extraFields?.type == 'video' ? (
              <ReactPlayer className="youtube-video" url={extraFields.videoLink} controls />
            ) : (
              <p>{eventData?.textDescription}</p>
            )} */}

            {extraFields?.map((item) => {
              return item?.type == 'image' ? (
                <>
                  <img src={item.imageURL} alt="" />
                </>
              ) : item?.type == 'video' ? (
                <ReactPlayer className="youtube-video" url={item.videoLink} controls />
              ) : (
                <p>{item?.textDescription}</p>
              )
            })}

            <h3 className='mt-5'>Description</h3>
            <p>{eventData?.description}</p>
            <hr />
            <h3>Map</h3>

            <div style={{ width: '100%' }}>
              <div style={{ height: '65vh' }}>
                <WeMap mapData={mapLocation} innerRef={mapRef} />
              </div>
            </div>
            <hr />
            <h3>Tags</h3>
            <div className="tags">
              <div className="pills">
                {eventData?.tags &&
                  eventData?.tags.map((item) => {
                    return (
                      <>
                        <div className="pill alert">
                          <span className="text">{item}</span>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="ticket-footer">
          {/* <a
            href="#"
            className="btn primary-btn"
            data-bs-toggle="modal"
            data-bs-target="#buyTicketsModal">
            Buy
          </a> */}
        </div>
      </section>
    </>
  );
};

export default ViewEvent;
