import WeInput from 'components/weInput';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateErrorData, updateTicketData } from 'store/slice/tickets';
const SelectTicketComponent = ({ ...props }) => {
  const dispatch = useDispatch();
  const { ticketListData, onHoldData } = props;
  const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  const { errorData, attendeeTicketData } = useSelector((state) => state.tickets);

  const checkOnHold = (id) => {
    let suspendIndex0 = onHoldData?.findIndex((item1) => item1.ticket === id);
    return suspendIndex0 > -1 ? onHoldData[suspendIndex0].remaining : 0;
  };

  return ticketListData?.map((item, index) => {
    const available = checkOnHold(item.id) > 0 ? checkOnHold(item.id) : item.remaining;
    return (
      <div className="block" key={index}>
        <div className="row">
          <div className="col-12 col-md-5">
            <div className="mb-3">
              Ticket Name:
              <br />
              <strong style={{ fontSize: '1.1em' }}>{item.name}</strong>
            </div>
            <div className="">
              <div className="d-inline-block me-4">
                Available:
                <br />
                <strong>
                  ({available}/{item.capacity})
                </strong>
              </div>
              <div className="d-inline-block">
                Orig. Price:
                <br />
                <strong>${item.price ? item.price : 0}</strong>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7">
            <div className="d-flex h-100">
              <div className="align-self-center w-100">
                <div className="row g-3">
                  <div className="col-3 col-lg-12 col-xl-3">
                    <WeInput
                      type="number"
                      minVal={0}
                      maxVal={available}
                      disabled={available ? false : true}
                      onKeyPress={(e) => blockInvalidChar(e)}
                      key={index}
                      // onBlur={() => showError(index, item.capacity - item.sold)}
                      className={'input-block mt-4 mt-lg-2'}
                      onChange={(value) => {
                        if (value <= available) {
                          const attendeeData = {
                            id: item._id,
                            quantity:  Number(value),
                            unitPrice: attendeeTicketData[index]?.unitPrice,
                            ticketItem: item,
                            total: attendeeTicketData[index]?.unitPrice * value
                          };
                          const data1 = {
                            index: index,
                            data: attendeeData
                          };
                          dispatch(updateTicketData(data1));
                          const data = {
                            index: index,
                            message: ''
                          };
                          dispatch(updateErrorData(data));
                        }
                      }}
                      inputLabel="Qty"
                      value={attendeeTicketData[index]?.quantity}
                    />
                    <p className="text-danger">{errorData[index]}</p>
                  </div>
                  <div className="col-9 col-lg-12 col-xl-9">
                    <div className="row g-3">
                      <div className="col-6 col-lg-12 col-xl-6">
                        <WeInput
                          type="number"
                          minVal={0}
                          disabled={item.capacity === available ? true : false}
                          placeholder={'0.00'}
                          className={'input-block mt-4 mt-lg-2'}
                          onChange={(value) => {
                            const attendeeData = {
                              id: item._id,
                              quantity: attendeeTicketData[index]?.quantity,
                              unitPrice: value,
                              ticketItem: item,
                              total: attendeeTicketData[index]?.quantity * value
                            };
                            const data1 = {
                              index: index,
                              data: attendeeData
                            };
                            dispatch(updateTicketData(data1));
                          }}
                          value={attendeeTicketData[index]?.unitPrice}
                          inputLabel="Unit Price"
                          prefix={'$'}
                        />
                      </div>
                      <div className="col-6 col-lg-12 col-xl-6">
                        <WeInput
                          type="number"
                          minVal={0}
                          placeholder={'0.00'}
                          className={'input-block mt-4 mt-lg-2 disabled'}
                          disabled={true}
                          value={
                            attendeeTicketData[index]?.total ? attendeeTicketData[index]?.total : 0
                          }
                          inputLabel="Total"
                          prefix={'$'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {checkOnHold(item.id) > 0 ? (
            <span className="text-success mt-2" style={{ fontSize: '0.8rem' }}>On Hold Applied</span>
          ) : (
            ''
          )}
          {available == 0 ? (
            <span className="text-danger mt-2" style={{ fontSize: '0.8rem' }}>Sold Out</span>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  });
};
export default React.memo(SelectTicketComponent);
