/* eslint-disable no-unused-vars */
import WeButton from 'components/button';
import WeInput from 'components/weInput';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { validYouTubeVimeoLink } from 'utils/appConstant';

const VideoContainer = ({ ...props }) => {
  const { itemData, btnDeleteItem, onChangeVideoLink, value } = props;
  const [validLink, setValidLink] = useState('');
  const [videLink, setVideoLink] = useState('');

  useEffect(() => {
    if (value) {
      setVideoLink(value);
    }
  }, [value]);

  return (
    <>
      <div className="summary-block">
        <div className="tools">
          {/* <div className="icon">
            <i className="fa-solid fa-up-down-left-right"></i>
          </div> */}
          <div
            className="icon"
            onClick={() => {
              btnDeleteItem(itemData);
            }}>
            <i className="fa-solid fa-trash-can"></i>
          </div>
        </div>
        <div className="embed-video">
          <br />
          {validYouTubeVimeoLink(videLink) && (
            // <iframe
            //   className="youtube-video"
            //   src={videLink} //"https://www.youtube.com/embed/5kW0RtcJZC8"
            //   title="YouTube video player"
            //   frameBorder="0"
            //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            //   allowfullscreen></iframe>
            <ReactPlayer className="youtube-video" url={videLink} controls />
          )}
          <WeInput
            inputLabel="Video Link"
            onChange={(val) => {
              setVideoLink(val);
              if (validYouTubeVimeoLink(val)) {
                onChangeVideoLink(val);
                setValidLink('');
              } else {
                setValidLink('Please enter valid youtube/vimeo link.');
              }
            }}
            formError={validLink}
            value={videLink}
          />
          <p style={{ padding: 5 }}>
            Add a video link from Youtube or Vimeo to show your event's vibe. The video will appear
            with your event images.
          </p>

          {itemData.isRequired && (
            <div style={{ color: 'red' }}>Please add video link or delete this item.</div>
          )}
        </div>
      </div>
      {/* <div className="embed-video">
        <iframe
          className="youtube-video"
          src="https://www.youtube.com/embed/5kW0RtcJZC8"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>

          <WeInput
            inputLabel="Video Link"
            onChange={(val) => {
              // setVideoLink(val);
              if (validYouTubeVimeoLink(val)) {
                onChangeVideoLink(val);
                setValidLink('');
              } else {
                setValidLink('Please enter valid youtube/vimeo link.');
              }
            }}
            formError={validLink}
            value={value}
          />
          <p style={{ padding: 5 }}>
            Add a video link from Youtube or Vimeo to show your event's vibe. The video will appear
            with your event images.
          </p>

          {itemData.isRequired && (
            <div style={{ color: 'red' }}>Please add video link or delete this item.</div>
          )}
        </div>

      <WeButton
        text="Delete"
        onClick={() => {
          btnDeleteItem(itemData);
        }}
      /> */}
    </>
  );
};

export default VideoContainer;
