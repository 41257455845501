import MultipleSelectDropDown from 'components/multipleSelectDropDown';
import WeInput from 'components/weInput';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  onTicketChange,
  getUserEventVenue,
  searchTicket,
  onPaymentChange,
  onVenuesChange
} from 'store/slice/order';
import { useOutsideAlerter } from 'utils/appConstant';

// const ticketType = [];
// const paymentType = [];
// const venues = [];
let showFilter = [];
const SalesFilter = ({ ...props }) => {
  const {
    filter,
    onStartDateChange,
    onEndDateChange,
    // onTicketChange,
    // onPaymentChange,
    // onVenuesChange,
    hideRefreshBtn,
    onRefreshClick,
    handleRemoveFilter,
    ticketListData
  } = props;

  const [filterDialogToggle, setFilterDialogToggle] = useState(false);
  const wrapperRef = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { ticketData, eventVenueData, ticketCheckData, paymentCheckData, VenuesCheckData } =
    useSelector((state) => state.order);

  useEffect(() => {
    dispatch(searchTicket());
    dispatch(getUserEventVenue());
  }, []);

  const paymentTypeData = [
    {
      label: 'Cash',
      id: 'cash'
    },
    {
      label: 'Check',
      id: 'check'
    },
    {
      label: 'Paypal',
      id: 'paypal'
    },
    {
      label: 'Stripe',
      id: 'stripe'
    },
    {
      label: 'Credit Card',
      id: 'creditCard'
    },
    {
      label: 'Debit Card',
      id: 'debitCard'
    },
    {
      label: 'Free',
      id: 'free'
    },
    {
      label: 'Other',
      id: 'other'
    }
  ];

  const onTicketSelect = (item) => {
    dispatch(onTicketChange(item.id));
  };

  const onPaymentSelect = (item) => {
    dispatch(onPaymentChange(item.id));
  };

  const handleShowFilter = (val) => {
    const found = showFilter.findIndex((element) => element === val);
    if (found < 0) {
      if (val == 'Ticket' && ticketListData?.length == 0) {
        toast.error("No ticket found")
      } else {
        showFilter.push(val);
      }
    } else {
      showFilter.splice(found, 1);
      if (val == 'Date') {
        handleRemoveFilter();
      } else if (val == 'Ticket') {
        dispatch(onTicketChange());
      } else if (val == 'Payment') {
        dispatch(onPaymentChange());
      } else {
        dispatch(onVenuesChange());
      }
    }
    setFilterDialogToggle(false);
  };

  const venuesSelect = (item) => {
    dispatch(onVenuesChange(item.id));
  };

  const fetchAllTickets = () => {
    setFilterDialogToggle(false);
  };

  // const onRefreshClick = () => {
  //   showFilter = [];
  //   handleRemoveFilter();
  //   dispatch(onTicketChange());
  //   dispatch(onPaymentChange());
  //   dispatch(onVenuesChange());
  //   handleSearch();
  // };

  useOutsideAlerter(wrapperRef, fetchAllTickets);

  return (
    <>
      <div className="mt-3 mt-lg-4">
        <div className="btn-group" ref={wrapperRef}>
          <button
            type="button"
            className="btn btn-checkbox reportFilterButton"
            onClick={() => setFilterDialogToggle(!filterDialogToggle)}>
            <i className="fa-solid fa-filter-list"></i>
            <span>Filters</span>
          </button>
          {!hideRefreshBtn ? (
            <button
              type="button"
              className="btn btn-checkbox mx-2"
              onClick={() => {
                showFilter = [];
                onRefreshClick();
              }}>
              <i className="fa-solid fa-rotate-right"></i>
            </button>
          ) : (
            ''
          )}
          <div className={filterDialogToggle ? 'report-filters active' : 'report-filters'}>
            <div className="row gx-2">
              <div className="col-6">
                <div
                  className={showFilter.includes('Date') ? 'block active' : 'block'}
                  onClick={() => handleShowFilter('Date')}>
                  <div className="icon">
                    <i className="fa-solid fa-money-bill"></i>
                  </div>
                  <div className="title">Transaction Date</div>
                </div>
              </div>
              <div className="col-6">
                <div
                  className={showFilter.includes('Ticket') ? 'block active' : 'block'}
                  onClick={() => handleShowFilter('Ticket')}>
                  <div className="icon">
                    <i className="fa-solid fa-ticket"></i>
                  </div>
                  <div className="title">Ticket Type</div>
                </div>
              </div>
            </div>
            <div className="row gx-2">
              <div className="col-6">
                <div
                  className={showFilter.includes('Payment') ? 'block active' : 'block'}
                  onClick={() => handleShowFilter('Payment')}>
                  <div className="icon">
                    <i className="fa-solid fa-credit-card"></i>
                  </div>
                  <div className="title">Payment Type</div>
                </div>
              </div>
              <div className="col-6">
                <div
                  className={showFilter.includes('Venue') ? 'block active' : 'block'}
                  onClick={() => handleShowFilter('Venue')}>
                  <div className="icon">
                    <i className="fa-solid fa-city"></i>
                  </div>
                  <div className="title">Venue Name</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="row">
            {showFilter.includes('Date') ? (
              <>
                <div className="col-6 col-xl-4">
                  <WeInput
                    type="date"
                    maxVal={filter?.endDate ? filter?.endDate : ''}
                    inputLabel="Transaction Start"
                    onChange={onStartDateChange}
                    value={filter?.startDate}
                  />
                </div>
                <div className="col-6 col-xl-4">
                  <WeInput
                    type="date"
                    minVal={filter?.startDate ? filter?.startDate : ''}
                    inputLabel="Transaction End"
                    onChange={onEndDateChange}
                    value={filter?.endDate}
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        {showFilter.includes('Ticket') ? (
          <>
            <div className="col-12 col-xl-4">
              <MultipleSelectDropDown
                label="Ticket Type"
                emptyValue="Select Ticket"
                selectData={
                  id
                    ? ticketListData &&
                    ticketListData?.map((item) => ({
                      label: `${item.name}`,
                      id: item.id
                    }))
                    : ticketData &&
                    ticketData?.map((item) => ({
                      label: `${item.ticketName}`,
                      id: item.ticketId
                    }))
                }
                onSelectItem={onTicketSelect}
                selectDataLength={ticketCheckData.length}
                defaultSelectValue={ticketCheckData}
              />
            </div>
          </>
        ) : (
          ''
        )}

        {showFilter.includes('Payment') ? (
          <>
            <div className="col-12 col-xl-4">
              <MultipleSelectDropDown
                label="Payment Type"
                emptyValue="Select Payment"
                selectData={paymentTypeData}
                onSelectItem={onPaymentSelect}
                selectDataLength={paymentCheckData.length}
                defaultSelectValue={paymentCheckData}
              />
            </div>
          </>
        ) : (
          ''
        )}

        {showFilter.includes('Venue') ? (
          <>
            <div className="col-12 col-xl-4">
              <MultipleSelectDropDown
                label="Venues"
                emptyValue="Select Venues"
                selectData={
                  eventVenueData &&
                  eventVenueData?.map((item) => ({
                    label: item,
                    id: item
                  }))
                }
                onSelectItem={venuesSelect}
                selectDataLength={VenuesCheckData.length}
                defaultSelectValue={VenuesCheckData}
              />
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
export default React.memo(SalesFilter);
