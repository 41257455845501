/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './index.css';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

/**Calendar class customize according to day view.
 * new Date(2023, 7, 2, 8, 30, 0),
 * @author : Expertinasia Pvt. Ltd.
 */
export default function EventCalendar(props) {
  const { date, onEventSelect, onCalendarSlotSelect } = props;
  const { eventScheduleList } = useSelector((state) => state.event);

  const [defaultSelectedDate, setDefaultSelectedDate] = useState(null);
  const [eventData, setEventData] = useState([]);
  const [resourceMap, setResourceMap] = useState([]);
  const now = new Date();

  let events = [];
  // const events = [
  //   {
  //     id: 0,
  //     title: 'Board meeting',
  //     start: new Date(2023, 7, 8, 9, 0, 0),
  //     end: new Date(2023, 7, 8, 13, 0, 0),
  //     resourceId: 1
  //   },

  //   {
  //     id: 0,
  //     title: 'Team meeting',
  //     start: new Date(2023, 7, 8, 7, 0, 0),
  //     end: new Date(2023, 7, 8, 13, 0, 0),
  //     resourceId: 2
  //   },

  //   {
  //     id: 0,
  //     title: 'Team meeting',
  //     start: new Date(2023, 7, 8, 14, 0, 0),
  //     end: new Date(2023, 7, 8, 15, 0, 0),
  //     resourceId: 2
  //   },

  //   {
  //     id: 0,
  //     title: 'Team meeting',
  //     start: new Date(2023, 7, 8, 14, 0, 0),
  //     end: new Date(2023, 7, 8, 15, 0, 0),
  //     resourceId: 4
  //   },
  // ];

  // const resourceMap = [
  //   { resourceId: 1, resourceTitle: 'Board room' },
  //   { resourceId: 2, resourceTitle: 'Training room' },
  //   { resourceId: 3, resourceTitle: 'Meeting room 1' },
  //   { resourceId: 4, resourceTitle: 'Meeting room 2' }
  // ];

  moment.locale('en-GB');
  const localizer = momentLocalizer(moment);

  useEffect(() => {
    let defaultDate = getCalDefaultDate();
    setDefaultSelectedDate(defaultDate);
  }, [date]);

  //return calendar formart default selected
  const getCalDefaultDate = () => {
    var localTime = date;
    let day = moment(localTime, 'YYYY/MM/DD').date();
    let month = moment(localTime, 'YYYY/MM/DD').month();
    let year = moment(localTime, 'YYYY/MM/DD').year();
    return new Date(year, month, day);
  };

  useEffect(() => {
    if (eventScheduleList && eventScheduleList.length > 0) {
      let rooms = [];
      eventScheduleList.map((item) => {
        let roomsItem = {
          resourceId: item._id,
          resourceTitle: item.roomTitle
        };
        rooms.push(roomsItem);
        if (item.activityData) {
          itemData(item.activityData, item._id);
        }
      });
      setResourceMap(rooms);
    }

  }, [eventScheduleList]);

  /***Create an event schedule object */
  const itemData = (activityData) => {
    activityData.map((event, index) => {
      let obj = {
        id: index,
        title: event.activityTitle,
        resourceId: event.resourceId,
        start: event.startTime ? getEventDate(event.startTime) : defaultSelectedDate,
        end: event.endTime ? getEventDate(event.endTime) : defaultSelectedDate,
        allDay: event.allDay,
        _id: event._id
      };

      events.push(obj);
    });
    setEventData(events);
  };

  /**Convert date time according to event calender below format*
   * new Date(2023, 7, 2, 8, 30, 0),
   * @author : Expertinasia Pvt. Ltd.
   */
  const getEventDate = (eventDate) => {
    var localTime = eventDate;
    let day = moment(localTime, 'YYYY/MM/DD').date();
    let month = moment(localTime, 'YYYY/MM/DD').month();
    let year = moment(localTime, 'YYYY/MM/DD').year();
    let hours = moment(localTime, 'YYYY/MM/DD HH:mm').hours();
    let minutes = moment(localTime, 'YYYY/MM/DD HH:mm').minutes();
    localTime = new Date(year, month, day, hours, minutes, 0);
    return localTime;
  };

  return (
    <div className="App">
      <br></br>
      {defaultSelectedDate && (
        <Calendar
          selectable
          views={['day']}
          localizer={localizer}
          defaultDate={defaultSelectedDate}
          defaultView="day"
          style={{ backgroundColor: '#1e1e1e', color: 'white' }}
          events={eventData}
          resourceIdAccessor="resourceId"
          resources={resourceMap}
          resourceTitleAccessor="resourceTitle"
          onSelectEvent={onEventSelect}
          onSelectSlot={onCalendarSlotSelect}
        />
      )}
    </div>
  );
}
