import React, { useRef } from 'react';
import moment from 'moment';
// import { useOutsideAlerter } from 'utils/appConstant';

const TicketItems = ({ ...props }) => {
  const {
    key,
    openTicketMenu,
    onItemMenuClick,
    itemData,
    onDragStart,
    onEditItem,
    onDeleteItem,
    groupItemData
    // eventFormData
  } = props;

  const wrapperRef = useRef(null);

  // const handleCloseMenu=()=>{
  //   onItemMenuClick(-1);
  // }

  // useOutsideAlerter(wrapperRef, handleCloseMenu);

  return (
    <>
      <div className={itemData?.groupId ?? 'group-section'}>
        <div className="ticket-row" key={key}>
          <div className="row">
            <div
              className="col-10 col-lg-6"
              draggable={groupItemData && !!groupItemData.length && true}
              onDragStart={() => {
                onDragStart(itemData);
              }}>
              <div className="d-flex align-items-center">
                <div className="move">
                  <i className="fa-solid fa-up-down-left-right"></i>
                </div>
                <div className="group-name">
                  <div className="title">{itemData?.name}</div>
                  <small>
                    <div>
                      For Sale{' '}
                      <span className="d-none d-lg-inline-block">
                        <i className="fa-solid fa-pipe"></i> Ends{' '}
                        {moment(itemData?.endDate).format('MMMM Do, YYYY')} at {itemData?.endTime}
                      </span>
                    </div>
                    <div className="d-block d-lg-none">
                      Sold: {itemData?.sold ? itemData?.sold : 0}/
                      {itemData?.capacity ? itemData?.capacity : 0}
                    </div>
                    <div className="d-block d-lg-none">
                      ${itemData?.price ? itemData?.price : 0}
                    </div>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-lg-2 d-none d-lg-block">
              <div className="text-end">
                {itemData?.sold ? itemData?.sold : 0}/{itemData?.capacity ? itemData?.capacity : 0}
              </div>
            </div>
            <div className="col-lg-3 d-none d-lg-block">
              <div className="text-end">${itemData?.price ? itemData?.price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0}</div>
            </div>
            <div className="col-2 col-lg-1">
              <div
                className={
                  openTicketMenu === itemData?._id
                    ? 'dropdown justify-content-end active'
                    : 'dropdown justify-content-end'
                }
                data-dropdown>
                <a
                  className="dropdown-button d-inline-block"
                  data-dropdown-button
                  onClick={() => {
                    if (openTicketMenu === itemData?._id) {
                      onItemMenuClick(-1);
                    } else {
                      onItemMenuClick(itemData?._id);
                    }
                  }}>
                  <span className="fa-stack fa-1x">
                    <i className="fa-solid fa-circle fa-stack-2x"></i>
                    <i className="fa-solid fa-ellipsis-vertical fa-stack-1x fa-inverse"></i>
                  </span>
                </a>
                <div className="dropdown-menu information-grid" ref={wrapperRef}>
                  <div className="dropdown-links">
                    <a className="link" onClick={() => onEditItem(itemData)}>
                      Edit
                    </a>
                    <a className="link" onClick={() => onDeleteItem(itemData)}>
                      Delete
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(TicketItems);
