import PaginatioonComponent from 'components/paginatioonComponent';
import WeInput from 'components/weInput';
import PageForNoPermission from 'pages/pageForNoPermission';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  apiFatchingStart,
  apiLoadingStart,
  attendeeList,
  changeCheckInStatus
} from 'store/slice/event';

const CheckIn = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  let page = 1;
  let limit = 8;
  const [pageNo, setPageNo] = useState(page);
  const [requestParams, setRequestParams] = useState();
  const { attendeeListData, userPermissionData, isOwnerValue, totalData, isLoading } =
    useSelector((state) => state.event);
  useEffect(() => {
    checkInListCall();
    dispatch(apiLoadingStart());
  }, []);

  const handlePagination = (data) => {
    dispatch(apiFatchingStart());
    // const page = flag == 'next' ? pageNo + 1 : pageNo - 1;
    console.log('onPageChange', data.selected + 1);
    setPageNo(data.selected + 1);
  };

  useEffect(() => {
    checkInListCall();
  }, [pageNo]);

  useEffect(() => {
    checkInListCall();
  }, [requestParams]);

  const checkInListCall = () => {
    let data = {
      eventUid: id,
      page: pageNo,
      perPage: limit,
      search: requestParams
    };
    dispatch(attendeeList(data));
  };

  const checkIn = (item) => {
    let data = {
      attendeeId: item._id,
      type: item.checkInStatus ? '2' : '1'
    };
    dispatch(changeCheckInStatus(data));
  };

  return isOwnerValue || userPermissionData?.attendee.checkInAttendee ? (
    isLoading ? (
      <div
        className="position-absolute top-50 start-50 translate-middle"
        style={{ color: 'var(--bs-lt-red)' }}>
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    ) : (
      <>
        <h2>Check In</h2>
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

        <div className="row">
          <div className="col-12 col-lg-6 col-xl-5">
            <div className="mt-2">
              <WeInput
                onChange={(val) => {
                  setRequestParams(val);
                }}
                inputLabel="Search"
                value={requestParams}
              />
            </div>
          </div>
        </div>

        <div className="ticket-table mt-3">
          <div className="row heading d-block">
            <div className="col-12">
              <div className="row">
                <div className="col-5 col-lg-4">Name</div>
                <div className="col-7 col-lg-8">Ticket Type</div>
              </div>
            </div>
          </div>

          <div style={{ height: 'auto', minHeight: '32rem' }}>
            {attendeeListData && attendeeListData.length != 0 ? (
              attendeeListData?.map((item, index) => {
                return (
                  <div
                    className="row event-row"
                    key={index}
                    onClick={() => checkIn(item)}
                    style={{ background: item?.checkInStatus ? 'black' : '' }}>
                    <div className="col-12 mt-1">
                      <div className="row">
                        <div className="col-5 col-lg-4">{item.attendee}</div>
                        <div className="col-6">{item.ticketName}</div>
                        <div className="col-1 col-lg-2"></div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : !isLoading ? (
              <p className="text-center pt-5"> No Data Found</p>
            ) : (
              ''
            )}
          </div>
          {/* {isLoading ? (
            <div
              className="position-absolute top-50 start-50 translate-middle"
              style={{ color: 'var(--bs-lt-red)' }}>
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            ''
          )} */}

          {attendeeListData && attendeeListData.length != 0 ? (
            <div className="mt-3" style={{ float: 'right' }}>
              {/* {isFetching ? (
                <div className="text-center" style={{ color: 'var(--bs-lt-red)' }}>
                  <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  <a onClick={() => (pageNo > 1 ? handlePagination('pre') : '')}>
                    <i
                      className={
                        pageNo > 1
                          ? 'fa-solid fa-circle-chevron-left fa-2xl paginationBtn'
                          : 'fa-solid fa-circle-chevron-left fa-2xl paginationDisableBtn'
                      }></i>
                  </a>
                  <a
                    className="px-1"
                    onClick={() => {
                      if (
                        (totalData % limit === 0
                          ? Math.floor(totalData / limit)
                          : Math.floor(totalData / limit) + 1) > pageNo
                      ) {
                        handlePagination('next');
                      }
                    }}>
                    <i
                      className={
                        (totalData % limit === 0
                          ? Math.floor(totalData / limit)
                          : Math.floor(totalData / limit) + 1) > pageNo
                          ? 'fa-solid fa-circle-chevron-right fa-2xl paginationBtn'
                          : 'fa-solid fa-circle-chevron-right fa-2xl paginationDisableBtn'
                      }></i>
                  </a>
                </>
              )} */}
                  <PaginatioonComponent
              pageCount={totalData % limit === 0?Math.floor(totalData / limit):Math.floor(totalData / limit) + 1}
              onPageChange={handlePagination}
              pageNo={pageNo}
            />
            </div>
          ) : (
            ''
          )}
        </div>
      </>
    )
  ) : (
    <PageForNoPermission />
  );
};

export default CheckIn;
