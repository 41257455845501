import React, { useEffect, useState } from 'react';
import SalesFilter from './subcomponents/salesFilter';
import WeInput from 'components/weInput';
import { useDispatch, useSelector } from 'react-redux';
import { attendeeReport, onPaymentChange, onTicketChange, onVenuesChange } from 'store/slice/order';
import PaginatioonComponent from 'components/paginatioonComponent';

export default function AttendeeReports() {
  const dispatch = useDispatch();
  let page = 1;
  let limit = 10;
  const [pageNo, setPageNo] = useState(page);
  const [filter, setFilter] = useState();
  const { attendeeReportData, ticketCheckData, paymentCheckData, VenuesCheckData,totalCount } = useSelector(
    (state) => state.order
  );

  const handlePagination = (data) => {
    // const page = flag == 'next' ? pageNo + 1 : pageNo - 1;
    console.log('onPageChange', data.selected + 1);
    setPageNo(data.selected + 1);
  };

  useEffect(() => {
    getAttendeeReport();
  }, [pageNo]);

  useEffect(() => {
    getAttendeeReport();
  }, []);

  useEffect(() => {
    getAttendeeReport();
  }, [filter]);

  useEffect(() => {
    getAttendeeReport();
  }, [ticketCheckData]);

  useEffect(() => {
    getAttendeeReport();
  }, [paymentCheckData]);
  useEffect(() => {
    getAttendeeReport();
  }, [VenuesCheckData]);

  const getAttendeeReport = () => {
    dispatch(
      attendeeReport({
        page: pageNo,
        perPage: limit,
        search: filter?.search,
        startDate: filter?.startDate,
        endDate: filter?.endDate,
        ticketType: ticketCheckData,
        paymentType: paymentCheckData,
        venues: VenuesCheckData
      })
    );
  };
  const onRefreshClick = () => {
    setFilter((filter) => ({
      ...filter,
      startDate: '',
      endDate: '',
      search: ''
    }));
    dispatch(onTicketChange());
    dispatch(onPaymentChange());
    dispatch(onVenuesChange());
  };

  return (
    <>
      <h2>Attendee Reports</h2>

      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/reports">Reports</a>
            </li>
            <li className="breadcrumb-item">Attendee Reports</li>
          </ol>
        </nav>
      </div>

      <SalesFilter
        onStartDateChange={(val) => {
          setFilter((filter) => ({
            ...filter,
            startDate: val
          }));
        }}
        filter={filter}
        onEndDateChange={(val) => {
          setFilter((filter) => ({
            ...filter,
            endDate: val
          }));
        }}
        handleRemoveFilter={() => {
          setFilter((filter) => ({
            ...filter,
            startDate: '',
            endDate: ''
          }));
        }}
        onRefreshClick={onRefreshClick}
      />
      <hr />

      <WeInput
        onChange={(val) => {
          setFilter(() => ({
            ...filter,
            search: val
          }));
        }}
        inputLabel="Search Attendee"
        value={filter?.search}
      />

      <div className="ticket-table mt-3">
        <div className="row heading">
          <div className="col-12">
            <div className="row">
              <div className="col-2">Order ID</div>
              <div className="col-2">Order Date</div>
              <div className="col-2">First Name</div>
              <div className="col-2">Last Name</div>
              <div className="col-2">Ticket Type</div>
              <div className="col-2">Email</div>
            </div>
          </div>
        </div>

        {attendeeReportData ? (
          attendeeReportData?.map((item) => {
            return (
              <>
                <div className="row event-row">
                  <div className="col-12 mt-1 d-none d-lg-block">
                    <div className="row">
                      <div className="col-2">
                        <div className="one-line">
                          <a href="#">{item.orderId}</a>
                        </div>
                      </div>
                      <div className="col-2">{item.orderData}</div>
                      <div className="col-2">
                        <div className="one-line">{item.firstName}</div>
                      </div>
                      <div className="col-2">
                        <div className="one-line">{item.lastName}</div>
                      </div>
                      <div className="col-2">
                        <div className="one-line">{item.ticketType}</div>
                      </div>
                      <div className="col-2">
                        <div className="one-line">{item.email}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 d-lg-none">
                    <div className="d-flex h-100 align-items-center">
                      <div className="mobile-event-block w-100 d-block d-lg-none">
                        <div className="info mb-2">
                          <div>
                            <strong>
                              <a href="#">
                                {item.orderId} {item.firstName} {item.lastName}
                              </a>
                            </strong>
                            <div>Order Date: {item.orderData}</div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="info">
                            <span className="heading d-block">Ticket Type:</span>
                            <div className="one-line">{item.ticketType}</div>
                          </div>
                          <div className="info">
                            <span className="heading d-block">Email:</span>
                            <div className="one-line">{item.email}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <p className="text-center pt-5"> No Data Found</p>
        )}

{attendeeReportData && totalCount > limit ? (
        <div className="mt-3" style={{ float: 'right' }}>
          <PaginatioonComponent
            pageCount={
              totalCount % limit === 0
                ? Math.floor(totalCount / limit)
                : Math.floor(totalCount / limit) + 1
            }
            onPageChange={handlePagination}
            pageNo={pageNo}
          />
        </div>
      ) : (
        ''
      )}
      </div>
    </>
  );
}
