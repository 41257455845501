import React from 'react';

const WeTextArea = ({ ...props }) => {
  const {
    innerRef,
    isRequired,
    onChange,
    maxRows,
    value,
    inputLabel,
    placeholder,
    formError,
    defaultValue,
    onKeyPress,
    minLength,
    maxLength,
    onBlur,
    className,
    autoFocus
  } = props;
  return (
    <>
      <div className={className ? className : 'input-block'}>
        <label htmlFor="keywordSearchTags" className="form-label">
          {inputLabel}
          <span style={{ color: 'red' }}>{isRequired ? '*' : ''}</span>
        </label>
        <textarea
          onBlur={onBlur}
          ref={innerRef}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholder}
          className="form-control"
          autoFocus={autoFocus}
          rows={maxRows}
          value={value}
          defaultValue={defaultValue}
          onKeyUp={onKeyPress}
          onChange={(e) => {
            let val = e.target.value;
            onChange(val);
          }}></textarea>
      </div>
      {formError && <div style={{ color: 'red' }}>{formError}</div>}
    </>
  );
};
export default React.memo(WeTextArea);
