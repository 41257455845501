import React from 'react';
import { useSelector } from 'react-redux';

function EventSideHeader(props) {
  const { subMenuActive } = props;
  const { eventDetailData, eventTitle } = useSelector((state) => state.event);

  return (
    <div className="row pt-2 pt-lg-1 pb-2 pb-lg-2">
      <div className="col-10 col-lg-12 d-flex d-lg-block align-items-center">
        <span className="d-none d-lg-inline-block">Event Name:</span>
        <p className="event-title" style={{ wordWrap: 'break-word' }}>
          {eventTitle ? eventTitle : eventDetailData && eventDetailData.title}
        </p>
      </div>
      <div className="col-2 col-lg-12 d-flex justify-content-end d-lg-none align-items-center">
        <div className="eventMenu" onClick={subMenuActive}>
          <i className="fa-solid fa-grid-2"></i>
        </div>
      </div>
    </div>
  );
}

export default EventSideHeader;
