import React, { useEffect, useState } from 'react';
import RoleList from './teamSubComponent/roleList';
import TeamList from './teamSubComponent/teamList';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAllInvites, getRoleAndTeamDeatils } from 'store/slice/options';
// import InvitationList from './teamSubComponent/invitationList';
import PageForNoPermission from 'pages/pageForNoPermission';

const Team = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  // const { invitesData } = useSelector((state) => state.options);
  const { userPermissionData, isOwnerValue } = useSelector((state) => state.event);
  const { teamMemberList,totalCount } = useSelector((state) => state.options);
  let page = 1;
  let limit = 5;
  const [isFetching, setIsFetching] = useState(false);
  const [pageNo, setPageNo] = useState(page);
  useEffect(() => {
    getRoleAndTeamDetails();
  }, []);

  const handlePagination = (data) => {
    // const page = flag == 'next' ? pageNo + 1 : pageNo - 1;
    console.log('onPageChange', data.selected + 1);
    setPageNo(data.selected + 1);
  };

  useEffect(() => {
    getRoleAndTeamDetails();
  }, [pageNo]);

  const getRoleAndTeamDetails = () => {
    let data = {
      eventUid: id,
      page: pageNo,
      perPage: limit
    };
    dispatch(getRoleAndTeamDeatils(data));
    dispatch(getAllInvites(data));
  };

  useEffect(() => {
    if (teamMemberList) {
      setIsFetching(false);
    }
  }, [teamMemberList]);

  return isOwnerValue || userPermissionData?.team?.manageTeam ? (
    <>
      <h2>Event Team</h2>
      <div className="row">
        <TeamList
          isFetching={isFetching}
          handlePagination={handlePagination}
          totalData={totalCount}
          limit={limit}
          pageNo={pageNo}
        />
        <div className="col-12 col-lg-1"></div>
        <RoleList />
      </div>
      {/* {!invitesData.length == 0 ? (
        <div className="row mt-5">
          <InvitationList invitesData={invitesData} />
        </div>
      ) : (
        ''
      )} */}
    </>
  ) : (
    <PageForNoPermission />
  );
};

export default Team;
