import WeButton from 'components/button';
import AddPayout from 'components/dialogs/addPayout';
import ConfirmDialog from 'components/dialogs/confirmDialog';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { deleteStripeBankAccount, getStripeBankAccount } from 'store/slice/payment';

const PaymentMethods = () => {
  const [showAddPayoutDialog, setshowAddPayoutDialog] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { stripeBankAccountList } = useSelector((state) => state.payment);
  const [bankAccountId, setBankAccountId] = useState();
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  useEffect(() => {
    let data = {
      eventUid: id
    };
    dispatch(getStripeBankAccount(data));
  }, []);
  const handleDelete = () => {
    let requestParam = {
      bankAccountId: bankAccountId
    };
    dispatch(deleteStripeBankAccount(requestParam, bankAccountId));
    setShowConfirmDeleteDialog(false);
  };
  return (
    <>
      <h2>Payout Method</h2>
      {/* <h1 className="notes">OPTIONS HAVENT BEEN EDITED</h1> */}
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>
      <WeButton
        className="mt-4"
        text="Add Payout Method"
        onClick={() => setshowAddPayoutDialog(true)}
      />
      <hr />
      <h4>Payouts being sent to:</h4>
      {stripeBankAccountList?.data.map((data) => {
        return (
          <>
            <ConfirmDialog
              message={'Are you sure, you want to remove this account?'}
              showDialog={showConfirmDeleteDialog}
              onDialogClose={() => {
                setShowConfirmDeleteDialog(false);
              }}
              btnConfirm={handleDelete}
            />
            <div className="icon d-flex ms-auto mt-2" style={{ justifyContent: 'space-between' }}>
              <div>
                <strong>{stripeBankAccountList?.eventName}</strong>
                <br />
                Bank Name: {data?.bank_name} <br />
                Account Number: ********{data?.last4}
              </div>
              <span
                className="d-block"
                style={{ color: 'red' }}
                onClick={() => {
                  setBankAccountId(data.id);
                  setShowConfirmDeleteDialog(true);
                }}>
                <i className="fa-solid fa-trash-can"></i>
              </span>
            </div>
          </>
        );
      })}
      <AddPayout
        showDialog={showAddPayoutDialog}
        onDialogClose={() => setshowAddPayoutDialog(false)}
        onSubmit={(data) => console.log('data ==', data)}
      />
    </>
  );
};

export default PaymentMethods;
