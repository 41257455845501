/* eslint-disable no-unused-vars */
import WeButton from 'components/button';
import WeTextArea from 'components/weTextArea';
import React from 'react';

const TextContainer = ({ ...props }) => {
  const { itemData, btnDeleteItem, onChangeText, value } = props;
  return (
    <>
      <div className="summary-block">
        <div className="tools">
          {/* <div className="icon">
            <i className="fa-solid fa-up-down-left-right"></i>
          </div> */}
          <div
            className="icon"
            onClick={() => {
              btnDeleteItem(itemData);
            }}>
            <i className="fa-solid fa-trash-can"></i>
          </div>
        </div>
        <WeTextArea maxRows={5} onChange={onChangeText} inputLabel="Add Text" value={value} />
        {itemData.isRequired && (
          <div style={{ color: 'red', padding: 5 }}>
            Please enter text value or delete this item.
          </div>
        )}
      </div>
    </>
  );
};

export default TextContainer;
