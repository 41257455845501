import { createSlice } from '@reduxjs/toolkit';
import api from '../../services';
import { TESLOOMAPI, API_RESPONSE } from '../../services/apiConstant';

import { toast } from 'react-toastify';

const slice = createSlice({
  name: 'tickets',
  initialState: {
    listAllTicketsData: null,
    groupItemData: null,
    ticketsItemData: null,
    viewTicketData: null,
    addTicketInGroupData: null,
    message: '',
    ticketItem: null,
    groupTicket: null,
    groupTicketId: null,
    EmailData: null,
    CouponData: null,
    scheduledEmailData: [],
    sentEmailData: [],
    ticketListData: null,
    allTicketsSuccess: null,
    eventCapacityData: null,
    ticketData: null,
    getEventOrdersData: null,
    attendeesData: null,
    addAttendeeTicketData: [],
    errorData: [],
    attendeeTicketData: [],
    totalPrice: 0,
    ticketArray: [],
    attendeesTicketData: null,
    hour: [],
    isFetching: false,
    min: [],
    codeData: [],
    totalFair: 0,
    isCouponDataFetching: false,
    totalData:0,
    totalCount1:0
  },
  reducers: {
    groupTicketDataSuccess(state, action) {
      state.groupTicket = action.payload.tickets;
      state.groupTicketId = action.payload.id;
    },

    groupItemDataSuccess(state, action) {
      state.groupItemData = action.payload;
    },
    ticketItemDataSuccess(state, action) {
      state.ticketsItemData = action.payload;
    },
    eventCapacitySuccess(state, action) {
      state.eventCapacityData = action.payload;
    },
    allTicketsSuccess(state, action) {
      state.allTicketsSuccess = action.payload.ticketData;
    },
    viewTicketSuccess(state, action) {
      state.viewTicketData = action.payload;
    },
    deleteTicketSuccess(state, action) {
      state.eventCapacityData = action.payload.eventCapacity;
      const newObjId = action.payload.deletedTicket.ticketId;
      const newObjId1 = state.groupTicketId;
      let groupId = action.payload.deletedTicket.groupId;
      state.ticketListData = state.ticketListData.filter((item) => item._id !== newObjId);
      if (!groupId) {
        state.ticketsItemData = state.ticketsItemData.filter((item) => item._id !== newObjId);
      } else {
        let suspendIndex0 = state.groupItemData?.findIndex((item1) => item1.id === newObjId1);
        state.groupItemData[suspendIndex0].tickets = state.groupItemData[
          suspendIndex0
        ].tickets.filter((item) => item._id !== newObjId);
      }
    },
    deleteGroupSuccess(state, action) {
      let reqData = action.payload.reqData;
      state.groupItemData = state.groupItemData.filter((item) => item.id !== reqData);
    },
    addTicketInGroupSuccess(state, action) {
      state.addTicketInGroupData = action.payload;
    },
    addTicketSuccess(state, action) {
      let groupId = action.payload.data.groupId;
      state.ticketListData = state.ticketListData.concat(action.payload.data);
      state.eventCapacityData = action.payload.eventCapacity;
      if (!groupId) {
        state.ticketsItemData = state.ticketsItemData.concat(action.payload.data);
      } else {
        let suspendIndex0 = state.groupItemData?.findIndex((item) => item.id === groupId);
        state.groupItemData[suspendIndex0].tickets = state.groupItemData[
          suspendIndex0
        ].tickets.concat(action.payload.data);
      }
    },
    createTicketGroupSuccess(state, action) {
      state.groupItemData = [...state.groupItemData, action.payload];
    },
    selectTicketData(state, action) {
      state.ticketItem = action.payload;
    },
    attendeeEmailData(state, action) {
      state.EmailData = action.payload;
    },
    emailLists(state, action) {
      state.EmailData = action.payload;
    },
    applyCouponData(state, action) {
      state.CouponData = action.payload;
    },
    scheduledEmailDataSuccess(state, action) {
      // state.scheduledEmailData = action.payload;
      if (action.payload == null) {
        state.scheduledEmailData = [];
        state.isFetching = false;
        state.isLoading = false;
      } else {
        state.scheduledEmailData = action.payload.data;
        state.isFetching = false;
        state.isLoading = false;
        state.totalData = action.payload ? action.payload.totalCount : 0;
      }
    },
    addScheduledEmailSuccess(state, action) {
      state.scheduledEmailData = state.scheduledEmailData.concat(action.payload);
    },
    sentEmailDataSuccess(state, action) {
      // state.sentEmailData = action.payload;
      if (action.payload == null) {
        state.sentEmailData = [];
        state.isFetching = false;
        state.isLoading = false;
      } else {
        state.sentEmailData = action.payload.data;
        state.isFetching = false;
        state.isLoading = false;
        state.totalData1 = action.payload ? action.payload.totalCount1 : 0;
      }
    },
    updateScheduledEmailSuccess(state, action) {
      const newObjId = action.payload._id;
      if (action.payload.status === 'scheduled') {
        const suspendIndex = state.scheduledEmailData?.findIndex((item) => item._id === newObjId);
        state.scheduledEmailData[suspendIndex] = action.payload;
      } else {
        const suspendIndex = state.sentEmailData?.findIndex((item) => item._id === newObjId);
        state.sentEmailData[suspendIndex] = action.payload;
      }
    },
    duplicateScheduledEmailSuccess(state, action) {
      state.scheduledEmailData = state.scheduledEmailData.concat(action.payload);
    },
    deleteScheduledEmailSuccess(state, action) {
      let newObjId = action.payload.reqData._id;

      if (action.payload.reqData.status === 'scheduled') {
        state.scheduledEmailData = state.scheduledEmailData.filter((item) => item._id !== newObjId);
      } else {
        state.sentEmailData = state.sentEmailData.filter((item) => item._id !== newObjId);
      }
    },

    TicketListDataSuccess(state, action) {
      let result = action.payload;
      state.ticketListData = result;
      let newItem = result?.map((data) => {
        let item = {
          id: data._id,
          quantity: 0,
          unitPrice: data.price,
          ticketItem: data,
          total: 0
        };
        return item;
      });

      state.attendeeTicketData = newItem;
    },
    clearAttendeeListSuceess(state, action) {
      state.EmailData = action.payload;
    },
    editTransferSettingsSuccess(state, action) {
      const newObjId = action.payload._id;
      const suspendIndex = state.ticketListData?.findIndex((item) => item._id === newObjId);
      let currentUser = action.payload;
      state.ticketListData[suspendIndex] = currentUser;
    },
    ticketDataSuccess(state, action) {
      state.ticketData = action.payload;
    },
    getEventOrdersSuccess(state, action) {
      // state.getEventOrdersData = action.payload;
      if (action.payload == null) {
        state.getEventOrdersData = [];
        state.isFetching = false;
        state.isLoading = false;
      } else {
        state.getEventOrdersData = action.payload.data;
        state.isFetching = false;
        state.isLoading = false;
        state.totalData = action.payload ? action.payload.totalCount : 0;
      }
    },
    getAttendeesDataSuccess(state, action) {
      state.attendeesTicketData = action.payload;
      let result = action.payload.ticket;
      let array = [];
      result?.map((data) => {
        let result1 = data.quantity;
        let item = null;
        if (result1 > 0) {
          for (let i = 0; i < result1; i++) {
            item = {
              firstName: '',
              lastName: '',
              email: '',
              ticketId: data.ticketItem.id,
              ticketItem: data?.ticketItem,
              isBuyer: false,
              isNewAttendee: false,
              showAddBtn: false,
              addedAttendee: false,
              firstNameError: '',
              lastNameError: '',
              emailError: ''
            };
            array.push(item);
          }
        }
      });
      state.attendeesData = array;
    },
    createTimeListSuceess(state, action) {
      const value = action.payload;
      let array = [];
      for (let i = value.start; i <= value.end; i++) {
        if (value.flag == 'min' && i < 10) {
          array.push(`0${i}`);
        } else {
          array.push(i);
        }
      }
      if (value.flag == 'hour') {
        state.hour = array;
      } else if (value.flag == 'min') {
        state.min = array;
      }
    },
    ticketTransferSuccess(state, action) {
      state.ticketData = action.payload;
    },
    addAttendeeTicketSuceess(state, action) {
      state.addAttendeeTicketData = state.addAttendeeTicketData.concat(action.payload);
    },
    updateAttendeeTicketSuceess(state, action) {
      const data = action.payload;
      const item = {
        firstName:
          data.type == 'firstName' ? data.value : state.attendeesData[data.index].firstName,
        lastName: data.type == 'lastName' ? data.value : state.attendeesData[data.index].lastName,
        email: data.type == 'email' ? data.value : state.attendeesData[data.index].email,
        ticketId: state.attendeesData[data.index].ticketId,
        ticketItem: state.attendeesData[data.index].ticketItem,
        isBuyer: data.type == 'isBuyer' ? data.value : state.attendeesData[data.index].isBuyer,
        isNewAttendee:
          data.type == 'isNewAttendee' ? data.value : state.attendeesData[data.index].isNewAttendee,
        showAddBtn:
          data.type == 'showAddBtn' ? data.value : state.attendeesData[data.index].showAddBtn,
        addedAttendee:
          data.type == 'addedAttendee' ? data.value : state.attendeesData[data.index].addedAttendee,
        firstNameError:
          data.type == 'firstNameError'
            ? data.value
            : state.attendeesData[data.index].firstNameError,
        lastNameError:
          data.type == 'lastNameError' ? data.value : state.attendeesData[data.index].lastNameError,
        emailError:
          data.type == 'emailError' ? data.value : state.attendeesData[data.index].emailError
      };
      state.attendeesData[data.index] = item;
    },
    buildErrorDataSuceess(state, action) {
      state.errorData = state.errorData.concat(action.payload);
    },
    updateErrorDataSuceess(state, action) {
      state.errorData[action.payload.index] = action.payload.message;
    },
    clearStatesDataSuccess(state) {
      state.eventCapacityData = null;
      state.groupItemData = null;
      state.ticketsItemData = null;
      state.getEventOrdersData = null;
      state.ticketListData = null;
      state.scheduledEmailData = null;
      state.sentEmailData = null;
      state.ticketData = null;
    },
    updateTicketDataSuceess(state, action) {
      console.log("action.payload.totalAmount == ",action.payload.totalAmount);
      if (action.payload.data) {
        state.attendeeTicketData[action.payload.data.index] = action.payload.data.data;
      }
      const total = state.attendeeTicketData.reduce(
        (total, currentItem) => (total = total + currentItem.total),
        0
      );
      state.totalFair = total;
      if (action.payload.totalAmount) {
        state.totalPrice = action.payload.totalAmount.finalAmonnt;
      }
      else {
        state.totalPrice = total;
      }
      state.isCouponDataFetching = false
    },
    clearAttendeeTicketDataSuceess(state) {
      state.attendeeTicketData = [];
      state.attendeesData = null;
      state.codeData = [];
      state.totalFair = 0;
      state.isCouponDataFetching = false;
    },
    addAttendeeInOrderSuceess(state, action) {
      state.ticketArray = state.ticketArray.concat(action.payload);
    },
    removeAttendeeInOrderSuceess(state, action) {
      let reqData = action.payload;
      state.ticketArray = state.ticketArray.filter((item) => item.ticketId !== reqData);
    },
    clearAttendeeInOrderSuceess(state) {
      state.ticketArray = [];
    },
    apiFatchingStartSuccess(state, action) {
      state.isFetching = action.payload;
    },
    handleCodeSuccess(state, action) {
      if (action.payload.type == "add") {
        state.codeData = state.codeData.concat(action.payload.value);
      } else {
        state.codeData = state.codeData.filter((item) => item !== action.payload.value);
      }
      state.CouponData=null;
      state.totalPrice=state.totalFair
    },
    couponDataFatching(state, action) {
      state.isCouponDataFetching = action.payload;
    },
  }
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

const {
  groupItemDataSuccess,
  ticketItemDataSuccess,
  viewTicketSuccess,
  deleteTicketSuccess,
  deleteGroupSuccess,
  createTicketGroupSuccess,
  selectTicketData,
  groupTicketDataSuccess,
  attendeeEmailData,
  applyCouponData,
  scheduledEmailDataSuccess,
  addScheduledEmailSuccess,
  sentEmailDataSuccess,
  updateScheduledEmailSuccess,
  duplicateScheduledEmailSuccess,
  deleteScheduledEmailSuccess,
  TicketListDataSuccess,
  eventCapacitySuccess,
  clearAttendeeListSuceess,
  editTransferSettingsSuccess,
  ticketDataSuccess,
  getEventOrdersSuccess,
  getAttendeesDataSuccess,
  ticketTransferSuccess,
  addAttendeeTicketSuceess,
  updateAttendeeTicketSuceess,
  buildErrorDataSuceess,
  updateErrorDataSuceess,
  clearStatesDataSuccess,
  buildTicketDataSuceess,
  updateTicketDataSuceess,
  clearAttendeeTicketDataSuceess,
  addAttendeeInOrderSuceess,
  removeAttendeeInOrderSuceess,
  createTimeListSuceess,
  apiFatchingStartSuccess,
  clearAttendeeInOrderSuceess,
  handleCodeSuccess,
  couponDataFatching
} = slice.actions;

export const createTicketGroup = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.createTicketGroup}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(createTicketGroupSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};
export const addTicket = (requestParams, fetchAllTickets) => async () => {
  api
    .post(`${TESLOOMAPI.addTicket}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        //dispatch(addTicketSuccess(result));
        fetchAllTickets();
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};
export const listAllTickets = (id) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.listAllTickets}`, id)
    .then((response) => {
      let result = response.data;
      if (result.status == API_RESPONSE.SUCCESS) {
        dispatch(eventCapacitySuccess(result.eventCapacity));
        result.groupData.map((item) => {
          var ticketData1 = item;
          dispatch(groupTicketDataSuccess(ticketData1));
        });
        dispatch(groupItemDataSuccess(result.groupData));
        dispatch(ticketItemDataSuccess(result.ticketData));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const deleteGroup = (id) => async (dispatch) => {
  api
    .delete(`${TESLOOMAPI.deleteGroup}` + id)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(deleteGroupSuccess({ reqData: id }));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const deleteTicket = (request) => async (dispatch) => {
  api
    .delete(`${TESLOOMAPI.deleteTicket}` + request.ticketId)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(
          deleteTicketSuccess({
            deletedTicket: request,
            eventCapacity: response.data.eventCapacity
          })
        );
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const viewTicket = (id) => async (dispatch) => {
  api
    .get(`${TESLOOMAPI.viewTicket}` + id)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(viewTicketSuccess(result));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const addTicketInGroup = (requestData, fetchAllTickets) => async () => {
  api
    .post(`${TESLOOMAPI.addTicketInGroup}`, requestData)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        fetchAllTickets();
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const editTicket = (requestParams, fetchAllTickets) => async () => {
  api
    .post(`${TESLOOMAPI.addTicket}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        // dispatch(editTicketSuccess(result));
        fetchAllTickets();
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const selectedTicketData = (data) => async (dispatch) => {
  dispatch(selectTicketData(data));
};

export const clearTicketData = () => async (dispatch) => {
  dispatch(selectTicketData());
};

export const searchAttendee = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.searchAttendee}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(attendeeEmailData(result.data));
      } else {
        dispatch(attendeeEmailData(null));
        toast.error(result.message);
      }
    })
    .catch(() => { });
};
export const clearAttendeeEmailList = (data) => async (dispatch) => {
  dispatch(clearAttendeeListSuceess(data));
};
export const applyCoupon = (requestParams) => async (dispatch) => {
  dispatch(couponDataFatching(true));
  api
    .post(`${TESLOOMAPI.applyCoupon}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status == 1) {
        dispatch(applyCouponData(result.data));
        dispatch(updateTicketDataSuceess({ data: '', totalAmount: result.data }));
      } else {
        toast.error(result.message, {
          toastId: 'videoError'
        });
        dispatch(couponDataFatching(false));
        dispatch(applyCouponData());
        dispatch(updateTicketDataSuceess({ data: '', totalAmount: 0}));
      }
    })
    .catch(() => { });
};
export const createReminder = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.createReminder}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(addScheduledEmailSuccess(result.data));
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};
export const updateReminder = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.updateReminder}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(updateScheduledEmailSuccess(result.data));
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};
export const duplicateReminder = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.duplicateReminder}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(duplicateScheduledEmailSuccess(result.data));
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};
export const deleteReminder = (data) => async (dispatch) => {
  api
    .delete(`${TESLOOMAPI.deleteReminder}/` + data._id)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(deleteScheduledEmailSuccess({ reqData: data }));
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const scheduledEmailList = (page, limit, requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.scheduledEmailList}?page=${page}&limit=${limit}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        // dispatch(scheduledEmailDataSuccess(result.data));
        dispatch(
          scheduledEmailDataSuccess({
            data: result.data,
            page: requestParams.page,
            totalCount: result.count
          })
        );
      } else {
        dispatch(
          scheduledEmailDataSuccess(null)
        );
        // toast.error(result.message);
      }
    })
    .catch(() => { });
};
export const sentEmailList = (page, limit, requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.sentEmailList}?page=${page}&limit=${limit}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        // dispatch(sentEmailDataSuccess(result.data));
        dispatch(
          sentEmailDataSuccess({
            data: result.data,
            page: requestParams.page,
            totalCount: result.count
          })
        );
      } else {
        // toast.error(result.message);
        dispatch(
          sentEmailDataSuccess(null)
        );
      }
    })
    .catch(() => { });
};
export const allTicketList = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.allTickets}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(apiFatchingStartSuccess(false));
        dispatch(ticketDataSuccess(result));
        dispatch(TicketListDataSuccess(result.ticketData));
      } else {
        // toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const editTransferSettings = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.editTicketTransferForTicket}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(editTransferSettingsSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const editTicketTransferForAllTicket = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.editTicketTransferForAllTicket}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(TicketListDataSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const getEventOrders = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.getEventOrders}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status == API_RESPONSE.SUCCESS) {
        dispatch(apiFatchingStartSuccess(false));
        // dispatch(getEventOrdersSuccess(result.data));
        dispatch(
          getEventOrdersSuccess({
            data: result.data,
            page: requestParams.page,
            totalCount: result.totalCount
          })
        );
      } else {
        dispatch(getEventOrdersSuccess(null));
        // toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const sendTestEmailRemainder = (requestParams) => async () => {
  api
    .post(`${TESLOOMAPI.sendTestEmailRemainder}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};
export const getAttendeesData = (data) => async (dispatch) => {
  dispatch(getAttendeesDataSuccess(data));
};

export const editTicketTransferForEvent = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.editTicketTransferForEvent}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message, {
          toastId: 'videoError'
        });
        dispatch(ticketTransferSuccess(result.data));
      } else {
        toast.error('Error', result.message, {
          toastId: 'videoError'
        });
      }
    })
    .catch(() => { });
};

export const addAttendeeTicket = (data) => async (dispatch) => {
  dispatch(addAttendeeTicketSuceess(data));
};

export const updateAttendeeTicket = (data) => async (dispatch) => {
  dispatch(updateAttendeeTicketSuceess(data));
};

export const buildErrorData = (data) => async (dispatch) => {
  dispatch(buildErrorDataSuceess(data));
};

export const updateErrorData = (data) => async (dispatch) => {
  dispatch(updateErrorDataSuceess(data));
};
export const clearticketStates = () => async (dispatch) => {
  dispatch(clearStatesDataSuccess());
};

export const buildTicketData = (data) => async (dispatch) => {
  dispatch(buildTicketDataSuceess(data));
};

export const updateTicketData = (data) => async (dispatch) => {
  dispatch(updateTicketDataSuceess({ data: data, totalAmount: 0 }));
};

export const clearAttendeeTicketData = (data) => async (dispatch) => {
  dispatch(clearAttendeeTicketDataSuceess(data));
};

export const addAttendeeInOrder = (data) => async (dispatch) => {
  dispatch(addAttendeeInOrderSuceess(data));
};
export const removeAttendeeInOrder = (data) => async (dispatch) => {
  dispatch(removeAttendeeInOrderSuceess(data));
};

export const checkIfAttendeeExists =
  (requestParams, data, ticketName, addAttendeeInTheList, index, isBuyer) => async () => {
    api
      .post(`${TESLOOMAPI.checkIfAttendeeExists}`, requestParams)
      .then((response) => {
        let result = response.data;
        if (result.status) {
          if (result.isExists) {
            toast.error(`You have already added ${data.email} for ${ticketName} ticket`);
          } else {
            addAttendeeInTheList(data, index, isBuyer);
          }
        } else {
          toast.error(result.message);
        }
      })
      .catch(() => { });
  };

export const createTimeList = (value) => async (dispatch) => {
  dispatch(createTimeListSuceess(value));
};

export const apiTicketFatchingStart = (status) => async (dispatch) => {
  dispatch(apiFatchingStartSuccess(status));
};

export const clearAttendeeInOrder = () => async (dispatch) => {
  dispatch(clearAttendeeInOrderSuceess());
}

export const handleCode = (data) => async (dispatch) => {
  dispatch(handleCodeSuccess(data));
}