import moment from 'moment';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const EntryDetailDialog = ({ ...props }) => {
  const { showDialog, onDialogClose, btnConfirm, data, btnDelete } = props;
  
  
  return (
    <Modal show={showDialog} centered>
      <Modal.Header>
        <Modal.Title>
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5"> Entry Details </h1>
            <a
              className="close closeBtn"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onDialogClose}>
              <i className="fa-solid fa-xmark"></i>
            </a>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row">
          <div className="col-6 py-3">Title</div>
          <div className="col-6 py-3">
            <span style={{ float: 'right' }}>{data?.eventTitle}</span>
          </div>
          <div className="col-6 py-3">Room</div>
          <div className="col-6 py-3">
            <span style={{ float: 'right' }}> {data?.roomName}</span>
          </div>
          <div className="col-6 py-3">Timing</div>
          <div className="col-6 py-3">
            <span style={{ float: 'right' }}>
              {data?.allDay ? 'All Day' : <>
                {moment(data?.startTime).format('MMM DD, YYYY')}
                {moment(data?.startTime).format(' hh:mm A ')}-
                <br /> {moment(data?.endTime).format('MMM DD, YYYY')}
                {moment(data?.endTime).format(' hh:mm A ')}
              </>}
            </span>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          //   style={{ backgroundColor: 'blue', color: 'white' }}
          className="btn primary-btn"
          variant="primary"
          onClick={btnConfirm}>
          Edit
        </Button>
        <Button
          //   style={{ backgroundColor: 'red' }}
          className="btn primary-btn"
          onClick={btnDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(EntryDetailDialog);
