import PageForNoPermission from 'pages/pageForNoPermission';
import React from 'react';
import { useSelector } from 'react-redux';

const PaymentOptions = () => {
  const { isOwnerValue } = useSelector((state) => state.event);
  return isOwnerValue ? (
    <>
      <h2>Payment Options</h2>
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>
      <div className="row">
        <div className="col-12 col-xxl-9">
          <div className="pricing-container">
            <div className="pricing-grid">
              <div className="info-row">
                <div className="row">
                  <div className="col-4 col-md-6 col-xl-7 pe-2">
                    <div className="info">Cost per transaction</div>
                  </div>
                  <div className="col-8 col-md-6 col-xl-5">
                    <div className="row text-center align-items-center h-100">
                      <div className="col-6">2.9%</div>
                      <div className="col-6">3.9%</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-row">
                <div className="row">
                  <div className="col-4 col-md-6 col-xl-7 pe-2">
                    <div className="info">Fixed fee per transaction</div>
                  </div>
                  <div className="col-8 col-md-6 col-xl-5">
                    <div className="row text-center align-items-center h-100">
                      <div className="col-6">
                        <i className="fa-solid fa-check"></i>
                      </div>
                      <div className="col-6">
                        <i className="fa-solid fa-x"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-row">
                <div className="row">
                  <div className="col-4 col-md-6 col-xl-7 pe-2">
                    <div className="info">PayPal checkout</div>
                  </div>
                  <div className="col-8 col-md-6 col-xl-5">
                    <div className="row text-center align-items-center h-100">
                      <div className="col-6">
                        <i className="fa-solid fa-check"></i>
                      </div>
                      <div className="col-6">
                        <i className="fa-solid fa-check"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-row">
                <div className="row">
                  <div className="col-4 col-md-6 col-xl-7 pe-2">
                    <div className="info">Apple Pay and Google Pay Checkout</div>
                  </div>
                  <div className="col-8 col-md-6 col-xl-5">
                    <div className="row text-center align-items-center h-100">
                      <div className="col-6">
                        <i className="fa-solid fa-check"></i>
                      </div>
                      <div className="col-6">
                        <i className="fa-solid fa-x"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-row">
                <div className="row">
                  <div className="col-4 col-md-6 col-xl-7 pe-2">
                    <div className="info">Schedule and manage payouts</div>
                  </div>
                  <div className="col-8 col-md-6 col-xl-5">
                    <div className="row text-center align-items-center h-100">
                      <div className="col-6">
                        <i className="fa-solid fa-check"></i>
                      </div>
                      <div className="col-6">
                        <i className="fa-solid fa-check"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-row">
                <div className="row">
                  <div className="col-4 col-md-6 col-xl-7 pe-2">
                    <div className="info">Assistance w/ chargebacks and disputes</div>
                  </div>
                  <div className="col-8 col-md-6 col-xl-5">
                    <div className="row text-center align-items-center h-100">
                      <div className="col-6">
                        <i className="fa-solid fa-check"></i>
                      </div>
                      <div className="col-6">
                        <i className="fa-solid fa-x"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-row">
                <div className="row">
                  <div className="col-4 col-md-6 col-xl-7 pe-2">
                    <div className="info">Mobile app for your event</div>
                  </div>
                  <div className="col-8 col-md-6 col-xl-5">
                    <div className="row text-center align-items-center h-100">
                      <div className="col-6">
                        <i className="fa-solid fa-check"></i>
                      </div>
                      <div className="col-6">
                        <i className="fa-solid fa-x"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-options">
              <div className="row h-100">
                <div className="col-4 col-md-6 col-xl-7"></div>
                <div className="col-8 col-md-6 col-xl-5">
                  <div className="row h-100">
                    <div className="col-6">
                      <div className="option selected">
                        <div className="title">Tesloom</div>
                        <div className="bottom"></div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="option option-paypal">
                        <div className="title">PayPal</div>
                        <div className="bottom"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form action=""></form>
    </>
  ) : (
    <PageForNoPermission />
  );
};

export default PaymentOptions;
