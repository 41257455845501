import React, { useState } from 'react';
import AddAttendees from './addAttendees';
import AddAttendeesCheckout from '../addAttendeesCheckout';
import { useSelector } from 'react-redux';
import PageForNoPermission from 'pages/pageForNoPermission';
const Attendee = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { userPermissionData, isOwnerValue } = useSelector((state) => state.event);
  const pull_data = () => {
    let newStep = activeStep + 1;
    setActiveStep(newStep);
  };
  const renderStepperView = () => {
    if (activeStep === 0) {
      return <AddAttendees func={pull_data} />;
    } else if (activeStep === 1) {
      return <AddAttendeesCheckout />;
    }
  };
  return isOwnerValue || userPermissionData?.attendee.manageAttendee ? (
    <>
      <div>{renderStepperView()}</div>
    </>
  ) : (
    <PageForNoPermission />
  );
};

export default Attendee;
