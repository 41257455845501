import WeInput from 'components/weInput';
import WeToggle from 'components/weToggle';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { useDispatch } from 'react-redux';
// import { editTransferSettings } from 'store/slice/tickets';

const EditAlltransferDialog = ({ ...props }) => {
  //const dispatch = useDispatch();
  const {
    showDialog,
    onDialogClose,
    editValue,
    handeleSubmit,
    allowTransferChange,
    allowTransferToggle,
    onStandardFee,
    formError
  } = props;
  //   const [ticketTransferValue, setTicketTransferValue] = useState();

  //   useEffect(() => {
  //     setTicketTransferValue((ticketTransferValue) => ({
  //       ...ticketTransferValue,
  //       ...editValue
  //     }));
  //   }, [editValue]);

  //   const handeleSubmit = () => {
  //     dispatch(editTransferSettings(ticketTransferValue));
  //     onDialogClose();
  //   };

  return (
    <Modal show={showDialog} onHide={onDialogClose} centered backdrop="static" keyboard={false}>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5">Edit All Tickets</h1>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Custom transfer settings for all tickets.</p>
        {editValue.editAllType == 1 ? (
          <div className="mt-2">
            <div className="mt-3">
              <WeToggle
                checkToogle={editValue && editValue?.allowTransfer}
                onChange={allowTransferChange}
                onSwitchClick={allowTransferToggle}
                toggleLabel="Allow Transfer"
              />
            </div>
          </div>
        ) : (
          <WeInput
            type={'number'}
            minVal={0}
            onChange={onStandardFee}
            autoFocus={true}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handeleSubmit();
              }
            }}
            prefix={'$'}
            inputLabel="Standard Transfer Fee"
            value={editValue?.transferFee}
            formError={formError?.transferFee}
          />
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn secondary-btn" onClick={onDialogClose}>
          Cancle
        </Button>
        <Button className="btn primary-btn" onClick={handeleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(EditAlltransferDialog);
