/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  eventTypes,
  getHashTagList,
  clearHashTagList,
  timezoneList,
  fetchAddresses,
  getAddressDetail,
  clearAddressList,
  addEvent,
  updateEvent,
  eventDetail,
  updateEventTitle,
  addHastag
} from 'store/slice/event';
import 'react-datepicker/dist/react-datepicker.css';
import '../event.css';
import Autocomplete from 'components/autocomplete';
import WeInput from 'components/weInput';
import WeDropDown from 'components/weDropDown';
import WeToggle from 'components/weToggle';
import EventContext from 'context/eventContext';
import { debounce, isFirefox, todayDate, validateEmpty } from 'utils/appConstant';
import { validateForm } from './eventFormValidation';
import EventHeader from '../eventHeader';
import { useParams } from 'react-router-dom';
import FooterButtons from '../footerButtons';
import { toast } from 'react-toastify';
import PageForNoPermission from 'pages/pageForNoPermission';
import WeMap from 'components/weMap';
import WeTimePicker from 'components/weTimePicker';
import PopOverSpinner from 'components/popOverSpinner';

const BasicInfo = ({ ...props }) => {
  const { eventFormData, setEventFormData } = useContext(EventContext);
  const { onSave, activeStep } = props;
  const dispatch = useDispatch();
  const {
    timezoneData,
    hashTagData,
    eventTypeData,
    addressListData,
    addressDetailData,
    eventCreatedData,
    userPermissionData,
    isOwnerValue,
    isLoading,
    eventDetailData
  } = useSelector((state) => state.event);
  const [autoFocus, setautoFocus] = useState(false);
  const [searchTag, setSearchTag] = useState('');
  const [showEventType, setShowEventType] = useState(false);
  const [showTimeZoneDropdown, setShowTimeZoneDropdown] = useState(false);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);

  const path = window.location.pathname;
  const isCreate = path.includes('create');
  const [formError, setFormError] = useState({});

  const inputRef = useRef({});
  const { id } = useParams();

  const language = [
    {
      label: 'English',
      code: 'en'
    },
    {
      label: 'Spanish',
      code: 'es'
    }
  ];
  const defaultMapLocation = {
    latitude: 35.6600072,
    longitude: -116.9616876,
    zoom: 5,
    transitionDuration: 100
  };
  const [mapLocation, setMapLocation] = useState(defaultMapLocation);
  const mapRef = useRef();

  useEffect(() => {
    if (id) {
      dispatch(eventDetail(id));
    }
  }, [id]);
  useEffect(() => {
    if (eventDetailData) {
      setEventFormData(eventDetailData);
      setMapLocation({
        ...mapLocation,
        latitude: eventDetailData.location.coordinates[1],
        longitude: eventDetailData.location.coordinates[0],
        zoom: 5
      });
    }
  }, [eventDetailData]);
  useEffect(() => {
    if (addressDetailData) {
      let coordinates = addressDetailData.geometry.location;
      let geometry = addressDetailData.geometry;
      setMapLocation({
        ...mapLocation,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        zoom: 10
      });
      addressDetailData?.address_components?.map((item) => {
        {
          item.types.map((data) => {
            if (data === 'administrative_area_level_1') {
              setEventFormData((eventFormData) => ({
                ...eventFormData,
                state: item.long_name
              }));
            } else if (data === 'postal_code') {
              setEventFormData((eventFormData) => ({
                ...eventFormData,
                zipcode: item.long_name
              }));
            } else if (data === 'country') {
              setEventFormData((eventFormData) => ({
                ...eventFormData,
                country: item.long_name
              }));
            } else if (data === 'locality') {
              setEventFormData((eventFormData) => ({
                ...eventFormData,
                city: item.long_name
              }));
            } else if (data === 'administrative_area_level_3') {
              setEventFormData((eventFormData) => ({
                ...eventFormData,
                city: item.long_name
              }));
            }
          });
        }
      });
      setEventFormData((eventFormData) => ({
        ...eventFormData,
        location: {
          type: "Point",
          coordinates: [
            coordinates.lng, // longitude
            coordinates.lat // latitude
          ]
        },
        venue: addressDetailData.formatted_address
          ? addressDetailData.formatted_address
          : addressDetailData?.name,
        address: addressDetailData.formatted_address
          ? addressDetailData.formatted_address
          : addressDetailData?.name
      }));
    }
  }, [addressDetailData]);
  useEffect(() => {
    dispatch(timezoneList());
    dispatch(eventTypes());
    setEventFormData((eventFormData) => ({
      ...eventFormData,
      eventType: 'social'
    }));
  }, []);

  const listOfHashTag = (item) => {
    let hashtagValue = item.value.toLowerCase();
    if (item) {
      if (!eventFormData.tags) {
        eventFormData.tags = [];
      }
      if (eventFormData?.tags && eventFormData?.tags.includes(hashtagValue)) {
        setFormError((formError) => ({
          ...formError,
          tags: 'This tag has already been added'
        }));
      } else {
        setEventFormData((eventFormData) => ({
          ...eventFormData,
          tags: [...eventFormData.tags, hashtagValue]
        }));
      }
      setSearchTag('');
    }
  };
  const btnAddTags = () => {
    if (searchTag.trim()) {
      let hashtagValue = searchTag.toLowerCase();
      if (!eventFormData.tags) {
        eventFormData.tags = [];
      }
      if (eventFormData?.tags && eventFormData?.tags.includes(hashtagValue)) {
        setFormError((formError) => ({
          ...formError,
          tags: 'This tag has already been added'
        }));
        dispatch(clearHashTagList());
      } else {
        setEventFormData((eventFormData) => ({
          ...eventFormData,
          tags: [...eventFormData.tags, hashtagValue.trim()]
        }));
        dispatch(
          addHastag({
            hashTag: hashtagValue
          })
        );
      }
      setSearchTag('');
    }
  };

  const removeTags = (item) => {
    if (item) {
      setFormError((formError) => ({
        ...formError,
        tags: ''
      }));
    }
    if (eventFormData.tags) {
      const tagsData = eventFormData.tags.filter((val) => val !== item);
      setEventFormData((eventFormData) => ({
        ...eventFormData,
        tags: tagsData
      }));
      setSearchTag('');
    }
  };

  const btnSaveContinue = () => {
    if (validateForm(setFormError, eventFormData, inputRef)) {
      if (validateEmpty(eventFormData.eventPageLanguage)) {
        eventFormData.eventPageLanguage = language[0]?.label;
      }
      if (
        eventFormData.startDate == eventFormData.endDate &&
        eventFormData.startTime > eventFormData.endTime
      ) {
        return toast.error('Please select valid time');
      }
      //update event
      if (id || eventFormData.eventUid) {
        dispatch(updateEvent(eventFormData));
        if (!id) {
          onSave();
        }
      } else {
        eventFormData.publishType = 'draft';
        dispatch(addEvent(eventFormData, onSave));
      }
    }
  };

  useEffect(() => {
    if (eventCreatedData) {
      //update event form state
      setEventFormData(eventCreatedData);
    }
  }, [eventCreatedData]);

  const searchVenueAddress = (val) => {
    let obj = {
      search: val,
      type: 1
    };
    dispatch(fetchAddresses(obj));
  };
  const handleSearchAddress = useCallback(debounce(searchVenueAddress, 300), '');

  const searchKeywordsFromDB = (val) => {
    var data = {
      search: val
    };
    dispatch(getHashTagList(data));
  };

  const updateTitle = (val) => {
    if (val) {
      setFormError((formError) => ({
        ...formError,
        title: ''
      }));
      setEventFormData((eventFormData) => ({
        ...eventFormData,
        title: val
      }));
    } else {
      setEventFormData((eventFormData) => ({
        ...eventFormData,
        title: ''
      }));
    }
    dispatch(updateEventTitle(val));
  };

  const handleHashTagChange = useCallback(debounce(searchKeywordsFromDB, 300), '');

  return isCreate || isOwnerValue || userPermissionData?.event.manageEvent ? (
    <>
      <EventHeader
        title="Event Info"
        subTitle="Name your event and highlight other information to attract attendees why they can join
       this event"
      />

      <WeInput
        innerRef={(el) => (inputRef.current['inputTitleRef'] = el)}
        isRequired
        maxLength={75}
        // onChange={(val) => {
        //   if (val) {
        //     setFormError((formError) => ({
        //       ...formError,
        //       title: ''
        //     }));
        //     setEventFormData((eventFormData) => ({
        //       ...eventFormData,
        //       title: val
        //     }));
        //   } else {
        //     setEventFormData((eventFormData) => ({
        //       ...eventFormData,
        //       title: ''
        //     }));
        //   }
        //   dispatch(updateEventTitle(val));
        // }}
        onChange={updateTitle}
        inputLabel="Event Title"
        formError={formError.title}
        value={eventFormData && eventFormData.title}
      />

      {/* <WeDropDown
        innerRef={(el) => (inputRef.current['eventTypeRef'] = el)}
        label="Event Type"
        isRequired
        openCloseDropdown={showEventType}
        onDropDownClick={() => setShowEventType(!showEventType)}
        btnWidth={true}
        selectData={
          eventTypeData &&
          eventTypeData?.map((item) => ({
            label: item.eventType,
            value: item._id
          }))
        }
        defaultValue={eventFormData && eventFormData.eventType ? eventFormData.eventType : ''}
        emptyValue="Select a Event Type"
        onSelectItem={(item) => {
          setEventFormData((eventFormData) => ({
            ...eventFormData,
            eventType: item //eventTypeData[item].eventType
          }));
          setShowEventType(false);
          setFormError((formError) => ({
            ...formError,
            eventType: ''
          }));
        }}
        formError={formError.eventType}
      /> */}

      <hr />
      <h3>
        Tags
        {/* ({eventFormData && eventFormData.tags? eventFormData.tags.length:0 }/{hashTagData && hashTagData?.length}) */}
      </h3>
      <p>Add hashtags to your event</p>

      <Autocomplete
        innerRef={(el) => (inputRef.current['hashTagRef'] = el)}
        inputLabel="Keyword Search Tags"
        autoFocus={autoFocus ? 'autoFocus' : ''}
        placeholder="ex. Soccer, Party, Bar."
        onSearchChange={(value) => {
          const val = value.replace(/[&/\\#, `!@^_.=[|;+()$~%.'":*?<>{}]/g, '').replace('-', '');
          if (val) {
            setFormError((formError) => ({
              ...formError,
              tags: ''
            }));
          }
          setautoFocus(true);
          setSearchTag(val.trim());
          handleHashTagChange(val);
        }}
        clearValue={() => {
          setSearchTag('');
        }}
        value={searchTag}
        searchData={hashTagData}
        onItemSelected={(item) => {
          listOfHashTag(item);
          dispatch(clearHashTagList());
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            btnAddTags();
          }
        }}
        formError={formError.tags}
      />

      <button className="btn primary-btn mt-2" onClick={btnAddTags}>
        Add Tag
      </button>

      <div className="pills">
        {eventFormData &&
          eventFormData.tags &&
          eventFormData.tags.length > 0 &&
          eventFormData?.tags.map((val, index) => {
            return (
              <>
                <div className="pill alert alert-dismissible" key={index}>
                  <span className="text">{val}</span>
                  <a
                    href="#a"
                    className="mark-close"
                    aria-label="Close"
                    onClick={() => {
                      removeTags(val);
                    }}>
                    <i className="fa-solid fa-xmark"></i>
                  </a>
                  {/* <a
                    className="mark-close"
                    aria-label="Close"
                    onClick={() => {
                      removeTags(val);
                    }}>
                    <i className="fa-solid fa-xmark"></i>
                  </a> */}
                </div>
              </>
            );
          })}
      </div>
      <hr />

      <h2>Location</h2>
      <p>Help people in the area discover your event and let attendees know where to show up</p>

      <div style={{ height: '50vh' }}>
        {mapLocation.latitude && mapLocation.longitude && (
          <WeMap mapData={mapLocation} innerRef={mapRef} />
        )}
      </div>

      <Autocomplete
        isRequired
        autoFocus={autoFocus ? 'autoFocus' : ''}
        innerRef={(el) => (inputRef.current['addressRef'] = el)}
        inputLabel="Venue Location"
        noBorder={true}
        onSearchChange={(val) => {
          setautoFocus(true);
          setEventFormData((eventFormData) => ({
            ...eventFormData,
            venue: val
          }));

          handleSearchAddress(val);
        }}
        onChange={(value) => {
          console.log(value);
        }}
        value={eventFormData && eventFormData.venue}
        searchData={
          addressListData &&
          addressListData?.map((item) => ({
            value: item?.description,
            id: item.place_id
          }))
        }
        clearValue={() => {
          setEventFormData((eventFormData) => ({
            ...eventFormData,
            venue: ''
          }));
          dispatch(clearAddressList());
        }}
        onItemSelected={(item) => {
          dispatch(getAddressDetail({ mapBoxId: item?.id }));
          dispatch(clearAddressList());
          setEventFormData((eventFormData) => ({
            ...eventFormData,
            venue: item?.value
          }));
          inputRef.current['addressRef'].value = '';
        }}
        formError={formError.venue}
      />

      <div style={{ marginTop: '2em' }}>
        <h5>Street Address</h5>
      </div>
      <div>
        <div className="row">
          <div className="col-12 col-md-6">
            <WeInput
              innerRef={(el) => (inputRef.current['streetAddressRef'] = el)}
              onChange={(val) => {
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    address: ''
                  }));
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    address: val
                  }));
                } else {
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    address: ''
                  }));
                }
              }}
              inputLabel="Venue Address"
              isRequired={true}
              formError={formError.address}
              value={eventFormData && eventFormData.address}
            />
          </div>
          <div className="col-12 col-md-6">
            <WeInput
              innerRef={(el) => (inputRef.current['cityRef'] = el)}
              value={eventFormData && eventFormData.city}
              onChange={(val) => {
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    city: ''
                  }));
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    city: val
                  }));
                } else {
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    city: ''
                  }));
                }
              }}
              inputLabel="City"
              isRequired={true}
              formError={formError.city}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <WeInput
              innerRef={(el) => (inputRef.current['stateRef'] = el)}
              value={eventFormData && eventFormData.state}
              onChange={(val) => {
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    state: ''
                  }));
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    state: val
                  }));
                } else {
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    state: ''
                  }));
                }
              }}
              inputLabel="State"
              isRequired={true}
              formError={formError.state}
            />
          </div>
          <div className="col-12 col-md-6">
            <WeInput
              innerRef={(el) => (inputRef.current['zipCodeRef'] = el)}
              value={eventFormData && eventFormData.zipcode}
              onChange={(val) => {
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    zipcode: ''
                  }));
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    zipcode: val
                  }));
                } else {
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    zipcode: ''
                  }));
                }
              }}
              inputLabel="ZipCode"
              isRequired={true}
              formError={formError.zipcode}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <WeInput
              innerRef={(el) => (inputRef.current['countryRef'] = el)}
              value={eventFormData && eventFormData.country}
              onChange={(val) => {
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    country: ''
                  }));
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    country: val
                  }));
                } else {
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    country: ''
                  }));
                }
              }}
              inputLabel="Country"
              isRequired={true}
              formError={formError.state}
            />
          </div>
        </div>
      </div>

      <hr />

      <h2>Date and time</h2>
      <p>Set your event Date and time</p>

      <div className="row">
        <div className="col-12 col-md-6">
          <WeInput
            innerRef={(el) => (inputRef.current['startDateRef'] = el)}
            type="date"
            minVal={todayDate()}
            maxVal={eventFormData.endDate && eventFormData.endDate}
            onChange={(val) => {
              if (val) {
                setFormError((formError) => ({
                  ...formError,
                  startDate: ''
                }));
                setEventFormData((eventFormData) => ({
                  ...eventFormData,
                  startDate: val
                }));
              } else {
                setFormError((formError) => ({
                  ...formError,
                  startDate: 'Please select event start date.'
                }));
              }
            }}
            inputLabel="Start Date"
            formError={formError.startDate}
            value={eventFormData && eventFormData?.startDate}
          />
        </div>
        <div className="col-12 col-md-6">
          {isFirefox ?
            <WeTimePicker
              innerRef={(el) => (inputRef.current['startTimeRef'] = el)}
              type="time"
              onChange={(val) => {
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    startTime: ''
                  }));
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    startTime: val
                  }));
                } else {
                  setFormError((formError) => ({
                    ...formError,
                    startTime: 'Please select event start time.'
                  }));
                }
              }}
              inputLabel="Start Time"
              formError={formError.startTime}
              value={eventFormData && eventFormData.startTime}
            />
            :
            <WeInput
              innerRef={(el) => (inputRef.current['startTimeRef'] = el)}
              type="time"
              onChange={(val) => {
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    startTime: ''
                  }));
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    startTime: val
                  }));
                } else {
                  setFormError((formError) => ({
                    ...formError,
                    startTime: 'Please select event start time.'
                  }));
                }
              }}
              inputLabel="Start Time"
              formError={formError.startTime}
              value={eventFormData && eventFormData.startTime}
            />
          }
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <WeInput
            innerRef={(el) => (inputRef.current['endDateRef'] = el)}
            type="date"
            minVal={eventFormData?.startDate ? eventFormData?.startDate : todayDate()}
            onChange={(val) => {
              if (val) {
                setFormError((formError) => ({
                  ...formError,
                  endDate: ''
                }));
                setEventFormData((eventFormData) => ({
                  ...eventFormData,
                  endDate: val
                }));
              } else {
                setFormError((formError) => ({
                  ...formError,
                  endDate: 'Please select event end date.'
                }));
              }
            }}
            inputLabel="End Date"
            formError={formError.endDate}
            value={eventFormData && eventFormData.endDate}
          />
        </div>

        <div className="col-12 col-md-6">
          {isFirefox ?
            <WeTimePicker
              innerRef={(el) => (inputRef.current['endTimeRef'] = el)}
              type="time"
              onChange={(val) => {
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    endTime: ''
                  }));
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    endTime: val
                  }));
                } else {
                  setFormError((formError) => ({
                    ...formError,
                    endTime: 'Please select event end time.'
                  }));
                }
              }}
              inputLabel="End Time"
              formError={formError.endTime}
              value={eventFormData && eventFormData.endTime}
            />
            :
            <WeInput
              innerRef={(el) => (inputRef.current['endTimeRef'] = el)}
              type="time"
              onChange={(val) => {
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    endTime: ''
                  }));
                  setEventFormData((eventFormData) => ({
                    ...eventFormData,
                    endTime: val
                  }));
                } else {
                  setFormError((formError) => ({
                    ...formError,
                    endTime: 'Please select event end time.'
                  }));
                }
              }}
              inputLabel="End Time"
              formError={formError.endTime}
              value={eventFormData && eventFormData.endTime}
            />
          }
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <WeDropDown
            innerRef={(el) => (inputRef.current['timeZoneRef'] = el)}
            label="Time Zone"
            openCloseDropdown={showTimeZoneDropdown}
            onDropDownClick={() => setShowTimeZoneDropdown(!showTimeZoneDropdown)}
            selectData={
              timezoneData &&
              timezoneData?.map((item) => ({
                label: `${item.label}`,
                value: item.tzCode
              }))
            }
            defaultValue={eventFormData && eventFormData.timezone ? eventFormData.timezone : ''}
            emptyValue="Select Timezone"
            onSelectItem={(item) => {
              setEventFormData((eventFormData) => ({
                ...eventFormData,
                timezone: item //timezoneData[item].tzCode
              }));
              setFormError((formError) => ({
                ...formError,
                timezone: ''
              }));
              setShowTimeZoneDropdown(false);
            }}
            formError={formError.timezone}
          />
        </div>
        <div className="col-12 col-md-6">
          <WeDropDown
            label="Language"
            openCloseDropdown={showLanguageDropdown}
            onDropDownClick={() => setShowLanguageDropdown(!showLanguageDropdown)}
            selectData={language}
            defaultValue={
              eventFormData && eventFormData.eventPageLanguage
                ? eventFormData.eventPageLanguage
                : language[0]?.label
            }
            emptyValue="Select Language"
            onSelectItem={(item) => {
              setEventFormData((eventFormData) => ({
                ...eventFormData,
                eventPageLanguage: item //language[item].label
              }));
              setShowLanguageDropdown(false);
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="mt-4">
            <WeToggle
              toggleLabel="Start time displayed to attendees."
              checkToogle={eventFormData && eventFormData.isStartTimeDisplayed}
              toggleId={'isStartTimeDisplayed'}
              id={'startTime'}
              htmlFor={'startTimeFor'}
              onChange={(checked) => {
                setEventFormData((eventFormData) => ({
                  ...eventFormData,
                  isStartTimeDisplayed: checked
                }));
              }}
              onSwitchClick={() => {
                setEventFormData((eventFormData) => ({
                  ...eventFormData,
                  isStartTimeDisplayed: !eventFormData.isStartTimeDisplayed
                }));
              }}
            />
          </div>

          <div className="mt-2">
            <WeToggle
              toggleLabel="End time displayed to attendees."
              checkToogle={eventFormData && eventFormData.isEndTimeDisplayed}
              id={'endTime'}
              htmlFor={'endTimeFor'}
              onChange={(checked) => {
                setEventFormData((eventFormData) => ({
                  ...eventFormData,
                  isEndTimeDisplayed: checked
                }));
              }}
              onSwitchClick={() => {
                setEventFormData((eventFormData) => ({
                  ...eventFormData,
                  isEndTimeDisplayed: !eventFormData.isEndTimeDisplayed
                }));
              }}
            />
          </div>
        </div>
        <FooterButtons
          btnSaveContinue={btnSaveContinue}
          // isLoading={!id ? isLoading : ''}
          activeStep={activeStep}
          isEdit={id ? true : false}
          btnSaveName={id ? 'Update' : 'Next'}
        />
      </div>
      {isLoading ? <PopOverSpinner /> : ''}
    </>
  ) : (
    <PageForNoPermission />
  );
};

export default BasicInfo;
