import CreateReminderDialog from 'components/dialogs/createReminderDialog';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  allTicketList,
  apiTicketFatchingStart,
  createReminder,
  scheduledEmailList,
  sentEmailList,
  updateReminder
} from 'store/slice/tickets';
import EmailReminderHeader from './subcomponent/emailReminderHeader';
import EmailReminderItem from './subcomponent/emailReminderItem';
import {
  attendeeList,
  clearSpecificAttendee,
  clearTicketType,
  eventSelectedActiveMenu,
  setSpecificAttendee,
  setTicketType,
  updateSpecificAttendee,
  updateTicketType
} from 'store/slice/event';
import { isObjectEmpty, validEmail, validateEmpty } from 'utils/appConstant';
import EmptyView from 'components/emptyView';
import PageForNoPermission from 'pages/pageForNoPermission';
import SpinnerComponent from 'components/spinnerComponent';
import PaginatioonComponent from 'components/paginatioonComponent';

// const specificAttendee = [];
// const ticketType = [];
const EmailReminders = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  let page = 1;
  let limit = 3;
  const [pageNo, setPageNo] = useState(page);
  const [pageNo1, setPageNo1] = useState(page);
  const [showReminderDialog, setShowReminderDialog] = useState(false);
  const [reminderDialog, setReminderDialog] = useState(false);

  const { scheduledEmailData, sentEmailData, ticketListData, isFetching, totalData,totalCount1 } = useSelector(
    (state) => state.tickets
  );
  const { specificAttendeeData, ticketTypeData, userPermissionData, isOwnerValue } = useSelector(
    (state) => state.event
  );
  useEffect(() => {
    dispatch(apiTicketFatchingStart(true));
    scheduleDataLists();
  }, []);

  const handlePagination = (data) => {
    // const page = flag == 'next' ? pageNo + 1 : pageNo - 1;
    console.log('onPageChange', data.selected + 1);
    setPageNo(data.selected + 1);
  };
  const handlePagination1 = (data) => {
    // const page = flag == 'next' ? pageNo + 1 : pageNo - 1;
    console.log('onPageChange', data.selected + 1);
    setPageNo1(data.selected + 1);
  };

  useEffect(() => {
    scheduleDataLists();
  }, [pageNo]);

  useEffect(() => {
    scheduleDataLists();
  }, [pageNo1]);

  const scheduleDataLists = () => {
    let requestParams = {
      eventUid: id
    };
    let requestParams1 = {
      eventUid: id,
      page: 0,
      perPage: 0
    };
    dispatch(scheduledEmailList(pageNo, limit, requestParams));
    dispatch(sentEmailList(pageNo1, limit, requestParams));
    dispatch(attendeeList(requestParams1));
    dispatch(allTicketList(requestParams));
  };

  const handleCreate = () => {
    setShowReminderDialog(true);
  };
  const [formEmailData, setFormEmailData] = useState({});
  const [formError, setFormError] = useState({});
  const [showEmailTo, setShowEmailTo] = useState(false);
  const [showSpecificAteendee, setShowSpecificAteendee] = useState(false);
  const [showTiming, setShowTiming] = useState(false);
  const [type, setType] = useState('');
  const senderNameChange = (val) => {
    if (val) {
      setFormEmailData((formEmailData) => ({
        ...formEmailData,
        senderName: val
      }));
      setFormError((formError) => ({
        ...formError,
        senderName: ''
      }));
    } else {
      setFormEmailData((formEmailData) => ({
        ...formEmailData,
        senderName: ''
      }));
    }
  };

  const ReplyToChange = (val) => {
    if (val) {
      setFormEmailData((formEmailData) => ({
        ...formEmailData,
        replyTo: val
      }));
      setFormError((formError) => ({
        ...formError,
        replyTo: ''
      }));
    } else {
      setFormEmailData((formEmailData) => ({
        ...formEmailData,
        replyTo: ''
      }));
    }
  };
  const SubjectChange = (val) => {
    if (val) {
      setFormEmailData((formEmailData) => ({
        ...formEmailData,
        subject: val
      }));
      setFormError((formError) => ({
        ...formError,
        subject: ''
      }));
    } else {
      setFormEmailData((formEmailData) => ({
        ...formEmailData,
        subject: ''
      }));
    }
  };
  const MessageChange = (val) => {
    if (val) {
      setFormEmailData((formEmailData) => ({
        ...formEmailData,
        message: val
      }));
      setFormError((formError) => ({
        ...formError,
        message: ''
      }));
    } else {
      setFormEmailData((formEmailData) => ({
        ...formEmailData,
        message: ''
      }));
    }
  };

  const customTimeChange = (val) => {
    if (val) {
      setFormEmailData((formEmailData) => ({
        ...formEmailData,
        date: val
      }));
    } else {
      setFormEmailData((formEmailData) => ({
        ...formEmailData,
        date: ''
      }));
    }
  };

  const handleClose = () => {
    setShowReminderDialog(false);
    setFormEmailData({});
    setFormError({});
    setReminderDialog(false);
    dispatch(clearSpecificAttendee([]));
    dispatch(clearTicketType([]));
  };

  const onEmailSelectItem = (item) => {
    setFormEmailData((formEmailData) => ({
      ...formEmailData,
      emailSendTo: item
    }));
    setFormError((formError) => ({
      ...formError,
      emailTo: '',
      emailSendTo: ''
    }));
    setShowEmailTo(!showEmailTo);
  };

  const onTimingSelect = (item) => {
    setFormEmailData((formEmailData) => ({
      ...formEmailData,
      date: item
    }));
    setFormError((formError) => ({
      ...formError,
      timing: '',
      date: ''
    }));
    setShowTiming(!showTiming);
  };
  const onSpecificAttendeeSelect = (item) => {
    dispatch(updateSpecificAttendee(item.id));
  };

  const handleAddTicket = () => {
    navigate(`/event/${id}/edit/tickets`);
    dispatch(eventSelectedActiveMenu('tickets'));
  };

  const onTicketSelect = (item) => {
    dispatch(updateTicketType(item.id));
  };

  const onEmailDropDownClick = () => {
    setShowEmailTo(!showEmailTo);
  };

  const onTimingDropDownClick = () => {
    setShowTiming(!showTiming);
  };

  const onSpecificDropDownClick = () => {
    setShowSpecificAteendee(!showSpecificAteendee);
  };
  const pull_Data = (data) => {
    setType(data);
  };

  const handleSubmit = (handlecloseDialog) => {
    if (isObjectEmpty(formEmailData)) {
      setFormError((formError) => ({
        ...formError,
        senderName: 'Please Enter Sender Name',
        replyTo: 'Please Enter Valid email',
        emailTo: 'Please Select Email Send To',
        subject: 'Please Enter Subject',
        message: 'Please Enter Message',
        timing: 'Please Select Timing'
      }));
      return;
    }
    if (validateEmpty(formEmailData.senderName)) {
      setFormError((formError) => ({
        ...formError,
        senderName: 'Please enter senderName'
      }));
      return;
    }
    if (!validateEmpty(formEmailData.replyTo) && !validEmail(formEmailData.replyTo)) {
      setFormError((formError) => ({
        ...formError,
        replyTo: 'Please enter valid email'
      }));
      return;
    }
    if (validateEmpty(formEmailData.emailSendTo)) {
      setFormError((formError) => ({
        ...formError,
        emailTo: 'Please select email send to'
      }));
      return;
    }
    if (validateEmpty(formEmailData.subject)) {
      setFormError((formError) => ({
        ...formError,
        subject: 'Please enter subject'
      }));
      return;
    }
    if (validateEmpty(formEmailData.message)) {
      setFormError((formError) => ({
        ...formError,
        message: 'Please enter message'
      }));
      return;
    }
    if (validateEmpty(formEmailData.date)) {
      setFormError((formError) => ({
        ...formError,
        date: 'Please select timing'
      }));
      return;
    }
    let data =
      type === 'Update'
        ? {
            emailRemainderId: formEmailData._id,
            senderName: formEmailData.senderName,
            replyTo: formEmailData.replyTo,
            emailSendTo: formEmailData.emailSendTo,
            ticketIdArray: formEmailData.emailSendTo == 'ticket' ? ticketTypeData : [],
            attendeeIdArray: formEmailData.emailSendTo == 'attendees' ? specificAttendeeData : [],
            subject: formEmailData.subject,
            message: formEmailData.message,
            isCustom: formEmailData?.timing === 'Custom' ? true : false,
            date: formEmailData?.date,
            attendees: true
          }
        : {
            eventUid: id,
            senderName: formEmailData.senderName,
            replyTo: formEmailData.replyTo,
            emailSendTo: formEmailData.emailSendTo,
            ticketIdArray: ticketTypeData,
            attendeeIdArray: specificAttendeeData,
            subject: formEmailData.subject,
            message: formEmailData.message,
            isCustom: formEmailData?.timing === 'Custom' ? true : false,
            date: formEmailData?.date,
            attendees: true
          };
    if (type === 'Update') {
      dispatch(updateReminder(data));
    } else {
      dispatch(createReminder(data));
    }
    setFormEmailData({});
    if (type === 'Update' || type === 'Duplicate') {
      handlecloseDialog();
    } else {
      handleClose();
    }
  };

  return isFetching ? (
    <SpinnerComponent />
  ) : isOwnerValue || userPermissionData?.orders.sendEmails ? (
    <>
      <CreateReminderDialog
        title={'Create'}
        formEmailData={formEmailData}
        showReminderDialog={showReminderDialog}
        showEmailTo={showEmailTo}
        showTiming={showTiming}
        formError={formError}
        showSpecificAteendee={showSpecificAteendee}
        SubjectChange={SubjectChange}
        MessageChange={MessageChange}
        handleClose={handleClose}
        senderNameChange={senderNameChange}
        ReplyToChange={ReplyToChange}
        onEmailSelectItem={onEmailSelectItem}
        onTimingSelect={onTimingSelect}
        onTicketSelect={onTicketSelect}
        onSpecificAttendeeSelect={onSpecificAttendeeSelect}
        onEmailDropDownClick={onEmailDropDownClick}
        onTimingDropDownClick={onTimingDropDownClick}
        onSpecificDropDownClick={onSpecificDropDownClick}
        customTimeChange={customTimeChange}
        handleSubmit={handleSubmit}
      />
      <h2>Email Reminders</h2>
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

      {ticketListData?.length === 0 || ticketListData == null ? (
        <>
          <EmptyView
            text="No Tickets or attendee Created"
            message="Create Tickets or attendee for creating Email Reminders."
            showButton={true}
            btnText={'Add Ticket'}
            onBtnClick={handleAddTicket}
          />
        </>
      ) : (
        <>
          <a onClick={handleCreate} className="btn primary-btn">
            Create Reminder
          </a>
          <div className="mt-4">
            <h4 className="mb-0">Scheduled</h4>
          </div>
          <div className="ticket-table">
            <EmailReminderHeader />
            <div style={{ height: 'auto', minHeight: '15rem' }}>
            {scheduledEmailData ? (
              scheduledEmailData?.map((item, index) => {
                return (
                  <EmailReminderItem
                    listData={item}
                    key={index}
                    func={pull_Data}
                    setFormEmailData={(data) => {
                      dispatch(setTicketType(data.ticketIdArray));
                      dispatch(setSpecificAttendee(data.attendeeIdArray));
                      setFormEmailData(data);
                    }}
                    formEmailData={formEmailData}
                    showEmailTo={showEmailTo}
                    showTiming={showTiming}
                    showSpecificAteendee={showSpecificAteendee}
                    SubjectChange={SubjectChange}
                    MessageChange={MessageChange}
                    handleClose={handleClose}
                    senderNameChange={senderNameChange}
                    ReplyToChange={ReplyToChange}
                    onEmailSelectItem={onEmailSelectItem}
                    onTimingSelect={onTimingSelect}
                    onTicketSelect={onTicketSelect}
                    onSpecificAttendeeSelect={onSpecificAttendeeSelect}
                    onEmailDropDownClick={onEmailDropDownClick}
                    onTimingDropDownClick={onTimingDropDownClick}
                    onSpecificDropDownClick={onSpecificDropDownClick}
                    customTimeChange={customTimeChange}
                    handleSubmit={handleSubmit}
                    reminderDialog={reminderDialog}
                    setReminderDialog={setReminderDialog}
                  />
                );
              })
            ) : (
              <p className="text-center pt-5"> No Data Found</p>
            )}
            </div>
            {scheduledEmailData && totalData > limit ? (
              <div className="mt-3" style={{ float: 'right' }}>
                <PaginatioonComponent
                  pageCount={
                    totalData % limit === 0
                      ? Math.floor(totalData / limit)
                      : Math.floor(totalData / limit) + 1
                  }
                  onPageChange={handlePagination}
                  pageNo={pageNo}
                />
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="mt-4">
            <h4 className="mb-0">Sent</h4>
          </div>
          <div className="ticket-table">
            <EmailReminderHeader />
            <div style={{ height: 'auto', minHeight: '15rem' }}>
            {sentEmailData ? (
              sentEmailData?.map((item, index) => {
                return <EmailReminderItem listData={item} key={index} isNotAction={true} />;
              })
            ) : (
              <p className="text-center pt-5"> No Data Found</p>
            )}
            </div>
            {sentEmailData && totalCount1 > limit ? (
              <div className="mt-3" style={{ float: 'right' }}>
                <PaginatioonComponent
                  pageCount={
                    totalCount1 % limit === 0
                      ? Math.floor(totalCount1 / limit)
                      : Math.floor(totalCount1 / limit) + 1
                  }
                  onPageChange={handlePagination1}
                  pageNo={pageNo1}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </>
      )}
    </>
  ) : (
    <PageForNoPermission />
  );
};

export default React.memo(EmailReminders);
