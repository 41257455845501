import WeButton from 'components/button';
import WeDropDown from 'components/weDropDown';
import PageForNoPermission from 'pages/pageForNoPermission';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { eventDetail } from 'store/slice/event';
import { refundAndTaxSettings } from 'store/slice/payment';
import { selectValue } from 'utils/appConstant';

const RefundSettings = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { eventDetailData, isOwnerValue } = useSelector((state) => state.event);
  const [taxSettingsValue, setTaxSettingsValue] = useState({
    eventUid: eventDetailData ? eventDetailData.eventUid : id
  });

  useEffect(() => {
    if (id) {
      dispatch(eventDetail(id));
    }
  }, [id]);

  useEffect(() => {
    setTaxSettingsValue((taxSettingsValue) => ({
      ...taxSettingsValue,
      refundPolicy: eventDetailData?.refundPolicy
    }));
  }, [eventDetailData]);

  const handleSubmit = () => {
    dispatch(refundAndTaxSettings(taxSettingsValue));
  };

  const refundPolicyData = [
    {
      label: '1 day before the event',
      id: 'oneDayBefore'
    },
    {
      label: '7 days before the event',
      id: 'sevenDayBefore'
    },
    {
      label: '30 days before the event',
      id: 'thirtyDayBefore'
    },
    {
      label: 'Case by case',
      id: 'caseByCase'
    }
  ];

  return isOwnerValue ? (
    <>
      <h2>Refund Settings</h2>
      <p>Tesloom doesn't refund ticket fees.</p>
      <div className="row">
        <div className="col-12 col-md-6 col-xl-5 col-xxl-4">
          <WeDropDown
            label="Refund Policy"
            selectData={refundPolicyData}
            value={taxSettingsValue.refundPolicy}
            emptyValue="Select a Type"
            onSelectItem={(item) => {
              const valueAtIndex = selectValue(refundPolicyData, item, true);
              setTaxSettingsValue((taxSettingsValue) => ({
                ...taxSettingsValue,
                refundPolicy: item,
                refundPolicyValue: valueAtIndex
              }));
            }}
          />
        </div>
      </div>
      <WeButton className="mt-4" text="Save" onClick={handleSubmit} />
    </>
  ) : (
    <PageForNoPermission />
  );
};

export default RefundSettings;
