import React, { useRef } from 'react';
import image from 'assets/img/placeholder.jpeg';
import moment from 'moment';
// import { useOutsideAlerter } from 'utils/appConstant';

const EventListItem = ({ ...props }) => {
  const { itemData, key, onEditMenu, onCopyEvent, onDuplicateEvent, onDeleteEvent, onEventClick, handlemenuOpen, openGroupMenuId } =
    props;
  const path = window.location.origin;
  const wrapperRef = useRef(null);

  const onViewEvent = () => {
    window.open(`${path}/${itemData?.eventUrl}`, '_blank');
    handlemenuOpen()
  };

  // useOutsideAlerter(wrapperRef, handlemenuOpen);

  return (
    <>
      <div className="row event-row" key={key}>
        <div className="col-10 col-md-11" onClick={() => onEventClick(itemData)}>
          <div className="row">
            <div className="col-12 col-md-7">
              <div className="row">
                <div
                  className="col-1 col-md-1 date
                              d-none d-md-flex">
                  <span className="day">{moment(itemData.startDate).format('DD')}</span>
                  <span className="month">{moment(itemData.startDate).format('MMM')}</span>
                </div>
                <div
                  className="col-2 col-md-2
                              thumbnail d-none d-md-flex">
                  <img
                    src={itemData.bannerImageName ? itemData.bannerImageName : image}
                    alt="logo"
                  />
                </div>
                <div
                  className="col-12 col-md-8
                              col-lg-9 event-info">
                  <span className="heading">{itemData.title}</span>
                  <span className="location">
                    <span className="location-name">{itemData.venue}</span>
                  </span>
                  <span className="full-date">
                    {moment(itemData.startDate).format('ddd, MMM DD, YYYY')},
                    {moment(itemData.startTime, 'HH:mm:ss').format(' HH:mm A ')}-{' '}
                    {moment(itemData.endDate).format('ddd, MMM DD, YYYY')},
                    {moment(itemData.endTime, 'HH:mm:ss').format(' HH:mm A ')}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-md-5
                      align-self-center d-none
                      d-md-block">
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="sold-info">
                    <span>
                      {itemData.sold}/{itemData.eventCapacity}
                    </span>
                    <div className="progress" style={{ height: '4px' }}>
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        style={{ width: `${itemData.sold}%` }}
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="0"></div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="revenue-info">$ {itemData?.revenue}</div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="status-info">
                    {itemData.publishType === 'public' ? 'On sale' : ''}
                    {itemData.publishType === 'private' ? 'On sale' : ''}
                    {itemData.publishType === 'draft' ? 'draft' : ''}
                    {itemData.publishType === 'unlisted' ? 'draft' : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-2 col-md-1 align-self-center">
          <div
            className={openGroupMenuId ? 'dropdown justify-content-end active' : 'dropdown justify-content-end'}
            data-dropdown>
            <a
              className="dropdown-button d-inline-block"
              data-dropdown-button
              onClick={handlemenuOpen}>
              <span className="fa-stack fa-1x">
                <i className="fa-solid fa-circle fa-stack-2x"></i>
                <i className="fa-solid fa-ellipsis-vertical fa-stack-1x fa-inverse"></i>
              </span>
            </a>
            <div className="dropdown-menu information-grid" ref={wrapperRef}>
              <div className="dropdown-links">
                <a onClick={onViewEvent} href="#" className="link">
                  View
                </a>
                <a onClick={() => onEditMenu(itemData)} className="link">
                  Edit
                </a>
                <a onClick={() => onCopyEvent(itemData)} className="link">
                  Copy URL
                </a>
                <a onClick={() => onDuplicateEvent(itemData)} className="link">
                  Duplicate
                </a>
                <a onClick={() => onDeleteEvent(itemData)} className="link">
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(EventListItem);
