import WeButton from 'components/button';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'rxjs';
import { resetPassword } from 'store/slice/auth';
const SignUp = () => {
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [oldPassError, setOldPassError] = useState('');
  const [newPassError, setNewPassError] = useState('');
  const [oldShow, setOldShow] = useState(false);
  const [newShow, setNewShow] = useState(false);
  const { isLoading } = useSelector((state) => state.auth);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!oldPassword) {
      setOldPassError('Please enter password.');
      return;
    }
    if (oldPassword.length < 8) {
      setOldPassError('Password must be minumum 8 characters.');
      return;
    }
    if (!newPassword) {
      setNewPassError('Please enter password.');
      return;
    }
    if (newPassword.length < 8) {
      setNewPassError('Password must be minumum 8 characters.');
      return;
    }
    let requestParam = {
      oldPassword: oldPassword,
      newPassword: newPassword
    };
    dispatch(resetPassword(requestParam));
    setOldPassword('');
    setNewPassword('');
  };
  return (
    <>
      <h3>Password Reset.....</h3>

      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: 10
        }}>
        <div className="contain">
          <div className="login-form">
            <div>
              <label
                className="mt-2"
                htmlFor="email  "
                style={{ float: 'left', color: 'rgb(70 69 69)' }}>
                Old Password
              </label>
              <div className="input-group mb-3">
                <input
                  name="password"
                  type={oldShow ? 'text' : 'password'}
                  className="form-control"
                  aria-describedby="basic-addon1"
                  placeholder="Enter old Password"
                  value={oldPassword}
                  onChange={(e) => {
                    let val = e.target.value.trim();
                    if (!isEmpty(val) && val.length < 8) {
                      setOldPassError('Password must be minumum 8 characters.');
                    } else {
                      setOldPassword(val);
                      setOldPassError('');
                    }
                  }}
                />
                <span
                  onClick={() => {
                    setOldShow(!oldShow);
                  }}
                  className="input-group-text"
                  id="basic-addon1">
                  {oldShow ? (
                    ''
                  ) : (
                    <span id="basic-addon2">
                      <i className="fa-light fa-eye-slash"></i>
                    </span>
                  )}
                  {oldShow ? (
                    <span id="basic-addon2">
                      <i className="fa-light fa-eye"></i>
                    </span>
                  ) : (
                    ''
                  )}
                </span>
              </div>
            </div>
            {oldPassError && <div style={{ color: 'red' }}>{oldPassError}</div>}
            <div>
              <label
                className="mt-2"
                htmlFor="email  "
                style={{ float: 'left', color: 'rgb(70 69 69)' }}>
                New password
              </label>
              <div className="input-group mb-3">
                <input
                  name="password"
                  type={newShow ? 'text' : 'password'}
                  className="form-control"
                  aria-describedby="basic-addon1"
                  placeholder="Enter New Password"
                  value={newPassword}
                  onChange={(e) => {
                    let val = e.target.value.trim();
                    if (!isEmpty(val) && val.length < 8) {
                      setNewPassError('Password must be minumum 8 characters.');
                    } else {
                      setNewPassword(val);
                      setNewPassError('');
                    }
                  }}
                />
                <span
                  onClick={() => {
                    setNewShow(!newShow);
                  }}
                  className="input-group-text"
                  id="basic-addon1">
                  {newShow ? (
                    ''
                  ) : (
                    <span id="basic-addon2">
                      <i className="fa-light fa-eye-slash"></i>
                    </span>
                  )}
                  {newShow ? (
                    <span id="basic-addon2">
                      <i className="fa-light fa-eye"></i>
                    </span>
                  ) : (
                    ''
                  )}
                </span>
              </div>
            </div>
            {newPassError && <div style={{ color: 'red' }}>{newPassError}</div>}
            <WeButton text="Reset Password" onClick={handleSubmit} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
