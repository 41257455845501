/* eslint-disable no-unused-vars */
import WeButton from 'components/button';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files';

const ImageContainer = ({ ...props }) => {
  const { itemData, btnDeleteItem, onImageChange, fileTypes, onRemoveImage, key } = props;

  const imageView = () => {
    return (
      <div className="w-100">
        <div className="content  w-100">
          {itemData.imageURL ? (
            <img src={itemData.imageURL} />
          ) : (
            <div>
              <i className="fa-light fa-image"></i>
              <div>Drag and drop an image</div>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="summary-block">
        <div className="tools">
          {/* <div className="icon">
            <i className="fa-solid fa-up-down-left-right"></i>
          </div> */}
          <div
            className="icon"
            onClick={() => {
              btnDeleteItem(itemData);
            }}>
            <i className="fa-solid fa-trash-can"></i>
          </div>
        </div>

        <div style={{ position: 'relative',width:'100%' }}>
          <FileUploader
            handleChange={(file) => {
              onImageChange(file, itemData);
            }}
            name="file"
            types={fileTypes}
            classes="banner"
            // eslint-disable-next-line react/no-children-prop
            children={imageView()}
          />
          {itemData.imagePath ? (
            <div
              style={{
                color: '#fff',
                position: 'absolute',
                top: '35px',
                right: '10px',
                cursor: 'pointer'
              }}
              onClick={() => onRemoveImage(itemData)}>
              <i className="fa-solid fa-xmark"></i>
            </div>
          ) : (
            ''
          )}
        </div>

        {itemData.isRequired && (
          <div style={{ color: 'red', padding: 5 }}>Please upload image or delete this item.</div>
        )}
      </div>
      {/* <div key={key + itemData.id}>
        <FileUploader
          handleChange={(file) => {
            onImageChange(file, itemData);
          }}
          name="file"
          types={fileTypes}
          // eslint-disable-next-line react/no-children-prop
          children={imageView()}
        />
        {itemData.isRequired && (
          <div style={{ color: 'red', padding: 5 }}>Please upload image or delete this item.</div>
        )}
        <br />
        <WeButton
          text="Delete"
          onClick={() => {
            btnDeleteItem(itemData);
          }}
        />
      </div> */}
    </>
  );
};

export default ImageContainer;
