import WeInput from 'components/weInput';
import WeToggle from 'components/weToggle';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { editTransferSettings } from 'store/slice/tickets';

const TransferSetingDialog = ({ ...props }) => {
  const dispatch = useDispatch();
  const { showDialog, onDialogClose, customData } = props;
  const [ticketTransferValue, setTicketTransferValue] = useState();

  useEffect(() => {
    setTicketTransferValue((ticketTransferValue) => ({
      ...ticketTransferValue,
      ...customData,
      ticketId: customData?._id
    }));
  }, [customData]);

  const handeleSubmit = () => {
    dispatch(editTransferSettings(ticketTransferValue));
    onDialogClose();
  };

  return (
    <Modal show={showDialog} onHide={onDialogClose} centered backdrop="static" keyboard={false}>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5">{customData?.name} </h1>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Custom transfer settings per ticket type.</p>
        <div className="mt-2">
          <div className="mt-3">
            <WeToggle
              checkToogle={ticketTransferValue && ticketTransferValue?.allowTransfer}
              onChange={(checked) => {
                setTicketTransferValue((ticketTransferValue) => ({
                  ...ticketTransferValue,
                  allowTransfer: checked
                }));
              }}
              onSwitchClick={() => {
                setTicketTransferValue((ticketTransferValue) => ({
                  ...ticketTransferValue,
                  allowTransfer: !ticketTransferValue?.allowTransfer
                }));
              }}
              toggleLabel="Allow Transfer"
            />
          </div>
        </div>
        <WeInput
          type={'number'}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handeleSubmit();
            }
          }}
          minVal={0}
          onChange={(val) => {
            setTicketTransferValue((ticketTransferValue) => ({
              ...ticketTransferValue,
              transferFee: val
            }));
          }}
          prefix={'$'}
          inputLabel="Standard Transfer Fee"
          value={ticketTransferValue?.transferFee}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn secondary-btn" onClick={onDialogClose}>
          Close
        </Button>
        <Button className="btn primary-btn" onClick={handeleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(TransferSetingDialog);
