import WeToggle from 'components/weToggle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getUserPayout, updateUserPayout } from 'store/slice/order';

export default function PayoutSchedule() {
  const dispatch = useDispatch();
  const { userPayoutData } = useSelector((state) => state.order);
  let [payoutToggle, setPayoutToggle] = useState();

  useEffect(() => {
    dispatch(getUserPayout());
  }, []);

  useEffect(() => {
    if (userPayoutData) {
      setPayoutToggle(userPayoutData);
    }
  }, [userPayoutData]);

  // useEffect(() => {
  //   const data = {
  //     payoutSchedule: payoutToggle
  //   };
  //   dispatch(updateUserPayout(data));
  // }, [payoutToggle]);

  const updatePayout = () => {
    if (!payoutToggle) {
      toast.error('You can not disable all the options for Payout Schedule ');
      setPayoutToggle(userPayoutData);
      return;
    }
    const data = {
      payoutSchedule: payoutToggle
    };
    dispatch(updateUserPayout(data));
  };

  return (
    <>
      <h2>Payout Schedule</h2>

      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/finance">Finance</a>
            </li>
            <li className="breadcrumb-item">Payout Schedule</li>
          </ol>
        </nav>
      </div>

      <div className="block">
        <div className="row">
          <div className="col-9">
            <h4>After Event</h4>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>
          </div>
          <div className="col-3 d-flex align-items-center justify-content-end">
            <WeToggle
              checkToogle={payoutToggle == 'afterEvent' ? true : false}
              onChange={(checked) => {
                if (checked) {
                  setPayoutToggle('afterEvent');
                  payoutToggle = 'afterEvent';
                } else {
                  setPayoutToggle('');
                  payoutToggle = '';
                }
                updatePayout();
              }}
            />
          </div>
        </div>
      </div>
      <div className="block">
        <div className="row">
          <div className="col-9">
            <h4>Weekly</h4>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>
          </div>
          <div className="col-3 d-flex align-items-center justify-content-end">
            <WeToggle
              checkToogle={payoutToggle == 'weekly' ? true : false}
              onChange={(checked) => {
                if (checked) {
                  setPayoutToggle('weekly');
                  payoutToggle = 'weekly';
                } else {
                  setPayoutToggle('');
                  payoutToggle = '';
                }
                updatePayout();
              }}
            />
          </div>
        </div>
      </div>
      <div className="block">
        <div className="row">
          <div className="col-9">
            <h4>Bi-Weekly</h4>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>
          </div>
          <div className="col-3 d-flex align-items-center justify-content-end">
            <WeToggle
              checkToogle={payoutToggle == 'biWeekly' ? true : false}
              onChange={(checked) => {
                if (checked) {
                  setPayoutToggle('biWeekly');
                  payoutToggle = 'biWeekly';
                } else {
                  setPayoutToggle('');
                  payoutToggle = '';
                }
                updatePayout();
              }}
            />
          </div>
        </div>
      </div>

      <div className="text-center m-5">
        <p>
          <b>Note :-</b> You can not disable all the options for payout schedule.
        </p>
      </div>
    </>
  );
}
