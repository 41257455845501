import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import SideMenu from 'pages/events/manage/sideMenu';
import { useDispatch, useSelector } from 'react-redux';
import { eventDetail, getUserPermissionForEvent } from 'store/slice/event';
import EventContext from 'context/eventContext';
import SpinnerComponent from 'components/spinnerComponent';

const EventLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate('');
  const [eventFormData, setEventFormData] = useState({});
  const { eventData } = useSelector((state) => state.event);
  const { eventDataDetails, isApiLoading } = useSelector((state) => state.event);
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getUserPermissionForEvent({
        eventUid: id
      },navigate)
    );
  }, []);

  useEffect(() => {
    if (eventData) {
      dispatch(eventDetail(eventData.id));
    }
  }, [eventData]);

  useEffect(() => {
    if (eventDataDetails) {
      setEventFormData(eventDataDetails);
    }
  }, [eventDataDetails]);

  return (
    <>
      {isApiLoading ? (
        <SpinnerComponent />
      ) : (
        <EventContext.Provider value={{ eventFormData, setEventFormData }}>
          <div className="row">
            <div className="col-12 col-lg-4 col-xxl-3 event-sidebar-nav">
              <SideMenu />
            </div>
            <div
              className={id ? 'col-12 col-lg-8 col-xxl-9 board' : 'col-12 col-lg-9 col-xl-8 board'}>
              <Outlet />
            </div>
          </div>
        </EventContext.Provider>
      )}
    </>
  );
};

export default EventLayout;
