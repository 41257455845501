import { toast } from 'react-toastify';
import { isObjectEmpty, validateEmpty } from 'utils/appConstant';

export const validateForm = (setFormError, eventFormData, inputRef) => {
  if (isObjectEmpty(eventFormData)) {
    setFormError((formError) => ({
      ...formError,
      title: 'Please enter your event title.',
      // eventType: 'Please select your event type.',
      venueLocation: 'Please enter venue location.',
      address: 'Please enter address for your event.',
      city: 'Please enter venue city',
      state: 'Please enter venue state.',
      country: 'Please enter country',
      zipcode: 'Please enter zipcode',
      startDate: 'Please enter event start date.',
      startTime: 'Please enter event start time.',
      endDate: 'Please enter event end date.',
      endTime: 'Please enter event end time',
      timezone: 'Please select event timezone.'
    }));
    window.scrollTo(0, 0);
    toast.error('Please complete form inputs.');
    return;
  }

  if (validateEmpty(eventFormData.title)) {
    setFormError((formError) => ({
      ...formError,
      title: 'Please enter your event title.'
    }));
    inputRef.current['inputTitleRef'].focus();
    return;
  }
  // if (validateEmpty(eventFormData.eventType)) {
  //   setFormError((formError) => ({
  //     ...formError,
  //     eventType: 'Please select your event type.'
  //   }));
  //   inputRef.current['eventTypeRef'].focus();
  //   return;
  // }

  if (validateEmpty(eventFormData.venue)) {
    setFormError((formError) => ({
      ...formError,
      venueLocation: 'Please enter venue location.'
    }));
    inputRef.current['addressRef'].focus();
    return;
  }

  if (validateEmpty(eventFormData.address)) {
    setFormError((formError) => ({
      ...formError,
      address: 'Please enter address for your event.'
    }));
    inputRef.current['streetAddressRef'].focus();
    return;
  }

  if (validateEmpty(eventFormData.city)) {
    setFormError((formError) => ({
      ...formError,
      city: 'Please enter venue city'
    }));
    inputRef.current['cityRef'].focus();
    return;
  }
  if (validateEmpty(eventFormData.state)) {
    setFormError((formError) => ({
      ...formError,
      state: 'Please enter venue state'
    }));
    inputRef.current['stateRef'].focus();
    return;
  }
  if (validateEmpty(eventFormData.zipcode)) {
    setFormError((formError) => ({
      ...formError,
      zipcode: 'Please enter venue zipcode'
    }));
    inputRef.current['countryRef'].focus();
    return;
  }
  if (validateEmpty(eventFormData.country)) {
    setFormError((formError) => ({
      ...formError,
      country: 'Please enter venue country'
    }));
    inputRef.current['zipCodeRef'].focus();
    return;
  }

  if (validateEmpty(eventFormData.startDate)) {
    setFormError((formError) => ({
      ...formError,
      startDate: 'Please enter event start date.'
    }));
    inputRef.current['startDateRef'].focus();
    return;
  }

  if (validateEmpty(eventFormData.startTime)) {
    setFormError((formError) => ({
      ...formError,
      startTime: 'Please enter event start time.'
    }));
    inputRef.current['startTimeRef'].focus();
    return;
  }

  if (validateEmpty(eventFormData.endDate)) {
    setFormError((formError) => ({
      ...formError,
      endDate: 'Please enter event end date.'
    }));
    inputRef.current['endDateRef'].focus();
    return;
  }

  if (validateEmpty(eventFormData.endTime)) {
    setFormError((formError) => ({
      ...formError,
      endTime: 'Please enter event end time.'
    }));
    inputRef.current['endTimeRef'].focus();
    return;
  }

  if (validateEmpty(eventFormData.timezone)) {
    setFormError((formError) => ({
      ...formError,
      timezone: 'Please select event timezone.'
    }));
    inputRef.current['timeZoneRef'].focus();
    return;
  }

  
  return true;
};
