import React from 'react';
import { Doughnut } from 'react-chartjs-2';
function DoughnutChart({ chartData }) {
  return (
    // <div className="chart-container">
      <Doughnut
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              //text: 'Users Gained between 2016-2020'
            },
            legend: {
              display: false
            }
          }
        }}
      />
    // </div>
  );
}
export default DoughnutChart;
