import WeDropDown from 'components/weDropDown';
import WeInput from 'components/weInput';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { addBanckAccountOnStripe, getStateList } from 'store/slice/payment';
import { selectValue, validEmail } from 'utils/appConstant';

const AddPayout = ({ ...props }) => {
  const { showDialog, onDialogClose } = props;
  const [payoutValue, setPayoutValue] = useState({});
  const { stateListData } = useSelector((state) => state.payment);
  const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStateList());
  }, []);
  const accountHolderData = [
    {
      label: 'Person',
      id: 'person'
    },
    {
      label: 'Company',
      id: 'company'
    }
  ];
  const handleSubmit = () => {
    if (payoutValue?.refundPolicy === 'company') {
      if (!payoutValue?.companyName) {
        setPayoutValue((payoutValue) => ({
          ...payoutValue,
          companyNameErr: 'Please enter company name'
        }));
        return;
      }
      if (!payoutValue?.companyPhone) {
        setPayoutValue((payoutValue) => ({
          ...payoutValue,
          companyPhoneErr: 'Please enter company phone number'
        }));
        return;
      }
      if (!payoutValue?.companyAddress) {
        setPayoutValue((payoutValue) => ({
          ...payoutValue,
          companyAddressErr: 'Please enter company address'
        }));
        return;
      }
      if (!payoutValue?.companyCity) {
        setPayoutValue((payoutValue) => ({
          ...payoutValue,
          companyCityErr: 'Please enter company city'
        }));
        return;
      }
      if (!payoutValue?.companyPostalCode) {
        setPayoutValue((payoutValue) => ({
          ...payoutValue,
          companyPostalCodeErr: 'Please enter company zip code'
        }));
        return;
      }
      if (!payoutValue?.companyState) {
        setPayoutValue((payoutValue) => ({
          ...payoutValue,
          companyStateErr: 'Please select company state'
        }));
        return;
      }
      if (!payoutValue?.companyTaxId) {
        setPayoutValue((payoutValue) => ({
          ...payoutValue,
          companyTaxIdErr: 'Please enter company tax Id'
        }));
        return;
      }
    }

    if (!validEmail(payoutValue?.email)) {
      setPayoutValue((payoutValue) => ({
        ...payoutValue,
        emailErr: 'Please enter valid email'
      }));
      return;
    }
    if (!payoutValue?.firstName) {
      setPayoutValue((payoutValue) => ({
        ...payoutValue,
        firstNameErr: 'Please enter first name'
      }));
      return;
    }
    if (!payoutValue?.lastName) {
      setPayoutValue((payoutValue) => ({
        ...payoutValue,
        lastNameErr: 'Please enter last name'
      }));
      return;
    }
    if (!payoutValue?.phone) {
      setPayoutValue((payoutValue) => ({
        ...payoutValue,
        phoneErr: 'Please enter phone number'
      }));
      return;
    }
    if (!payoutValue?.dob) {
      setPayoutValue((payoutValue) => ({
        ...payoutValue,
        dobErr: 'Please enter date of birth'
      }));
      return;
    }
    if (!payoutValue?.addressLine1) {
      setPayoutValue((payoutValue) => ({
        ...payoutValue,
        addressLine1Err: 'Please enter street name'
      }));
      return;
    }
    if (!payoutValue?.addressLine2) {
      setPayoutValue((payoutValue) => ({
        ...payoutValue,
        addressLine2Err: 'Please enter street name 2'
      }));
      return;
    }
    if (!payoutValue?.city) {
      setPayoutValue((payoutValue) => ({
        ...payoutValue,
        cityErr: 'Please enter city'
      }));
      return;
    }
    if (!payoutValue?.postalCode) {
      setPayoutValue((payoutValue) => ({
        ...payoutValue,
        postalCodeErr: 'Please enter zip code'
      }));
      return;
    }
    if (!payoutValue?.state) {
      setPayoutValue((payoutValue) => ({
        ...payoutValue,
        stateErr: 'Please select state'
      }));
      return;
    }
    if (!payoutValue?.sssNo) {
      setPayoutValue((payoutValue) => ({
        ...payoutValue,
        sssNoErr: 'Please enter sssNo '
      }));
      return;
    }
    if (!payoutValue?.routingNumber) {
      setPayoutValue((payoutValue) => ({
        ...payoutValue,
        routingNumberErr: 'Please enter routing number '
      }));
      return;
    }
    if (!payoutValue?.accountNumber) {
      setPayoutValue((payoutValue) => ({
        ...payoutValue,
        accountNumberErr: 'Please enter account number '
      }));
      return;
    }
    if (!payoutValue?.confirmAccountNumber) {
      setPayoutValue((payoutValue) => ({
        ...payoutValue,
        confirmAccountNumberErr: 'Please re-enter account number '
      }));
      return;
    }

    if (payoutValue?.confirmAccountNumber != payoutValue?.accountNumber) {
      setPayoutValue((payoutValue) => ({
        ...payoutValue,
        confirmAccountNumberErr: "account number doesn't match "
      }));
      return;
    }
    let data = {
      routingNumber: payoutValue?.routingNumber,
      accountNumber: payoutValue?.accountNumber,
      accountHolder: payoutValue?.refundPolicy ? payoutValue?.refundPolicy : 'person',
      email: payoutValue?.email,
      firstName: payoutValue?.firstName,
      lastName: payoutValue?.lastName,
      ssnNo: payoutValue?.sssNo,
      dob: payoutValue?.dob,
      phone: payoutValue?.phone,
      city: payoutValue?.city,
      addressLine1: payoutValue?.addressLine1,
      addressLine2: payoutValue?.addressLine2,
      postalCode: payoutValue?.postalCode,
      state: payoutValue?.state,
      title: payoutValue?.title,
      companyName: payoutValue?.companyName,
      companyCity: payoutValue?.companyCity,
      companyAddress: payoutValue?.companyAddress,
      companyPostalCode: payoutValue?.companyPostalCode,
      companyState: payoutValue?.companyState,
      companyPhone: payoutValue?.companyPhone,
      companyTaxId: payoutValue?.companyTaxId
    };
    dispatch(addBanckAccountOnStripe(data));
    onDialogClose();
    setPayoutValue({});
  };

  return (
    <Modal
      show={showDialog}
      onHide={() => {
        setPayoutValue({});
        onDialogClose();
      }}
      centered
      backdrop="static"
      keyboard={false}>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          {' '}
          <div style={{ display: 'flex' }}>
            <h1 className="fs-5"> Banking Information</h1>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <WeDropDown
          label="Account Holder"
          selectData={accountHolderData}
          defaultValue={
            payoutValue.refundPolicy ? selectValue(accountHolderData, payoutValue.refundPolicy) : ''
          }
          emptyValue="Select Account Holder"
          onSelectItem={(item) => {
            setPayoutValue((payoutValue) => ({
              ...payoutValue,
              refundPolicy: item //accountHolderData[item].id
            }));
          }}
        />
        {payoutValue?.refundPolicy === 'company' ? (
          <>
            <WeInput
              onChange={(val) =>
                setPayoutValue((payoutValue) => ({
                  ...payoutValue,
                  companyName: val,
                  companyNameErr: ''
                }))
              }
              inputLabel="Company Name"
              formError={payoutValue?.companyNameErr}
              value={payoutValue?.companyName}
            />
            <WeInput
              type={'number'}
              maxVal={9999999999}
              onKeyPress={(e) => blockInvalidChar(e)}
              onChange={(val) => {
                if (val.length < 11) {
                  setPayoutValue((payoutValue) => ({
                    ...payoutValue,
                    companyPhone: val,
                    companyPhoneErr: ''
                  }));
                }
              }}
              inputLabel="Company Phone"
              formError={payoutValue?.companyPhoneErr}
              value={payoutValue?.companyPhone}
            />
            <WeInput
              onChange={(val) =>
                setPayoutValue((payoutValue) => ({
                  ...payoutValue,
                  companyAddress: val,
                  companyAddressErr: ''
                }))
              }
              inputLabel="Company Address"
              formError={payoutValue?.companyAddressErr}
              value={payoutValue?.companyAddress}
            />
            <WeInput
              onChange={(val) =>
                setPayoutValue((payoutValue) => ({
                  ...payoutValue,
                  companyCity: val,
                  companyCityErr: ''
                }))
              }
              inputLabel="Company City"
              formError={payoutValue?.companyCityErr}
              value={payoutValue?.companyCity}
            />
            <div className="row">
              <div className="col-6">
                <WeInput
                  onChange={(val) =>
                    setPayoutValue((payoutValue) => ({
                      ...payoutValue,
                      companyPostalCode: val,
                      companyPostalCodeErr: ''
                    }))
                  }
                  inputLabel="Company zip Code"
                  formError={payoutValue?.companyPostalCodeErr}
                  value={payoutValue.companyPostalCode ?? ''}
                />
              </div>
              <div className="col-6">
                <WeDropDown
                  label="Company State"
                  selectState={stateListData}
                  defaultValue={
                    payoutValue.companyState
                      ? selectValue(stateListData, payoutValue.companyState)
                      : ''
                  }
                  emptyValue="Select Company State"
                  onSelectItem={(item) => {
                    setPayoutValue((payoutValue) => ({
                      ...payoutValue,
                      companyState: item, //stateListData[item],
                      companyStateErr: ''
                    }));
                  }}
                />
              </div>
            </div>
            <WeInput
              onChange={(val) =>
                setPayoutValue((payoutValue) => ({
                  ...payoutValue,
                  companyTaxId: val,
                  companyTaxIdErr: ''
                }))
              }
              inputLabel="Company Tax Id"
              formError={payoutValue?.companyTaxIdErr}
              value={payoutValue.companyTaxId ?? ''}
            />
            <h1 className="fs-6 mt-3"> Company Representative</h1>
            <WeInput
              onChange={(val) =>
                setPayoutValue((payoutValue) => ({
                  ...payoutValue,
                  title: val
                }))
              }
              inputLabel="Designation"
              // formError={holdOnsCodeError}
              value={payoutValue.title ?? ''}
            />
          </>
        ) : (
          ''
        )}
        <WeInput
          onChange={(val) =>
            setPayoutValue((payoutValue) => ({
              ...payoutValue,
              email: val,
              emailErr: ''
            }))
          }
          inputLabel="Email"
          formError={payoutValue?.emailErr}
          value={payoutValue?.email}
        />
        <WeInput
          onChange={(val) =>
            setPayoutValue((payoutValue) => ({
              ...payoutValue,
              firstName: val,
              firstNameErr: ''
            }))
          }
          inputLabel="First Name"
          formError={payoutValue?.firstNameErr}
          value={payoutValue?.firstName}
        />
        <WeInput
          onChange={(val) =>
            setPayoutValue((payoutValue) => ({
              ...payoutValue,
              lastName: val,
              lastNameErr: ''
            }))
          }
          inputLabel="Last Name"
          formError={payoutValue?.lastNameErr}
          value={payoutValue?.lastName}
        />
        <WeInput
          type={'number'}
          maxVal={9999999999}
          onKeyPress={(e) => blockInvalidChar(e)}
          onChange={(val) => {
            if (val.length < 11) {
              setPayoutValue((payoutValue) => ({
                ...payoutValue,
                phone: val,
                phoneErr: ''
              }));
            }
          }}
          inputLabel="Phone Number"
          formError={payoutValue?.phoneErr}
          value={payoutValue?.phone}
        />
        <WeInput
          onChange={(val) =>
            setPayoutValue((payoutValue) => ({
              ...payoutValue,
              dob: val,
              dobErr: ''
            }))
          }
          type={'date'}
          inputLabel="Date Of Birth"
          formError={payoutValue?.dobErr}
          value={payoutValue?.dob}
        />
        <WeInput
          onChange={(val) =>
            setPayoutValue((payoutValue) => ({
              ...payoutValue,
              addressLine1: val,
              addressLine1Err: ''
            }))
          }
          inputLabel="Street Name"
          formError={payoutValue?.addressLine1Err}
          value={payoutValue?.addressLine1 ?? ''}
        />
        <WeInput
          onChange={(val) =>
            setPayoutValue((payoutValue) => ({
              ...payoutValue,
              addressLine2: val,
              addressLine2Err: ''
            }))
          }
          inputLabel="Street Name 2"
          formError={payoutValue?.addressLine2Err}
          value={payoutValue?.addressLine2 ?? ''}
        />
        <WeInput
          onChange={(val) =>
            setPayoutValue((payoutValue) => ({
              ...payoutValue,
              city: val,
              cityErr: ''
            }))
          }
          inputLabel="City"
          formError={payoutValue?.cityErr}
          value={payoutValue.city ?? ''}
        />
        <div className="row">
          <div className="col-6">
            <WeInput
              onChange={(val) =>
                setPayoutValue((payoutValue) => ({
                  ...payoutValue,
                  postalCode: val,
                  postalCodeErr: ''
                }))
              }
              inputLabel="Zip Code"
              formError={payoutValue?.postalCodeErr}
              value={payoutValue.postalCode ?? ''}
            />
          </div>
          <div className="col-6">
            <WeDropDown
              label="State"
              selectState={stateListData}
              defaultValue={payoutValue.state ? selectValue(stateListData, payoutValue.state) : ''}
              emptyValue="Select State"
              onSelectItem={(item) => {
                setPayoutValue((payoutValue) => ({
                  ...payoutValue,
                  state: item, //stateListData[item],
                  stateErr: ''
                }));
              }}
            />
          </div>
        </div>
        <hr />
        <WeInput
          onChange={(val) =>
            setPayoutValue((payoutValue) => ({
              ...payoutValue,
              sssNo: val,
              sssNoErr: ''
            }))
          }
          inputLabel="SSS Number"
          formError={payoutValue?.sssNoErr}
          value={payoutValue.sssNo ?? ''}
        />
        <WeInput
          onChange={(val) =>
            setPayoutValue((payoutValue) => ({
              ...payoutValue,
              routingNumber: val,
              routingNumberErr: ''
            }))
          }
          inputLabel="Routing Number"
          formError={payoutValue?.routingNumberErr}
          value={payoutValue.routingNumber ?? ''}
        />
        <WeInput
          onChange={(val) =>
            setPayoutValue((payoutValue) => ({
              ...payoutValue,
              accountNumber: val,
              accountNumberErr: ''
            }))
          }
          inputLabel="Account Number"
          formError={payoutValue?.accountNumberErr}
          value={payoutValue.accountNumber ?? ''}
        />
        <WeInput
          onChange={(val) =>
            setPayoutValue((payoutValue) => ({
              ...payoutValue,
              confirmAccountNumber: val,
              confirmAccountNumberErr: ''
            }))
          }
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
          inputLabel="Account Number (re-enter)"
          formError={payoutValue?.confirmAccountNumberErr}
          value={payoutValue.confirmAccountNumber ?? ''}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="btn secondary-btn"
          onClick={() => {
            setPayoutValue({});
            onDialogClose();
          }}>
          Close
        </Button>
        <Button className="btn primary-btn" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(AddPayout);
