import { createSlice } from '@reduxjs/toolkit';
import api from '../../services';
import { API_RESPONSE, TESLOOMAPI } from '../../services/apiConstant';
import { toast } from 'react-toastify';

const slice = createSlice({
  name: 'event',
  initialState: {
    eventListData: [],
    attendeeListData: [],
    timezoneData: null,
    image: null,
    eventTypeData: [],
    message: '',
    hashTagData: null,
    isLoading: false,
    addressListData: [],
    addressDetailData: null,
    eventCreatedData: null,
    eventDetailData: null,
    userDashboardData: null,
    activeEventMenu: 0,
    eventScheduleList: [],
    activeSideMenu: null,
    addActivityStatus: null,
    eventTitle: '',
    previousTeamData: null,
    specificAttendeeData: [],
    ticketTypeData: [],
    eventHomeScreenData: null,
    checkInviteData: null,
    userPermissionData: null,
    isApiLoading: true,
    inviteError: null,
    eventURL: null,
    googleDescriptionLength: 0,
    isFetching: false,
    totalData: 0
  },
  reducers: {
    apiFetching: (state) => {
      state.isLoading = true;
    },
    eventCreatedSuccess: (state, action) => {
      state.eventCreatedData = action.payload;
      state.isLoading = false;
    },
    eventListDataSuccess(state, action) {
      if (action.payload == null) {
        state.eventListData = [];
      } else {
        state.eventListData = action.payload.data;
        state.isFetching = false;
        state.isLoading = false;
        state.totalData = action.payload ? action.payload.totalCount : 0;
      }
    },
    userDashboardSuccess(state, action) {
      state.userDashboardData = action.payload;
    },
    timezoneDataSuccess(state, action) {
      state.timezoneData = action.payload;
      state.isLoading = false;
    },
    eventTypeData(state, action) {
      state.eventTypeData = action.payload;
      state.isLoading = false;
    },
    imageUpload(state, action) {
      state.image = action.payload;
      state.isLoading = false;
    },

    hashTagList: (state, action) => {
      state.hashTagData = action.payload;
      state.isLoading = false;
    },
    getHashTagFailed: (state, action) => {
      state.message = action.payload;
      state.isLoading = false;
    },
    addressLists: (state, action) => {
      state.addressListData = action.payload ? action.payload : [];
      state.isLoading = false;
    },
    addressDetail: (state, action) => {
      state.addressDetailData = action.payload.result;
      state.isLoading = false;
    },

    eventDetailDataSuccess: (state, action) => {
      state.eventDetailData = action.payload;
      if (action.payload.googleDescription) {
        state.googleDescriptionLength = action.payload.googleDescription.length;
      }
    },
    EventDeletesuccess: (state, action) => {
      const deleteIndex = action.payload.deleteIndexId;
      state.eventListData = state.eventListData.filter((item) => item.id !== deleteIndex);
    },
    duplicateEventsuccess: (state, action) => {
      state.eventListData = [action.payload, ...state.eventListData];
    },
    attendeeListDataSuccess: (state, action) => {
      if (action.payload == null) {
        state.attendeeListData = [];
        state.isFetching = false;
        state.isLoading = false;
      } else {
        state.attendeeListData = action.payload.data;
        state.isFetching = false;
        state.isLoading = false;
        state.totalData = action.payload ? action.payload.totalCount : 0;
      }
    },
    activeEventMenuItem: (state, action) => {
      state.activeEventMenu = action.payload;
      state.isLoading = false;
    },
    activeSideMenuItem: (state, action) => {
      state.activeSideMenu = action.payload;
    },
    dataEventSuccess: (state, action) => {
      state.eventData = action.payload;
      state.isLoading = false;
    },
    checkInStatusSuccess(state, action) {
      const newObjId = action.payload.data._id;
      const suspendIndex = state.attendeeListData?.findIndex((item) => item._id === newObjId);
      let currentUser = action.payload.data;
      state.attendeeListData[suspendIndex] = currentUser;
      state.isLoading = false;
    },
    eventScheduleList: (state, action) => {
      state.eventScheduleList = action.payload;
      state.isLoading = false;
    },
    roomAddSuccess: (state, action) => {
      state.eventScheduleList = state.eventScheduleList.concat(action.payload);
    },
    roomADeleteSuccess(state, action) {
      let reqData = action.payload;
      state.eventScheduleList = state.eventScheduleList.filter((item) => item._id !== reqData);
    },
    //not in use
    addActivitySuccess: (state, action) => {
      state.addActivityStatus = true;
      let changeRoomId = action.payload._id;
      var roomIndex = state.eventScheduleList.findIndex((x) => x._id == changeRoomId);
      state.eventScheduleList[roomIndex] = action.payload;
    },
    clearEventData: (state) => {
      state.eventDetailData = null;
    },
    updateEventTitleSuccess(state, action) {
      state.eventTitle = action.payload;
    },
    editOrganizationInfoSuccess(state, action) {
      state.userDashboardData.organizationInfo = action.payload;
    },
    previousTeamSuccess: (state, action) => {
      if (action.payload.length == 0) {
        state.previousTeamData = null;
      } else {
        state.previousTeamData = action.payload;
      }
    },
    deletePreviousTeamSuccess(state, action) {
      state.previousTeamData.splice(action.payload, 1);
    },
    specificAttendeeSuccess(state, action) {
      state.specificAttendeeData = action.payload;
    },
    updateAttendeeSuccess(state, action) {
      const found = state.specificAttendeeData.findIndex((element) => element === action.payload);
      if (found < 0) {
        state.specificAttendeeData = state.specificAttendeeData.concat(action.payload);
      } else {
        if (state.specificAttendeeData.includes("all")) {
          state.specificAttendeeData = state.specificAttendeeData.filter(
            (item) => item !== 'all'
          );
        }
        state.specificAttendeeData = state.specificAttendeeData.filter(
          (item) => item !== action.payload
        );
      }
    },
    clearAttendeeSuccess(state, action) {
      state.specificAttendeeData = action.payload;
    },
    ticketTypeSuccess(state, action) {
      state.ticketTypeData = action.payload;
    },
    updateticketTypeSuccess(state, action) {
      const found = state.ticketTypeData.findIndex((element) => element === action.payload);
      if (found < 0) {
        state.ticketTypeData = state.ticketTypeData.concat(action.payload);
      } else {
        state.ticketTypeData = state.ticketTypeData.filter((item) => item !== action.payload);
      }
    },
    clearTicketTypeSuccess(state, action) {
      state.ticketTypeData = action.payload;
    },
    eventHomeScreenSuccess(state, action) {
      state.eventHomeScreenData = action.payload;
    },
    checkInviteSuccess(state, action) {
      state.checkInviteData = action.payload;
      state.isApiLoading = false;
    },
    userPermissionSuccess(state, action) {
      state.userPermissionData = action.payload.data;
      state.isOwnerValue = action.payload.isOwner;
      state.isApiLoading = false;
    },
    userPermissionFail(state) {
      state.isApiLoading = false;
    },
    fetchingEndSuccess: (state) => {
      state.isApiLoading = false;
    },
    updateCustomUrlSuccess: (state, action) => {
      state.eventHomeScreenData.eventUrl = action.payload;
    },
    clearEventTitleSuccess(state) {
      state.eventTitle = null;
      state.eventDetailData = null;
      state.userPermissionData = null;
      state.isOwnerValue = null;
      state.isApiLoading = true;
      state.attendeeListData = [];
      state.eventHomeScreenData = null;
      state.eventScheduleList = [];
      state.googleDescriptionLength = 0;
      localStorage.removeItem('calSelectedDate');
      // state.isLoading = true;
    },
    checkInviteError(state, action) {
      state.inviteError = action.payload;
      state.isApiLoading = false;
    },
    clearStatesDataSuccess(state) {
      state.userDashboardData = null;
      state.eventListData = [];
      state.userPermissionData = null;
      state.isOwnerValue = null;
    },
    setEventURLSuccess(state, action) {
      state.eventURL = action.payload;
    },
    clearCouponSuccess(state) {
      state.CouponData = null;
    },
    apiFatchingStartSuccess(state) {
      state.isFetching = true;
    },
    apiFatchingEndSuccess(state) {
      state.isFetching = false;
    },
    apiLoadingStartSuccess(state) {
      state.isLoading = true;
    },
    apiLoadingEndSuccess(state) {
      state.isLoading = false;
    },
    selectAllAttendeeSuccess(state, action) {
      const found = state.specificAttendeeData.findIndex(
        (element) => element === action.payload.id
      );
      if (found < 0 && !action.payload.checkStatus) {
        state.specificAttendeeData = state.specificAttendeeData.concat(action.payload.id);
      } else if (action.payload.checkStatus) {
        state.specificAttendeeData = state.specificAttendeeData.filter((item) => item !== action.payload.id);
      }
    }
  }
});
export default slice.reducer;

/**********************ACTIONS************************************************ */

const {
  eventCreatedSuccess,
  timezoneDataSuccess,
  eventTypeData,
  eventListDataSuccess,
  eventDetailDataSuccess,
  EventDeletesuccess,
  // duplicateEventsuccess,
  attendeeListDataSuccess,
  activeEventMenuItem,
  activeSideMenuItem,
  userDashboardSuccess,
  checkInStatusSuccess,
  eventScheduleList,
  roomAddSuccess,
  //addActivitySuccess,
  clearEventData,
  updateEventTitleSuccess,
  editOrganizationInfoSuccess,
  previousTeamSuccess,
  deletePreviousTeamSuccess,
  specificAttendeeSuccess,
  clearAttendeeSuccess,
  ticketTypeSuccess,
  updateticketTypeSuccess,
  clearTicketTypeSuccess,
  updateAttendeeSuccess,
  eventHomeScreenSuccess,
  checkInviteSuccess,
  userPermissionSuccess,
  userPermissionFail,
  fetchingEndSuccess,
  updateCustomUrlSuccess,
  clearEventTitleSuccess,
  checkInviteError,
  clearStatesDataSuccess,
  setEventURLSuccess,
  clearCouponSuccess,
  apiFatchingStartSuccess,
  apiFatchingEndSuccess,
  apiLoadingStartSuccess,
  apiLoadingEndSuccess,
  selectAllAttendeeSuccess,
  roomADeleteSuccess
} = slice.actions;

// const { imageSuccess } = slice.actions;
const { apiFetching, hashTagList, addressLists, addressDetail } = slice.actions;

export const clearOldEventData = () => async (dispatch) => {
  dispatch(clearEventData());
};
export const addEvent = (requestParams, onSave) => async (dispatch) => {
  dispatch(apiFetching());
  api
    .post(`${TESLOOMAPI.addEvent}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(eventCreatedSuccess(result.data));
        onSave();
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const updateEvent = (requestParams) => async (dispatch) => {
  dispatch(apiFetching());
  api
    .post(`${TESLOOMAPI.updateEvent}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(eventCreatedSuccess(result.data));
        toast.success('Event updated successfully...');
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};
export const duplicateEvent = (requestParams, navigate) => async () => {
  api
    .post(`${TESLOOMAPI.duplicateEvent}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        const id = result.data.eventUid;
        toast.success(result.message);
        navigate(`/event/${id}/edit/basicInfo`, true);
        //dispatch(duplicateEventsuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const listEvent = (page, limit, requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.eventListAll}?page=${page}&limit=${limit}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(
          eventListDataSuccess({ data: result.data, page: page, totalCount: result.totalCount })
        );
      } else {
        dispatch(eventListDataSuccess(null));
        dispatch(apiFatchingEndSuccess());
        // toast.error('Error', null);
      }
    })
    .catch(() => { });
};
export const attendeeList = (requestParams) => async (dispatch) => {
  api
    .post(
      `${TESLOOMAPI.attendeeList}?page=${requestParams.page}&limit=${requestParams.perPage}`,
      requestParams
    )
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(
          attendeeListDataSuccess({
            data: result.data,
            page: requestParams.page,
            totalCount: result.totalCount
          })
        );
      } else {
        attendeeListDataSuccess(null);
        dispatch(apiFatchingEndSuccess());
        dispatch(apiLoadingEndSuccess());
        // toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const timezoneList = () => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.timezoneList}`)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(timezoneDataSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const eventTypes = () => async (dispatch) => {
  api
    .get(`${TESLOOMAPI.eventTypeList}`)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(eventTypeData(result.data));
      }
    })
    .catch(() => { });
};

export const bannerImageUpload = (imagePath, folderName, formData, eventFormData, checkExtraFields) => async () => {
  api
    .post(`${TESLOOMAPI.uploadFile}?previousFile=${imagePath}&folderName=${folderName}`, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        let filename = result.imageName;
        let imageURL = result.imagePath;
        const eventDataWithBanner = eventFormData;
        eventDataWithBanner.bannerImage = filename;
        eventDataWithBanner.bannerImageName = imageURL;
        console.log("eventDataWithBanner == ",eventDataWithBanner);
        checkExtraFields(imageURL, eventDataWithBanner);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const extraImageUpload = (imagePath, folderName, formData, item,handleExtraField,) => async () => {
  api
    .post(`${TESLOOMAPI.uploadFile}?previousFile=${imagePath}&folderName=${folderName}`, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        let filename = result.imageName;
        let imageURL = result.imagePath;
        const imageData = {
          filename: filename,
          imageURL: imageURL
        }
       const data = handleExtraField(imageData,item);
       console.log("data == ",data);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const eventDetail = (id) => async (dispatch) => {
  api
    .get(`${TESLOOMAPI.eventDetail}/` + id)
    .then((response) => {
      let result = response.data;
      if (result.status == API_RESPONSE.SUCCESS) {
        dispatch(eventDetailDataSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const eventDelete = (id) => async (dispatch) => {
  api
    .delete(`${TESLOOMAPI.deleteEvent}/` + id)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message);
        dispatch(
          EventDeletesuccess({
            deleteIndexId: id
          })
        );
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const getHashTagList = (requestParams) => async (dispatch) => {
  // dispatch(apiFetching());
  api
    .post(`${TESLOOMAPI.hashTagList}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result) {
        dispatch(hashTagList(result.data));
      }
    })
    .catch(() => {
      dispatch(hashTagList([]));
    });
};

export const clearHashTagList = () => (dispatch) => {
  dispatch(hashTagList([]));
};

export const fetchAddresses = (searchData) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.searchAddress}`, searchData)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(addressLists(result.data.predictions));
      }
    })
    .catch(() => { });
};

export const clearAddressList = () => async (dispatch) => {
  //clear old data
  dispatch(addressLists(null));
};
export const getAddressDetail = (addressId) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.searchAddressDetail}`, addressId)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(addressDetail(result.data));
      }
    })
    .catch(() => { });
};

export const userDashboard = () => async (dispatch) => {
  api
    .get(`${TESLOOMAPI.userDashboard}`)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(userDashboardSuccess(result));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const eventSelectedActiveMenu = (data) => async (dispatch) => {
  dispatch(activeEventMenuItem(data));
};

export const clearCoupon = () => async (dispatch) => {
  dispatch(clearCouponSuccess());
};

export const sideDrawerActiveMenu = (data) => async (dispatch) => {
  dispatch(activeSideMenuItem(data));
};

export const changeCheckInStatus = (data) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.checkIn}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status == 0) {
        dispatch(checkInStatusSuccess(result));
        toast.success(`Attendee ${data.type == 1 ? 'Check In' : 'Check Out'} Successfully`);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};
export const addRoomForEvent = (data) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.addRoomForEvent}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(roomAddSuccess(result.data));
        toast.success(result.message);
      }
    })
    .catch(() => { });
};

export const deleteRoom = (data) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.deleteRoom}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(roomADeleteSuccess(data.roomId));
        toast.success(result.message);
      }
    })
    .catch(() => { });
};

export const getEventSchdeuleDate = (data) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.eventScheduleList}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(apiFatchingEndSuccess());
        dispatch(eventScheduleList(result.data));
      } else if (result.status === API_RESPONSE.FAILURE) {
        dispatch(apiFatchingEndSuccess());
        dispatch(eventScheduleList(null));
      }
    })
    .catch(() => { });
};

//function to add scheule in calendar for room
export const addActivityForEvent = (data, fetchEventScheduleList) => async () => {
  api
    .post(`${TESLOOMAPI.addActivityForEvent}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        toast.success(result.message);
        fetchEventScheduleList();
        //
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const editActivityInRoom = (data, fetchEventScheduleList) => async () => {
  api
    .post(`${TESLOOMAPI.editActivityInRoom}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        toast.success(result.message);
        fetchEventScheduleList();
        //
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const editOrganizationInfo = (data) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.editOrganizationInfo}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(editOrganizationInfoSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const updateEventTitle = (val) => async (dispatch) => {
  dispatch(updateEventTitleSuccess(val));
};

export const clearEventTitle = () => async (dispatch) => {
  dispatch(clearEventTitleSuccess());
};

export const getPreviousTeamList = () => async (dispatch) => {
  api
    .get(`${TESLOOMAPI.getPreviousTeamList}`)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(previousTeamSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const deletePreviousTeam = (val) => async (dispatch) => {
  dispatch(deletePreviousTeamSuccess(val));
};

export const updatePreviousEventTeam = (requestParams) => async () => {
  api
    .post(`${TESLOOMAPI.updatePreviousEventTeam}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const setSpecificAttendee = (val) => async (dispatch) => {
  dispatch(specificAttendeeSuccess(val));
};

export const updateSpecificAttendee = (val) => async (dispatch) => {
  dispatch(updateAttendeeSuccess(val));
};

export const selectAllAttendee = (val) => async (dispatch) => {
  dispatch(selectAllAttendeeSuccess(val));
};

export const clearSpecificAttendee = (val) => async (dispatch) => {
  dispatch(clearAttendeeSuccess(val));
};

export const setTicketType = (val) => async (dispatch) => {
  dispatch(ticketTypeSuccess(val));
};

export const updateTicketType = (val) => async (dispatch) => {
  dispatch(updateticketTypeSuccess(val));
};

export const clearTicketType = (val) => async (dispatch) => {
  dispatch(clearTicketTypeSuccess(val));
};

export const getEventHomeScreenData = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.getEventHomeScreenData}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(eventHomeScreenSuccess(result));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const checkInvite = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.checkInvite}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.FAILURE) {
        dispatch(checkInviteError('differentEmail'));
      } else if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(checkInviteSuccess(result.data));
      } else {
        dispatch(checkInviteError('differentEmail'));
      }
    })
    .catch(() => { });
};

export const updateTeamInviteStatus = (requestParams, navigate) => async () => {
  api
    .post(`${TESLOOMAPI.updateTeamInviteStatus}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        toast.success(result.message);
        navigate('/', true);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const getUserPermissionForEvent = (requestParams, navigate) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.getUserPermissionForEvent}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(userPermissionSuccess({ data: result.data, isOwner: result.isOwner }));
      } else {
        dispatch(userPermissionFail());
        toast.error(result.message);
        navigate('/');
      }
    })
    .catch(() => { });
};

export const apiFetchingEnd = () => async (dispatch) => {
  dispatch(fetchingEndSuccess());
};

export const addHastag = (requestParams) => async () => {
  api
    .post(`${TESLOOMAPI.addHastag}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        console.log('result == ', result);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const updateCustomUrl = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.updateCustomUrl}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        toast.success(result.message);
        dispatch(updateCustomUrlSuccess(requestParams.customUrl));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};
export const clearEventStates = () => async (dispatch) => {
  dispatch(clearStatesDataSuccess());
};

export const setEventURL = (val) => async (dispatch) => {
  dispatch(setEventURLSuccess(val));
};

export const deleteActivityInRoom = (requestParams, fetchEventScheduleList) => async () => {
  api
    .post(`${TESLOOMAPI.deleteActivityInRoom}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        toast.success(result.message);
        fetchEventScheduleList();
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const apiFatchingStart = () => async (dispatch) => {
  dispatch(apiFatchingStartSuccess());
};

export const apiFatchingEnd = () => async (dispatch) => {
  dispatch(apiFatchingEndSuccess());
};

export const apiLoadingStart = () => async (dispatch) => {
  dispatch(apiLoadingStartSuccess());
};

export const apiLoadingEnd = () => async (dispatch) => {
  dispatch(apiLoadingEndSuccess());
};

export const addAttendeesData = (requestParams, navigate, id) => async () => {
  api
    .post(`${TESLOOMAPI.addAttendees}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success('Order placed  successfully.');
        navigate(`/event/${id}/attendee/list`);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};
