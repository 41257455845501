import PaginatioonComponent from 'components/paginatioonComponent';
import OrderFilter from 'pages/subcomponents/orders/orderFilter';
import OrderList from 'pages/subcomponents/orders/orderList';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearSearchTicket, getOrganiserOrders, searchTicket } from 'store/slice/order';

const initialValues = {
  page: 1,
  perPage: 10
};

export default function Orders() {
  const dispatch = useDispatch();
  let page = 1;
  let limit = 10;
  const [pageNo, setPageNo] = useState(page);
  const [filter, setFilter] = useState(initialValues);
  const { getOrganiserOrdersData, totalCount } = useSelector((state) => state.order);
  const [autoFocus, setautoFocus] = useState(false);
  const [searchEmail, setSearchEmail] = useState('');
  const [ticketValue, setTicketValue] = useState('');
  const { ticketData } = useSelector((state) => state.order);
  const [checkListBox, setCheckListBox] = useState(false);

  useEffect(() => {
    fetchAllTickets();
    setFilter((filter) => ({
      ...filter,
      page: page,
      perPage: limit
    }));
  }, []);

  const handlePagination = (data) => {
    // const page = flag == 'next' ? pageNo + 1 : pageNo - 1;
    console.log('onPageChange', data.selected + 1);
    setPageNo(data.selected + 1);
  };

  useEffect(() => {
    setFilter((filter) => ({
      ...filter,
      page: pageNo
    }));
  }, [pageNo]);

  const fetchAllTickets = () => {
    dispatch(getOrganiserOrders(filter));
  };

  const selectedGroup = (data, value) => {
    const valueIndex = data?.findIndex((item) => item.ticketId === value);
    const valueAtIndex = data[valueIndex].ticketName;
    return valueAtIndex;
  };

  const handleSearch = (val) => {
    setSearchEmail(val);
    setautoFocus(true);
    if (val) {
      const data = {
        search: val
      };
      dispatch(searchTicket(data));
    } else {
      setFilter((filter) => ({
        ...filter,
        ticketId: ''
      }));
    }
  };

  useEffect(() => {
    fetchAllTickets();
  }, [filter]);

  return (
    <>
      <h2>All Orders</h2>
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

      <OrderFilter
        checkListBox={checkListBox}
        setCheckListBox={setCheckListBox}
        onSearchOrderChange={(val) => {
          setFilter((filter) => ({
            ...filter,
            search: val
          }));
          fetchAllTickets();
        }}
        filter={filter}
        isOrderType={true}
        onOrderTypeChange={(item) => {
          setFilter((filter) => ({
            ...filter,
            orderType: item
          }));
          fetchAllTickets();
        }}
        isAutoComplete={true}
        onTicketSearch={handleSearch}
        autoFocus={autoFocus ? 'autoFocus' : ''}
        clearSearch={() => {
          setSearchEmail('');
          dispatch(clearSearchTicket({ data: null }));
          setFilter((filter) => ({
            ...filter,
            ticketId: ''
          }));
          setTicketValue([]);
        }}
        searchEmail={
          searchEmail
            ? searchEmail
            : filter.ticketId
            ? selectedGroup(ticketValue, filter.ticketId)
            : ''
        }
        ticketData={searchEmail ? ticketData : ''}
        onSearchSelect={(item) => {
          setFilter((filter) => ({
            ...filter,
            ticketId: item.ticketId
          }));
          setTicketValue(ticketData);
          setSearchEmail('');
          dispatch(clearSearchTicket({ data: null }));
        }}
        onDateChange={(item) => {
          setFilter((filter) => ({
            ...filter,
            date: item
          }));
        }}
        onCheckListChange={(item) => {
          setFilter((filter) => ({
            ...filter,
            checkList: item.id
          }));
        }}
        onRefreshClick={() => {
          setSearchEmail('');
          setFilter(initialValues);
        }}
      />

      <OrderList
        getEventOrdersData={getOrganiserOrdersData}
        checkListBox={checkListBox}
        setCheckListBox={setCheckListBox}
      />

      {getOrganiserOrdersData && totalCount > limit ? (
        <div className="mt-3" style={{ float: 'right' }}>
          <PaginatioonComponent
            pageCount={
              totalCount % limit === 0
                ? Math.floor(totalCount / limit)
                : Math.floor(totalCount / limit) + 1
            }
            onPageChange={handlePagination}
            pageNo={pageNo}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
}
