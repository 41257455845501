import { BrowserRouter } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Router from './routes';
import { AxiosInterceptor } from 'services';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { TESLOOMAPI } from 'services/apiConstant';
import LoadingComponent from 'pages/loadingComponent';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const App = () => {
  const [isVerified, setIsVerified] = useState(true);

  useEffect(() => {
    userDashboardApiCalling();
  }, []);

  const userDashboardApiCalling = () => {
    axios
      .get(`${TESLOOMAPI.userDashboard}`)
      .then((response) => {
        console.log('response == ', response);
        setIsVerified(false);
      })
      .catch((error) => {
        console.log(error);
        setIsVerified(false);
      });
  };

  return (
    <div className="App">
      {isVerified ? (
        <LoadingComponent />
      ) : (
        <>
          <ToastContainer />
          <BrowserRouter>
            <AxiosInterceptor>
              <Router />
            </AxiosInterceptor>
          </BrowserRouter>
        </>
      )}
    </div>
  );
};

export default App;
