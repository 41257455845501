import Autocomplete from 'components/autocomplete';
import OrderFilterDialog from 'components/dialogs/orderFilterDialog';
import WeDropDown from 'components/weDropDown';
import WeInput from 'components/weInput';
import SalesFilter from 'pages/reports/subcomponents/salesFilter';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addAllCheckList, clearAllCheckList } from 'store/slice/order';

const OrderFilter = ({ ...props }) => {
  const {
    filter,
    onOrderTypeChange,
    onSearchOrderChange,
    onTicketNameChange,
    onDateChange,
    onStartDateChange,
    onEndDateChange,
    ticketListData,
    onTicketSearch,
    autoFocus,
    clearSearch,
    searchEmail,
    onSearchSelect,
    ticketData,
    handleRemoveFilter,
    // onTicketTypeChange,
    // onPaymentChange,
    // onVenuesChange,
    onRefreshClick,
    onActionChange,
    checkListBox,
    onAllCheckChange,
    actionValue,
    key,
    getEventOrdersData
  } = props;

  const [show, setShow] = useState(false);
  // const handleShow = () => setShow(true);
  const { id } = useParams();
  const handleClose = () => {
    setShow(false);
  };
  const dispatch = useDispatch();

  const orderTypeData = [
    {
      label: 'All',
      id: 'all'
    },
    {
      label: 'Social',
      id: 'social'
    },
    {
      label: 'Congress',
      id: 'congress'
    }
  ];

  const dateData = [
    {
      label: 'All',
      id: ''
    },
    {
      label: 'Last Week',
      id: 'Week'
    },
    {
      label: 'Last Month',
      id: 'Month'
    },
    {
      label: 'Last Year',
      id: 'Year'
    }
  ];

  const actionData = [
    {
      label: 'Resend Order Notification',
      id: 'notification'
    },
    {
      label: 'Refund',
      id: 'Refund'
    }
  ];

  const handleAllCheckList = (val) => {
    onAllCheckChange();
    // setCheckListBox(!checkListBox);
    if (val) {
      getEventOrdersData?.map((item) => {
        dispatch(addAllCheckList(item._id));
      });
    } else {
      dispatch(clearAllCheckList());
    }
  };

  return (
    <>
      <WeInput inputLabel="Search Order" onChange={onSearchOrderChange} value={filter?.search} />

      {id ? (
        <SalesFilter
          filter={filter}
          hideRefreshBtn={true}
          ticketListData={ticketListData}
          onStartDateChange={onStartDateChange}
          onEndDateChange={onEndDateChange}
          handleRemoveFilter={handleRemoveFilter}
        // onTicketChange={onTicketTypeChange}
        // onPaymentChange={onPaymentChange}
        // onVenuesChange={onVenuesChange}
        />
      ) : (
        <div className="row d-none d-lg-flex">
          <div className={!id ? 'col-6 col-lg-4 col-xl-4' : 'col-6 col-lg-4 col-xl-3'}>
            <Autocomplete
              key={key}
              inputLabel="Ticket Name"
              autoFocus={autoFocus}
              onSearchChange={onTicketSearch}
              clearValue={clearSearch}
              value={searchEmail}
              searchTicket={ticketData}
              onItemSelected={onSearchSelect}
              placeholder={'Search Ticket'}
            />
          </div>
          <div className={!id ? 'col-6 col-lg-4 col-xl-4' : 'col-6 col-lg-4 col-xl-3'}>
            <WeDropDown
              label="Date"
              btnWidth={true}
              selectData={dateData}
              onSelectItem={onDateChange}
              defaultValue={filter.date ? filter.date : ''}
              emptyValue="Select Date"
            />
          </div>
        </div>
      )}

      <div className="mt-3 d-flex">
        {id ? (
          // <div className="btn-group">
          //   <button
          //     type="button"
          //     className="btn btn-checkbox d-flex"
          //     onClick={() => handleAllCheckList(!checkListBox)}>
          //     <WeCheckbox onChange={(val)=> handleAllCheckList(val)}/>
          //     <span>All</span>
          //   </button>
          // </div>

          <label htmlFor="myCheck">
            <div className="btn-group" htmlFor="myCheck">
              <div className='btn btn-checkbox d-flex'>
                <input className="form-check-input order-checkbox" id="myCheck" type="checkbox" onClick={() => handleAllCheckList(!checkListBox)} />
                <span>All</span>
              </div>
            </div>
          </label>
        ) : (
          ''
        )}
        <div className="btn-group">
          <button type="button" className="btn btn-checkbox" onClick={onRefreshClick}>
            <i className="fa-solid fa-rotate-right"></i>
          </button>
        </div>
        {id ? (
          <WeDropDown
            label="Action"
            className={'dropdown input-block mt-0'}
            selectData={actionData}
            onSelectItem={onActionChange}
            defaultValue={actionValue ? actionValue : ''}
            emptyValue="Select action"
          />
        ) : (
          ''
        )}
      </div>

      {/* <a href="" className="mt-3 d-block" data-bs-toggle="modal" data-bs-target="#refundAllModal">
        Refund all Modal
      </a> */}
      {/* <!-- Refund all Modal --> */}
      {/* <div
        className="modal fade ai-modal"
        id="refundAllModal"
        tabIndex="-1"
        aria-labelledby="refundAllModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content add-room-modal">
            <div className="modal-body">
              <a href="#" className="close" data-bs-dismiss="modal" aria-label="Close">
                <i className="fa-solid fa-xmark"></i>
              </a>
              <div className="notice-icon"></div>
              <h3 className="mb-1">Ticket Transfer</h3>
              <div className="dropdown input-block">
                <label className="form-label" htmlFor="ticket">
                  Ticket
                </label>
                <div className="select-block">
                  <select name="ticket">
                    <option>Ticket 1 (Prev Attendee)</option>
                    <option>Ticket 2 (Prev Attendee)</option>
                    <option>Ticket 3 (Prev Attendee)</option>
                    <option>Ticket 4 (Prev Attendee)</option>
                  </select>
                </div>
              </div>
              <div className="input-block">
                <label htmlFor="usernameEmailAddress" className="form-label">
                  Username / Email Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="usernameEmailAddress"
                  aria-describedby="usernameEmailAddress"
                  value=""
                  placeholder=""
                />
              </div>
              <a href="" className="mt-3 d-block">
                New Attendee?
              </a>
              <div className="d-flex justify-content-end mt-4">
                <a
                  href="#"
                  className="btn primary-btn ms-auto"
                  data-bs-toggle="modal"
                  data-bs-target="#step2Modal">
                  Complete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <OrderFilterDialog
        showDialog={show}
        onDialogClose={handleClose}
        onTicketNameChange={onTicketNameChange}
        dateData={dateData}
        onDateChange={onDateChange}
        btnSave={handleClose}
        key={key}
        autoFocus={autoFocus}
        onTicketSearch={onTicketSearch}
        clearSearch={clearSearch}
        searchEmail={searchEmail}
        ticketData={ticketData}
        onSearchSelect={onSearchSelect}
        ticketListData={ticketListData}
        orderTypeData={orderTypeData}
        onOrderTypeChange={onOrderTypeChange}
      />
    </>
  );
};
export default React.memo(OrderFilter);
