import React, { useState } from 'react';
import NewUsers from './subcomponents/newUsers';

const buttonData = [
  {
    icon: 'fa-solid fa-money-bill-transfer',
    title: 'Revenue',
    _id: 'revenue'
  },
  {
    icon: 'fa-solid fa-eye',
    title: 'Total Views',
    _id: 'views'
  },
  {
    icon: 'fa-solid fa-users',
    title: 'Attendees',
    _id: 'attendees'
  }
];

export default function Analytics() {
  const [btnData, setBtnData] = useState('revenue');

  return (
    <>
      <h2>Analytics</h2>

      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</p>

      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/reports">Reports</a>
            </li>
            <li className="breadcrumb-item">Analytics</li>
          </ol>
        </nav>
      </div>

      <ul className="nav nav-tabs schedule-tabs slide mt-4" id="myTab" role="tablist">
        {buttonData ? (
          buttonData?.map((item) => {
            return (
              <>
                <li className="nav-item" role="presentation">
                  <button
                    className={btnData == item._id ? 'nav-link active' : 'nav-link'}
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="home-tab-pane"
                    aria-selected="true"
                    onClick={() => setBtnData(item._id)}>
                    <div className="analytics-block">
                      <div className="icon">
                        <i className={item.icon}></i>
                      </div>
                      <div className="title">{item.title}</div>
                    </div>
                  </button>
                </li>
              </>
            );
          })
        ) : (
          <p className="text-center pt-5"> No Data Found</p>
        )}
      </ul>
      <div className="tab-content" id="myTabContent">
        {/* {btnData == 'users' ? (
          <NewUsers />
        ) : btnData == 'views' ? (
          <div
            className={btnData == 'views' ? '' : 'tab-pane fade'}
            id="profile-tab-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabIndex="0">
            pop2
          </div>
        ) : (
          <div
            className={btnData == 'conversions' ? '' : 'tab-pane fade'}
            id="contact-tab-pane"
            role="tabpanel"
            aria-labelledby="contact-tab"
            tabIndex="0">
            pop3
          </div>
        )} */}
        <NewUsers btnData={btnData} />
      </div>
    </>
  );
}
