import React, { useEffect, useState } from 'react';
import TotelCounts from './subcomponents/totelCounts';
import EventList from './subcomponents/eventList';
import { useDispatch, useSelector } from 'react-redux';
import { clearOldEventData, sideDrawerActiveMenu, userDashboard } from 'store/slice/event';
import { useNavigate } from 'react-router-dom';
import WeButton from 'components/button';
import EditOrganizationInfoDialog from 'components/dialogs/editOrganizationInfoDialog';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDashboardData } = useSelector((state) => state.event);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const organizationInfo = userDashboardData?.organizationInfo;

  useEffect(() => {
    dispatch(userDashboard());
  }, []);

  const handleUpdate = (path) => {
    if (path) {
      dispatch(clearOldEventData());
      dispatch(sideDrawerActiveMenu(path));
      navigate(path);
    }
  };

  const handleOpenUrl = (url) => {
    window.open(url, '_blank');
  };

  // const formatNumber = (num) => {
  //   let number = typeof num == 'number' ? num.toString() : num
  //   number = number.replace(/,/g, '').replace("$", "")
  //   if (number > 99999) {
  //     if (number >= 10000000) {
  //       return (number / 10000000).toFixed(2) + ' Cr';
  //     } else if (number >= 100000) {
  //       return (number / 100000).toFixed(2) + ' Lakh';
  //     }
  //     return number.toString();
  //   } else {
  //     return num;
  //   }
  // };

  return (
    <>
      <h1>Overview</h1>

      <div className="row gx-3 mb-4">
        <TotelCounts
          icon={'fa-solid fa-sack-dollar'}
          title={'Net Sales'}
          number={userDashboardData?.netSales ? userDashboardData?.netSales : 0}
        />
        <TotelCounts
          icon={'fa-kit fa-tickets'}
          title={'Ticket Sold'}
          number={userDashboardData?.ticketSold ? userDashboardData?.ticketSold : 0}
        />
        <TotelCounts
          icon={'fa-solid fa-calendar-lines-pen'}
          title={'Next Event'}
          number={userDashboardData?.nextEvent}
        />
      </div>
      <hr />
      <h4>Events ({userDashboardData?.totalEventCount})</h4>

      <div className="overview-events d-inline-flex">
        {userDashboardData &&
          userDashboardData?.upcomingEvent.map((item) => {
            return (
              <>
                <EventList
                  ticketData={item}
                  onClickItem={(itemData) =>
                    handleUpdate(`/event/${itemData.eventUid}/edit/basicInfo`)
                  }
                />
              </>
            );
          })}

        <a
          className="overview-block"
          onClick={() => {
            handleUpdate('/event/create');
          }}>
          <div className="event-block create-event">
            <div className="background-image">
              <div className="overlay"></div>
            </div>
            <div className="content d-flex flex-column">
              <i className="fa-solid fa-plus"></i>
              <div className="text">Create Event</div>
            </div>
          </div>
        </a>
      </div>
      <a
        className="btn primary-btn mb-4"
        onClick={() => {
          handleUpdate('/event/dashboard');
        }}>
        View All Events
      </a>
      <hr />
      <div className="row">
        <div className="col-12 col-lg-5">
          <div className="company-info-overview">
            <h4 className="mb-3">Organization Info</h4>
            <h5>Name</h5>
            <p>{organizationInfo?.organizationName ? organizationInfo.organizationName : '-'}</p>
            <h5>Bio</h5>
            <p>{organizationInfo?.organizationBio ? organizationInfo.organizationBio : '-'}</p>
            <h5>Website</h5>
            <p>
              <a
                href="#"
                onClick={() =>
                  handleOpenUrl(organizationInfo?.website ? organizationInfo.website : '#')
                }>
                {organizationInfo?.website ? organizationInfo.website : '-'}
              </a>
            </p>
            <h5>Organization URL</h5>
            <p>
              <a
                href="#"
                onClick={() =>
                  handleOpenUrl(
                    organizationInfo?.organzationUrl ? organizationInfo.organzationUrl : '#'
                  )
                }>
                {organizationInfo?.organzationUrl ? organizationInfo.organzationUrl : '-'}
              </a>
            </p>
            <WeButton text="Edit Organization Info" onClick={() => setShowInfoDialog(true)} />
          </div>
        </div>
      </div>

      <EditOrganizationInfoDialog
        showDialog={showInfoDialog}
        organizationData={organizationInfo}
        onDialogClose={() => setShowInfoDialog(false)}
      />
    </>
  );
};

export default Dashboard;
