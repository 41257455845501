import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTicket,
  addTicketInGroup,
  allTicketList,
  createTicketGroup,
  deleteGroup,
  deleteTicket,
  editTicket,
  listAllTickets
} from 'store/slice/tickets';
import CreateGroupDialog from 'components/dialogs/createGroupDialog';
import AddTicketDialog from 'components/dialogs/addTicketDialog';
import TicketItem from '../subcomponents/ticketItem';
import GroupItems from '../subcomponents/groupItem';
import ConfirmDialog from 'components/dialogs/confirmDialog';
import EventContext from 'context/eventContext';
import EmptyView from 'components/emptyView';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { currentTime, todayDate } from 'utils/appConstant';
import { listAllCoupons } from 'store/slice/coupon';
import { clearSpecificAttendee, updateSpecificAttendee } from 'store/slice/event';

const TabTickets = () => {
  let { eventFormData } = useContext(EventContext);
  const dispatch = useDispatch();

  const initialValues = {
    ticketId: '',
    eventUid: eventFormData.eventUid,
    type: '',
    groupId: '',
    name: '',
    capacity: 0,
    price: 0,
    feeSubtraction: false,
    availability: '',
    startDate: todayDate(),
    startTime: currentTime(),
    endDate: eventFormData.endDate,
    endTime: eventFormData.endTime,
    endOfSaleOf: '',
    description: '',
    visibility: '',
    minTicket: 1,
    maxTicket: ''
  };

  const [addTicketvalues, setaddTicketValues] = useState(initialValues);
  const [groupMenuId, setGroupMenuId] = useState(-1);
  const [openTicketMenu, setOpenTicketMenu] = useState(-1);
  const [groupName, setgroupName] = useState('');
  const [groupNameError, setgroupNameError] = useState('');
  const [showGroupDialog, setShowGroupDialog] = useState(false);
  const [basicBtnActive, setBasicBtnActive] = useState(true);
  const [addTicketsDialog, setAddTicketsDialog] = useState(false);
  const [dragTicketItem, setDragTicketItem] = useState('');
  const [confirmDialogData, setConfirmDialogData] = useState(null);
  const [showEventType, setShowEventType] = useState(false);
  const [selectGroup, setSelectGroup] = useState(false);
  const [availability, setAvailability] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const { ticketItem } = useSelector((state) => state.tickets);
  const { listAllCouponsData } = useSelector((state) => state.coupon);
  const { specificAttendeeData } = useSelector((state) => state.event);
  const [selectTicket, setSelectTicket] = useState(false);

  //const for fee substraction
  const [feeToggleChecked, setFeeToggleChecked] = useState(false);

  const { groupItemData, ticketsItemData, ticketListData, eventCapacityData } = useSelector(
    (state) => state.tickets
  );

  const proceData = {
    ticketPrice: 0,
    serviceFee: 0,
    serviceFeeTax: 0,
    costToBuyer: 0,
    yourPayout: 0
  };

  const [calPrice, setCalPrice] = useState(proceData);
  const typeData = [
    {
      label: 'Paid',
      id: 'paid'
    },
    {
      label: 'Free',
      id: 'free'
    },
    {
      label: 'Donation',
      id: 'donation'
    }
  ];

  const availabilityData = [
    {
      label: 'End of sale of',
      id: 'endOfSaleOf'
    },
    {
      label: 'Date & Time',
      id: 'dateAndTime'
    }
  ];

  const visibilityData = [
    {
      label: 'Visible',
      id: 'visible'
    },
    {
      label: 'Hidden',
      id: 'hidden'
    },

    {
      label: 'Hidden When Not In Sale',
      id: 'hiddenWhenNotInSale'
    }
  ];

  const { id } = useParams();

  useEffect(() => {
    fetchAllTickets();
  }, []);


  useEffect(() => {
    setaddTicketValues((addTicketvalues) => ({
      ...addTicketvalues,
      addedCouponData: specificAttendeeData
    }))
  }, [specificAttendeeData]);

  useEffect(() => {
    if (ticketListData && ticketListData.length > 0) {
      setaddTicketValues((addTicketvalues) => ({
        ...addTicketvalues,
        availability: 'dateAndTime'
      }));
    }
  }, [ticketListData]);

  useEffect(() => {
    if (groupItemData?.length == 0) {
      setaddTicketValues((addTicketvalues) => ({
        ...addTicketvalues,
        groupId: 'no_group'
      }));
    }
  }, [groupItemData]);

  useEffect(() => {
    setaddTicketValues((addTicketvalues) => ({
      ...addTicketvalues,
      ...ticketItem
    }));
  }, [ticketItem]);

  const fetchAllTickets = () => {
    const requestParam = {
      eventUid: eventFormData.eventUid ?? id
    };
    dispatch(listAllTickets(requestParam));
    dispatch(allTicketList(requestParam));
    dispatch(listAllCoupons(requestParam));
  };
  const deleteGroupById = (item) => {
    dispatch(deleteGroup(item.id));
  };

  const dialogClose = () => {
    setAddTicketsDialog(false);
    setaddTicketValues(initialValues);
    dispatch(clearSpecificAttendee([]));    
    setOpenTicketMenu(-1);
    setBasicBtnActive(true);
    setaddTicketValues((addTicketvalues) => ({
      ...addTicketvalues,
      availability: 'dateAndTime'
    }));
  };

  const deleteTicketById = (item) => {
    let request = {
      groupId: item.groupId,
      ticketId: item._id
    };
    dispatch(deleteTicket(request));
  };

  //if toggle is change
  useEffect(() => {
    priceCalc(addTicketvalues.price, true);
  }, [feeToggleChecked]);

  useEffect(() => {
    priceCalc(addTicketvalues.price, true);
  }, [addTicketvalues]);

  const priceCalc = (val, onlineBtnValue) => {
    const value = val;
    const serviceFeeForOnline = value * 0.037 + 1.79;
    const serviceFeeForAtdoor = value * 0.01;
    const serviceFee = onlineBtnValue ? serviceFeeForOnline : serviceFeeForAtdoor;
    const serviceFeeTax = (serviceFee * 0.1).toFixed(2);
    let serviceFees = (parseFloat(serviceFeeTax) + parseFloat(serviceFee)).toFixed(2);
    let costToBuyer = 0;
    if (!addTicketvalues.feeSubtraction) {
      costToBuyer = (
        parseFloat(value) +
        parseFloat(serviceFee) +
        parseFloat(serviceFeeTax)
      ).toFixed(2);

      setCalPrice((calPrice) => ({
        ...calPrice,
        ticketPrice: value,
        serviceFee: serviceFees,
        serviceFeeTax: serviceFeeTax,
        costToBuyer: costToBuyer,
        yourPayout: value
      }));
      setaddTicketValues((addTicketvalues) => ({
        ...addTicketvalues,
        buyPrice: costToBuyer,
        fees: serviceFee.toFixed(2)
      }));
    } else {
      costToBuyer = (
        parseFloat(value) -
        parseFloat(serviceFee) -
        parseFloat(serviceFeeTax)
      ).toFixed(2);
      setCalPrice((calPrice) => ({
        ...calPrice,
        ticketPrice: value,
        serviceFee: serviceFees,
        serviceFeeTax: serviceFeeTax,
        costToBuyer: value,
        yourPayout: costToBuyer
      }));
      setaddTicketValues((addTicketvalues) => ({
        ...addTicketvalues,
        buyPrice: costToBuyer,
        fees: serviceFee.toFixed(2)
      }));
    }
  };

  const editTicketById = (item) => {
    setaddTicketValues((addTicketValues) => ({
      ...addTicketValues,
      ...item,
      ticketId: item._id,
      eventUid: eventFormData.eventUid ?? id,
      groupId: item.groupId ? item.groupId : 'no_group'
    }));
    item.couponArray && item.couponArray?.map((item) => {
      dispatch(updateSpecificAttendee(item._id));
    });
    if (item.couponArray?.length == ticketListData?.length) {
      dispatch(updateSpecificAttendee('all'));
    }
    setAddTicketsDialog(true);
  };

  const btnAddGroup = () => {
    if (!groupName) {
      setgroupNameError('Please enter group name');
      return;
    }
    const createGroupData = {
      eventUid: eventFormData.eventUid ?? id,
      name: groupName
    };
    dispatch(createTicketGroup(createGroupData));
    setShowGroupDialog(false);
  };

  const handleAddTicketSubmit = () => {
    // const strAfterDot = addTicketvalues?.price?.split('.', 2)[1];
    if (!addTicketvalues.type) {
      return toast.error('Please select type');
    }
    if (!addTicketvalues.name) {
      return toast.error('Please add ticket name');
    }
    if (addTicketvalues.type == 'paid' && !addTicketvalues.price) {
      return toast.error('Please add price');
    }
    if (addTicketvalues.availability == 'endOfSaleOf' && !addTicketvalues.endOfSaleOf) {
      return toast.error('Please add ticket');
    }
    if (addTicketvalues.availability == 'dateAndTime' || selectTicket?.length == 0) {
      if (!addTicketvalues.startDate || !addTicketvalues.startTime) {
        return toast.error('Please add start date and time');
      }
      if (!addTicketvalues.endDate || !addTicketvalues.endTime) {
        return toast.error('Please add end date and time');
      }
    }
    if (
      addTicketvalues.startDate == addTicketvalues.endDate &&
      addTicketvalues.startTime > addTicketvalues.endTime
    ) {
      return toast.error('Please select valid time');
    }
    if (addTicketvalues.groupId == 'no_group') {
      addTicketvalues.groupId = '';
    }
    if (addTicketvalues.minTicket > 1) {
      if (addTicketvalues.maxTicket < addTicketvalues.minTicket) {
        return toast.error('Max Ticket shoud be greater then min ticket');
      }
    }
    // if (strAfterDot?.length < 2) {
    //   addTicketvalues.price = addTicketvalues?.price + '0';
    // }
    if (addTicketvalues._id) {
      dispatch(editTicket(addTicketvalues, fetchAllTickets));
    } else {
      dispatch(addTicket(addTicketvalues, fetchAllTickets));
    }
    dialogClose();
  };

  const addTicketToGroupOnDrop = (item) => {
    const data = {
      eventUid: eventFormData.eventUid ?? id,
      groupId: item ? item.id : '',
      ticketId: dragTicketItem._id
    };
    dispatch(addTicketInGroup(data, fetchAllTickets));
  };

  const renderGroupList = () => {
    return (
      <>
        <div className="fs-5">
          <strong>Event Capacity: 0/{eventCapacityData}</strong>
        </div>

        <div className="group-heading d-none d-lg-block">
          <div className="row">
            <div className="col-lg-6">
              <div className="d-flex align-items-center">
                <div className="group-name">
                  <div className="title">Group Name</div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="text-end">Sold</div>
            </div>
            <div className="col-lg-3">
              <div className="text-end">Price</div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>

        {groupItemData &&
          groupItemData?.map((groupItem, i) => {
            return (
              <>
                <div className="group-section">
                  <GroupItems
                    key={i}
                    openGroupMenuId={groupMenuId}
                    onItemGroupMenuClick={(groupId) => setGroupMenuId(groupId)}
                    itemData={groupItem}
                    onDropComplete={(item) => {
                      addTicketToGroupOnDrop(item);
                    }}
                    onDeleteGroup={(item) => {
                      setGroupMenuId(-1);
                      setConfirmDialogData((confirmDialogData) => ({
                        ...confirmDialogData,
                        show: true,
                        type: 'Group',
                        item: item,
                        saveBtnHide: item.totalSold ? true : false,
                        title: 'Delete Group',
                        message: item.totalSold
                          ? "You can't delete goup as some tickets of this group are already sold"
                          : 'Are you sure, you want to delete group?'
                      }));
                    }}
                  />
                  <div
                    className="tickets"
                    onDrop={() => {
                      addTicketToGroupOnDrop(groupItem);
                    }}
                    onDragOver={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}>
                    {groupItem.tickets.map((item, index) => {
                      return (
                        <>
                          <TicketItem
                            key={index}
                            openTicketMenu={openTicketMenu}
                            onItemMenuClick={(menuId) => setOpenTicketMenu(menuId)}
                            itemData={item}
                            eventFormData={eventFormData}
                            onDragStart={(item) => {
                              setDragTicketItem(item);
                            }}
                            groupItemData={groupItemData}
                            onEditItem={() => {
                              setOpenTicketMenu(-1);
                              editTicketById(item);
                            }}
                            onDeleteItem={(item) => {
                              setOpenTicketMenu(-1);
                              setConfirmDialogData((confirmDialogData) => ({
                                ...confirmDialogData,
                                show: true,
                                type: 'Ticket',
                                item: item,
                                saveBtnHide: groupItem.totalSold ? true : false,
                                title: 'Delete Ticket',
                                message: groupItem.totalSold
                                  ? "You can't delete this ticket as some tickets of this group are already sold, If you want to delete this ticket then ungoup this ticket first"
                                  : 'Are you sure, you want to delete ticket?'
                              }));
                            }}
                          />
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            );
          })}
        <div
          style={{
            height: ticketsItemData?.length == 0 ? '100px' : '',
            width: ticketsItemData?.length == 0 ? '100%' : ''
          }}
          onDrop={() => {
            addTicketToGroupOnDrop('');
          }}
          onDragOver={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}>
          {ticketsItemData &&
            ticketsItemData?.map((item, index) => {
              return (
                <TicketItem
                  key={index}
                  openTicketMenu={openTicketMenu}
                  onItemMenuClick={(menuId) => setOpenTicketMenu(menuId)}
                  itemData={item}
                  eventFormData={eventFormData}
                  onDragStart={(item) => {
                    setDragTicketItem(item);
                  }}
                  groupItemData={groupItemData}
                  onEditItem={() => {
                    setOpenTicketMenu(-1);
                    editTicketById(item);
                  }}
                  onDeleteItem={(item) => {
                    setOpenTicketMenu(-1);
                    setConfirmDialogData((confirmDialogData) => ({
                      ...confirmDialogData,
                      show: true,
                      type: 'Ticket',
                      item: item,
                      saveBtnHide: item.sold ? true : false,
                      title: 'Delete Ticket',
                      message: item.sold
                        ? "You can't delete this ticket as some tickets are already sold"
                        : 'Are you sure, you want to delete ticket?'
                    }));
                  }}
                />
              );
            })}
        </div>
      </>
    );
  };
  return (
    <>
      <div className="mt-3">
        <div className="mb-3">
          <a className="btn secondary-btn mt-2" onClick={() => setShowGroupDialog(true)}>
            Create Group
          </a>

          <a
            className="btn primary-btn mt-2 mx-2"
            onClick={() => {
              setAddTicketsDialog(true);
              setaddTicketValues((addTicketvalues) => ({
                ...addTicketvalues,
                eventUid: eventFormData.eventUid ?? id,
                endDate: eventFormData.endDate,
                endTime: eventFormData.endTime
              }));
            }}>
            Add Tickets
          </a>
        </div>

        {ticketsItemData &&
          ticketsItemData.length === 0 &&
          groupItemData &&
          groupItemData.length === 0 ? (
          <>
            <EmptyView text="No Group" message="Create Group/Ticket for your event." />
          </>
        ) : (
          <> {renderGroupList()}</>
        )}
      </div>

      <CreateGroupDialog
        showDialog={showGroupDialog}
        btnSave={btnAddGroup}
        formError={groupNameError}
        onInputChange={(e) => {
          if (e.target.value) {
            setgroupNameError('');
          }
          setgroupName(e.target.value);
        }}
        onDialogClose={() => setShowGroupDialog(false)}
      />

      <AddTicketDialog
        showDialog={addTicketsDialog}
        onDialogClose={dialogClose}
        basicBtnActive={basicBtnActive}
        basic={() => setBasicBtnActive(true)}
        advanced={() => setBasicBtnActive(false)}
        eventFormData={eventFormData}
        addTicketvalues={addTicketvalues}
        // selectedCouponData={(val) =>
        //   setaddTicketValues((addTicketvalues) => ({
        //     ...addTicketvalues,
        //     addedCouponData: val
        //   }))}
        typeDropdown={showEventType}
        typeDropdownToggle={() => setShowEventType(!showEventType)}
        type={typeData}
        couponsData={
          listAllCouponsData &&
          listAllCouponsData?.map((item) => ({
            label: `${item.name}`,
            id: item._id
          }))
        }
        setTypeData={(item) => {
          setaddTicketValues((addTicketvalues) => ({
            ...addTicketvalues,
            type: item
          }));
          if (item == 'free') {
            setaddTicketValues((addTicketvalues) => ({
              ...addTicketvalues,
              price: 0
            }));
            priceCalc(0);
          }
        }}
        onTicketNameInputChange={(val) =>
          setaddTicketValues((addTicketvalues) => ({
            ...addTicketvalues,
            name: val
          }))
        }
        selectGroupDropdown={selectGroup}
        selectGroupDropdownToggle={() => setSelectGroup(!selectGroup)}
        selectGroup={
          groupItemData &&
          groupItemData?.map((item) => ({
            label: `${item.groupName}`,
            id: item.id
          }))
        }
        setSelectGroupData={(item) => {
          setaddTicketValues((addTicketvalues) => ({
            ...addTicketvalues,
            groupId: item
          }));
        }}
        onQuantityInputChange={(val) => {
          if (addTicketvalues.sold) {
            setaddTicketValues((addTicketvalues) => ({
              ...addTicketvalues,
              capacity: val >= addTicketvalues.sold ? val : addTicketvalues.capacity
            }))
          }
          else {
            setaddTicketValues((addTicketvalues) => ({
              ...addTicketvalues,
              capacity: val
            }))
          }
        }}
        onPriceInputChange={(val) => {
          const cleanNumber = val.replace(/[^0-9.]/g, '');
          const strAfterDot = cleanNumber.split('.', 2)[1];
          if (!strAfterDot || strAfterDot.length <= 2) {
            setaddTicketValues((addTicketvalues) => ({
              ...addTicketvalues,
              price: val
            }));
            priceCalc(val, true);
          }
        }}
        setFeeToggleChecked={setFeeToggleChecked}
        calPrice={calPrice}
        feeSubtractedChange={(checked) => {
          // setaddTicketValues((addTicketvalues) => ({
          //   ...addTicketvalues,
          //   feeSubtraction: checked
          // }));
          setFeeToggleChecked(checked);
        }}
        feeSubtractedSwitch={() => {
          setaddTicketValues((addTicketvalues) => ({
            ...addTicketvalues,
            feeSubtraction: !addTicketvalues.feeSubtraction
          }));
        }}
        availabilityDropdown={availability}
        availabilityDropdownToggle={() => setAvailability(!availability)}
        availability={availabilityData}
        setAvailabilityData={(item) => {
          setaddTicketValues((addTicketvalues) => ({
            ...addTicketvalues,
            availability: item
          }));
          if (item.id == 'endOfSaleOf') {
            setaddTicketValues((addTicketvalues) => ({
              ...addTicketvalues,
              startDate: '',
              startTime: '',
              endDate: '',
              endTime: ''
            }));
          }
          if (item.id == 'dateAndTime') {
            setaddTicketValues((addTicketvalues) => ({
              ...addTicketvalues,
              endOfSaleOf: '',
              startDate: todayDate(),
              startTime: currentTime(),
              endDate: eventFormData.endDate,
              endTime: eventFormData.endTime
            }));
          }
          setAvailability(false);
        }}
        onStartDateInputChange={(val) =>
          setaddTicketValues((addTicketvalues) => ({
            ...addTicketvalues,
            startDate: val
          }))
        }
        onStartTimeInputChange={(val) =>
          setaddTicketValues((addTicketvalues) => ({
            ...addTicketvalues,
            startTime: val
          }))
        }
        onEndDateInputChange={(val) =>
          setaddTicketValues((addTicketvalues) => ({
            ...addTicketvalues,
            endDate: val
          }))
        }
        onEndTimeInputChange={(val) =>
          setaddTicketValues((addTicketvalues) => ({
            ...addTicketvalues,
            endTime: val
          }))
        }
        selectTicketDropdown={selectTicket}
        selectTicketDropdownToggle={() => setSelectTicket(!selectTicket)}
        selectTicket={
          ticketListData &&
          ticketListData?.map((item) => ({
            label: `${item.name}`,
            id: item.id
          }))
        }
        setSelectTicketData={(item) => {
          setaddTicketValues((addTicketvalues) => ({
            ...addTicketvalues,
            endOfSaleOf: item
          }));
          setaddTicketValues((addTicketvalues) => ({
            ...addTicketvalues,
            startDate: '',
            startTime: ''
          }));
        }}
        briefMaxLength={150}
        onticketDescriptionInputChange={(val) => {
          setaddTicketValues((addTicketvalues) => ({
            ...addTicketvalues,
            description: val
          }));
        }}
        visibilityDropdown={visibility}
        visibilityDropdownToggle={() => setVisibility(!visibility)}
        visibility={visibilityData}
        visibilityData={(item) => {
          setaddTicketValues((addTicketvalues) => ({
            ...addTicketvalues,
            visibility: item
          }));
          setVisibility(false);
        }}
        onMinTicketsInputChange={(val) =>
          setaddTicketValues((addTicketvalues) => ({
            ...addTicketvalues,
            minTicket: val
          }))
        }
        onMaxTicketsInputChange={(val) =>
          setaddTicketValues((addTicketvalues) => ({
            ...addTicketvalues,
            maxTicket: val
          }))
        }
        btnSave={handleAddTicketSubmit}
      />

      {confirmDialogData && (
        <ConfirmDialog
          showDialog={confirmDialogData.show}
          title={confirmDialogData.title}
          message={confirmDialogData.message}
          saveBtnHide={confirmDialogData.saveBtnHide}
          onDialogClose={() => {
            setConfirmDialogData((confirmDialogData) => ({
              ...confirmDialogData,
              show: false
            }));
          }}
          btnConfirm={() => {
            if (confirmDialogData.type === 'Ticket') {
              deleteTicketById(confirmDialogData.item);
              setConfirmDialogData((confirmDialogData) => ({
                ...confirmDialogData,
                show: false
              }));
            } else if (confirmDialogData.type === 'Group') {
              deleteGroupById(confirmDialogData.item);
              setConfirmDialogData((confirmDialogData) => ({
                ...confirmDialogData,
                show: false
              }));
            }
          }}
        />
      )}
    </>
  );
};

export default React.memo(TabTickets);
