import { createSlice } from '@reduxjs/toolkit';
import api from '../../services';
import { API_RESPONSE, TESLOOMAPI } from '../../services/apiConstant';
import { toast } from 'react-toastify';

const slice = createSlice({
  name: 'order',
  initialState: {
    message: '',
    getOrganiserOrdersData: null,
    ticketData: null,
    orderData: null,
    userData: null,
    userTaxInfo: null,
    userPayoutData: null,
    salesReportData: [],
    attendeeReportData: [],
    payoutData: null,
    reportAnalyticsData: null,
    eventVenueData: null,
    getCheckListData: [],
    ticketCheckData: [],
    paymentCheckData: [],
    VenuesCheckData: [],
    notesData: [],
    totalCount:0
  },
  reducers: {
    getOrganiserOrdersSuccess(state, action) {
      // state.getOrganiserOrdersData = action.payload;

      if (action.payload == null) {
        state.getOrganiserOrdersData = [];
        state.isFetching = false;
        state.isLoading = false;
      } else {
        state.getOrganiserOrdersData = action.payload.data;
        state.isFetching = false;
        state.isLoading = false;
        state.totalCount = action.payload ? action.payload.totalCount : 0;
      }
    },
    ticketDataSuccess(state, action) {
      state.ticketData = action.payload;
    },
    clearSearchTicketSuceess(state, action) {
      state.ticketData = action.payload;
    },
    getOrderDetailsSuccess(state, action) {
      state.orderData = action.payload;
      state.notesData = action.payload.notes;
    },
    getUserDataSuccess(state, action) {
      state.userData = action.payload;
    },
    eventActionSuccess(state, action) {
      const suspendIndex = state.userData?.findIndex(
        (item) => item._id === action.payload.userTicketListId
      );
      const data = {
        _id: action.payload.userTicketListId,
        isLike: !action.payload.status
      };
      state.userData[suspendIndex] = data;
    },
    getUserTaxInfoSuccess(state, action) {
      state.userTaxInfo = action.payload;
    },
    updateUserPayoutSuccess(state, action) {
      state.userPayoutData = action.payload;
    },
    getUserPayoutSuccess(state, action) {
      state.userPayoutData = action.payload;
    },
    salesReportSuccess(state, action) {
      // state.salesReportData = action.payload;
      if (action.payload == null) {
        state.salesReportData = [];
        state.isFetching = false;
        state.isLoading = false;
      } else {
        state.salesReportData = action.payload.data;
        state.isFetching = false;
        state.isLoading = false;
        state.totalCount = action.payload ? action.payload.totalCount : 0;
      }
    },
    attendeeReportSuccess(state, action) {
      // state.attendeeReportData = action.payload;
      if (action.payload == null) {
        state.attendeeReportData = [];
        state.isFetching = false;
        state.isLoading = false;
      } else {
        state.attendeeReportData = action.payload.data;
        state.isFetching = false;
        state.isLoading = false;
        state.totalCount = action.payload ? action.payload.totalCount : 0;
      }
    },
    getOrganiserPayoutsSuccess(state, action) {
      // state.payoutData = action.payload;
      if (action.payload == null) {
        state.payoutData = [];
        state.isFetching = false;
        state.isLoading = false;
      } else {
        state.payoutData = action.payload.data;
        state.isFetching = false;
        state.isLoading = false;
        state.totalCount = action.payload ? action.payload.totalCount : 0;
      }
    },
    reportAnalyticsSuccess(state, action) {
      state.reportAnalyticsData = action.payload;
    },
    eventVenueSuccess(state, action) {
      state.eventVenueData = action.payload;
    },
    addCheckListSuccess(state, action) {
      const found = state.getCheckListData.findIndex((element) => element === action.payload);
      if (found < 0) {
        state.getCheckListData = state.getCheckListData.concat(action.payload);
      } else {
        state.getCheckListData = state.getCheckListData.filter((item) => item !== action.payload);
      }
    },
    allCheckListSuccess(state, action) {
      const found = state.getCheckListData.findIndex((element) => element === action.payload);
      if (found < 0) {
        state.getCheckListData = state.getCheckListData.concat(action.payload);
      }
    },
    clearCheckListSuccess(state) {
      state.getCheckListData = [];
    },
    onTicketChangeSuccess(state, action) {
      if (action.payload) {
        const found = state.ticketCheckData.findIndex((element) => element === action.payload);
        if (found < 0) {
          state.ticketCheckData = state.ticketCheckData.concat(action.payload);
        } else {
          state.ticketCheckData = state.ticketCheckData.filter((item) => item !== action.payload);
        }
      } else {
        state.ticketCheckData = [];
      }
    },
    onPaymentChangeSuccess(state, action) {
      if (action.payload) {
        const found = state.paymentCheckData.findIndex((element) => element === action.payload);
        if (found < 0) {
          state.paymentCheckData = state.paymentCheckData.concat(action.payload);
        } else {
          state.paymentCheckData = state.paymentCheckData.filter((item) => item !== action.payload);
        }
      } else {
        state.paymentCheckData = [];
      }
    },
    onVenuesChangeSuccess(state, action) {
      if (action.payload) {
        const found = state.VenuesCheckData.findIndex((element) => element === action.payload);
        if (found < 0) {
          state.VenuesCheckData = state.VenuesCheckData.concat(action.payload);
        } else {
          state.VenuesCheckData = state.VenuesCheckData.filter((item) => item !== action.payload);
        }
      } else {
        state.VenuesCheckData = [];
      }
    },
    clearTicketChangeSuccess(state) {
      state.ticketCheckData = [];
    },
    clearPaymentChangeSuccess(state) {
      state.paymentCheckData = [];
    },
    clearVenuesChangeSuccess(state) {
      state.VenuesCheckData = [];
    },
    addNotesInOrderSuccess(state, action) {
      state.notesData = action.payload.notes;
    },
    clearStatesDataSuccess(state) {
      state.getOrganiserOrdersData = null;
    }
  }
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

const {
  getOrganiserOrdersSuccess,
  ticketDataSuccess,
  clearSearchTicketSuceess,
  getOrderDetailsSuccess,
  getUserDataSuccess,
  eventActionSuccess,
  getUserTaxInfoSuccess,
  updateUserPayoutSuccess,
  getUserPayoutSuccess,
  salesReportSuccess,
  attendeeReportSuccess,
  getOrganiserPayoutsSuccess,
  reportAnalyticsSuccess,
  eventVenueSuccess,
  addCheckListSuccess,
  allCheckListSuccess,
  clearCheckListSuccess,
  onTicketChangeSuccess,
  onPaymentChangeSuccess,
  onVenuesChangeSuccess,
  clearTicketChangeSuccess,
  clearPaymentChangeSuccess,
  clearVenuesChangeSuccess,
  addNotesInOrderSuccess,
  clearStatesDataSuccess
} = slice.actions;

export const getOrganiserOrders = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.getOrganiserOrders}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status == API_RESPONSE.SUCCESS) {
        // dispatch(getOrganiserOrdersSuccess(result.data));
        dispatch(
          getOrganiserOrdersSuccess({
            data: result.data,
            page: requestParams.page,
            totalCount: result.totalCount
          })
        );
      } else {
        dispatch(getOrganiserOrdersSuccess(null));
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const searchTicket = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.searchTicket}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(ticketDataSuccess(result.data));
      } else {
        dispatch(ticketDataSuccess(null));
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const clearSearchTicket = (data) => async (dispatch) => {
  dispatch(clearSearchTicketSuceess(data));
};

export const getOrderDetails = (id) => async (dispatch) => {
  api
    .put(`${TESLOOMAPI.getOrderDetails}` + id)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getOrderDetailsSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const getUserData = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.getUserData}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getUserDataSuccess(result.data));
      } else {
        toast.error(result.message);
        dispatch(getUserDataSuccess(null));
      }
    })
    .catch(() => {});
};

export const eventAction = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.eventAction}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(eventActionSuccess(requestParams));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const saveUserTaxInfo = (requestParams, navigate) => async () => {
  api
    .post(`${TESLOOMAPI.saveUserTaxInfo}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message);
        navigate('/finance');
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const getUserTaxInfo = () => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.getUserTaxInfo}`)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getUserTaxInfoSuccess(result.data));
      } else {
        // toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const updateUserPayout = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.updateUserPayout}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message);
        dispatch(updateUserPayoutSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const getUserPayout = () => async (dispatch) => {
  api
    .get(`${TESLOOMAPI.getUserPayout}`)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getUserPayoutSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const salesReport = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.salesReport}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        // dispatch(salesReportSuccess(result.data));
        dispatch(
          salesReportSuccess({
            data: result.data,
            page: requestParams.page,
            totalCount: result.totalCount
          })
        );
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const attendeeReport = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.attendeeReport}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        // dispatch(attendeeReportSuccess(result.data));
        dispatch(
          attendeeReportSuccess({
            data: result.data,
            page: requestParams.page,
            totalCount: result.totalCount
          })
        );
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const getOrganiserPayouts = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.getOrganiserPayouts}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        // dispatch(getOrganiserPayoutsSuccess(result.data));
        dispatch(
          getOrganiserPayoutsSuccess({
            data: result.data,
            page: requestParams.page,
            totalCount: result.totalCount
          })
        );
      } else {
        // toast.error(result.message);
        console.log(result.message);
      }
    })
    .catch(() => {});
};

export const reportAnalytics = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.reportAnalytics}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(reportAnalyticsSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const getUserEventVenue = () => async (dispatch) => {
  api
    .get(`${TESLOOMAPI.getUserEventVenue}`)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(eventVenueSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const addCheckListData = (data) => async (dispatch) => {
  dispatch(addCheckListSuccess(data));
};

export const addAllCheckList = (data) => async (dispatch) => {
  dispatch(allCheckListSuccess(data));
};

export const clearAllCheckList = () => async (dispatch) => {
  dispatch(clearCheckListSuccess());
};

export const onTicketChange = (data) => async (dispatch) => {
  dispatch(onTicketChangeSuccess(data));
};

export const onPaymentChange = (data) => async (dispatch) => {
  dispatch(onPaymentChangeSuccess(data));
};

export const onVenuesChange = (data) => async (dispatch) => {
  dispatch(onVenuesChangeSuccess(data));
};

export const clearTicketChange = () => async (dispatch) => {
  dispatch(clearTicketChangeSuccess());
};

export const clearPaymentChange = () => async (dispatch) => {
  dispatch(clearPaymentChangeSuccess());
};

export const clearVenuesChange = () => async (dispatch) => {
  dispatch(clearVenuesChangeSuccess());
};

export const addNotesInOrder = (requestParams) => async (dispatch) => {
  api
    .post(`${TESLOOMAPI.addNotesInOrder}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(addNotesInOrderSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const refundUserByOrderId = (requestParams) => async () => {
  api
    .post(`${TESLOOMAPI.refundUserByOrderId}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const transferTickets = (requestParams) => async () => {
  api
    .post(`${TESLOOMAPI.transferTickets}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const sendEmailOrNotificationToOrder = (requestParams) => async () => {
  api
    .post(`${TESLOOMAPI.sendEmailOrNotificationToOrder}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const previewOrderPdf = (requestParams) => async () => {
  api
    .post(`${TESLOOMAPI.previewOrderPdf}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        window.open(result.data, '_blank');
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const clearOrderStates = () => async (dispatch) => {
  dispatch(clearStatesDataSuccess());
};
