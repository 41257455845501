import React from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';

const WeMap = ({ ...props }) => {
  const { innerRef, mapData } = props;

  return (
    <ReactMapGL
      ref={innerRef}
      {...mapData}
      mapboxAccessToken={process.env.REACT_APP_MAP_TOKEN}
      initialViewState={mapData}
      onViewportChange={mapData}
      mapStyle="mapbox://styles/mapbox/streets-v11">
      <Marker longitude={mapData.longitude} latitude={mapData.latitude} />
    </ReactMapGL>
  );
};
export default React.memo(WeMap);
